import { IsArray, IsNumber, IsObject, IsString, IsUUID, ValidateNested } from "class-validator";
import { AssessmentTypeCode } from "../../../common/codes.types";

export class GetCurrentAssessmentResultsResult {
    @IsUUID()
    public assessmentId: string;

    @IsString()
    assessmentTypeCode: AssessmentTypeCode

    @IsObject()
    @ValidateNested()
    overallScore: GetCurrentAssessmentResultsResult_Result;

    @IsArray()
    @ValidateNested()
    sections: GetCurrentAssessmentResultsResult_Section[]

    @IsArray()
    @ValidateNested()
    supportRequests: GetCurrentAssessmentResultsResult_SupportRequest[]
}

export class GetCurrentAssessmentResultsResult_Section {
    @IsUUID()
    sectionId: string
    
    @IsString()
    sectionName: string

    @IsObject()
    @ValidateNested()
    score: GetCurrentAssessmentResultsResult_Result
}

export class GetCurrentAssessmentResultsResult_Result {
    @IsNumber()
    resultScore: number;

    @IsString()
    resultText: string;

    @IsString()
    resultDetails: string;
}

export class GetCurrentAssessmentResultsResult_SupportRequest {
    @IsUUID()
    supportRequestId: string;
}