import { FC } from "react";
import { useParams } from "react-router";
import { AdminUser } from "../../../../../modules/admin/components/screens/admin-user/admin-user";

export const AdminUserRoute: FC = () => {

    // router hooks
    const { user_id } = useParams() as { user_id: string }

    return (
        <AdminUser
            userId={user_id}
        />
    )
}