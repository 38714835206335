import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { PrimaryButton } from "../../../../../../../common/components/buttons/buttons";
import { ArrowRightIcon } from "../../../../../../../common/components/icons/arrow-right-icon/arrow-right-icon";
import { LoadingSpinner } from "../../../../../../../common/components/loading-spinner/loading-spinner";
import { AppColors } from "../../../../../../../common/constants/colors/app-colors";
import { buildSectionUrl } from "../../section-questions.utils";

type NextSectionButtonProps = {
    nextSectionId: string | null
    shouldSave: boolean

    isSaving: boolean
    onSave: () => Promise<void>
}

export const NextSectionButton: FC<NextSectionButtonProps> = ({
    nextSectionId,
    shouldSave,
    isSaving,
    onSave,
}) => {

    // router hooks
    const location = useLocation()
    const navigate = useNavigate()

    // constants
    const isDisabled = isSaving;

    // event handlers
    const handleClick = useCallback(async () => {
        if (shouldSave) await onSave();

        if (!nextSectionId) {
            navigate('/assessment')
            return;
        }

        const sectionUrl = buildSectionUrl(location.pathname, nextSectionId ?? '')
        navigate(sectionUrl)
    }, [location.pathname, navigate, nextSectionId, onSave, shouldSave])

    return (
        <PrimaryButton
            disabled={isDisabled}
            onClick={handleClick}
        >
            {isSaving ? 'Saving...' : 'Next'}
            {isSaving ? (
                <LoadingSpinner
                    size={14}
                    color={AppColors.text.onDarkBackground}
                />
            ) : (
                <ArrowRightIcon
                    size={14}
                    color={AppColors.text.onDarkBackground}
                />
            )}
        </PrimaryButton>
    )
}