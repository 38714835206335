import styled from "@emotion/styled";
import { AppColors } from "../common/constants/colors/app-colors";
import { Gap, Padding } from "../common/components/sizes/sizes";

const VERTICAL_FORM_BREAKPOINT = '@media (max-width: 1000px)';

export const AuthenticationFormContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.large,

    // breakpoints
    [VERTICAL_FORM_BREAKPOINT]: {
        gap: Gap.desktop.large * 0.75,
    },
})

export const AuthenticationFormTitle = styled.h1({
    margin: 0,
})

export const AuthenticationFormSubTitle = styled.span({
    color: AppColors.text.subtitle.onLightBackground,
    fontSize: 16,
})

export const AuthenticationForm = styled.form({
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.large,
})

export const AuthenticationFormFooter = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    gap: Gap.desktop.normal,

    // padding
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,
})

export const AuthenticationFooterMessage = styled.div<{
    type: 'info' | 'error'
}>(({ type }) => ({
    // font
    color: type === 'info' ? AppColors.text.blue.primary : AppColors.text.red.primary,
}))

export const LinkContainer = styled.div()