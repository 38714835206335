import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";
import { Gap } from "../sizes/sizes";

export const InlineLoadingContainer = styled.div<{
    color: string | undefined
}>(({ color }) => ({
    // layout
    display: 'grid',
    alignItems: 'center',
    gap: Gap.desktop.normal,
    gridAutoFlow: 'column',
    gridTemplateColumns: '12px 1fr',

    // text
    fontStyle: 'italic',
    color: color ?? AppColors.text.blue.primary,
    fontSize: 16,
}))

export const SpinnerContainer = styled.div({
    paddingTop: 5,
})

export const Tip = styled.span()