import styled from "@emotion/styled";
import { Gap, Padding } from "../../../../common/components/sizes/sizes";
import { AppColors } from "../../../../common/constants/colors/app-colors";

export const AccountDetailsForm = styled.form({
    display: 'grid',
    gap: Gap.desktop.large,
})

export const FormItems = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gap: Gap.desktop.normal,
})

export const FooterContainer = styled.div({
    // layout
    display: 'grid',
    justifyContent: 'end',
    borderTop: `1px solid ${AppColors.border.default}`,

    // padding
    paddingTop: Padding.desktop.normal,
})