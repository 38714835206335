import { DateTime } from "luxon";
import { CellProps, Renderer } from "react-table";
import { GetAdminAssessmentsResult_Assessment } from "tech-health-assessment-sdk/dist";
import { DateCell } from "../../../../common/grid/cell-renderers/date-cell";
import { LinkCell } from "../../../../common/grid/cell-renderers/link-cell";
import { PercentageCell } from "../../../../common/grid/cell-renderers/percentage-cell";
import { GridColumnDefinition } from "../../../../common/grid/grid.types";
import { AssessmentStatusCell } from "./subcomponents/assessment-status-cell/assessment-status-cell";

export const columns = (onRefresh: () => void): GridColumnDefinition<GetAdminAssessmentsResult_Assessment>[] => ([
    {
        Header: 'Company Name',
        accessor: 'account.companyName',
        minWidth: 120,
    },
    {
        id: 'sections',
        Header: 'Sections',
        accessor: 'account.accountId',
        Cell: LinkCell({
            link: ({ row }) => (`/admin/assessments/${row.original.assessmentId}`),
            text: 'See Sections',
        }),
    },
    {
        id: 'results',
        Header: 'Results',
        accessor: 'account.accountId',
        Cell: LinkCell({
            link: ({ row }) => (`/admin/assessments/${row.original.assessmentId}/results`),
            text: 'See Results',
        }),
    },
    {
        Header: 'Assessment Type',
        accessor: 'assessmentType.label',
    },
    {
        Header: 'Assessment Status',
        accessor: 'assessmentStatus',
        Cell: AssessmentStatusCell(onRefresh) as Renderer<CellProps<GetAdminAssessmentsResult_Assessment, unknown>>,
        minWidth: 180,
    },
    {
        Header: 'Completion Percentage',
        accessor: 'completionPercentage',
        Cell: PercentageCell(),
    },
    {
        Header: 'Workspace Link',
        accessor: 'workspaceLink',
        Cell: LinkCell({
            link: ({ value }) => value as string,
            text: ({ value }) => value ? 'Open Workspace' : '',
            type: 'anchor',
        })
    },
    {
        Header: 'Smartsheet Link',
        accessor: 'smartsheetLink',
        Cell: LinkCell({
            link: ({ value }) => value as string,
            text: 'Open Smartsheet',
            type: 'anchor',
        })
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: DateCell({
            datetimeFormatOptions: DateTime.DATETIME_MED,
        }),
    },
])