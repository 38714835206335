import {
  IsArray,
  IsNotEmpty,
  IsNumber,
  IsString,
  IsUUID,
  ValidateIf,
  ValidateNested,
} from 'class-validator';
import { AssessmentTypeCode, AssessmentStatusCode } from '../../../common/codes.types';

export class GetAssessmentResult {
  @IsUUID()
  @IsNotEmpty()
  public id: string;

  @IsString()
  @IsNotEmpty()
  public assessmentType: AssessmentTypeCode;

  @IsString()
  @IsNotEmpty()
  public assessmentStatus: AssessmentStatusCode;

  @IsArray()
  @ValidateNested()
  public sections: GetAssessmentResult_Section[];

  @IsUUID()
  @ValidateIf((object, value) => value !== null)
  public currentSectionId: string | null;
}

export class GetAssessmentResult_Section {
  @IsUUID()
  @IsNotEmpty()
  public id: string;

  @IsString()
  @IsNotEmpty()
  public name: string;

  @IsString()
  @IsNotEmpty()
  public iconSvg: string;

  @IsNumber()
  public completionPercentage: number;
}
