import { FC, useCallback, useContext, useState } from "react";
import { AdminUpdateAssessmentStatusResult, AssessmentStatusCode } from "tech-health-assessment-sdk/dist";
import { CardFooter, CardHeader } from "../../../../../../assessments/components/assessment-overview/subcomponents/assessment-complete-card/assessment-complete-card.styled";
import { PrimaryButton, SecondaryButton, WarnButton } from "../../../../../../common/components/buttons/buttons";
import { CalloutCardLoadingState } from "../../../../../../common/components/callout-card/callout-card";
import { Dialog, DialogProps } from "../../../../../../common/components/dialog/dialog";
import { DialogCard, DialogFooter, DialogHeader, DialogTitle } from "../../../../../../common/components/dialog/dialog.styled";
import { LoadingSpinner } from "../../../../../../common/components/loading-spinner/loading-spinner";
import { SnackbarContext } from "../../../../../../common/components/snackbar-provider/snackbar-provider";
import { InnerContainer } from "../../../../../../common/components/standard-card-header/standard-card-header.styled";
import { StandardDialogInnerContainer } from "../../../../../../common/components/standard-dialog-inner-container/standard-dialog-inner-container";
import { AppColors } from "../../../../../../common/constants/colors/app-colors";
import { ButtonSkeleton, ParagraphSkeleton, TitleSkeleton } from "../../../../../../common/skeletons/skeletons";
import { service } from "./change-status-dialog.service";
import { InProgressCalloutCard } from "./subcomponents/in-progress-callout-card/in-progress-callout-card";
import { ScoringCalloutCard } from "./subcomponents/scoring-callout-card/scoring-callout-card";
import { SetupRequiredCalloutCard } from "./subcomponents/setup-required-callout-card/setup-required-callout-card";
import { SubmittedCalloutCard } from "./subcomponents/submitted-callout-card/submitted-callout-card";

// TODO: clean up imports (we're breaking rules of co-location)

type ChangeStatusDialogProps = {
    assessmentId: string
    targetStatusOption: { value: AssessmentStatusCode, label: string } | null
    onRefresh: () => void
} & Omit<DialogProps, 'children'>

export const ChangeStatusDialog: FC<ChangeStatusDialogProps> = ({
    assessmentId,
    targetStatusOption,
    isOpen,
    onClose,
    onRefresh,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleChangeStatusClick = useCallback(async () => {
        if (!targetStatusOption?.value) return;

        setIsLoading(true)

        let result: AdminUpdateAssessmentStatusResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.updateAssessmentStatus(assessmentId, { assessmentStatusCode: targetStatusOption.value })
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully updated assessment status',
            })
        }

        setIsLoading(false)
        onClose()
        onRefresh()
    }, [assessmentId, onClose, onRefresh, showSnackbar, targetStatusOption?.value])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <DialogCard isOpen={isOpen}>
                <StandardDialogInnerContainer>
                    <DialogHeader>
                        <DialogTitle>
                            Change Status to "{targetStatusOption?.label}"?
                        </DialogTitle>
                    </DialogHeader>
                    {targetStatusOption?.value === 'in_progress' && (
                        <InProgressCalloutCard />
                    )}
                    {targetStatusOption?.value === 'scoring' && (
                        <ScoringCalloutCard />
                    )}
                    {targetStatusOption?.value === 'setup_required' && (
                        <SetupRequiredCalloutCard />
                    )}
                    {targetStatusOption?.value === 'submitted' && (
                        <SubmittedCalloutCard />
                    )}
                    {!targetStatusOption && (
                        <CalloutCardLoadingState>
                            <InnerContainer>
                                <CardHeader>
                                    <TitleSkeleton background="dark" />
                                    <ParagraphSkeleton background="dark" />
                                </CardHeader>
                                <CardFooter>
                                    <ButtonSkeleton background="dark" />
                                </CardFooter>
                            </InnerContainer>
                        </CalloutCardLoadingState>
                    )}
                    <DialogFooter>
                        <SecondaryButton
                            disabled={isLoading}
                            onClick={onClose}
                        >
                            Cancel
                        </SecondaryButton>
                        {['setup_required', 'scoring'].includes(targetStatusOption?.value ?? '') && (
                            <WarnButton
                                disabled={isLoading}
                                onClick={handleChangeStatusClick}
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Change Status
                            </WarnButton>
                        )}
                        {['in_progress', 'submitted'].includes(targetStatusOption?.value ?? '') && (
                            <PrimaryButton
                                disabled={isLoading}
                                onClick={handleChangeStatusClick}
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Change Status
                            </PrimaryButton>
                        )}
                    </DialogFooter>
                </StandardDialogInnerContainer>
            </DialogCard>
        </Dialog>
    )
}