import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString, IsUUID, ValidateIf } from "class-validator";
import { UserTypeCode } from "../../../../common/codes.types";

export class AdminCreateUserPayload {
    @IsString()
    @IsNotEmpty()
    userTypeCode: UserTypeCode;

    @IsUUID()
    @ValidateIf((object: AdminCreateUserPayload) => object.userTypeCode === 'external')
    accountId?: string;

    @IsString()
    @IsNotEmpty()
    firstName: string;
  
    @IsString()
    @IsNotEmpty()
    lastName: string;
  
    @IsString()
    @IsEmail()
    emailAddress: string;
  
    @IsString()
    @IsPhoneNumber()
    phoneNumber: string;
  
    @IsString()
    @IsNotEmpty()
    jobTitle: string;
}