import { FC, useMemo } from "react";
import { GetAdminUsersResult_User, PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { StandardCardGridContainer } from "../../../../common/components/standard-card-grid-container/standard-card-grid-container";
import { StandardCardHeader, StandardCardHeaderLoadingState } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { Grid, GridLoadingState } from "../../../../common/grid/grid";
import { columns } from "./admin-users-grid-card.columns";
import { CreateUserButton } from "./subcomponents/create-user-button/create-user-button";

type AdminUsersGridCardProps = {
    accountId: string | null
    users: GetAdminUsersResult_User[]
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
    totalCount: number
}

export const AdminUsersGridCard: FC<AdminUsersGridCardProps> = ({
    accountId,
    users,
    pagination,
    onPaginationChange,
    totalCount,
}) => {

    // constants
    const memoizedColumns = useMemo(() => (columns), [])

    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeader
                    title="Users"
                    subtitle="Manage users and reset passwords"
                    actions={(
                        <CreateUserButton
                            accountId={accountId}
                        />
                    )}
                />
                <StandardCardGridContainer>
                    <Grid
                        columns={memoizedColumns}
                        rows={users}
                        pagination={pagination}
                        onPaginationChange={onPaginationChange}
                        totalCount={totalCount}
                    />
                </StandardCardGridContainer>
            </StandardCardLayout>
        </BodyCard>
    )
}

export const AdminUsersGridCardLoadingState: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeaderLoadingState
                    hasSubtitle={true}
                />
                <GridLoadingState />
            </StandardCardLayout>
        </BodyCard>
    )
}