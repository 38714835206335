export const AppColors = {
  text: {
    onDarkBackground: 'white',
    onLightBackground: '#4B4B4B',
    blue: {
      primary: '#28B6FE',
      dark: '#253047',
      superDark: '#0E0E0E',
    },
    red: {
      primary: '#FF5047',
    },
    green: {
      primary: '#90C607',
    },
    gray: '#878787',
    subtitle: {
      onLightBackground: '#4B4B4B',
    },
  },
  background: {
    light: {
      white: 'white',
      hover: '#F3F3F3',
    },
    blue: {
      light: '#ebf8ff',
      primary: '#28B6FE',
    },
    orange: {
      primary: '#FF8E03',
    },
    green: {
      primary: '#90C607',
    },
    red: {
      primary: '#FF5047',
    },
    dark: '#253047',
    superDark: '#0E0E0E',
    gray: {
      primary: '#D8D8D8',
      dark: '#696969',
    }
  },
  buttons: {
    primary: {
      background: '#28B6FE',
      text: 'white',
      hover: '#1aa4eb',
      active: '#1698db',
    },
    secondary: {
      background: '#878787',
      text: 'white',
      hover: '#787777',
      active: '#636363',
    },
    warn: {
      background: '#FF8E03',
      text: 'white',
      hover: '#e68105',
      active: '#cc7204',
    },
    danger: {
      background: '#FF5047',
      text: 'white',
      hover: '#ed463e',
      active: '#d63d36',
    },
    primaryOutline: {
      background: 'white',
      text: '#28B6FE',
      hover: '#ebf8ff',
      border: '#28B6FE',
    },
    secondaryOutline: {
      background: 'white',
      text: '#4B4B4B',
      hover: '#f0f0f0',
      border: '#878787',
      active: '#e6e6e6'
    },
    warnOutline: {
      background: 'white',
      text: '#FF8E03',
      hover: '#fff9f2',
      border: '#FF8E03',
      active: '#fff5e8'
    },
    secondaryGhost: {
      text: '#878787',
      hover: '#f0f0f0',
      active: '#d9d9d9',
    },
  },
  border: {
    default: '#F3F3F3',
    darker: '#BDBDBD',
    blue: {
      primary: '#28B6FE',
      dark: '#253047',
      superDark: '#0E0E0E',
    },
  },
  notifications: {
    blue: {
      background: {
        primary: 'white',
        hover: '#f1fafe',
        active: '#d8f2fe'
      },
      text: '#28B6FE'
    },
    orange: {
      background: {
        primary: 'white',
        hover: '#fff9f2',
        active: '#ffedd9'
      },
      text: '#FF8E03'
    },
    green: {
      background: {
        primary: 'white',
        hover: '#fcfff2',
        active: '#f4ffd9'
      },
      text: '#90C607'
    },
    red: {
      background: {
        primary: 'white',
        hover: '#fff3f2',
        active: '#ffd9d9'
      },
      text: '#FF5047'
    },
  },
  dialog: {
    backdrop: {
      open: 'rgba(14, 14, 14, 0.75)',
      closed: 'transparent',
    },
  }
};
