import { FC } from 'react';
import { LandingBottomSection } from './landing-bottom-section';
import { LandingContainer } from './landing.styled';
import { LandingFooterSection } from './landing-footer-section';
import { LandingMiddleSection } from './landing-middle-section';
import { LandingTopSection } from './landing-top-section';

export const Landing: FC = () => {
  return (
    <LandingContainer>
      <LandingTopSection />
      <LandingMiddleSection />
      <LandingBottomSection />
      <LandingFooterSection />
    </LandingContainer>
  );
};
