import styled from "@emotion/styled";
import { Padding } from "../../../components/sizes/sizes";
import { AppColors } from "../../../constants/colors/app-colors";

export const GridContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',

    // size
    flex: 1,

    // size
    maxWidth: '100%',
})

export const TableContainer = styled.div({
    // size
    flex: 1,

    // overflow
    overflow: 'auto',
})

export const Table = styled.table({
    // size
    minWidth: '100%',
    height: 'max-content',
})

export const TableHead = styled.thead()

export const TableRow = styled.tr({
    '> td,th': {
        borderBottom: `1px solid ${AppColors.border.default}`,
    }
})

export const HeaderCell = styled.th({
    // text
    textAlign: 'left',

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.smaller,
    paddingBottom: Padding.desktop.smaller,

    // first child
    ':first-child': {
        paddingLeft: Padding.desktop.large,
    },

    // last child
    ':last-child': {
        paddingRight: Padding.desktop.large,
    },
})

export const TableBody = styled.tbody()

export const TableCell = styled.td({
    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.smaller,
    paddingBottom: Padding.desktop.smaller,

    // first child
    ':first-child': {
        paddingLeft: Padding.desktop.large,
    },

    // last child
    ':last-child': {
        paddingRight: Padding.desktop.large,
    },
})