import styled from "@emotion/styled";
import { Gap } from "../../../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints";

export const AuthenticationHeroContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.larger,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        gap: Gap.mobile.larger,
    },
})

export const HeroHeader = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.small,
})

export const HeroImage = styled.img({
    // size
    width: '100%',
    height: '100%',

    // object
    objectFit: 'contain',
    objectPosition: 'left top',

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        maxWidth: 380,
    },
})