import styled from "@emotion/styled";
import { BorderRadius } from "../../../sizes/sizes";

export const SnackbarCloseButtonContainer = styled.div({
    // layout
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',

    // size
    width: 36,
    height: 36,

    // border
    borderRadius: BorderRadius.small,

    // cursor
    cursor: 'pointer',

    // hover
    ':hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.16)',
    },

    // active
    ':active': {
        backgroundColor: 'rgba(255, 255, 255, 0.32)',
    },
})