import { FC } from "react";
import { AssessmentResults, AssessmentResultsLoadingState } from "../../../../assessment-results/components/assessment-results/assessment-results";
import { useAdminAssessmentResults } from "./admin-results.hooks";

type AdminResultsProps = {
    assessmentId: string
}

export const AdminResults: FC<AdminResultsProps> = ({
    assessmentId,
}) => {
    
    // data hooks
    const { isLoading, data: assessmentResults, refreshData } = useAdminAssessmentResults(assessmentId)

    if (isLoading || !assessmentResults) {
        return (
            <AssessmentResultsLoadingState />
        )
    }

    return (
        <AssessmentResults
            assessmentResults={assessmentResults}
            onRefreshData={refreshData}
        />
    )
}