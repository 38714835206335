import { FC } from "react";
import { useAssessmentSection } from "./assessment-section.hooks";
import { SectionQuestions, SectionQuestionsLoadingState } from "./subcomponents/section-questions/section-questions";

type AssessmentSectionProps = {
    sectionId: string
}

export const AssessmentSection: FC<AssessmentSectionProps> = ({
    sectionId,
}) => {

    // data hooks
    const { assessmentSection, isLoading } = useAssessmentSection(sectionId);

    if (!assessmentSection || isLoading) {
        return (
            <SectionQuestionsLoadingState />
        )
    }

    return (
        <SectionQuestions section={assessmentSection} />
    )
}