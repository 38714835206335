import styled from "@emotion/styled";
import { TABLET_BREAKPOINT, MOBILE_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints";
import { AppColors } from "../../../../../common/constants/colors/app-colors";

export const FooterContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
})

export const FooterContent = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 24,
    gridTemplateColumns: '.6fr .4fr',
    justifyContent: 'space-between',

    // colors
    backgroundColor: AppColors.background.dark,
    color: AppColors.text.onDarkBackground,

    // padding
    padding: 60,

    // breakpoints
    [TABLET_BREAKPOINT]: {
        gridTemplateColumns: '.5fr .5fr',
    },

    [MOBILE_BREAKPOINT]: {
        // layout
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'row',
        gridAutoRows: 'max-content',
        gap: 20,

        // padding
        padding: 20,
    }
})

export const BrandContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: 32,

    // size
    maxWidth: 500,
})

export const LogoContainer = styled.div()

export const BrandDescription = styled.span({
    fontSize: 15,
})

export const ContactContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',

    // breakpoints
    [TABLET_BREAKPOINT]: {
        width: '50%',
    },
})

export const ContactUsTitle = styled.h3({
    // color
    color: AppColors.text.blue.primary,

    // margin
    margin: 0,
})

export const BrandCopyrightBar = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'row',

    // colors
    color: AppColors.text.onDarkBackground,
    backgroundColor: AppColors.background.superDark,

    // padding
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 20,
    paddingBottom: 20,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
})