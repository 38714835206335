import React, { FC } from 'react'
import { LoadingSpinner } from '../loading-spinner/loading-spinner'
import { InlineLoadingContainer, SpinnerContainer, Tip } from './inline-loading.styled'

type InlineLoadingProps = {
	children: JSX.Element | string
	color?: string
}

export const InlineLoading: FC<InlineLoadingProps> = ({
	children,
	color,
}) => {
	return (
		<InlineLoadingContainer color={color}>
			<SpinnerContainer>
				<LoadingSpinner
					size={18}
					color={color}
				/>
			</SpinnerContainer>
			<Tip>{children}</Tip>
		</InlineLoadingContainer>
	)
}