import { createContext, FC } from "react";
import { GetSelfResult } from "tech-health-assessment-sdk/dist/modules/self";
import { BodyLoading } from "../../../common/components/body-loading/body-loading";
import { useSelf } from "./self-provider.hooks";

type SelfProviderProps = {
    children: JSX.Element
}

export const SelfProvider: FC<SelfProviderProps> = ({
    children,
}) => {

    // state
    const { isLoading, data: self } = useSelf();

    if (isLoading || !self) {
        return (
            <BodyLoading />
        )
    }

    return (
        <SelfContext.Provider value={{
            self,
        }}>
            {children}
        </SelfContext.Provider>
    )
}

type SelfContextType = {
    self: GetSelfResult,
}

export const SelfContext = createContext<SelfContextType>({
    self: null as unknown as GetSelfResult, // this should never happen
})