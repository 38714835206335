import {
  CTAHeader,
  CTALink,
  CenteredContainer,
  HeroMessage,
  HeroMessageContainer,
  HeroSection,
  HeroTimeSection,
  HeroTitle,
  LinkContainer,
} from './landing.styled';
import { FC, useCallback } from 'react';

import ReactGA from 'react-ga4';

export const LandingTopSection: FC = () => {
  const onCTAClick = useCallback(() => {
    ReactGA.event({
      category: 'Link Click',
      action: 'You want to get a Your sample scope and find out more?!',
      label: 'Click here',
    });
  }, []);

  return (
    <HeroSection>
      <CenteredContainer>
        <CTAHeader>
          To find out more and get a Sample Assessment Score
        </CTAHeader>
        <LinkContainer>
          <CTALink
            data-testid="get-your-sample-link"
            to="/sign-up"
            onClick={onCTAClick}
          >
            Click here
          </CTALink>
        </LinkContainer>
      </CenteredContainer>
      <HeroTitle>
        AcquireTek Technology <br />
        Health Assessment
      </HeroTitle>
      <HeroMessageContainer>
        <HeroMessage>
          <p>Get a 3-week fixed cost assessment with board-ready dashboards that 
          identifies where your technology is advanced, intermediate, and rudimentary.</p>
          <br></br>
          <p>Why AcquireTek?</p>
          <ul>
            <li>AcquireTek works with companies with revenues of 50M to 1B+</li>
            <li>We’ve learned and built an engine of 400+ best practices, that we rightsized across 7 domains of Technology.</li>
            <li>We provide 3rd Party validation of the health of your Technology Department from a firm with over 200 Conducted Assessments.</li>
          </ul>
        </HeroMessage>
        <HeroTimeSection>
          <p>3 Weeks</p>
          <p>2 - 4 SMEs with Former CIO Oversight</p>
          <p>Board-ready Executive Reports</p>
        </HeroTimeSection>
      </HeroMessageContainer>
    </HeroSection>
  );
};
