import { FC, useCallback, useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router";
import { GetSectionResult, GetSectionResult_Question } from "tech-health-assessment-sdk/dist";
import { SaveContext } from "../../../../../app/providers/save-provider/save-provider";
import { BodyCard } from "../../../../../common/components/body-card/body-card";
import { GhostSecondaryButton } from "../../../../../common/components/buttons/buttons";
import { ButtonSkeleton, TitleSkeleton } from "../../../../../common/skeletons/skeletons";
import { QuestionsContainer, QuestionsFormTitle, SectionFooterContainer, SectionQuestionsContainer } from "./section-questions.styled";
import { PreviousSectionButton } from "./subcomponents/previous-section-button/previous-section-button";
import { QuestionsGridHeader, QuestionsGridHeaderLoadingState } from "./subcomponents/questions-grid-header/questions-grid-header";
import { QuestionsGridRow, QuestionsGridRowLoadingState } from "./subcomponents/questions-grid-row/questions-grid-row";
import service from "./section-questions.services";
import { NextSectionButton } from "./subcomponents/next-section-button/next-section-button";
import { SnackbarContext } from "../../../../../common/components/snackbar-provider/snackbar-provider";
import { THAP_ENVIRONMENT } from "../../../../../common/environment/environment-constants";
import { useBlockNavigation } from "../../../../../common/hooks/use-block-navigation/use-block-navigation";

type SectionQuestionsProps = {
    section: GetSectionResult,
    readOnly?: boolean
}

export const SectionQuestions: FC<SectionQuestionsProps> = ({
    section,
    readOnly,
}) => {

    // router hooks
    const location = useLocation()

    // context
    const {
        isSaving: contextIsSaving,
        onIsSaving: onIsContextSaving,
        addOnSaveListener: addContextOnSaveListener
    } = useContext(SaveContext)
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLocalSaving, setIsLocalSaving] = useState<boolean>(false);
    const [forceSave, setForceSave] = useState(false);

    // form state
    const {
        control,
        getValues,
        setValue,
        reset,
        formState: { isDirty },
        trigger,
    } = useForm({
        defaultValues: (
            Object.fromEntries(section.questions.map(question => ([
                question.id,
                question,
            ]))) as Record<string, GetSectionResult_Question>
        ),
    });

    // constants
    const isSaving = isLocalSaving || contextIsSaving;

    // block navigation
    useBlockNavigation(isDirty)

    // imperative logic
    const saveChanges = useCallback(async () => {
        // save state
        const clearSaving = onIsContextSaving()
        setIsLocalSaving(true)

        // save changes
        const values = getValues()
        try {
            await service.saveSection(section.id, {
                questions: Object.values(values).map(value => ({
                    questionId: value.id,
                    assessmentResponseValueId: value.selectedAssessmentResponseValueId,
                }))
            })

            // reset form state
            reset(values)

            showSnackbar({
                type: 'success',
                title: 'Changes Saved Successfully',
            })
        } catch {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support.'
            })
        }

        // clear save state
        setIsLocalSaving(false)
        clearSaving()
    }, [getValues, onIsContextSaving, reset, section.id, showSnackbar])

    // side effects
    useEffect(() => {
        const unsubscribe = addContextOnSaveListener(saveChanges)
        return () => {
            unsubscribe()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        window.scrollTo({ left: 0, top: 0 })
    }, [location.pathname])

    // event handlers
    const handleSave = useCallback(() => {
        return saveChanges()
    }, [saveChanges])

    const handleAutofillQuestionsClicked = useCallback(() => {
        const values = getValues()
        Object.entries(values).forEach(([questionId, value]) => {
            const randomValue = section.responseValues[Math.round(Math.random() * 2)]
            setValue(questionId, {
                ...value,
                selectedAssessmentResponseValueId: randomValue.id,
            })
        })
        trigger()
        setForceSave(true)
    }, [getValues, section.responseValues, setValue, trigger])

    return (
        <BodyCard style={{ flex: 1, position: 'relative' }}>
            <SectionQuestionsContainer>
                <QuestionsFormTitle>
                    {section.name}
                </QuestionsFormTitle>
                <QuestionsContainer>
                    <QuestionsGridHeader responseValues={section.responseValues} />
                    {section.questions.map(question => (
                        <Controller
                            key={question.id}
                            control={control}
                            name={question.id}
                            rules={{ validate: (value) => value.selectedAssessmentResponseValueId !== null }}
                            render={({ field }) => (
                                <QuestionsGridRow
                                    {...field}
                                    readOnly={readOnly ?? false}
                                    responseValues={section.responseValues}
                                />
                            )}
                        />
                    ))}
                </QuestionsContainer>
            </SectionQuestionsContainer>
            <SectionFooterContainer>
                <PreviousSectionButton
                    shouldSave={!readOnly && (isDirty || forceSave)}
                    previousSectionId={section.previousSectionId}
                    isSaving={isSaving}
                    onSave={handleSave}
                />
                <NextSectionButton
                    shouldSave={!readOnly && (isDirty || forceSave)}
                    nextSectionId={section.nextSectionId}
                    isSaving={isSaving}
                    onSave={handleSave}
                />
            </SectionFooterContainer>
            {(THAP_ENVIRONMENT === 'development' || THAP_ENVIRONMENT === 'demo') && (
                <div style={{ position: 'absolute', right: 15, top: 15 }} onClick={handleAutofillQuestionsClicked}>
                    <GhostSecondaryButton disabled={readOnly}>
                        Autofill Answers
                    </GhostSecondaryButton>
                </div>
            )}
        </BodyCard>
    )
}

export const SectionQuestionsLoadingState: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <SectionQuestionsContainer>
                <TitleSkeleton />
                <QuestionsContainer>
                    <QuestionsGridHeaderLoadingState />
                    <QuestionsGridRowLoadingState />
                    <QuestionsGridRowLoadingState />
                    <QuestionsGridRowLoadingState />
                </QuestionsContainer>
            </SectionQuestionsContainer>
            <SectionFooterContainer>
                <ButtonSkeleton />
                <ButtonSkeleton />
            </SectionFooterContainer>
        </BodyCard>
    )
}