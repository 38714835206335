import { createContext, FC, useCallback, useState } from "react";
import { v4 } from 'uuid';

type SaveProviderProps = {
    children: JSX.Element
}

export const SaveProvider: FC<SaveProviderProps> = ({
    children,
}) => {

    // state
    const [onSaveListeners, setOnSaveListeners] = useState<Function[]>([])
    const [loadingIds, setSavingIds] = useState<string[]>([])

    // constants
    const canSave = onSaveListeners.length > 0;
    const isSaving = loadingIds.length > 0;

    // event handlers
    const handleOnSave = useCallback(() => {
        onSaveListeners.forEach(listener => listener())
    }, [onSaveListeners])

    const handleAddOnSaveListener = useCallback((callback: () => void) => {
        setOnSaveListeners(oldListeners => [...oldListeners, callback])
        const unsubscribe = () => {
            setOnSaveListeners((oldListeners) => (
                oldListeners.filter(listener => listener !== callback)
            ))
        }
        return unsubscribe;
    }, [])

    const handleOnIsSaving = useCallback(() => {
        const savingId = v4()
        setSavingIds(oldSavingIds => [...oldSavingIds, savingId])
        const onLoadingComplete = () => {
            setSavingIds(oldSavingIds => oldSavingIds.filter(oldId => oldId !== savingId))
        }
        return onLoadingComplete;
    }, [])

    return (
        <SaveContext.Provider value={{
            // save
            canSave,
            onSave: handleOnSave,
            addOnSaveListener: handleAddOnSaveListener,
            
            // loading
            isSaving,
            onIsSaving: handleOnIsSaving,
        }}>
            {children}
        </SaveContext.Provider>
    )
}

type SaveContextType = {
    // save
    canSave: boolean;
    onSave: () => void;
    addOnSaveListener: (listener: () => void) => (() => void);

    // loading
    isSaving: boolean;
    onIsSaving: () => (() => void);
}

export const SaveContext = createContext<SaveContextType>({
    // save
    canSave: false,
    onSave: () => {},
    addOnSaveListener: () => (() => {}),

    // loading
    isSaving: false,
    onIsSaving: () => (() => {}),
})