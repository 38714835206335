import { FC, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { AuthenticationFormContainer, AuthenticationFormTitle, AuthenticationFormSubTitle, AuthenticationForm, LinkContainer, AuthenticationFormFooter, AuthenticationFooterMessage } from "../../authentication.styled";
import { AuthenticationContext } from "../../providers/authentication-provider/authentication-provider";
import { validate as validateEmail } from 'email-validator'
import { signUp } from "./sign-up.services";
import { PrimaryButton } from "../../../common/components/buttons/buttons";
import { FormItem } from "../../../common/components/form-item/form-item";
import { InlineLoading } from "../../../common/components/inline-loading/inline-loading";
import { Input } from "../../../common/components/input/input";
import { FormValidationMessage } from "../../../common/components/form-validation-message/form-validation-message";
import { phone } from 'phone'
import { FormItemColumns } from "../../../common/components/form-item-columns/form-item-columns";
import { SnackbarContext } from "../../../common/components/snackbar-provider/snackbar-provider";

export const SignUp: FC = () => {

    // context
    const { refreshAuthenticationState } = useContext(AuthenticationContext)
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    // form state
    const { register, getValues, handleSubmit, formState } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            jobTitle: '',
            companyName: '',
            emailAddress: '',
            phoneNumber: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        const formValues = getValues()
        const result = await signUp({
            ...formValues,
            phoneNumber: phone(formValues.phoneNumber).phoneNumber!
        })

        if (result.success) {
            refreshAuthenticationState()
            showSnackbar({
                type: 'success',
                title: 'Welcome!',
                message: 'We\'ve set up your account. You can now complete your assessment',
            })
        } else {
            let errorMessage: string = 'An unknown error occurred. If the issue persists, please contact support.'

            if (result.errorCode === 'sign_up__user_already_exists') {
                errorMessage = 'A user with that email address already exists. You can try signing in or using a different email address.'
            }

            setErrorMessage(errorMessage)
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: errorMessage,
            })
        }

        setIsLoading(false)
    }, [getValues, refreshAuthenticationState, showSnackbar])

    return (
        <AuthenticationFormContainer>
            <AuthenticationFormTitle>
                Sign Up
            </AuthenticationFormTitle>
            <AuthenticationFormSubTitle>
                Sign up to receive your company’s technology health assessment.
            </AuthenticationFormSubTitle>
            <LinkContainer>
                <Link
                    data-testid="sign-in-link"
                    to="/sign-in"
                >
                    Already have an account? Click here to sign in.
                </Link>
            </LinkContainer>
            <AuthenticationForm
                data-testid="sign-up-form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <FormItemColumns>
                    <FormItem label="Company Name">
                        <>
                            <Input
                                {...register('companyName', {
                                    required: true,
                                })}
                                data-testid="company-name-input"
                                type="text"
                                autoComplete="organization"
                                placeholder="Company Name"
                                disabled={isLoading}
                            />
                            {formState.errors.companyName?.type === 'required' && (
                                <FormValidationMessage data-testid="company-name-missing-warning">
                                    Company Name is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Job Title">
                        <>
                            <Input
                                {...register('jobTitle', {
                                    required: true,
                                })}
                                data-testid="job-title-input"
                                type="text"
                                autoComplete="organization-title"
                                placeholder="Job Title"
                                disabled={isLoading}
                            />
                            {formState.errors.jobTitle?.type === 'required' && (
                                <FormValidationMessage data-testid="job-title-missing-warning">
                                    Job Title is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                <FormItemColumns>
                    <FormItem label="First Name">
                        <>
                            <Input
                                {...register('firstName', { required: true })}
                                data-testid="first-name-input"
                                type="text"
                                autoComplete="fname"
                                placeholder="First Name"
                                disabled={isLoading}
                            />
                            {formState.errors.firstName?.type === 'required' && (
                                <FormValidationMessage data-testid="first-name-missing-warning">
                                    First name is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Last Name">
                        <>
                            <Input
                                {...register('lastName', { required: true })}
                                data-testid="last-name-input"
                                type="text"
                                autoComplete="lname"
                                placeholder="Last Name"
                                disabled={isLoading}
                            />
                            {formState.errors.lastName?.type === 'required' && (
                                <FormValidationMessage data-testid="last-name-missing-warning">
                                    Last name is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                <FormItemColumns>
                    <FormItem label="Email Address">
                        <>
                            <Input
                                {...register('emailAddress', {
                                    required: true,
                                    validate: validateEmail,
                                })}
                                data-testid="email-address-input"
                                type="email"
                                autoComplete="username"
                                placeholder="Email Address"
                                disabled={isLoading}
                            />
                            {formState.errors.emailAddress?.type === 'required' && (
                                <FormValidationMessage data-testid="email-address-missing-warning">
                                    Email address is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.emailAddress?.type === 'validate' && (
                                <FormValidationMessage data-testid="email-address-invalid-warning">
                                    Please enter a valid email address
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Phone Number">
                        <>
                            <Input
                                {...register('phoneNumber', {
                                    required: true,
                                    validate: (value) => phone(value).isValid,
                                })}
                                data-testid="phone-number-input"
                                type="text"
                                autoComplete="tel"
                                placeholder="Phone Number"
                                disabled={isLoading}
                            />
                            {formState.errors.phoneNumber?.type === 'required' && (
                                <FormValidationMessage data-testid="phone-number-missing-warning">
                                    Phone Number is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.phoneNumber?.type === 'validate' && (
                                <FormValidationMessage data-testid="phone-number-invalid-warning">
                                    Please enter a valid phone number
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                {/* <FormItemColumns>
                    <FormItem label="Password">
                        <>
                            <Input
                                {...register('password', {
                                    required: true,
                                    validate: validatePassword,
                                })}
                                data-testid="password-input"
                                type="password"
                                autoComplete="new-password"
                                placeholder="Password"
                                disabled={isLoading}
                            />
                            {formState.errors.password?.type === 'required' && (
                                <FormValidationMessage data-testid="password-missing-warning">
                                    Password is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.password?.type === 'validate' && (
                                <FormValidationMessage data-testid="password-validation-warning">
                                    Password must:
                                    <ul>
                                        {!PASSWORD_REGEX.LENGTH.test(password) && (
                                            <li>have at least 10 characters</li>
                                        )}
                                        {!PASSWORD_REGEX.LOWERCASE_LETTER.test(password) && (
                                            <li>contain a lowercase letter</li>
                                        )}
                                        {!PASSWORD_REGEX.UPPERCASE_LETTER.test(password) && (
                                            <li>contain an uppercase letter</li>
                                        )}
                                        {!PASSWORD_REGEX.NUMBER.test(password) && (
                                            <li>contain a number</li>
                                        )}
                                        {!PASSWORD_REGEX.SYMBOL.test(password) && (
                                            <li>contain a symbol ({`~\`!@#$%^&*()-_+={}[]|\\;:"<>,./?`})</li>
                                        )}
                                    </ul>
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Re-enter Your Password">
                        <>
                            <Input
                                {...register('verifyPassword', {
                                    required: true,
                                    validate: (verifyPassword) => getValues().password === verifyPassword,
                                })}
                                data-testid="verify-password-input"
                                type="password"
                                autoComplete="new-password"
                                placeholder="Re-enter Your Password"
                                disabled={isLoading}
                            />
                            {formState.errors.verifyPassword?.type === 'required' && (
                                <FormValidationMessage data-testid="verify-password-missing-warning">
                                    Password verification is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.verifyPassword?.type === 'validate' && (
                                <FormValidationMessage data-testid="verify-password-validation-warning">
                                    Passwords must match
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns> */}
                <AuthenticationFormFooter>
                    <PrimaryButton
                        data-testid="sign-up-button"
                        type="submit"
                        disabled={isLoading}
                    >
                        Sign Up
                    </PrimaryButton>
                    {!!isLoading && (
                        <InlineLoading>
                            Loading...
                        </InlineLoading>
                    )}
                </AuthenticationFormFooter>
                {errorMessage && (
                    <AuthenticationFooterMessage type='error'>
                        {errorMessage}
                    </AuthenticationFooterMessage>
                )}
            </AuthenticationForm>
        </AuthenticationFormContainer>
    )
}