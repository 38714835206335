import { FC } from "react";
import { AdminGridItem } from "./subcomponents/admin-grid-item/admin-grid-item";
import { AdminOptionsGrid } from "./admin-home.styled";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { AccountIcon } from "../../../../common/components/icons/account-icon/account-icon";
import { AssessmentIcon } from "../../../../common/components/icons/assessment-icon/assessment-icon";
import { UserIcon } from "../../../../common/components/icons/user-icon/user-icon";
import { StandardCardContentContainer } from "../../../../common/components/standard-card-content-container/standard-card-content-container";
import { StandardCardHeader } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { EmailIcon } from "../../../../common/components/icons/email-icon/email-icon";
import { THAP_ENVIRONMENT } from "../../../../common/environment/environment-constants";
import { ResetEnvironmentGridItem } from "./subcomponents/admin-grid-item/subcomponents/reset-environment-grid-item/reset-environment-grid-item";
import { DemoDashboardGridItem } from "./subcomponents/admin-grid-item/subcomponents/demo-dashboard-grid-item/demo-dashboard-grid-item";

export const AdminHome: FC = () => {
    const showDemoGridItems = THAP_ENVIRONMENT === 'demo'

    return (
        <BodyContainer>
            <BodyCard style={{ flex: 1 }}>
                <StandardCardLayout>
                    <StandardCardHeader
                        title="Admin Home"
                        subtitle="Administer the Tech Health Assessment Platform"
                    />
                    <StandardCardContentContainer>
                        <AdminOptionsGrid>
                            <AdminGridItem
                                linkTo="/admin/accounts"
                                title="Accounts"
                                subtitle="See and manage accounts"
                                iconComponent={AccountIcon}
                            />
                            <AdminGridItem
                                linkTo="/admin/assessments"
                                title="Assessments"
                                subtitle="Review assessments and update statuses"
                                iconComponent={AssessmentIcon}
                            />
                            <AdminGridItem
                                linkTo="/admin/users"
                                title="Users"
                                subtitle="Manage users, reset passwords"
                                iconComponent={UserIcon}
                            />
                            <AdminGridItem
                                linkTo="/admin/email-templates"
                                title="Email Templates"
                                subtitle="See and manage accounts"
                                iconComponent={EmailIcon}
                            />
                            {showDemoGridItems && (
                                <>
                                    <ResetEnvironmentGridItem />
                                    <DemoDashboardGridItem />
                                </>
                            )}
                        </AdminOptionsGrid>
                    </StandardCardContentContainer>
                </StandardCardLayout>
            </BodyCard>
        </BodyContainer>
    )
}