import { createContext, FC } from "react";
import { AppColors } from "../../constants/colors/app-colors";
import { NotificationType } from "../../types/notification.types";
import { CalloutCardContainer, CalloutCardContainerSkeleton } from "./callout-card.styled";

type CalloutCardProps = {
    type?: NotificationType
    children: JSX.Element | JSX.Element[]
}

export const CalloutCard: FC<CalloutCardProps> = ({
    type = 'info',
    children,
}) => {

    // constants
    const color =
        type === 'info'
        ? 'blue'
        : type === 'error'
        ? 'red'
        : type === 'success'
        ? 'green'
        : 'orange'

    return (
        <CalloutCardContext.Provider value={{
            color,
        }}>
            <CalloutCardContainer color={color}>
                {children}
            </CalloutCardContainer>
        </CalloutCardContext.Provider>
    )
}

type CalloutCardContextType = {
    color: keyof Pick<typeof AppColors.background, 'blue' | 'orange' | 'green' | 'red'>
}

export const CalloutCardContext = createContext<CalloutCardContextType>({
    color: 'blue',
})

type CalloutCardLoadingStateProps = {
    children: JSX.Element
}

export const CalloutCardLoadingState: FC<CalloutCardLoadingStateProps> = ({
    children,
}) => {
    return (
        <CalloutCardContainerSkeleton>
            {children}
        </CalloutCardContainerSkeleton>
    )
}