import { FC, useCallback, useEffect, useState } from "react";
import { Portal } from "../portal/portal";
import { DialogBackdrop, DialogCard, DialogContainer } from "./dialog.styled";
import { DialogCloseButton } from "./subcomponents/dialog-close-button/dialog-close-button";

export type DialogProps = {
    isOpen: boolean
    onClose: () => void
    children: JSX.Element | JSX.Element[] | string
}

export const Dialog: FC<DialogProps> = ({
    isOpen,
    onClose,
    children,
}) => {

    // state
    const [renderDialog, setRenderDialog] = useState(false)

    // side effects
    useEffect(() => {
        if (isOpen) {
            setRenderDialog(true)
            return;
        }

        const closeTimeout = setTimeout(() => {
            setRenderDialog(false)
        }, 250);

        return () => {
            clearTimeout(closeTimeout)
        }
    }, [isOpen])

    // event handlers
    const handleDialogBackdropClick = useCallback(() => {
        onClose()
    }, [onClose])

    const handleCloseButtonClick = useCallback(() => {
        onClose()
    }, [onClose])

    if (!renderDialog) return null;

    return (
        <Portal>
            <DialogContainer isOpen={isOpen}>
                <DialogBackdrop
                    isOpen={isOpen}
                    onClick={handleDialogBackdropClick}
                />
                <DialogCard isOpen={isOpen}>
                    {children}
                    <DialogCloseButton onClick={handleCloseButtonClick} />
                </DialogCard>
            </DialogContainer>
        </Portal>
    )
}