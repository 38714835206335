import { FC } from "react";
import { useParams } from "react-router";
import { AssessmentSection } from "../../../../modules/assessment-sections/components/assessment-section/assessment-section";

export const AssessmentSectionRoute: FC = () => {

    // router hooks
    const { section_id } = useParams() as { section_id: string }

    return (
        <AssessmentSection
            sectionId={section_id}
        />
    )
}