import { CompleteSignUpPayload, CompleteSignUpResult, GetCompleteSignUpScreenDataRequest, GetCompleteSignUpScreenDataResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../services/core-api-service";

class CompleteSignUpService {
    public async getCompleteSignUpScreenData(payload: GetCompleteSignUpScreenDataRequest): Promise<GetCompleteSignUpScreenDataResult> {
        return api.auth.getCompleteSignUpScreenData(payload)
    }

    public async completeSignUp(payload: CompleteSignUpPayload): Promise<CompleteSignUpResult> {
        return api.auth.completeSignUp(payload)
    }
}

export const service = new CompleteSignUpService()