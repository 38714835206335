import styled from "@emotion/styled";
import { BorderRadius, Padding } from "../sizes/sizes";

export const Input = styled.input({
    // font
    fontSize: 16,

    // padding
    paddingLeft: Padding.desktop.small,
    paddingRight: Padding.desktop.small,
    paddingTop: Padding.desktop.smaller,
    paddingBottom: Padding.desktop.smaller,

    // border
    border: '1px solid #BDBDBD',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: BorderRadius.small,
})