import styled from "@emotion/styled";
import { BorderRadius, Gap, Padding } from "../../../../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT } from "../../../../../../common/constants/breakpoints/breakpoints";
import { AppColors } from "../../../../../../common/constants/colors/app-colors";

export const AdminGridItemContainer = styled.div({
    // display
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateRows: 'max-content',
    gap: Gap.desktop.normal,
    justifyItems: 'start',

    // cursor
    cursor: 'pointer',

    // padding
    padding: Padding.desktop.smaller,

    // text
    color: AppColors.text.blue.dark,
    textAlign: 'left',

    // size
    boxSizing: 'border-box',

    // hover
    ':hover': {
        backgroundColor: AppColors.background.blue.light,
    },

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        padding: Padding.mobile.smaller,
        paddingTop: Padding.mobile.small,
        paddingBottom: Padding.mobile.small,
    },
})

export const AdminGridItemContainerLoadingContainer = styled.div({
    // display
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gridTemplateRows: 'max-content',
    gap: Gap.desktop.normal,
    justifyItems: 'start',

    // cursor
    cursor: 'pointer',

    // padding
    padding: Padding.desktop.smaller,

    // border
    borderRadius: BorderRadius.small,

    // text
    textAlign: 'left',

    // size
    boxSizing: 'border-box',
    maxWidth: 'unset',

    // hover
    ':hover': {
        backgroundColor: AppColors.background.blue.light,
    },

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        padding: Padding.mobile.smaller,
        paddingTop: Padding.mobile.small,
        paddingBottom: Padding.mobile.small,
    },
})

export const AdminGridItemTextLoadingContainer = styled.div({
    display: 'grid',
    alignContent: 'center',
    gap: Gap.desktop.smaller,
    gridAutoRows: 'max-content',

    // text
    wordBreak: 'normal',
})

export const TextContainer = styled.div({
    // layout
    display: 'grid',
    alignContent: 'center',
    gap: Gap.desktop.smaller,
    gridAutoRows: 'max-content',
})

export const NameLabel = styled.span({
    // text
    fontWeight: 600,
    fontsize: 14,

    // size
    maxHeight: 'calc(2rem + 12px)',

    // overflow
    overflow: 'hidden',
})

export const SubtitleLabel = styled.span({
    fontSize: 13,
    fontStyle: 'italic',
})