import { CoreAPI } from "../core";
import { EmailReportResult, GetAssessmentResult, GetCurrentAssessmentResultsResult, GetSupportPayload, GetSupportResult, SubmitAssessmentResult } from "./dtos";

export class AssessmentsAPI {
  private basePath: string;

  constructor(private readonly coreApi: CoreAPI) {
    this.basePath = `${this.coreApi.apiUrl}/assessments`;
  }

  public async getAssessment(assessmentId: string): Promise<GetAssessmentResult> {
    const result = await this.coreApi.get(`${this.basePath}/${assessmentId}`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAssessmentResult;

    return resultData;
  }

  public async getCurrentAssessment(): Promise<GetAssessmentResult> {
    const result = await this.coreApi.get(`${this.basePath}/currentAssessment`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAssessmentResult;

    return resultData;
  }

  public async submitAssessment(assessmentId: string): Promise<SubmitAssessmentResult> {
    const result = await this.coreApi.post(`${this.basePath}/${assessmentId}/submit`, {})

    if (!result?.data) throw new Error();

    const resultData = result.data as SubmitAssessmentResult;

    return resultData;
  }

  public async getCurrentAssessmentResults(): Promise<GetCurrentAssessmentResultsResult> {
    const result = await this.coreApi.get(`${this.basePath}/currentAssessment/results`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetCurrentAssessmentResultsResult;

    return resultData;
  }

  public async getSupport(assessmentId: string, payload: GetSupportPayload): Promise<GetSupportResult> {
    const result = await this.coreApi.post(`${this.basePath}/${assessmentId}/getSupport`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetSupportResult;

    return resultData;
  }

  public async emailReport(assessmentId: string): Promise<EmailReportResult> {
    const result = await this.coreApi.post(`${this.basePath}/${assessmentId}/emailReport`, {})

    if (!result?.data) throw new Error();

    const resultData = result.data as EmailReportResult;

    return resultData;
  }
}