import "reflect-metadata"
import { CoreAPI as TechAssessmentAPI, APIConfiguration } from "./modules/core";

export const createClient = (options: APIConfiguration): TechAssessmentAPI => {
    return new TechAssessmentAPI(options);
}

export * from "./common/codes.types";
export * from "./modules/admin";
export * from "./modules/auth";
export * from "./modules/assessments";
export * from "./modules/assessment-sections";
export * from "./modules/core";
export * from "./modules/common";
export * from "./modules/demo";
export * from "./modules/self";