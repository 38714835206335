import { FC } from "react";
import { useParams } from "react-router";
import { AdminAccount } from "../../../../../modules/admin/components/screens/admin-account/admin-account";

export const AdminAccountRoute: FC = () => {

    // router hooks
    const { account_id } = useParams() as { account_id: string }

    return (
        <AdminAccount
            accountId={account_id}
        />
    )
}