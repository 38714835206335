import { FC } from "react";
import { HeroContainer } from "./calculating-results-hero.styled";

export const CalculatingResultsHero: FC = () => {
    return (
        <HeroContainer>
            <svg width="256" height="428" viewBox="0 0 256 428" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M226.048 0.115234H29.9489C13.751 0.115234 0.620117 13.2462 0.620117 29.444V398.553C0.620117 414.751 13.751 427.882 29.9489 427.882H226.048C242.245 427.882 255.376 414.751 255.376 398.553V29.444C255.376 13.2462 242.245 0.115234 226.048 0.115234Z" fill="#F3F3F3" />
                <path d="M0.620117 65.4702H255.376V362.53H0.620117V65.4702Z" fill="#28B6FE" />
                <path d="M171.354 199.581C170.679 199.045 169.905 198.647 169.076 198.41C168.247 198.173 167.38 198.101 166.523 198.2C165.667 198.299 164.838 198.565 164.085 198.984C163.331 199.403 162.668 199.967 162.133 200.642L143.338 224.312L124.543 200.642C124.015 199.945 123.353 199.361 122.596 198.923C121.839 198.485 121.003 198.203 120.135 198.094C119.268 197.984 118.387 198.048 117.545 198.283C116.703 198.519 115.917 198.92 115.232 199.463C114.547 200.007 113.977 200.682 113.557 201.448C113.136 202.215 112.873 203.058 112.783 203.927C112.693 204.797 112.778 205.676 113.032 206.512C113.286 207.349 113.705 208.126 114.264 208.799L134.956 234.865L114.264 260.931C113.705 261.604 113.286 262.381 113.032 263.217C112.778 264.054 112.693 264.933 112.783 265.802C112.873 266.672 113.136 267.515 113.557 268.282C113.977 269.048 114.547 269.723 115.232 270.266C115.917 270.81 116.703 271.211 117.545 271.446C118.387 271.682 119.268 271.746 120.135 271.636C121.003 271.526 121.839 271.244 122.596 270.807C123.353 270.369 124.015 269.784 124.543 269.087L143.338 245.417L162.125 269.087C162.653 269.784 163.315 270.369 164.072 270.807C164.828 271.244 165.665 271.526 166.533 271.636C167.4 271.746 168.281 271.682 169.123 271.446C169.965 271.211 170.751 270.81 171.436 270.266C172.121 269.723 172.69 269.048 173.111 268.282C173.531 267.515 173.794 266.672 173.885 265.802C173.975 264.933 173.89 264.054 173.636 263.217C173.382 262.381 172.963 261.604 172.404 260.931L151.708 234.865L172.404 208.799C173.486 207.437 173.984 205.701 173.787 203.973C173.59 202.244 172.715 200.665 171.354 199.581V199.581Z" fill="white" />
                <path d="M222.388 155.77H99.4063C98.0023 155.767 96.6344 156.214 95.5037 157.047C94.373 157.879 93.5393 159.052 93.125 160.394L65.4125 250.154L48.9725 226.326C48.3698 225.451 47.5633 224.736 46.6226 224.242C45.6818 223.748 44.6351 223.491 43.5725 223.491H33.6125C31.8721 223.491 30.2029 224.183 28.9722 225.413C27.7414 226.644 27.05 228.313 27.05 230.054C27.05 231.794 27.7414 233.463 28.9722 234.694C30.2029 235.925 31.8721 236.616 33.6125 236.616H40.1375L62.3038 268.75C62.9079 269.624 63.7147 270.338 64.6551 270.832C65.5956 271.325 66.6416 271.584 67.7038 271.585C68.0378 271.585 68.3713 271.558 68.7013 271.506C69.9192 271.32 71.0601 270.795 71.9933 269.99C72.9264 269.186 73.6141 268.135 73.9775 266.958L104.248 168.906H222.373C224.113 168.906 225.782 168.215 227.013 166.984C228.244 165.753 228.935 164.084 228.935 162.344C228.935 160.603 228.244 158.934 227.013 157.703C225.782 156.473 224.113 155.781 222.373 155.781L222.388 155.77Z" fill="white" />
                <path d="M138.5 39.3555H90.804C89.0635 39.3555 87.3943 38.6641 86.1636 37.4334C84.9329 36.2027 84.2415 34.5335 84.2415 32.793C84.2415 31.0525 84.9329 29.3833 86.1636 28.1526C87.3943 26.9219 89.0635 26.2305 90.804 26.2305H138.5C140.241 26.2305 141.91 26.9219 143.141 28.1526C144.371 29.3833 145.063 31.0525 145.063 32.793C145.063 34.5335 144.371 36.2027 143.141 37.4334C141.91 38.6641 140.241 39.3555 138.5 39.3555V39.3555Z" fill="#BDBDBD" />
                <path d="M176.987 39.3555H164.529C162.789 39.3555 161.119 38.6641 159.889 37.4334C158.658 36.2027 157.967 34.5335 157.967 32.793C157.967 31.0525 158.658 29.3833 159.889 28.1526C161.119 26.9219 162.789 26.2305 164.529 26.2305H176.987C178.727 26.2305 180.396 26.9219 181.627 28.1526C182.858 29.3833 183.549 31.0525 183.549 32.793C183.549 34.5335 182.858 36.2027 181.627 37.4334C180.396 38.6641 178.727 39.3555 176.987 39.3555V39.3555Z" fill="#BDBDBD" />
            </svg>
        </HeroContainer>
    )
}