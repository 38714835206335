import { AxiosError } from 'axios';
import { SignUpPayload, SignUpResult } from 'tech-health-assessment-sdk/dist';
import { api } from '../../../../services/core-api-service';

export const signUp = async (payload: SignUpPayload): Promise<SignUpResult> => {
  let result: SignUpResult | undefined = undefined;
  let error: unknown;
  try {
    result = await api.auth.signUp(payload);
  } catch (err) {
    error = err;
  }

  if (!!result && !error) return result;

  const signUpResult = new SignUpResult()
  signUpResult.success = false;

  if (!(error instanceof AxiosError)) {
    signUpResult.errorCode = 'unknown_error';
    return signUpResult;
  }
  
  if (error.code === '400') {
    signUpResult.errorCode = 'payload_validation_error'
  } else {
    signUpResult.errorCode = 'unknown_error'
  }

  return signUpResult;
};
