import { FC } from "react";
import { IconSvg } from "../icons.styled";
import { IconProps } from "../icons.types";

export const ArrowRightIcon: FC<IconProps> = ({
    size,
    color,
}) => {
    return (
        <IconSvg
            size={size}
            color={color}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M7.00016 0.333252L5.82516 1.50825L10.4752 6.16658H0.333496V7.83325H10.4752L5.82516 12.4916L7.00016 13.6666L13.6668 6.99992L7.00016 0.333252Z"/>
        </IconSvg>
    )
}
