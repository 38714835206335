import { FC } from "react";
import { ProgressBarContainer, ProgressBarCoverage } from "./progress-bar.styled";

type ProgressBarProps = {
    progress: number
}

export const ProgressBar: FC<ProgressBarProps> = ({
    progress,
}) => {
    return (
        <ProgressBarContainer>
            <ProgressBarCoverage progress={progress} />
        </ProgressBarContainer>
    )
}

export const ProgressBarLoadingState: FC = () => {
    return (
        <ProgressBarContainer>
            <ProgressBarCoverage progress={0} />
        </ProgressBarContainer>
    )
}