import styled from "@emotion/styled";
import { SkeletonStyles } from "../../../../../../../../common/constants/animations/animations";
import { AppColors } from "../../../../../../../../common/constants/colors/app-colors";

export const AdminGridItemIconContainer = styled.div<{
    size: number
}>(({ size }) => ({
    // position
    position: 'relative',
    
    // display
    display: 'grid',
    width: size,
    height: size,
    justifyContent: 'center',
    alignItems: 'center',

    // border
    borderRadius: '50%',

    // background
    backgroundColor: AppColors.background.dark,
}))

export const AdminGridItemIconSkeleton = styled.div<{
    size: number,
}>(({ size }) => ({
    // display
    width: size,
    height: size,

    // border
    borderRadius: '50%',

    ...SkeletonStyles(),
}))