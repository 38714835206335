import styled from "@emotion/styled";
import { Gap, Padding } from "../../../../../common/components/sizes/sizes";

export const GetSupportForm = styled.form({
    // layout
    display: 'grid',
    gap: Gap.desktop.large,
    
    // padding
    padding: Padding.desktop.large,

    // size
    boxSizing: 'border-box',
    width: 'calc(100vw - 24px)',
    maxWidth: 640,
})

const VERTICAL_FORM_BREAKPOINT = '@media (max-width: 1000px)';

export const FormContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.normal,

    // breakpoints
    [VERTICAL_FORM_BREAKPOINT]: {
        gap: Gap.desktop.large * 0.75,
    },
})