import { FC } from "react";
import { HeroContainer } from "./preparing-assessment-hero.styled";

export const PreparingAssessmentHero: FC = () => {
    return (
        <HeroContainer>
            <svg width="512" height="512" id="_04" data-name="04" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4680 3120">
                <defs>
                    <clipPath id="clippath">
                        <rect x="1703.78" y="2070.04" width="481" height="313.32" style={{ fill: 'none' }} />
                    </clipPath>
                </defs>
                <rect id="_04-2" data-name="04" width="4680" height="3120" style={{ fill: 'rgba(255, 255, 255, 0)' }} />
                <path d="M2840.03,1049.84c-210.62-233.73-394.46-587.86-1026.42-593.73-475.54-4.41-1145.75,738-1064.09,1390.32,65.73,525.04,325.47,809.65,546.85,799.19,221.38-10.46,2822.86-34.69,2822.86-34.69,0,0,460.23-753.23,92.08-1332.58-230.5-362.74-1160.66,5.22-1371.28-228.51Z" style={{ fill: '#e7f5f9' }} />
                <ellipse cx="2594.73" cy="2647.18" rx="1739.56" ry="112.82" style={{ fill: '#5d8aa9' }} />
                <path d="M1291.06,613.98s91.7,1371.16,144.45,1781.17c50.86,21.81,103.42,42.02,157.33,60.67,46.23,16,93.47,30.87,141.47,44.63l-401.84-1983.4-41.41,96.93Z" style={{ fill: '#183553' }} />
                <polygon points="1382.95 480.27 1571.12 2600.64 2899.54 2440.48 2727.69 480.27 1382.95 480.27" style={{ fill: '#7fd4fc' }} />
                <polygon points="1332.47 517.05 1521.78 2581.14 1571.12 2600.64 1382.95 480.27 1332.47 517.05" style={{ fill: '#298ad1' }} />
                <path d="M2686.39,562.88l-1189.23-23.47s-12.88,1548,229.63,2042.46l1172.76-141.4-118.46-1351.26-94.7-526.33Z" style={{ fill: '#298ad1' }} />
                <path d="M1487.51,585.48c0,303.94,83.61,1437.09,230.87,1881.59,150.37-28.75,313.27-56.27,479.49-79.96,267.32-38.11,543.22-24.82,789.31-32.36-114.62-580.83-260.34-1200.11-300.33-1790.78-399.78,7.17-799.56,14.35-1199.34,21.51Z" style={{ fill: '#f0fcfe' }} />
                <path d="M1520.58,577.31c0,303.94,90.14,1186,185.12,1678.57,0,0,167.11,151.37,481.07,127.5,14.31-1.1,28.93-2.54,43.85-4.39,.11-.01,.22-.03,.32-.04,267.32-38.11,543.22-66.32,789.31-73.85-114.62-580.83-260.34-1158.61-300.33-1749.29-399.78,7.16-799.56,14.33-1199.34,21.5Z" style={{ fill: '#fff' }} />
                <g style={{ opacity: '.35' }}>
                    <g style={{ clipPath: 'url(#clippath)' }}>
                        <g>
                            <path d="M1873.55,2070.04c9.06,79.41-169.77,185.83-169.77,185.83l132.27,5.71,326.59,114.05c-87.95-48.26-289.09-305.59-289.09-305.59Z" style={{ fill: '#d6fafd' }} />
                            <path d="M2162.64,2375.63c8.54,4.69,16.02,7.41,22.14,7.73l-22.14-7.73Z" style={{ fill: '#d6fafd' }} />
                        </g>
                    </g>
                </g>
                <path d="M1863,2144.16c-48.21,103.19-159.23,111.72-159.23,111.72,0,0,167.11,151.37,481.07,127.5-.02-.01-.05-.01-.07-.01-182.2-14.16-321.77-239.21-321.77-239.21Z" style={{ fill: '#fff' }} />
                <polygon points="1869.22 1321.6 1877.49 1373.75 1919.91 1370.89 1911.65 1318.74 1869.22 1321.6" style={{ fill: '#5d8aa9' }} />
                <path d="M1843.71,1343.07l-151.99,10.25c-.26,.02-.42,.29-.37,.6l1.89,11.92c.05,.31,.3,.55,.55,.54l151.99-10.25c.25-.02,.42-.29,.37-.6l-1.89-11.92c-.05-.31-.3-.56-.55-.54Z" style={{ fill: '#7fd4fc' }} />
                <polygon points="1854.5 1228.67 1862.76 1280.81 1905.18 1277.95 1896.92 1225.81 1854.5 1228.67" style={{ fill: '#7fd4fc' }} />
                <path d="M1828.98,1250.14l-151.99,10.25c-.26,.02-.42,.29-.37,.6l1.89,11.92c.05,.31,.3,.56,.55,.54l151.99-10.25c.26-.02,.42-.29,.37-.6l-1.89-11.92c-.05-.32-.3-.56-.55-.54Z" style={{ fill: '#7fd4fc' }} />
                <polygon points="1883.95 1414.5 1892.22 1466.65 1934.64 1463.79 1926.37 1411.64 1883.95 1414.5" style={{ fill: '#183553' }} />
                <path d="M1858.43,1435.97l-151.99,10.25c-.26,.02-.42,.29-.37,.6l1.89,11.92c.05,.31,.3,.55,.55,.54l151.99-10.25c.25-.02,.42-.28,.37-.6l-1.89-11.92c-.05-.31-.3-.55-.55-.54Z" style={{ fill: '#7fd4fc' }} />
                <path d="M1953.16,1614.51l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89-.58-3.6-3.42-6.36-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <path d="M1961.66,1668.13l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89-.58-3.6-3.42-6.37-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <path d="M1970.16,1721.74l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89-.58-3.6-3.42-6.36-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <path d="M1978.65,1775.36l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89-.57-3.6-3.41-6.37-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <path d="M1987.15,1828.98l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89s-3.41-6.37-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <path d="M1995.65,1882.59l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89-.58-3.6-3.42-6.36-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <path d="M2004.15,1936.21l-212.87,14.35c-2.93,.2-4.85,3.28-4.28,6.89s3.41,6.37,6.35,6.17l212.87-14.35c2.93-.2,4.85-3.28,4.28-6.89-.58-3.61-3.42-6.37-6.35-6.17Z" style={{ fill: '#7fd4fc' }} />
                <polygon points="2316.3 1458.8 2398.41 1976.82 2543.44 1967.04 2461.34 1449.02 2316.3 1458.8" style={{ fill: '#7fd4fc' }} />
                <path d="M2366.86,1777.74l31.55,199.07,145.03-9.78-21.66-136.63c-53.48-9.75-105.41-30.22-154.92-52.66Z" style={{ fill: '#183553' }} />
                <polygon points="2576.17 1586.76 2635.46 1960.84 2780.49 1951.06 2721.2 1576.98 2576.17 1586.76" style={{ fill: '#7fd4fc' }} />
                <polygon points="2054.72 1320.01 2161.36 1992.8 2306.39 1983.02 2199.76 1310.23 2054.72 1320.01" style={{ fill: '#7fd4fc' }} />
                <path d="M2113.17,1688.73l48.19,304.07,145.03-9.78-40.01-252.44c-56.17-25.71-107.8-45.07-153.21-41.85Z" style={{ fill: '#183553' }} />
                <path d="M2442.05,935.26l-590.03,1.92c309.32,58.11,590.03-1.92,590.03-1.92Z" style={{ fill: '#e8e8e8' }} />
                <g>
                    <path d="M3570.89,362.93c-.78-1.79-2.56-3.03-4.52-2.93-1.95,.02-3.71,1.2-4.46,3l-150.11,359.9c-1.04,2.49,.14,5.35,2.63,6.39,2.47,1,5.34-.13,6.38-2.63l145.72-349.35,155.21,354.63c.8,1.84,2.59,2.93,4.48,2.93,.65,0,1.31-.13,1.95-.41,2.47-1.08,3.59-3.96,2.51-6.42l-159.79-365.11Z" />
                    <path d="M3420.64,705.76c-7.63,19-11.86,39.71-11.86,61.43,0,91.12,73.87,165,164.99,165s164.99-73.87,164.99-165c0-21.72-4.24-42.44-11.86-61.43h-306.26Z" style={{ fill: '#298ad1' }} />
                    <ellipse cx="3573.77" cy="705.74" rx="153.13" ry="24.25" style={{ fill: '#5d8aa9' }} />
                    <path d="M3635.41,689.62c-2.79-.08-5.66-.33-8.52-.41-4.51-.25-9.1-.49-13.77-.66-2.79-.08-5.49-.25-8.36-.33-8.93-.24-18.19-.41-27.7-.41h-8.69c-15.66,.08-30.65,.49-44.59,1.23-3.11,.16-6.15,.33-9.18,.49-46.63,2.87-78.85,9.09-78.85,16.23,0,7.87,38.6,14.42,92.37,16.88,2.87,.09,5.82,.25,8.77,.33,11.72,.41,24.1,.66,36.88,.66h.08c2.87,0,5.74,0,8.61-.08,6.56,0,12.95-.08,19.18-.25,2.87-.08,5.66-.16,8.36-.33,4.18-.08,8.2-.24,12.21-.49,2.95-.09,5.82-.25,8.61-.41,47.78-2.79,80.98-9.02,80.98-16.31s-31.06-13.1-76.39-16.14Z" style={{ fill: '#183553' }} />
                    <g>
                        <path d="M3731.49,630.11c-29.93-12.14-64.83-10.87-93.74,3.54-22.66,11.3-40.67,30.36-51.66,52.99-10.71-34.45-24.04-69.13-51.47-93.66-26.24-23.46-60.88-31.38-94.45-20.03-34.14,11.54-59.5,38.64-77.11,69.23-39.27,68.21-47.28,149.22-54.68,226.03-.2,2.04,.75,3.33,2.07,4.03,1.12,4.52,9.04,3.96,9.59-1.62,3.34-33.72,10.88-66.95,22.28-98.86,10.22-28.61,23.66-56.07,39.91-81.73,15.49-24.47,33.47-51.58,61.51-62.61,25.75-10.13,55.59-7.44,79.43,6.42,12.56,7.3,23.19,17.32,31.69,28.93-7.73-2.01-16.07-1.18-22.61,4-9.4,7.44-14.09,21.11-18.86,31.7-5.73,12.7-10.84,25.69-15.33,38.87-8.97,26.31-15.52,53.4-20.46,80.74-9.14,50.64-12.84,102.02-15.9,153.33-1.21,5.32-2.59,10.59-3.77,15.92-1.22,5.52,6.22,8.11,8.73,4.12,1.98-.46,3.74-1.91,3.89-4.49,.29-5.01,.62-10.02,.92-15.02,4.17-18.2,8.48-36.38,13.06-54.48,10.83-42.79,22.81-85.29,35.92-127.44,6.43-20.69,13.08-41.33,20.12-61.83,2.82-8.22,6.13-17.75,15.15-20.81,7.58-2.57,15.83-.09,20.98,5.86,3.62,4.18,9.18-.65,7.72-5.09,.21,.07,.41,.11,.63,.15,1.14,2.86,4.96,4.76,7.91,1.97,23.31-22.07,48.81-44.11,79.94-54.03,24.04-7.66,52.13-7.45,74.21,5.87,22.01,13.27,32.03,37.54,36.05,61.91,4.6,27.86,2.86,56.74,2.73,84.84-.14,31.52-.27,63.04-.41,94.56-.03,.12-.06,.23-.09,.35-.24,.96-.15,1.79,.08,2.55,0,.78-.01,1.57-.01,2.35-.03,6.29,9.73,6.28,9.76,0,.01-1.33,.01-2.67,.02-4.01,10.55-41.38,21.08-83.5,20.71-126.48-.5-58.94-26.3-124.48-84.46-148.07Z" style={{ fill: '#4896b3' }} />
                        <path d="M3688.66,613.26c-24.48-23.58-66.95-31.61-92.41-5.06-18.77,19.56-21.33,48.58-19.98,75.35-12.24-12.6-27.43-22.16-44.33-27.61-42.36-13.66-90.2,2.88-116.3,38.63-14.22,19.48-20.96,43.4-24.3,66.99-3.22,22.71-4.04,45.8-3.56,68.75-1.07,4.35-2.32,8.64-3.27,13.02-1.18,5.45,6.09,8.07,8.66,4.29,2.44,0,4.81-1.57,4.68-4.71-.17-4.1-.23-8.24-.32-12.37,4.11-16.37,9.04-32.52,14.88-48.36,6.6-17.92,13.88-36.14,23.8-52.51,9.53-15.74,22.74-27.15,38.94-35.68,8.04-4.23,16.41-7.76,24.83-11.13,9.37-3.75,18.92-8.54,29.08-5.08,16.4,5.59,32.27,15.71,46.69,25.63,.21,.3,.47,.55,.75,.81,.12,1.33,.76,2.63,2.23,3.54,11.67,7.18,14.86,21.56,16.75,34.21,2.54,17.02,4.12,34.25,6.14,51.34l12.86,108.75c.65,5.54,7.54,6.56,9.59,1.3,14.48-37.21,17.9-78.11,10.67-117.33-3.57-19.37-9.87-38.24-18.65-55.88-6.23-12.52-14.29-26.29-27.28-31.84,1.32-15.55-4.4-31.87,1.49-46.63,7.53-18.87,28.58-10.59,39.6,.32,9.7,9.59,15.96,22.14,21.66,34.37,7.21,15.48,13.75,31.27,19.63,47.3,11.3,30.8,20.18,62.5,26.56,94.68,12.84,64.71,15.37,131.28,7.64,196.78-.68,5.76,7.54,6.07,9.37,1.22,.36-.52,.63-1.15,.76-1.94,8.51-52.4,16.91-104.93,20.85-157.92,3.47-46.69,3.29-93.73-3.56-140.12-5.78-39.05-14.49-84.55-44.15-113.11Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M3584.55,702.57c-22.6,.5-25.24,29.71-27.49,46.66-3.41,25.68-4.69,51.58-3.93,77.47,.18,6.32,9.4,6.24,9.76,0,1.24-21.51,3.91-42.9,8.17-64.03,2.09-10.39,4.52-20.74,7.36-30.95,2.08-7.48,4.61-14.7,10.27-20.07,.69-.39,1.21-.95,1.57-1.62,4.33-3.58-1.05-9.43-5.71-7.46Z" style={{ fill: '#d2d4d5' }} />
                    </g>
                </g>
                <g>
                    <path d="M3210.59,817.88c-3.96-19.61,6.16-38.09-5.75-56.16-8.59-13.03-23.86-19.47-33.28-31.57-15.94-20.47-6.41-47.51-15.81-70.27-15.69-37.96-71.2-80.62-115.02-73.84-7.75,1.2,13.19,13.82,20.38,16.69,7.38,2.94,14.73,6.01,21.9,9.32-60.96,54.67-48.68,165.47-35.77,236.52,6.96,38.28,8.22,81.04,50.21,95.57,21.09,7.3,44.3,7.4,66.28,6.53,20.59-.81,45.6-.71,64.96-9.24,23.02-10.14,36.84-31.24,28.75-56.4-8.49-26.39-41.42-40.26-46.85-67.15Z" />
                    <path d="M3040.73,586.04c-46.09-14.47-125.28,20.07-124.11,72.58,.18,7.91,1.47,15.87-.08,23.71-2.35,11.9-9.88,16.61-18.9,23.06-9.74,6.95-17.2,15.71-21.02,27.21-4.16,12.53-1.98,23.19,.52,35.69,2.54,12.73,1.89,21.31-8.38,29.97-6.59,5.56-25.22,32.27-28.22,40.32-15.61,41.84,2.2,58.5,19.18,75.23,22.81,22.48,57.1-5.07,82.06,10.18,10.25,6.26-10.03-37.62,2.47-36.08,11.26,1.39,26.76,.22,37.24-4.04,21.21-8.61,33.09-42.94,36.36-63.62,2.75-17.42-1.58-34.38-4.47-51.47-3.55-20.94-5.78-42.94-3.19-64.11,2.5-20.44,8.53-39.67,16.69-58.35,8.72-19.95,14.64-60.66,13.85-60.28Z" />
                    <path d="M3184.46,806.03c3.8-16.3-7.46-35.97-20.56-46.97-11.72-9.86-13.31-26.25-13.32-26.41-.12-1.28-1.46-2.2-2.57-2.15-1.31,.12-2.27,1.27-2.16,2.57,.07,.75,1.75,18.48,15.01,29.63,11.98,10.07,22.35,27.82,18.99,42.27-3.59,15.33,2.2,32.92,9.46,41.68,.48,.57,1.15,.86,1.83,.86,.54,0,1.07-.18,1.51-.54,1.01-.84,1.16-2.33,.32-3.34-5.61-6.77-11.95-22.89-8.51-37.6Z" style={{ fill: '#919191' }} />
                    <path d="M2917.11,720.46c-.86,.64-20.94,15.85-16.62,36.85,4.57,22.29,7.17,44.31-4.79,54.5-1,.86-1.12,2.35-.28,3.34,.48,.56,1.14,.83,1.8,.83,.55,0,1.09-.18,1.53-.56,13.83-11.8,11.23-35.36,6.36-59.07-3.69-18.01,14.64-31.95,14.82-32.1,1.04-.78,1.27-2.27,.49-3.31-.78-1.05-2.26-1.27-3.31-.48Z" style={{ fill: '#919191' }} />
                    <path d="M2937.38,794.39c-1.31,.16-2.22,1.34-2.07,2.64,.04,.25,2.74,24.51-18.18,39.63-1.06,.77-1.3,2.26-.53,3.31,.46,.64,1.19,.98,1.92,.98,.49,0,.96-.14,1.39-.44,23.17-16.74,20.24-42.94,20.1-44.05-.14-1.3-1.34-2.28-2.63-2.07Z" style={{ fill: '#919191' }} />
                    <g>
                        <g>
                            <path d="M2891.33,2246.99c6.66-7.69,10.82-12.73,10.82-12.73,0,0-4.54,5.22-10.82,12.73Z" style={{ fill: '#176ab5' }} />
                            <path d="M2941.11,2249.56s-79.32,54.4-86.1,50.54c-4.81-2.74,20.95-34.76,36.31-53.11-17.86,20.61-53.75,60.32-76.01,73.41-4.54,2.67-4.07,18.16-.31,18.67s54.1,.8,62.76-1.4c8.66-2.19,57.08-52.86,57.08-52.86l6.38,43.99,10.41,.13s3.13-44.1,5.05-55.04c1.92-10.94-7.43-30.98-7.43-30.98l-8.14,6.65Z" style={{ fill: '#176ab5' }} />
                        </g>
                        <path d="M2921.9,2183.74l-19.75,50.52s-55.95,59.13-47.13,65.84c8.82,6.72,94.34-44.75,94.25-57.18,.54-19.94-4.21-59.18-4.21-59.18h-23.16Z" style={{ fill: '#d2d4d5' }} />
                    </g>
                    <g>
                        <g>
                            <path d="M3214.48,2226.27c3.64-9.51,5.83-15.67,5.83-15.67,0,0-2.49,6.46-5.83,15.67Z" style={{ fill: '#176ab5' }} />
                            <path d="M3267.55,2202.65l-5.39,9.03s-55.98,78.21-63.67,76.89c-5.45-.93,7.82-39.81,15.99-62.31-9.75,25.48-29.92,75.04-46.37,94.94-3.36,4.06,2.37,18.46,6.08,17.66,3.71-.81,51.12-17.72,58.51-22.74s35.61-69.17,35.61-69.17l21.02,39.16,9.83-3.43s-12.11-42.52-14.04-53.46c-1.94-10.93-17.57-26.57-17.57-26.57Z" style={{ fill: '#176ab5' }} />
                        </g>
                        <path d="M3222.1,2160.97l-18.45,1.93,16.66,47.7s-32.4,74.68-21.82,77.98c10.58,3.3,73.4-74.27,69.06-85.93-20.34-14.12-45.45-41.68-45.45-41.68Z" style={{ fill: '#d2d4d5' }} />
                    </g>
                    <path d="M3206.98,1378.93c3.95-48.44-53.3-166.38-53.3-166.38h-266.5s-39.48,122.15-47.38,183.23c-7.9,61.07,62.35,838.47,62.35,838.47l47.11,8.66,74.78-706.11,27.67,323.7c.62,7.23,2.28,14.33,4.95,21.08l163.65,329.02,47.24-7.95-74.86-328.21s12.14-469.21,14.29-495.51Z" style={{ fill: '#d2d4d5' }} />
                    <path d="M2887.18,1212.55s-39.48,122.15-47.38,183.23c-4.09,31.66-5.47,266.57,14.27,467.31,240.66,56.78,328.25,31.6,441.9-37.74-75.99-166.59-15.11-442.01-128.98-583.6-41.76-51.92-279.81-29.2-279.81-29.2Z" style={{ fill: '#217dd3' }} />
                    <path d="M2824.82,967.36c-26.87,42.57-54.54,134.04-66.38,138.27-22.87-6.21-16.84,102.27,33.89,92.03,26.69-5.39,109.09-133.94,128.46-206.57,18.45-69.19-68.69-66.94-95.97-23.73Z" style={{ fill: '#d2d4d5' }} />
                    <path d="M2940.82,863.37c-31.24-26.1-107.64,28.72-137.98,103.06,16.6,24.14,95.85,47.32,135.23,54.15,33.71-63.17,26.63-137.26,2.75-157.21Z" style={{ fill: '#71b0ce' }} />
                    <polygon points="2566.54 1136.31 2634.3 1146.8 2636.28 1090.25 2581.32 1078.71 2566.54 1136.31" style={{ fill: '#898989' }} />
                    <path d="M3156.33,870.36c-39.62-23.08-96.36-34.46-142.06-30.44-50.98,4.5-102.1,10.99-118.06,35.49-46.14,70.79-49.02,134.46-22.34,212.18,7.98,23.24,8.12,75.07,12.49,126.95,0,0,110.43,12.05,263.65,3.16-11.04-84.48,4.53-212.37,7.73-221.39,4.33-12.22,43.72-99.67-1.41-125.95Z" style={{ fill: '#71b0ce' }} />
                    <path d="M3073.47,771.46s-58.94,24.86-79.67,21.4c0,0,.47,43.16-22.74,53.85l42.74,50.06s56.19-40.37,77.24-52.06c-20.87-17.36-18.36-49.34-17.57-73.25Z" style={{ fill: '#d2d6d9' }} />
                    <g>
                        <path d="M3014.71,661.03c-17.48-24.58-9.62-50.15-6.8-57.4l-2.75-1.07c-2.96,7.6-11.19,34.39,7.14,60.18,15.46,21.74,44.44,34.57,75.63,33.49l-.1-2.95c-30.21,1.05-58.23-11.3-73.12-32.25Z" style={{ fill: '#222' }} />
                        <path d="M3050.91,600.77c-56.4-6.75-89.1,27.66-95.29,79.38,0,0,.21,35.65,.02,38.36-.03,.35-18.08,58.1,32.47,106.27,2.86,2.72,6.9,3.41,10.95,3.86,84.32,9.24,83.74-73.35,83.74-73.35,1.15-4.17,33.69-46.72,34.22-51.14,6.18-51.73-9.71-96.63-66.11-103.38Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2995.87,774.46l-.99,2.84c.37,.13,4.55,1.52,10.94,1.17,7.75-.43,18.76-3.42,30.15-14.37l-2.08-2.17c-19.38,18.63-37.26,12.79-38.02,12.53Z" style={{ fill: '#1c1c1c' }} />
                        <path d="M3047.21,694.39c-.8-.43-20.33-8.04-24.01-3.94-1.46,1.63-1.52,4.86-.15,6.96,2.32,3.56,7.31,1.72,19.17,4.26,5.57,1.19,7.83,2.32,8.31,1.52,.82-1.38,2.79-5.51-3.32-8.8Z" style={{ fill: '#222' }} />
                        <path d="M2964.54,693.37c-5.24,2.27-5.03,5.45-4.45,6.63,.34,.69,2.28-.07,6.92-.66,9.91-1.25,13.87,.61,16.02-2.15,1.27-1.63,1.45-4.27,.36-5.72-2.72-3.63-18.16,1.6-18.85,1.9Z" style={{ fill: '#222' }} />
                        <path d="M2987.15,744.24c-.95-3.66,1.33-8.45,6.59-13.85l-2.15-2.1c-6.13,6.29-8.6,11.91-7.34,16.72,1.64,6.27,8.96,8.5,9.27,8.6l.86-2.88c-.07-.02-6.03-1.86-7.23-6.49Z" style={{ fill: '#1c1c1c' }} />
                        <g>
                            <path d="M3101.81,715.1c-15.11-2.44-29.56,9.14-32.27,25.87s7.35,32.28,22.46,34.72c15.11,2.44,29.56-9.14,32.27-25.87,2.71-16.73-7.35-32.27-22.46-34.72Z" style={{ fill: '#d2d4d5' }} />
                            <path d="M3095.58,733.05c-4.63,2.3-7.93,7.15-9.79,14.42l-.48,1.87h1.93c2.92,.03,10.98,.95,13.15,4.98l2.65-1.43c-2.65-4.91-10.22-6.14-13.83-6.45,1.67-5.37,4.25-8.96,7.68-10.68,4.62-2.31,9.34-.4,9.39-.38l1.16-2.77c-.25-.11-6-2.48-11.86,.44Z" style={{ fill: '#1c1c1c' }} />
                        </g>
                        <g>
                            <path d="M3081.88,728.32c-19.2,1.06-33.91,17.48-32.85,36.68,.81,14.68,10.6,26.7,23.74,31.09,10.73-19.67,10.6-40.75,10.6-40.75,.64-13.16-1.49-27.02-1.49-27.02Z" style={{ fill: '#d2d6d9' }} />
                            <path d="M2953.37,730.12c-1.61,12.43-2,33.47,7.69,56.24,7.25-6.79,11.57-16.62,10.98-27.32-.7-12.71-8.15-23.41-18.67-28.92Z" style={{ fill: '#d2d6d9' }} />
                        </g>
                        <path d="M3035.53,714.34c-2.16-.3-4.32,2.46-4.84,6.16-.52,3.7,.82,6.95,2.97,7.25,2.16,.3,4.32-2.46,4.84-6.16,.51-3.71-.82-6.95-2.97-7.25Z" style={{ fill: '#1c1c1c' }} />
                        <path d="M2973.36,714c-1.97-.27-3.94,2.24-4.41,5.61s.74,6.33,2.71,6.6c1.97,.27,3.94-2.24,4.41-5.61s-.74-6.33-2.71-6.6Z" style={{ fill: '#1c1c1c' }} />
                    </g>
                    <path d="M2897.44,873.6c-.98-.65-2.36-.42-3.04,.58-27.32,40.14-40.59,84.96-39.44,133.2,.45,18.34,5.97,39.06,11.29,59.11,2.03,7.61,3.94,14.8,5.49,21.58,.23,.98,1.09,1.66,2.06,1.69,.19,.01,.37-.01,.57-.05,1.17-.27,1.91-1.45,1.64-2.63-1.58-6.85-3.49-14.08-5.53-21.72-5.27-19.8-10.71-40.27-11.14-58.09-1.14-47.32,11.87-91.27,38.68-130.64,.67-.98,.42-2.35-.58-3.03Z" style={{ fill: '#1c1d1e' }} />
                    <path d="M3055.28,1056.83c-38.4-11.1-119.94-11.74-153.08-1.19-1.15,.37-1.78,1.6-1.42,2.75,.29,.9,1.11,1.49,2.01,1.52,.24,.01,.5-.03,.74-.1,32.54-10.38,112.73-9.72,150.53,1.21,1.17,.33,2.39-.33,2.71-1.49,.35-1.15-.33-2.37-1.49-2.7Z" style={{ fill: '#1c1d1e' }} />
                    <path d="M3013.47,890l-38.58-45.86c-7.93,.06-14.09,2.07-14.09,2.07l51.82,55.95,88.09-54.09-9.67-3.36c-24.94,7.96-77.57,45.29-77.57,45.29Z" style={{ fill: '#1c1d1e' }} />
                    <path d="M2574.29,1106.49c-11.7-3.56-12.66-17.01-12.67-17.14-.07-1.27-1.15-2.3-2.44-2.16-1.28,.07-2.24,1.17-2.16,2.44,.03,.69,1.2,16.8,15.93,21.29,.19,.05,.39,.08,.59,.09,1.02,.03,1.98-.6,2.29-1.63,.38-1.22-.31-2.51-1.54-2.89Z" style={{ fill: '#8b8b8b' }} />
                    <g>
                        <path d="M2955.61,1123.39c-14-9.07-31.87-26.87-49.89-24.48-11.94,1.58-24.82,4.28-36.27,8.01-5.6,1.82-26.8,11.92-25.16,18.03,1.22,4.51,13.47,1.13,24.6-1.29,10.73-2.34,20.41-3.67,18.23-2.12-9.83,7.03,2.96,14.73,2.88,14.84-.94,1.25-9.1,9.39,6.48,14.02-1.24,2.14,1.09,8.48,2.75,9.12,8,3.05,14.16-.13,20.81,1.73,9.19,2.57,12.42,6.7,22.37,4.57,18.52-3.98,20.85-23.84,14.42-38.4,.53-1.37,.34-3.03-1.22-4.03Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2908.42,1096.4c-.86,.08-21.39,2.19-39.7,8.33-11.21,3.75-24.35,10.63-26.68,16.85-.77,2.04-.28,3.59,.28,4.54,1.19,2.04,3.11,2.83,5.53,2.92,2.87,.1,6.45-.8,10.29-1.76,3.16-.79,6.74-1.7,10.26-2.14,9.84-1.25,18.64-1.27,18.74-1.28,1.27,0,2.3-1.03,2.3-2.3s-.98-2.26-2.22-2.3h-.08c-.37,0-9.17,.01-19.32,1.31-3.79,.48-7.52,1.42-10.79,2.25-4.38,1.09-9.86,2.51-10.71,.99-.03-.05-.1-.18,.05-.59,1.26-3.36,10.73-9.7,23.83-14.09,17.81-5.97,38.49-8.09,38.7-8.11,1.27-.13,2.19-1.27,2.07-2.53-.15-1.29-1.36-2.1-2.55-2.09Z" style={{ fill: '#d2d6d9' }} />
                        <path d="M2895.22,1133.44c-.05,.02-4.46,.78-7.21-1.2-1.19-.86-1.88-2.17-2.12-3.99-.52-3.95,4.97-6.25,5.03-6.27,1.17-.48,1.74-1.83,1.26-3-.48-1.18-1.83-1.78-3-1.28-.36,.14-8.85,3.65-7.87,11.14,.4,3.11,1.75,5.5,4.01,7.15,2.28,1.65,5.06,2.13,7.22,2.21,1.95,.07,3.4-.19,3.53-.21,1.25-.25,2.07-1.45,1.83-2.69-.22-1.28-1.51-2.1-2.68-1.86Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2906.69,1118.51c-.15-1.27-1.27-2.27-2.56-2.02-1.27,.14-2.17,1.29-2.02,2.56,.1,.85,2.47,20.82,8.99,31.45,.42,.69,1.14,1.07,1.89,1.1,.44,.01,.88-.08,1.28-.34,1.09-.66,1.43-2.08,.76-3.17-5.98-9.78-8.31-29.39-8.34-29.58Z" style={{ fill: '#d2d6d9' }} />
                        <path d="M2920.33,1158.95c-.22-.03-5.27-.65-15.28-.44-2.22-.02-3.66-.51-4.68-1.62-2.03-2.24-1.6-6.18-1.59-6.22,.12-1.03-.46-2.02-1.41-2.41-4.38-1.8-6.9-3.88-7.32-6.02-.43-2.22,1.55-4.18,1.57-4.21,.9-.88,.93-2.34,.04-3.25-.88-.89-2.33-.94-3.25-.07-.14,.15-3.78,3.67-2.9,8.35,.64,3.48,3.54,6.45,8.61,8.81,.03,2.07,.49,5.52,2.8,8.09,1.78,1.98,4.26,3.04,7.37,3.15,.28,.01,.56,.01,.84,.01,9.61-.19,14.59,.39,14.61,.42,1.18,.12,2.42-.75,2.58-2.01,.17-1.27-.73-2.42-1.99-2.58Z" style={{ fill: '#d2d6d9' }} />
                    </g>
                    <path d="M3094.54,968.05c-1.14,50.33,22.2,143,14.22,152.72-22.81,6.44-153.16,2.62-153.16,2.62,6.12,11.03-15.7,40.44-15.7,40.44,0,0,207.04,53.29,245.26,18.42,20.12-18.35,24.62-170.98,3.86-243.23-19.76-68.82-93.33-22.06-94.48,29.03Z" style={{ fill: '#d2d4d5' }} />
                    <path d="M3153.28,866.05c-39.51-9.82-84.24,72.89-79.03,153.01,25.48,14.45,106.89,.65,145.29-10.42,2.7-71.55-36.06-135.09-66.26-142.59Z" style={{ fill: '#71b0ce' }} />
                    <path d="M3146.69,863.07c-25.86,2.25-40.76,26.63-47.91,38.34-.71,1.17-1.35,2.2-1.92,3.08-22.65,35.4-27.09,102.27-24.76,114.97l.18,.98,.86,.5c6.21,3.62,16.26,5.21,30.5,5.71,7.69,.27,16.6,.22,26.8,.01,1.2-.03,2.15-1.03,2.14-2.24-.03-1.19-.8-2.04-2.24-2.14-27.32,.59-45.12,0-54.1-4.62-1.78-14.23,3.18-77.81,24.31-110.82,.57-.9,1.22-1.96,1.95-3.15,6.78-11.1,20.89-34.21,44.56-36.28,1.21-.1,2.09-1.16,1.98-2.36-.07-1.21-.96-2.15-2.35-1.98Zm-72.49,156h0Z" style={{ fill: '#1c1d1e' }} />
                    <path d="M2886.36,1214.53l-51.39,33.16c194.09,58.79,360.08,12.57,360.08,12.57l-46.21-52.66c-36.67,7.76-262.48,6.93-262.48,6.93Z" style={{ fill: '#71b0ce' }} />
                    <g>
                        <path d="M2843.8,1031.64c-1-8.26-7.87-14.57-16.18-14.85l-276.22-9.65c-10.37-.36-18.61,8.62-17.36,18.92l23.91,197.67c1,8.26,7.86,14.56,16.18,14.85l276.22,9.65c10.37,.36,18.61-8.62,17.36-18.92l-23.91-197.67Z" style={{ fill: '#183553' }} />
                        <path d="M2828.43,1031.1c-1-8.26-7.87-14.57-16.19-14.86l-276.22-9.64c-10.37-.36-18.61,8.62-17.36,18.91l23.91,197.67c1,8.26,7.86,14.57,16.18,14.86l276.21,9.65c10.37,.36,18.61-8.62,17.36-18.92l-23.89-197.67Z" style={{ fill: '#298ad1' }} />
                    </g>
                    <g>
                        <path d="M2571.19,1111.71c-1.65-1.25-3.56-2-5.55-2.57-.84-.55-4.91-.16-6.09-.34-13.73-2.12-27.13-6.78-38.19-3.1-4.71,1.57-7.59,7.02-5.04,12.24,2.76,5.66,10.24,6.35,15.45,7.55,7.98,1.83,16.03,3.2,24.13,4.04,5.69,.59,14.3,2.62,18.25-2.71,3.6-4.87,1.66-11.59-2.96-15.11Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2574.56,1128.55c-1.65-1.25-3.56-2.01-5.55-2.58-.84-.55-1.83-.97-3.01-1.15-13.73-2.12-27.95-9.17-41.27-2.29-4.4,2.28-7.59,7.02-5.03,12.24,2.76,5.66,10.24,6.35,15.45,7.54,7.98,1.83,16.03,3.2,24.13,4.04,5.68,.59,14.3,2.62,18.25-2.71,3.59-4.85,1.65-11.57-2.97-15.09Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2568.83,1142.8c-1.43-1.03-3.1-1.62-4.85-2.07-.73-.45-1.6-.78-2.63-.91-12.09-1.51-24.51-7.19-36.43-1.06-3.94,2.03-6.85,6.13-4.72,10.51,2.32,4.75,8.91,5.18,13.49,6.08,7.01,1.38,14.1,2.37,21.24,2.91,5.01,.38,12.59,1.92,16.19-2.7,3.29-4.2,1.72-9.87-2.29-12.76Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2559.92,1157.13c-1.05-.76-2.29-1.2-3.58-1.53-.54-.34-1.18-.58-1.94-.67-8.91-1.13-18.06-5.33-26.84-.82-2.9,1.5-5.04,4.52-3.46,7.75,1.71,3.51,6.57,3.83,9.94,4.5,5.17,1.02,10.39,1.76,15.65,2.17,3.69,.28,9.27,1.43,11.92-1.98,2.43-3.1,1.27-7.28-1.69-9.42Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M2578.23,1121.66c.78-2.3,.5-4.58-.86-6.61-6.37-9.52-37.34-11.66-40.85-11.86h-.06c-1.25-.04-2.31,.92-2.38,2.16-.08,1.27,.89,2.37,2.16,2.44,12.37,.76,33.44,4.07,37.29,9.84,.55,.82,.65,1.6,.33,2.52-1.39,4.05-4.03,11.61-48.54,.13-.42-.08-3.28-.63-5.72,1.27-1.9,1.47-2.92,3.88-3.03,7.16-.31,9.02,12.1,15.1,36.89,18.04,4.45,.52,8.48,.94,12,1.07,8.34,.29,13.89-1.1,15.8-6.74,2.18-6.43-3.47-13.61-5.02-15.42,.89-1.24,1.5-2.6,1.99-4Zm-1.35,17.94c-1.48,4.3-8.56,4.26-22.87,2.56-20.77-2.46-33.05-7.43-32.84-13.28,.04-1.27,.3-2.93,1.25-3.67,.78-.6,1.83-.43,1.82-.43,29.5,7.61,42.37,7.28,48.51,3.89,1.79,2.07,5.38,7.25,4.13,10.93Z" style={{ fill: '#d2d6d9' }} />
                        <path d="M2572.31,1143.09c-1.2,.43-1.82,1.75-1.4,2.95,.01,.04,1.45,4.22-.56,7.14-1.44,2.08-4.42,3.19-8.87,3.29-16.05,.45-33.78-4.39-38.41-6.96-.54-.3-1.16-.84-1.08-2.3,.15-2.68,2.41-5.75,3.81-6.33,1.18-.48,1.73-1.83,1.24-3.01-.48-1.17-1.84-1.75-3.01-1.24-3.3,1.37-6.41,6.19-6.65,10.32-.16,2.94,1.07,5.28,3.47,6.61,4.62,2.56,20.13,6.96,35.65,7.5,1.7,.06,3.4,.07,5.09,.03,6.03-.14,10.26-1.94,12.58-5.33,3.33-4.87,1.17-11.02,1.08-11.28-.43-1.2-1.72-1.84-2.94-1.39Z" style={{ fill: '#d2d6d9' }} />
                    </g>
                    <path d="M3004.22,1209.03c-36.43,5.26-117,3.33-117.81,3.31-1.2-.03-2.21,.93-2.24,2.13s.92,2.2,2.11,2.24h.02c.81,.02,81.74,1.95,118.53-3.36,1.2-.18,2.02-1.28,1.86-2.47-.18-1.19-1.3-2.03-2.47-1.85Z" style={{ fill: '#1c1d1e' }} />
                    <path d="M3107.43,620.66c-30.32-27.68-76.38-30.88-76.38-30.88-5.9,22.89-15.62,51.4-15.62,51.4,0,0,11.32,14.09,31.55,26.33s28.36,6.1,31.94,35.95-2.21,48.66,9.01,37.5,8.24-27.05,24.1-20.97c15.88,6.08,25.72-71.65-4.6-99.33Z" />
                    <path d="M2935.29,691.64c10.03-33.68,93.14-54.15,93.14-54.15,20.15-16.5,12.29-51.46,12.29-51.46-116.58-8.44-105.43,105.61-105.43,105.61Z" />
                </g>
                <g>
                    <polygon points="3849.96 1809.49 3857.5 2474.66 3901.05 2474.39 3893.51 1809.22 3849.96 1809.49" style={{ fill: '#183553' }} />
                    <path d="M4036.98,2454.74l-315.65,1.97c-10.38,.06-18.74,8.53-18.68,18.91,.07,10.38,8.53,18.75,18.92,18.68l315.64-1.97c10.38-.06,18.75-8.53,18.68-18.91-.06-10.38-8.53-18.74-18.91-18.68Z" style={{ fill: '#183553' }} />
                    <path d="M3880.7,2009.51l-15.44,.1c-25.54,.16-46.1,20.99-45.94,46.52l.46,73.18c.16,25.53,20.99,46.1,46.52,45.94l15.45-.1c25.53-.16,46.1-20.99,45.94-46.52l-.46-73.18c-.17-25.53-20.99-46.1-46.53-45.94Z" style={{ fill: '#1a3e61' }} />
                    <path d="M3807.94,1621.1l-87.33,483.09,418.07-29.43,121.81-480.23-410.31-26.34c-12.21,.44-43.36,20.95-42.24,52.91Z" style={{ fill: '#5d8aa9' }} />
                    <path d="M3548.41,1999.92c-50.02,1.75-49.7,72.85-31.09,77.16,88.05,20.4,473.94,31.36,473.94,31.36l-34.55-88.57-408.3-19.95Z" style={{ fill: '#818181' }} />
                    <path d="M4268.82,1595.48c-32.39-4.96-47.48,13.35-50.62,25.15l-90.76,393.27-232.14,8.11c-39.46,1.38-64.17,74.98-26.35,86.3,32.12,9.61,286.35,2.51,311.7-46.49,60.16-116.28,119.46-412.99,119.46-412.99,1.54-29.01-11.63-50.33-31.29-53.35Z" style={{ fill: '#72abd1' }} />
                    <path d="M3758.7,2484.4c-15.11,.09-27.29,12.42-27.2,27.54,.09,15.11,12.42,27.29,27.54,27.2,15.11-.09,27.29-12.43,27.2-27.54-.09-15.12-12.42-27.29-27.54-27.2Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M3758.76,2494.07c-9.77,.06-17.64,8.03-17.58,17.81,.06,9.77,8.03,17.65,17.8,17.59,9.77-.06,17.65-8.03,17.59-17.81-.07-9.77-8.04-17.65-17.81-17.59Z" style={{ fill: '#1a3e61' }} />
                    <path d="M3879.33,2483.65c-15.11,.09-27.29,12.42-27.2,27.54,.09,15.11,12.43,27.29,27.54,27.2,15.12-.09,27.29-12.42,27.2-27.54-.09-15.12-12.42-27.3-27.54-27.2Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M3879.39,2493.32c-9.77,.06-17.65,8.03-17.59,17.81,.06,9.77,8.03,17.65,17.81,17.59,9.77-.06,17.65-8.03,17.58-17.81-.06-9.77-8.03-17.65-17.8-17.59Z" style={{ fill: '#1a3e61' }} />
                    <path d="M3999.96,2482.9c-15.12,.09-27.29,12.42-27.2,27.54s12.42,27.3,27.54,27.2c15.12-.09,27.3-12.43,27.2-27.54-.09-15.12-12.42-27.3-27.54-27.2Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M4000.02,2492.57c-9.77,.06-17.65,8.03-17.59,17.81,.06,9.77,8.03,17.65,17.81,17.59,9.77-.06,17.65-8.03,17.58-17.8-.05-9.79-8.03-17.66-17.8-17.6Z" style={{ fill: '#1a3e61' }} />
                </g>
                <g>
                    <g>
                        <polygon points="3351.73 2528.61 3419.42 2545.71 3426.04 2528.35 3442.14 2445.29 3368.68 2440.9 3351.73 2528.61" style={{ fill: '#d9d9e0' }} />
                        <path d="M3349.23,2502.47s-41.81,29.87-62.43,32.9c-20.62,3.03-97.99,4.63-78.51,31.09,31.89,43.3,169.69,42.22,214.71,12.81,10.67-6.98,10.16-27.5,3.04-50.92,0,0-50.58,5.5-76.81-25.88Z" />
                        <path d="M3425.78,2525.81c-.47,.03-45.44,4.36-74.74-25.14-1.01-.96-2.58-1-3.61-.01-1,.99-1,2.61-.01,3.61,20.56,20.69,47.63,25.75,64.14,26.73,8.41,.5,14.09-.05,14.74-.12,1.41-.15,2.42-1.4,2.27-2.8-.14-1.4-1.47-2.36-2.79-2.27Z" style={{ fill: '#242424' }} />
                        <path d="M3334.45,2511.95c-.93-1.07-2.55-1.19-3.6-.28-1.07,.92-1.19,2.52-.28,3.59l11.01,12.82c.46,.55,1.11,.85,1.78,.89,.63,.04,1.3-.17,1.81-.62,1.07-.91,1.19-2.52,.28-3.59l-11-12.81Z" style={{ fill: '#242424' }} />
                        <path d="M3316.46,2523.04c-.93-1.1-2.55-1.18-3.59-.28-1.07,.92-1.19,2.54-.27,3.59l11.02,12.81c.47,.56,1.12,.85,1.78,.89,.63,.04,1.3-.17,1.81-.62,1.07-.91,1.19-2.54,.28-3.59l-11.03-12.8Z" style={{ fill: '#242424' }} />
                    </g>
                    <path d="M3612.13,1850.08c-26.78,4.48-225.54,119.99-240.64,177.77-26.42,101.09-31.46,455.96-31.46,455.96,31.92,12.75,117.52,19.55,117.52,19.55,0,0,54.24-378.36,66.89-399.89,12.65-21.53,248.26-72.97,269.9-118.27s69.5-117.4,69.5-117.4c0,0-224.93-22.19-251.71-17.72Z" style={{ fill: '#176ab5' }} />
                    <g>
                        <polygon points="3792.31 2384.98 3762.57 2452.28 3838.84 2498.77 3878.49 2441.31 3864.53 2429.05 3792.31 2384.98" style={{ fill: '#d9d9e0' }} />
                        <path d="M3864.53,2429.05s-12.5,49.32-51.05,62.95c0,0,13.4,49.61,9.04,69.99-4.36,20.38-29.85,93.44,1.74,84.42,51.71-14.77,98.78-144.29,86.93-196.75-2.81-12.44-22.23-19.11-46.66-20.61Z" />
                        <path d="M3865.16,2426.58c-1.44-.28-2.76,.47-3.1,1.83-.11,.43-11.77,44.1-49.63,61.26-1.28,.58-1.85,2.09-1.28,3.37,.41,.89,1.26,1.45,2.18,1.5,.4,.03,.81-.05,1.2-.22,40.14-18.2,52-62.76,52.48-64.64,.34-1.35-.48-2.75-1.85-3.1Z" style={{ fill: '#242424' }} />
                        <path d="M3833.05,2503.31l-15.85,5.85c-1.31,.48-2,1.95-1.51,3.27,.36,.98,1.26,1.6,2.24,1.66,.34,.02,.7-.03,1.04-.16l15.85-5.85c1.32-.48,2-1.95,1.51-3.27-.49-1.3-1.98-1.98-3.28-1.5Z" style={{ fill: '#242424' }} />
                        <path d="M3837.17,2524.05l-15.86,5.85c-1.32,.48-2,1.95-1.5,3.27,.36,.98,1.26,1.6,2.24,1.66,.35,.02,.7-.03,1.04-.16l15.85-5.85c1.32-.48,2-1.95,1.51-3.27-.49-1.31-1.99-1.99-3.28-1.5Z" style={{ fill: '#242424' }} />
                    </g>
                    <path d="M3481.56,1661.77l-210.89,100.03,14.55,46.79s206.3-26.12,251.5-49.93c46.59-24.54,144.93-253.98,144.93-253.98l-116.08-47.61-84.01,204.7Z" style={{ fill: '#d9d9e0' }} />
                    <path d="M3810.69,1871.8c-26.78,4.48-241.78,170.99-256.62,276.76-8.3,59.15,205.58,352.72,205.58,352.72,62.45-56.93,97.36-112.44,97.36-112.44,0,0-116.66-180.57-128.78-218.71,17.14-13.19,257.74-126.9,279.38-172.2,21.64-45.3,9.02-139.87,9.02-139.87,0,0-179.16,9.26-205.94,13.74Z" style={{ fill: '#217dd3' }} />
                    <path d="M3728.69,1930.68c-92.06,53.37-141.71,139.84-142.2,140.71-.67,1.17-.25,2.66,.92,3.32,.33,.19,.69,.29,1.06,.31,.9,.05,1.79-.4,2.26-1.22,.49-.86,49.53-86.21,140.4-138.9,1.17-.68,1.56-2.16,.89-3.32-.67-1.19-2.15-1.6-3.33-.9Z" style={{ fill: '#242424' }} />
                    <path d="M3896.76,1234.18s-76.62,16.79-97.89,10.51c0,0,.16,41.37-1.66,71.85,0,0,36.04,46.16,100.67,1.69,1.5-25.07-1.79-57.83-1.12-84.05Z" style={{ fill: '#b6c8ce' }} />
                    <path d="M3705.12,1331.21c-56.97-41.03-174.22,167.87-174.22,167.87,0,0,68.32,64.94,155.14,77.68,0,0,68.25-210.15,19.08-245.55Z" style={{ fill: '#176ab5' }} />
                    <path d="M3905.48,1302.2s-71.94,18.83-118.09-7.05c0,0-114.6,33.63-125.52,45.98s-52.84,481.3-49.74,508.94c0,0,190.26,94.79,429.33,65.36,8.7-145.86-6.77-550.61-23.27-564.6-16.51-13.98-112.71-48.63-112.71-48.63Z" style={{ fill: '#71b0ce' }} />
                    <path d="M3662.45,1338.92c-1.33-.41-2.48,.41-2.82,1.65-4.23,16.13-29.82,212.43-30.9,220.78-.17,1.26,.72,2.42,1.99,2.58,.05,.02,.11,.02,.16,.02,1.21,.07,2.26-.79,2.42-2.01,.27-2.04,26.65-204.34,30.8-220.2,.33-1.24-.42-2.5-1.65-2.82Z" style={{ fill: '#4792ab' }} />
                    <path d="M3861.56,1700.68s7.25,81.13,59.88,82.57c52.64,1.44,108.15-272.26,108.15-272.26l-90.82-63.11-77.21,252.8Z" style={{ fill: '#d9d9e0' }} />
                    <path d="M4010.76,1344.1c-68.02-17.39-132.76,202.26-132.76,202.26,0,0,80.99,48.21,168.49,41.43,0,0,55.43-220.39-35.73-243.69Z" style={{ fill: '#176ab5' }} />
                    <path d="M4015.47,1974.57c-9.66-3.39-26.06-10.41-29-14.85-2.98-4.52-4.96-26.51-5.52-39.31-.04-1.36-1.25-2.47-2.53-2.33-1.35,.05-2.4,1.19-2.33,2.53,.14,3.53,1.6,34.67,6.32,41.8,4.8,7.24,28.73,15.8,31.44,16.76,.23,.08,.45,.11,.67,.13,1.06,.06,2.08-.57,2.45-1.61,.43-1.28-.23-2.66-1.5-3.12Z" style={{ fill: '#242424' }} />
                    <path d="M4050.64,1544.94c-47.67-7.65-111.1-7.38-150.93-36.5-6.52-4.77-13.42,5.67-6.95,10.41,42.53,31.09,106.84,30.51,157.13,38.58,7.95,1.27,8.62-11.23,.75-12.49Z" style={{ fill: '#4792ab' }} />
                    <path d="M4010.95,1341.79c-1.99,.09-48.5-3.24-80.03,66.42-30.88,68.2-54.86,136.69-55.1,137.38l-.59,1.71,1.52,.98c.25,.15,25.2,16.13,51.34,23.87,.18,.05,.35,.08,.52,.09,1.05,.06,2.04-.6,2.35-1.65,.36-1.22-.34-2.51-1.56-2.87-21.84-6.47-42.86-18.83-48.62-22.37,3.94-11.08,26.28-73.29,54.33-135.24,29.77-65.75,74.1-63.82,75.44-63.74h.08c1.24,.08,2.34-.86,2.44-2.12,.1-1.23-.85-2.36-2.12-2.46Z" style={{ fill: '#4792ab' }} />
                    <g>
                        <path d="M3879.96,1024.5c-55.97-9.23-91.08,7.86-108.44,65.42-.88,2.92-1.56,5.87-2.29,8.83-3.42,13.93-6.17,30.99-2.5,38.87,0,0-30.19,72.59,27.66,135.09,6.59,7.12,38.47,3.3,47.39,1.52,75.23-15.05,67.71-97.8,67.71-97.8,1.68-5,29.59-6.92,31.19-12.09,15.35-49.72,7.65-128.57-60.72-139.84Z" style={{ fill: '#d9d9e0' }} />
                        <path d="M3766.34,1136.62c.12,.34,.24,.69,.39,1,0,0-17.47,42.11-.27,89.79,11.51-10.69,18.89-27.02,18.89-45.34,0-18.39-7.43-34.76-19.01-45.45Z" style={{ fill: '#b6c8ce' }} />
                        <path d="M3905.73,1153.86c-18.7,0-33.86,19.37-33.86,43.26,0,19.23,9.83,35.51,23.41,41.14,17.22-28.27,14.2-61.83,14.2-61.83,1.09-3.23,13.05-5.17,21.86-7.53-6.2-9.2-15.36-15.04-25.61-15.04Z" style={{ fill: '#b6c8ce' }} />
                        <path d="M3810.38,1210.28l-1.24,3.02c.4,.17,4.64,1.84,11.34,2.11,9.46,.38,23.84-2.03,39.39-15.38l-2.13-2.47c-24.97,21.46-47.14,12.8-47.36,12.72Z" style={{ fill: '#1c1c1c' }} />
                        <path d="M3876.68,1118.53c-.95-.58-24.23-11.17-28.98-6.41-1.89,1.89-2.18,5.81-.66,8.46,2.58,4.49,8.79,2.59,23.06,6.48,6.7,1.83,9.38,3.35,10.02,2.41,1.1-1.62,3.77-6.52-3.44-10.94Z" style={{ fill: '#404040' }} />
                        <path d="M3778.75,1111.16c-6.18,2.93-6.35,8.49-5.84,10.08,.3,.93,2.58-.05,7.9-.74,11.33-1.47,15.6,1.08,18.35-2.55,1.62-2.15,2.13-5.67,1.08-7.62-2.67-4.9-20.68,.44-21.49,.83Z" style={{ fill: '#404040' }} />
                        <path d="M3858.99,1144.77c-2.36-.62-5.06,1.88-6.03,5.59-.97,3.7,.16,7.2,2.52,7.82s5.06-1.88,6.03-5.59-.16-7.21-2.52-7.82Z" style={{ fill: '#1c1c1c' }} />
                        <path d="M3790.71,1136.09c-2.15-.56-4.68,1.97-5.64,5.67-.97,3.69,0,7.14,2.15,7.7,2.15,.56,4.68-1.97,5.64-5.67,.96-3.69,0-7.14-2.15-7.7Z" style={{ fill: '#1c1c1c' }} />
                        <path d="M3897.33,1019.44c-50.13-29.46-102.64-10.42-133.76-1.04s7.95,71.52,7.95,71.52c3.3-14.12,12.27-24.5,12.27-24.5,24.17,22.55,119.42,17.74,119.42,17.74l2.91,87.23,4.34,23.32-2.23,5.41c4.65,11.62,15.78,13.69,21.89,2.77,19.15-34.25,48.17-91.1,38.6-105.56-7.49-11.32-21.26-47.44-71.39-76.89Z" style={{ fill: '#404040' }} />
                        <path d="M3765.5,1002.58l.97,3.11c1-.32,101.31-30.58,159.85,41.27l2.53-2.06c-59.92-73.54-162.32-42.64-163.35-42.32Z" style={{ fill: '#404040' }} />
                        <g>
                            <path d="M3935,1146.85c-15.02-4.8-31.51,4.8-36.82,21.43s2.57,34,17.59,38.8,31.51-4.8,36.82-21.43c5.31-16.63-2.57-34-17.59-38.8Z" style={{ fill: '#d9d9e0' }} />
                            <path d="M3925.89,1164.1c-5.09,1.64-9.2,6.1-12.22,13.24l-.81,1.92,2.07,.32c2.96,.46,10.99,2.62,12.58,7.02l3.07-1.11c-1.95-5.4-9.39-7.82-13.07-8.71,2.5-5.14,5.64-8.36,9.35-9.57,5.01-1.63,9.52,1.01,9.56,1.03l1.69-2.79c-.23-.13-5.75-3.43-12.22-1.35Z" style={{ fill: '#1c1c1c' }} />
                        </g>
                        <path d="M3905.74,1163.24l.98-83.39-7.84-16.77s-23.65,94.67,6.86,100.16Z" style={{ fill: '#404040' }} />
                        <path d="M3817.3,1156.62l-.6-3.21c-3.43,.64-20.64,4.12-23.3,9.84-.59,1.26-.53,2.58,.17,3.81,3.82,6.74,13.03,12.27,13.42,12.5l1.67-2.8c-.09-.05-8.86-5.33-12.26-11.31-.2-.35-.17-.57-.05-.83,1.44-3.08,13.12-6.53,20.95-8Z" style={{ fill: '#1c1c1c' }} />
                    </g>
                </g>
                <g>
                    <rect x="2711.95" y="2627.63" width="870.79" height="41.15" style={{ fill: '#1a3e61' }} />
                    <polygon points="2873.76 2627.63 3416.29 2627.63 3420.92 2584.83 2873.76 2584.83 2873.76 2627.63" style={{ fill: '#183553' }} />
                    <rect x="3122.82" y="1926.67" width="49.05" height="658.16" style={{ fill: '#183553' }} />
                </g>
                <g>
                    <path d="M3145.35,1804.96c-292.89,0-547,19.92-673.62,49.1h-85.57v42.09c0,50.36,339.9,91.19,759.19,91.19s759.19-40.83,759.19-91.19v-42.09h-85.58c-126.61-29.17-380.72-49.1-673.61-49.1Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M3145.35,1762.88c-419.29,0-759.19,40.83-759.19,91.19s339.9,91.19,759.19,91.19,759.19-40.83,759.19-91.19-339.9-91.19-759.19-91.19Z" style={{ fill: '#72abd1' }} />
                </g>
                <g>
                    <g>
                        <path d="M2842.93,1878.22h170.14c10.4,0,18.84-8.43,18.84-18.84h-188.97v18.84h-.01Z" style={{ fill: '#298ad1' }} />
                        <path d="M2860.53,1619.94c-3.41-13.56-15.59-23.11-29.62-23.11h-343.97c-14.67,0-25.46,13.79-21.91,28.09l1.66,6.69,61.49,246.62h396.74l-6.32-25.37-58.07-232.92Z" style={{ fill: '#2993da' }} />
                        <g style={{ opacity: '.31' }}>
                            <path d="M2715.18,1794.18c-51.25-19.65-99.67-42.53-147.46-69.43-26.62-14.99-51.62-33.35-72.38-55.87-7.84-8.49-17.11-26.71-28.65-37.27l61.49,246.62h396.74l-6.32-25.37c-68.18-9.41-138.28-33.72-203.42-58.68Z" style={{ fill: '#6ccbf9' }} />
                        </g>
                    </g>
                    <path d="M2749.16,1738.39c-.98-3.93-4.51-6.68-8.55-6.68h-79.38c-5.73,0-9.94,5.38-8.55,10.94l1.11,4.45c.98,3.93,4.5,6.68,8.55,6.68h79.38c5.73,0,9.94-5.38,8.55-10.94l-1.11-4.45Z" style={{ fill: '#fff' }} />
                </g>
                <g>
                    <path d="M1311.64,1723.09c-135.59,7.22-243.02,138.8-291.35,281.21-3.46,10.18-5.85,22.08-7.29,35.38-16.74,153.78,91.82,495.96,115.73,568.87,.12,.33,.22,.66,.33,.97,.15-.37,.32-.75,.48-1.14l79.87-16.84c-.82-34.64,3.07-101.71,12.02-179.43,16.8-146.19,51.47-330.13,106.33-407.15,91.88-129.07,119.48-289.09-16.12-281.87Z" style={{ fill: '#4896b3' }} />
                    <path d="M1013,2039.67c-33.35-77.6-74.58-144.72-125.41-187.19-213.39-178.25-509.07-191.13-555.87-82.28-46.8,108.87,203.96,173.29,266.85,299.54,62.91,126.25,154.01,81.91,212.38,133.79,34.99,31.09,105.87,184.56,157.02,302.49,12.82,29.59,24.42,56.94,33.9,79.56,8,19.13,14.5,34.87,18.96,45.72l107.9-22.76c.12,.33,.22,.66,.33,.97,.15-.37,.31-.75,.48-1.14l12.4-2.61c-.65-6.4-1.39-13.53-2.24-21.33-12.22-111.47-46.67-358.38-126.7-544.76Z" style={{ fill: '#d2d4d5' }} />
                    <path d="M1014.83,2270.89c-28.69-61.6-61.21-131.42-83.09-195.79-28.02-82.43-56.47-95.88-103.61-118.17-25.95-12.27-58.25-27.54-100.31-59.32-117.9-89.11-306.54-121.2-308.43-121.52-3.44-.57-6.69,1.75-7.26,5.2-.57,3.44,1.76,6.7,5.2,7.27,1.86,.31,187.43,31.88,302.88,119.13,43.1,32.57,76.05,48.15,102.52,60.67,45.62,21.57,70.75,33.45,97.05,110.81,22.1,65.02,54.77,135.17,83.6,197.06,26.27,56.41,48.96,105.13,55.34,132.64,13.2,56.94,10.8,180.71,10.77,181.95-.07,3.49,2.7,6.37,6.19,6.45,.49,.01,.98-.04,1.44-.14,2.81-.59,4.94-3.06,5.01-6.05,.11-5.14,2.46-126.59-11.1-185.07-6.69-28.8-29.63-78.07-56.2-135.12Z" style={{ fill: '#4896b3' }} />
                    <path d="M824.99,2345.11c-88.62-46.74-246.46-60.49-251.26-3.68-4.8,56.8,91.63,85.52,91.63,85.52,0,0,45.62,2,75.87,4.66,30.26,2.66,15.79,31.99,41.59,50.2,25.79,18.23,63.54,25.75,84.74,40.65,21.22,14.9,55.96,20.48,80.7,39.8,15.96,12.47,34.59,46.49,45.79,69.03,.89,1.78,1.93,3.46,3.11,4.99l45.5-9.6c-13.61-23.76-41.42-71.08-74.68-120.68-44.95-66.99-99.83-138.12-142.99-160.89Z" style={{ fill: '#4896b3' }} />
                </g>
                <g>
                    <path d="M2083.88,1555.5c-5.46-3.81-13.92-4.16-20.2-5.61-9.56-2.21-19.1-4.42-28.66-6.63,7.69-.71,15.38-1.41,23.07-2.11,4.75-.44,13.28,.67,17.41-2.34,6.5-4.74-3.42-9.24-7.2-10.41-23.33-7.19-99.76-26.75-114.78,.62-.13,.23,1.6,33.85,8.81,43.09,10.84,13.88,29.3,12.85,45.37,14.99,9.93,1.32,19.84,2.71,29.78,3.96,4.85,.6,12.18,2.31,15.17-3.12,1.67-3.03,.32-5.76-2-7.82-.66-.58-1.42-1.06-2.22-1.5,3.37,.69,6.74,1.32,10.12,1.8,6.95,.99,12.97,.44,18.07-4.62,.55-.54,.55-1.69,.07-2.28-.2-.25-.4-.49-.6-.73,4.24-.97,8.34-2.57,10.4-6.73,1.94-3.97,1.06-7.99-2.61-10.56Z" style={{ fill: '#d2d4d5' }} />
                    <path d="M1759.16,1470.95s-51.83-115.95-72.88-152.63c-23.53-41-98.09-143.73-143.19-123.51-101.38,45.45,99.03,370.05,145.23,391.53,42.43,19.73,284.84-4.67,284.84-4.67l-11.71-67.03-202.29-43.69Z" style={{ fill: '#fcfdfe' }} />
                    <g>
                        <polygon points="1099.1 2457.97 1094.69 2472.24 1145.99 2494.99 1190.92 2439.51 1136.13 2401.61 1099.1 2457.97" style={{ fill: '#d2d4d5' }} />
                        <path d="M1183.72,2531.47c-11.55-12.14-23.19-51.77-23.19-51.77-32.15,6.84-65.53-24.56-65.53-24.56-16.12,11.29-26.62,24.02-23.33,33.72,14.3,42.11,103.44,113.12,146.29,101.21,25.45-7.08-22.7-46.46-34.24-58.6Z" style={{ fill: '#71b0ce' }} />
                        <path d="M1160.28,2477.66c-33.33,4.04-63.58-23.74-63.88-24.02-.86-.78-2.15-.74-2.9,.1-.77,.83-.72,2.13,.1,2.9,1.21,1.12,27.79,25.55,59.7,25.55,2.47,0,4.96-.15,7.48-.47,1.13-.13,1.92-1.16,1.78-2.28-.13-1.13-1.13-1.92-2.28-1.78Z" style={{ fill: '#242424' }} />
                        <path d="M1165.18,2493.02l-13.32,2.65c-1.11,.22-1.83,1.3-1.6,2.41,.19,.98,1.05,1.65,2.01,1.65,.13,0,.26-.01,.4-.04l13.32-2.65c1.11-.22,1.84-1.3,1.61-2.41-.23-1.11-1.26-1.91-2.42-1.61Z" style={{ fill: '#242424' }} />
                        <path d="M1171.06,2508.96l-13.32,2.65c-1.11,.22-1.83,1.3-1.61,2.41,.19,.98,1.05,1.65,2.01,1.65,.13,0,.26-.01,.4-.05l13.32-2.65c1.11-.22,1.83-1.3,1.6-2.41-.2-1.1-1.23-1.83-2.4-1.6Z" style={{ fill: '#242424' }} />
                    </g>
                    <g>
                        <polygon points="1519.24 2595.61 1523.73 2609.86 1578.86 2599.38 1584.24 2528.19 1517.62 2528.19 1519.24 2595.61" style={{ fill: '#d2d4d5' }} />
                        <path d="M1630.64,2607.91c-16.4-3.42-48.52-29.39-48.52-29.39-22.55,23.92-67.86,17.09-67.86,17.09-6.83,18.45-8.23,34.9,0,41,35.72,26.5,149.43,34.18,177.89,0,16.89-20.29-45.11-25.28-61.51-28.7Z" style={{ fill: '#71b0ce' }} />
                        <path d="M1580.74,2577c-24.94,22.3-65.78,16.64-66.2,16.58-1.07-.16-2.16,.61-2.32,1.74-.16,1.12,.61,2.16,1.74,2.32,.59,.09,5.68,.79,13.19,.79,14.76,0,38.84-2.74,56.33-18.37,.85-.76,.91-2.05,.16-2.9-.76-.86-2.06-.91-2.9-.16Z" style={{ fill: '#242424' }} />
                        <path d="M1593.52,2586.84l-9.45,9.76c-.78,.8-.76,2.11,.05,2.9,.39,.38,.91,.57,1.42,.57s1.07-.2,1.48-.63l9.46-9.76c.78-.8,.76-2.11-.05-2.9-.81-.79-2.13-.78-2.91,.06Z" style={{ fill: '#242424' }} />
                        <path d="M1607.43,2596.59l-9.46,9.76c-.78,.8-.76,2.11,.05,2.9,.39,.38,.91,.58,1.42,.58s1.07-.21,1.48-.63l9.45-9.76c.78-.8,.76-2.11-.05-2.9-.8-.78-2.11-.78-2.89,.05Z" style={{ fill: '#242424' }} />
                    </g>
                    <path d="M1262.23,1720.99c-16.45,35.65-35.42,461.72-35.42,461.72l-127.83,227.87,93.11,64.02,152.02-214.01c13.79-19.41,23.8-41.17,29.55-64.28,21.4-86,75.54-300.95,79.93-292.16,5.49,10.97,27.32,286.71,29.89,337.73,1.79,35.71,13.89,315.71,13.89,315.71l107.75,.48s13.4-226.44,25.02-324.85c13.42-113.75,4.09-504.93,4.09-504.93,0,0-118.23,4.54-372-7.3Z" style={{ fill: '#176ab5' }} />
                    <path d="M1474.65,1774.04h-.08c-1.1,0-2.01,.87-2.05,1.98-.03,.86-3.36,86.21-21.22,127.74-.45,1.04,.03,2.25,1.06,2.69,.27,.12,.54,.17,.81,.17,.8,0,1.55-.47,1.88-1.24,18.17-42.24,21.44-125.68,21.57-129.21,.04-1.13-.85-2.09-1.97-2.13Z" style={{ fill: '#242424' }} />
                    <path d="M1515.75,1748.07c-1.26-.11-2.13,.74-2.22,1.86-3.12,34.83-9.11,91.67-11.9,96-2.86,3.8-24.82,18.95-38.9,28.21-.94,.63-1.2,1.9-.58,2.84,.4,.6,1.05,.93,1.71,.93,.39,0,.78-.11,1.13-.34,3.63-2.4,35.71-23.59,39.91-29.19,4.24-5.65,11.32-82.71,12.7-98.09,.11-1.12-.72-2.12-1.85-2.22Z" style={{ fill: '#242424' }} />
                    <path d="M1407.83,1111.18c-.8,23.29-5.44,52.18-5.44,74.51,54.97,42.87,89.39,3.83,89.39,3.83,0-27.14,2.33-63.83,2.33-63.83-19.2,4.45-86.28-14.51-86.28-14.51Z" style={{ fill: '#d2d6d9' }} />
                    <path d="M1501.62,1171.06c-42.31,20.51-105.12,0-105.12,0,0,0-87.18,25.64-102.56,37.18-15.39,11.54-42.56,384.05-42.56,513.89,210.52,38.76,389.88,9.11,389.88,9.11,4.22-24.36-21.69-501.21-30.72-512.75-9.05-11.53-108.92-47.43-108.92-47.43Z" style={{ fill: '#fff' }} />
                    <path d="M1280.76,1205.79c-23.38,5.66-47,521.02-47,521.02,0,0,133.17,21.47,179.83,23.31,0,0,31.7-412.41-17.09-579.06,0,0-92.37,29.06-115.74,34.73Z" style={{ fill: '#fcfdfe' }} />
                    <path d="M1155.73,1530.28c-2.87-2.3-28.33-22.53-37.81-24.41-1.19-.27-2.18,.48-2.41,1.6-.23,1.11,.5,2.19,1.61,2.41,5.79,1.15,20.67,11.78,30.22,19.05-6.24-.55-15.08-.86-21.16,.98-1.08,.34-1.69,1.48-1.37,2.56,.27,.89,1.09,1.45,1.97,1.45,.19,0,.4-.03,.6-.09,9.18-2.79,26.56,.03,26.74,.05,.94,.17,1.83-.33,2.22-1.18,.36-.85,.11-1.84-.61-2.42Z" style={{ fill: '#242424' }} />
                    <path d="M1277.2,2189.49l-54.98-9.67,15.92,22.15c.39,.56,1.02,.86,1.66,.86,.41,0,.84-.13,1.19-.39,.92-.65,1.13-1.93,.47-2.86l-10.03-13.98,45.06,7.94c1.1,.22,2.18-.54,2.38-1.66,.18-1.13-.57-2.2-1.67-2.39Z" style={{ fill: '#242424' }} />
                    <path d="M1501.62,1171.06s73.96,284.84,57.29,583.68c0,0,72.71,1.76,101.43-10.28,0,0-33.41-506.41-49.8-525.96-16.41-19.56-108.92-47.44-108.92-47.44Z" style={{ fill: '#fcfdfe' }} />
                    <path d="M1612.54,1218.09c-.22-1.1-1.22-1.88-2.41-1.6-1.11,.22-1.83,1.3-1.61,2.41,2.86,14.33,26.58,212.49,26.82,214.42,3.33,39.2,13.98,174.27,14.09,175.63,.09,1.07,.98,1.89,2.04,1.89,.05,0,.11,0,.16-.01,1.13-.09,1.97-1.07,1.88-2.2-.11-1.36-10.75-136.44-14.09-175.72-.98-8.17-23.98-200.33-26.88-214.82Z" style={{ fill: '#242424' }} />
                    <path d="M1217.73,1751.26s9.5,30.7,15.29,29.92c14.1-1.91,29.68,37.47,40.52,25.2,4.37-4.95-5.5-19.09-12.48-29.22-6.98-10.12-43.33-25.9-43.33-25.9Z" style={{ fill: '#d2d4d5' }} />
                    <g>
                        <path d="M1194.91,1723.41c.78-.93-37.18,5.36-48.67,17.97-6.85,7.52-6.17,10.57-2.42,20,4.85,12.18,11.17,23.75,15.66,36.08,4.62,12.69,.74,19.86,7.64,31.47,5.14,8.65,14.85,6.68,20.62-.01,3,6.95,6.89,8.37,14.17,7.59,3.08-.33,9.44-4.94,10.26-7.51,3.43,5.66,18.99,.68,19.64-8.67,5.55,4.39,11.46-.28,14.34-2.58,10.46-8.3-.25-18.74-2.51-23.92-14.27-32.62-29.98-40.34-48.73-70.42Z" style={{ fill: '#d2d4d5' }} />
                        <path d="M1223.15,1764.56c-.81,.79-.83,2.09-.05,2.9,.25,.26,24.8,25.81,25.46,42,.12,2.88-.65,5-2.36,6.5-4.5,3.96-13.92,2.39-14.02,2.36-1.1-.14-2.18,.55-2.38,1.66-.19,1.12,.54,2.18,1.66,2.38,.19,.03,2.27,.39,5.05,.39,3.8,0,8.91-.66,12.37-3.69,2.67-2.33,3.94-5.62,3.77-9.77-.72-17.72-25.55-43.58-26.61-44.67-.79-.84-2.1-.85-2.89-.06Z" style={{ fill: '#d2d6d9' }} />
                        <path d="M1218.41,1808.09c-5.69,2.73-24.71,4.45-31.56,4.9-1.13,.07-1.99,1.04-1.91,2.17,.07,1.09,.96,1.92,2.04,1.92,.04,0,.09,0,.13-.01,1.04-.07,25.5-1.66,33.07-5.29,1.02-.49,1.45-1.7,.96-2.73-.47-1.01-1.67-1.47-2.73-.96Z" style={{ fill: '#d2d6d9' }} />
                    </g>
                    <path d="M1307.48,1216.42c-85.39-71.08-288.84,251.62-287.82,302.57,.93,46.78,129.42,253.79,129.42,253.79l55.05-39.99-49.68-200.91s81.36-97.53,105.04-132.55c26.48-39.17,85.97-151.29,47.99-182.91Z" style={{ fill: '#fcfdfe' }} />
                    <path d="M1280.66,1203.74c-1.13,.05-2.01,1.02-1.95,2.15s.94,2.03,2.15,1.95c.13,.01,14.97-.62,25.46,11.21,9.88,11.13,12.77,29.35,8.59,54.17-10.96,64.98-56.57,124.25-57.02,124.83-.69,.89-.53,2.18,.36,2.88,.38,.29,.81,.44,1.26,.44,.61,0,1.22-.27,1.62-.79,.47-.6,46.69-60.66,57.83-126.68,4.41-26.09,1.19-45.46-9.55-57.57-11.77-13.24-28-12.63-28.75-12.59Z" style={{ fill: '#242424' }} />
                    <path d="M1369.09,1588.01l-22.85-11.11c-.51-.25-1.11-.26-1.6-.09-.52,.19-.95,.59-1.17,1.1-12.18,27.1-83.39,94.08-84.11,94.76-.78,.72-.87,1.93-.2,2.77l13.86,17.6c.39,.51,1,.78,1.61,.78,.3,0,.6-.07,.9-.21,45.58-22.24,92.45-99.41,94.42-102.68,.29-.5,.37-1.1,.21-1.65-.17-.57-.56-1.02-1.07-1.27Zm-93.89,101.16l-11.67-14.81c10.07-9.53,69.09-65.97,82.76-92.88l18.95,9.22c-7.54,12.08-49.89,77.79-90.04,98.47Z" style={{ fill: '#242424' }} />
                    <path d="M1656.14,1678.48c-29.02-2.58-45.83-38.69-47.94-43.47l3.56-21.17c15.6,19.84,37.6,34.51,38.59,35.17,.93,.61,2.2,.37,2.84-.58,.62-.95,.36-2.21-.59-2.85-.25-.16-25.24-16.83-40.4-37.98-.48-.66-1.27-.95-2.14-.8-.8,.19-1.42,.84-1.55,1.66l-4.46,26.46c-.07,.37-.02,.76,.12,1.11,.73,1.78,18.17,43.56,51.6,46.54,.06,.01,.13,.01,.18,.01,1.05,0,1.94-.8,2.04-1.87,.1-1.14-.73-2.13-1.85-2.23Z" style={{ fill: '#fcfdfe' }} />
                    <path d="M1992.62,1366.61s37.46,213.14-33.79,346.83c0,0,135.58,87.22,265.26,26.22,0,0,69.46-206.38,7.87-346.53,0,0-174.82-52.9-239.34-26.52Z" style={{ fill: '#f0fcfe' }} />
                    <path d="M2231.04,1391.03c-1.16,.51-1.69,1.87-1.18,3.03,58.44,132.98-2.74,328.8-7.64,343.95-122.19,57.58-260.78-25.68-262.18-26.53-1.09-.67-2.5-.32-3.16,.76s-.32,2.5,.76,3.16c1.05,.64,78.93,47.46,169.94,47.46,31.8,0,65.22-5.72,97.51-21.13,.56-.27,.99-.75,1.19-1.34,.7-2.07,69.03-208.83,7.79-348.19-.51-1.16-1.87-1.68-3.03-1.17Z" style={{ fill: '#7fd4fc' }} />
                    <path d="M1981.61,1519.26c-7.89,2.25-13.25,27.89-11.21,27.36,5.28-1.37,16.81-6.73,22.3-6.85,9.46-.22,15.17,3.94,21.27,10.55,2.18,2.35,3.54,9.75,7.33,8.15,8.32-3.52,3.67-25.67,0-30.53-7.87-10.42-28.74-11.8-39.69-8.68Z" style={{ fill: '#d2d4d5' }} />
                    <path d="M1981.24,1517.24c-1.11,.19-1.86,1.26-1.66,2.38,.2,1.11,1.29,1.85,2.38,1.66,.29-.04,30.1-5.1,37.58,7.7,5.83,9.99,6.55,21.2,3.88,25.86-.91,1.59-1.81,1.59-2.11,1.59-1.48,0-3.46-2.58-5.57-5.33-4.38-5.7-10.3-13.41-21.71-13.41-.47,0-.94,.01-1.43,.03-.8,.05-1.51,.55-1.8,1.31-.04,.1-4.14,10.33-10.75,12.36-1.08,.34-1.69,1.49-1.37,2.56,.34,1.08,1.5,1.67,2.56,1.37,6.96-2.14,11.34-10.34,12.82-13.53,9.38,.03,14.38,6.53,18.42,11.8,2.86,3.72,5.33,6.94,8.82,6.94,2.3,0,4.31-1.29,5.65-3.65,3.32-5.77,2.77-18.55-3.89-29.96-8.89-15.29-40.47-9.92-41.82-9.68Z" style={{ fill: '#d2d6d9' }} />
                    <g>
                        <path d="M1514.31,908.02c-1.74-6.94-11.29-16.06-27.35-16.93-16.06-.87-29.95,12.59-29.95,12.59,0,0-5.64-5.21-12.15-5.64s-10.85,10.42-10.85,10.42c0,0-10.42-14.32-33.42-8.68s-48.94,40.86-48.94,40.86c-42.85,9.41,6.8,163.62,52.52,151.6l118.35-99.54c6.97-.05,3.07-16.54,3.07-16.54,13.02-5.21,10.42-30.38,10.42-30.38,0,0,8.94-6.51,7.38-20.4-3.48-30.82-29.08-17.36-29.08-17.36Z" style={{ fill: '#1e1e1e' }} />
                        <g>
                            <path d="M1521.39,990.49c-.65-2.63-1.25-5.26-2.03-7.86-15.45-51.23-46.7-66.43-96.51-58.22-60.85,10.03-67.7,80.21-54.04,124.45,1.42,4.61,26.26,6.31,27.76,10.76,0,0-6.7,73.65,60.26,87.04,7.94,1.59,36.31,4.99,42.18-1.35,51.48-55.63,24.61-120.23,24.61-120.23,3.26-7.01,.82-22.19-2.23-34.59Z" style={{ fill: '#d2d4d5' }} />
                            <path d="M1523.97,1024.2c-10.31,9.51-16.92,24.08-16.92,40.45s6.56,30.84,16.81,40.36c15.31-42.44-.24-79.91-.24-79.91,.13-.29,.23-.6,.35-.9Z" style={{ fill: '#d2d6d9' }} />
                            <path d="M1399.91,1039.54c-9.12,0-17.28,5.2-22.8,13.39,7.84,2.1,18.49,3.83,19.46,6.7,0,0-2.69,29.87,12.64,55.03,12.09-5.01,20.84-19.5,20.84-36.61,0-21.28-13.49-38.51-30.14-38.51Z" style={{ fill: '#d2d6d9' }} />
                            <path d="M1442.62,1078.42l-1.89,2.2c13.83,11.89,26.63,14.03,35.05,13.69,5.97-.24,9.73-1.73,10.1-1.88l-1.1-2.69c-.2,.08-19.93,7.78-42.16-11.32Z" style={{ fill: '#1c1c1c' }} />
                            <path d="M1425.77,1008.09c-6.42,3.94-4.04,8.3-3.07,9.74,.57,.84,2.96-.52,8.92-2.14,12.7-3.46,18.22-1.77,20.52-5.77,1.36-2.36,1.1-5.85-.58-7.53-4.23-4.24-24.95,5.18-25.79,5.7Z" style={{ fill: '#404040' }} />
                            <path d="M1512.92,1001.53c-.72-.34-16.75-5.1-19.12-.74-.94,1.73-.49,4.87,.96,6.78,2.45,3.24,6.24,.96,16.33,2.27,4.73,.61,6.76,1.49,7.03,.66,.46-1.42,.31-6.36-5.2-8.97Z" style={{ fill: '#404040' }} />
                            <path d="M1441.51,1031.44c-2.1,.55-3.11,3.67-2.25,6.96,.86,3.29,3.26,5.52,5.36,4.97s3.11-3.67,2.25-6.96-3.26-5.52-5.36-4.97Z" style={{ fill: '#1c1c1c' }} />
                            <path d="M1502.28,1023.72c-1.92,.5-2.77,3.57-1.91,6.86s3.11,5.54,5.02,5.04c1.92-.5,2.77-3.57,1.91-6.86-.85-3.28-3.1-5.54-5.02-5.04Z" style={{ fill: '#1c1c1c' }} />
                            <path d="M1526.43,918.98c-27.7-8.35-74.43-25.29-119.04,.92-44.62,26.21-56.87,58.36-63.53,68.42-8.52,12.87,17.31,63.47,34.35,93.95,5.44,9.72,15.34,7.88,19.48-2.47l-1.98-4.82,3.87-20.75,2.59-77.63s84.77,4.28,106.28-15.78c0,0,7.98,9.24,10.92,21.81-.01,0,34.76-55.3,7.06-63.65Z" style={{ fill: '#1e1e1e' }} />
                            <g>
                                <path d="M1373.87,1033.3c-13.37,4.27-20.38,19.73-15.66,34.53,4.73,14.8,19.4,23.34,32.77,19.08,13.37-4.27,20.38-19.73,15.66-34.53-4.73-14.81-19.4-23.35-32.77-19.08Z" style={{ fill: '#d2d4d5' }} />
                                <path d="M1381.97,1048.65c-5.76-1.85-10.67,1.09-10.88,1.21l1.51,2.48s4.05-2.37,8.51-.92c3.3,1.08,6.1,3.94,8.32,8.52-3.28,.79-9.9,2.95-11.63,7.75l2.73,.98c1.41-3.92,8.56-5.84,11.2-6.25l1.84-.29-.72-1.71c-2.69-6.34-6.35-10.31-10.88-11.77Z" style={{ fill: '#1c1c1c' }} />
                            </g>
                            <path d="M1399.03,973.67l.88,74.22c27.15-4.88,6.11-89.14,6.11-89.14l-6.99,14.92Z" style={{ fill: '#1e1e1e' }} />
                            <path d="M1479.15,1039.14l-.53,2.85c6.97,1.3,17.36,4.38,18.63,7.12,.11,.23,.13,.42-.04,.74-3.02,5.33-10.83,10.02-10.91,10.07l1.49,2.49c.35-.21,8.54-5.13,11.95-11.13,.62-1.1,.68-2.27,.15-3.39-2.37-5.08-17.69-8.18-20.74-8.75Z" style={{ fill: '#1c1c1c' }} />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <path d="M3635.16,1669.94c-20.01,6.66-41.44,32.55-40.18,37.59,1.27,5.05,58.47-3.9,66.94-4.49,8.47-.6,31.1-6.6,31.1-6.6,0,0-43.29-31.34-57.86-26.5Z" style={{ fill: '#898989' }} />
                        <g>
                            <path d="M3639.57,1634.55c-5.44-1.92-11.41,.95-13.32,6.39l-21.88,62.97,19.72,6.93,21.88-62.97c1.91-5.44-.95-11.41-6.4-13.32Z" style={{ fill: '#282828' }} />
                            <polygon points="3569.22 1789.95 3598.87 1800.36 3648.91 1655.4 3619.26 1644.99 3569.22 1789.95" style={{ fill: '#d0d0d0' }} />
                            <path d="M3617.06,1642.98c-5.82,5.62-10.16,12.01-12.1,19.95-.9,3.69-.93,9.07-1.38,14.42-.33,4.02-2.07,7.79-4.85,10.71-2.56,2.69-5.51,5.88-6.81,9.14-.65,1.61,.89,3.28,2.58,2.89,7.09-1.65,10.29-7.13,11.94-14,3.58-14.87,1.35-27.93,13.52-39.67,2.08-2.01-.82-5.45-2.9-3.44Z" style={{ fill: '#282828' }} />
                            <path d="M3651.6,1652.41l-32.55-11.43c-.34-.12-.7,.06-.82,.39l-1.38,3.94c-.12,.34,.06,.7,.39,.82l32.54,11.43c.34,.12,.7-.06,.82-.39l1.39-3.94c.12-.34-.05-.7-.39-.82Z" style={{ fill: '#282828' }} />
                            <polygon points="3571.78 1829.49 3598.87 1800.36 3569.22 1789.95 3571.78 1829.49" style={{ fill: '#282828' }} />
                        </g>
                        <g>
                            <g>
                                <path d="M3632.95,1679.36c-14.77,3.59-80.29,50.94-72.9,61.8,7.07,10.39,24.48-8.66,25.97-10.32,.07-.08,.11-.13,.11-.13,0,0-.05,.05-.11,.13-1.71,1.93-24.1,27.38-16.59,34.95,7.81,7.86,16.92,1.39,16.92,1.39,0,0,2.28,14.19,18.83,.56,0,0-1.41,15.09,6.69,10.74,8.1-4.35,29.54-28.48,37.6-29.47s19.28,8.25,33.87,5.32c26.74-5.36,33.04-19.12,33.04-19.12l-12.42-40.68s-56.24-18.76-71.01-15.17Z" style={{ fill: '#d9d9e0' }} />
                                <path d="M3586.02,1730.84c.07-.08,.11-.13,.11-.13,0,0-.05,.05-.11,.13Z" style={{ fill: '#d9d9e0' }} />
                            </g>
                            <path d="M3679.37,1684.84c-1.25-.65-30.87-15.69-47.41-7.44-15.45,7.71-59.54,35.23-71.85,54.38-1.45,2.25-5.87,9.1-1.85,12.42,5.6,4.6,22.42-5.23,29.39-11.9,.86-.84,.9-2.23,.06-3.11-.84-.87-2.31-.78-3.12-.06-8.35,8.12-21.79,13.26-23.54,11.67,.01-.01-.6-1.41,2.75-6.63,11.73-18.23,55.47-45.55,70.12-52.85,14.54-7.24,43.13,7.28,43.42,7.43,.78,.41,1.69,.3,2.35-.21,.25-.19,.45-.44,.61-.73,.58-1.09,.15-2.42-.93-2.97Z" style={{ fill: '#b6c8ce' }} />
                            <path d="M3631.68,1694.28c-.03-1.21-1.05-2.16-2.26-2.14-1.19,.04-2.17,1.04-2.14,2.26,.02,.84,.67,20.74,10.17,35.24,.66,1.02,2.02,1.29,3.04,.63,.05-.02,.09-.06,.14-.09,.9-.69,1.14-1.98,.49-2.95-8.81-13.46-9.43-32.75-9.44-32.95Z" style={{ fill: '#b6c8ce' }} />
                            <path d="M3626.97,1716.17c-21.01,13.68-35.64,34.16-40.42,43.11-3.71,6.88-9.92,7.87-12.19,7.29-.59-.15-1.66-.63-2.28-2.08-.87-2.07-1.31-7.31,5.49-19.28,10.35-18.15,48.63-39.43,49.02-39.64,1.1-.7,1.45-1.94,.87-2.99-.59-1.06-1.93-1.46-2.99-.87-1.61,.9-39.79,22.08-50.72,41.34-5.89,10.37-7.82,18.16-5.71,23.16,.98,2.33,2.87,4.02,5.19,4.63,2.82,.74,7.61,.21,11.9-3.08,1.95-1.5,3.79-3.58,5.29-6.38,3.7-6.91,17.96-27.75,38.95-41.5,1.07-.52,1.31-2.02,.64-3.04-.66-1.05-2.02-1.34-3.04-.67Z" style={{ fill: '#b6c8ce' }} />
                            <path d="M3649.82,1746.83c-6.85-1.15-17.48,8.73-30.68,21.82-3.63,3.59-6.72,6.41-8.59,8.07-1.38,1.05-2.56,1.47-3.07,1.1-1.05-.73-1.73-4.52-.2-9.44,1.98-6.31,19.7-24.31,26.38-30.72,.86-.84,.9-2.23,.06-3.12-.84-.86-2.29-.89-3.12-.05-2.54,2.43-24.86,24.05-27.52,32.58-1.66,5.33-1.72,11.83,1.87,14.36,1.29,.9,4.08,1.96,8.25-1.19,.04-.03,.08-.07,.12-.09,2.05-1.58,5.09-4.58,8.9-8.37,7.59-7.52,21.7-21.47,26.88-20.6,1.19,.19,2.32-.61,2.53-1.81,.2-1.21-.61-2.33-1.81-2.54Z" style={{ fill: '#b6c8ce' }} />
                        </g>
                    </g>
                    <path d="M3915.87,1698.4c-33.39-16.71-221.28-6.86-221.28-6.86,0,0,1.72,57.6,5.62,57.33,3.9-.27,178.83,54.52,225.42,35.06,46.59-19.46,23.62-68.82-9.76-85.53Z" style={{ fill: '#d9d9e0' }} />
                </g>
                <path d="M3481.44,1662.84l-196.41,93.16,13.87,44.61s134.6-17.04,205.5-35.78c2.54-20.85,1.85-41.97-4.15-62.34-4.18-14.21-10.75-27.39-18.81-39.65Z" style={{ fill: '#d9d9e0' }} />
                <path d="M3202.13,1808.72l112.42,45.34,285.06-30.98s-115.96-28.26-167.88-34.65l-229.6,20.29Z" style={{ fill: '#fff' }} />
                <g>
                    <path d="M3299.49,1756.03c-.29-1.55-1.5-2.99-3.71-3.07-19.89-.75-49.34,3.97-66.4,16.1-11.31,8.04-38.93,36.19-36,42.5,.67,1.44,4.66,2.53,6.53,2.46,2.45-.09,10.37-8.66,12.89-9.94-3.45,5.2-9.52,9.8-12.19,15.17-2.28,4.58-5.24,9.46-3.59,12.68,2.28,4.46,3.86,2.89,7.28,1.73-.02,.15-1.12,1.94-1.14,2.09-.21,1.69,7.49,.26,9.08,1.21,9,5.43,38.44-43.05,54.69-36.41,.01,1.3,1.81,10.08-3.39,17.23-13.57,4.79-18.03,13.54-17.03,15.04,1.13,1.71,13.69,3.45,15.59,2.58,14.15-6.58,11.97-3.47,21.7-12.61,7.91-7.43,15.25-15.58,24.18-23.05,16.61-13.9,6.96-33.68-8.49-43.71Z" style={{ fill: '#d9d9e0' }} />
                    <path d="M3235.31,1788.72c-9,5.43-17.58,20.15-24.47,31.98-2.78,4.78-6.17,10.61-7.85,12.35-1.58-.6-1.94-1.32-2.07-1.58-1.38-2.78,2.19-9.5,3.92-12.73,4.62-8.69,21.32-27.87,21.5-28.06,.95-1.08,.75-2.65-.45-3.51-.79-.56-1.79-.67-2.66-.38-.47,.16-.89,.42-1.21,.79-.71,.81-17.26,19.81-22.15,29-4.05,7.62-6,12.83-3.98,16.92,1.1,2.24,3.29,3.8,6.51,4.64,4,1.04,6.49-3.42,13.31-15.1,6.25-10.74,14.81-25.42,22.64-30.15,1.28-.77,1.63-2.31,.78-3.46-.86-1.16-2.56-1.46-3.82-.71Zm-31.38,44.61h0Z" style={{ fill: '#b6c8ce' }} />
                    <path d="M3267.26,1797.05c-.36,.03-7.22,.52-12.76,2.36-1.35,.45-2.6,.98-3.68,1.59-10.56,6.04-17.05,14.8-22.27,21.83-3.93,5.31-7.34,9.9-10.9,11.08-.04,.02-.09,.03-.13,.04-2.55,.78-4.54,.86-5.73,.2-1.18-.65-1.42-1.97-1.42-1.98-.23-1.33-1.62-2.29-3.1-2.1-1.53,.27-2.55,1.41-2.36,2.76,.04,.36,.57,3.64,3.92,5.54,2.68,1.52,6.2,1.63,10.46,.31,.09-.03,.17-.05,.26-.07,5.25-1.75,9.11-6.97,13.58-12.99,5.18-6.99,11.06-14.93,20.64-20.4,3.58-2.03,11.29-3.02,13.92-3.19,1.48-.1,2.65-1.31,2.53-2.68-.12-1.38-1.45-2.41-2.96-2.3Z" style={{ fill: '#b6c8ce' }} />
                    <path d="M3306.59,1799.89c-1.21-.85-2.95-.63-3.86,.47-6,7.16-23.07,26.38-29.85,29.31-7.18,3.14-18.3,5.1-22.63,2.93-1.05-.53-1.59-1.26-1.72-2.35-.16-1.28,5.65-4.14,8.13-5.36,3.04-1.49,5.9-2.9,7.85-4.53,3.84-3.24,4.27-8.46,4.68-13.5,.19-2.44,.39-4.74,.95-6.68,.38-1.34-.51-2.7-1.98-3.05-.57-.13-1.14-.09-1.64,.08-.82,.27-1.48,.9-1.72,1.71-.67,2.37-.9,5.02-1.1,7.57-.34,4.09-.69,8.3-2.95,10.21-1.43,1.21-4.11,2.53-6.7,3.8-5.7,2.79-11.58,5.68-10.99,10.32,.34,2.7,1.91,4.82,4.52,6.15,7.18,3.6,20.77,.17,27.69-2.78,9.2-3.98,29.57-28.07,31.85-30.8,.91-1.1,.67-2.68-.53-3.5Z" style={{ fill: '#b6c8ce' }} />
                </g>
                <g>
                    <path d="M2975.5,1684.88c12.6,10.09,129.85,12.06,142.06-3.23h-142.52l.46,3.23Z" style={{ fill: '#b5b5b5' }} />
                    <path d="M2975.04,1681.65c-1,1.16-.81,2.23,.46,3.23,12.6,10.09,129.85,12.06,142.06-3.23,13.36-16.8-119.1-26.5-142.52,0Z" style={{ fill: '#72abd1' }} />
                    <g>
                        <path d="M3084.21,1611.25c-9.21,45.37-16.16,91.09-21.04,137.13-1.57,14.79,21.56,14.37,23.11-.25,4.66-43.99,11.48-87.64,20.28-130.98,2.96-14.56-19.39-20.47-22.35-5.9Z" style={{ fill: '#183553' }} />
                        <path d="M3105.57,1602c-.36-1.81-.73-3.62-1.14-5.42-.37-1.62-1-2.83-2.6-3.48-.44-.18-.9-.08-1.31,.14-.49,.01-.99,.21-1.4,.55-2.86,2.36-5.65,4.82-8.36,7.36-2.54,2.39-5.05,4.79-6.36,8.09-.22,.54-.04,1.11,.3,1.57,.22,.56,.64,1.04,1.24,1.13,6.32,.95,12.61,2.06,18.87,3.34,.46,.1,.85,0,1.19-.16,.02-.01,.06,0,.08,0,1.1-.31,1.47-1.36,1.32-2.39-.52-3.58-1.13-7.16-1.83-10.73Z" style={{ fill: 'silver' }} />
                        <path d="M3101.83,1593.1c-.44-.18-.9-.08-1.31,.14-.49,.01-.99,.21-1.4,.55-1.97,1.63-3.86,3.35-5.76,5.07,3.99,.92,8.03,1.63,12.05,2.41-.31-1.57-.63-3.13-.98-4.69-.36-1.62-1-2.83-2.6-3.48Z" style={{ fill: '#424242' }} />
                        <path d="M3080.23,1747.85c-4.49-1-9.3-.85-13.87-1.07-.31-.02-.58,.03-.81,.13-1-.44-2.27-.18-2.52,1.19-.51,2.77-.9,5.56-1.1,8.37-.19,2.63,.54,4.99,3.05,6.22,3.99,1.95,8.98,2.52,13.36,2.21,1.2-.08,2.4-.25,3.59-.47,.95-.17,1.92-.38,2.67-1.03,.92-.81,1.24-1.96,1.57-3.1,.33-1.11,.6-2.25,.82-3.39,.42-2.2,.75-4.42-.87-6.22-1.43-1.6-3.86-2.39-5.89-2.84Z" style={{ fill: 'silver' }} />
                        <path d="M3085.3,1742.94c-6.75-.31-13.5-.63-20.25-.94-1.03-.05-1.6,.6-1.76,1.37-.73,.62-1.14,1.64-1.15,2.71-.05,4.18,5.52,3.82,8.34,4.09,3.74,.36,7.47,.72,11.2,1.07,1.72,.16,4.12,.85,5.02-1.16,.34-.76,.26-1.71,.29-2.52,.03-.65,.05-1.29,.08-1.94,.36-1.1-.24-2.61-1.77-2.68Z" style={{ fill: '#d0d0d0' }} />
                    </g>
                    <g>
                        <path d="M3002.21,1608.1c-1.13-14.81-24.25-14.4-23.11,.43,3.53,46.17,9.32,92.04,17.2,137.67,2.53,14.65,24.67,7.93,22.16-6.55-7.52-43.6-12.88-87.45-16.25-131.55Z" style={{ fill: '#5d8aa9' }} />
                        <path d="M2997.12,1593.79c-.84-1.65-1.69-3.28-2.57-4.91-.8-1.46-1.74-2.45-3.45-2.64-.47-.05-.89,.17-1.22,.49-.47,.15-.9,.47-1.2,.91-2.11,3.06-4.12,6.18-6.03,9.37-1.8,2.99-3.56,5.98-3.92,9.52-.06,.57,.27,1.08,.72,1.43,.37,.48,.9,.83,1.51,.75,6.34-.81,12.69-1.46,19.06-1.93,.47-.04,.82-.23,1.11-.48,.02-.01,.05-.01,.08-.03,.96-.6,1.04-1.71,.62-2.65-1.5-3.31-3.06-6.59-4.71-9.83Z" style={{ fill: 'silver' }} />
                        <path d="M2991.1,1586.25c-.47-.05-.89,.17-1.22,.49-.47,.15-.9,.47-1.2,.91-1.45,2.1-2.8,4.28-4.15,6.45,4.09-.21,8.17-.63,12.25-.98-.72-1.42-1.46-2.84-2.22-4.24-.81-1.45-1.75-2.44-3.46-2.63Z" style={{ fill: '#424242' }} />
                        <path d="M3019.01,1742.15c-1.81-1.15-4.36-1.25-6.44-1.13-4.59,.26-9.18,1.72-13.64,2.76-.3,.07-.55,.19-.75,.34-1.08-.15-2.23,.45-2.1,1.83,.27,2.8,.66,5.6,1.23,8.35,.54,2.58,1.89,4.65,4.63,5.15,4.37,.79,9.32-.02,13.46-1.51,1.13-.41,2.24-.9,3.32-1.43,.87-.43,1.74-.89,2.28-1.72,.67-1.03,.66-2.22,.67-3.41,.01-1.16-.04-2.32-.14-3.48-.18-2.23-.47-4.46-2.52-5.75Z" style={{ fill: 'silver' }} />
                        <path d="M3018.55,1737.01c.04-1.16-.95-2.45-2.44-2.1-6.58,1.54-13.16,3.09-19.74,4.63-1.01,.23-1.38,1.01-1.32,1.8-.53,.79-.65,1.89-.36,2.93,1.09,4.03,6.35,2.16,9.14,1.65,3.69-.68,7.38-1.35,11.07-2.03,1.71-.31,4.2-.31,4.51-2.48,.12-.83-.22-1.71-.4-2.51-.16-.63-.31-1.26-.46-1.89Z" style={{ fill: '#d0d0d0' }} />
                    </g>
                    <g>
                        <path d="M3024.95,1601.92c-8.52,45.51-14.78,91.33-18.95,137.44-1.34,14.81,21.78,14.04,23.1-.6,3.99-44.05,10.15-87.8,18.29-131.28,2.73-14.6-19.71-20.17-22.44-5.56Z" style={{ fill: '#183553' }} />
                        <path d="M3046.16,1592.35c-.39-1.8-.79-3.61-1.22-5.41-.39-1.61-1.04-2.81-2.65-3.44-.44-.17-.9-.06-1.31,.16-.49,.02-.99,.22-1.39,.57-2.83,2.41-5.58,4.91-8.25,7.49-2.51,2.43-4.98,4.86-6.24,8.18-.21,.54-.02,1.11,.33,1.57,.23,.56,.66,1.03,1.26,1.11,6.33,.85,12.64,1.87,18.91,3.06,.46,.09,.85-.01,1.19-.18,.03-.01,.06,0,.08-.01,1.09-.33,1.45-1.38,1.28-2.4-.57-3.58-1.24-7.15-1.99-10.7Z" style={{ fill: 'silver' }} />
                        <path d="M3042.29,1583.5c-.44-.17-.9-.06-1.31,.16-.49,.02-.99,.22-1.39,.57-1.95,1.66-3.81,3.41-5.68,5.16,4.01,.86,8.05,1.51,12.08,2.22-.34-1.56-.68-3.12-1.05-4.68-.39-1.6-1.04-2.8-2.65-3.43Z" style={{ fill: '#424242' }} />
                        <path d="M3023.04,1738.56c-4.5-.93-9.31-.71-13.89-.86-.31-.01-.58,.04-.81,.14-1.01-.43-2.27-.14-2.5,1.22-.47,2.77-.81,5.58-.97,8.39-.15,2.63,.62,4.98,3.14,6.17,4.01,1.9,9.01,2.39,13.39,2.01,1.2-.1,2.4-.28,3.58-.52,.95-.19,1.91-.41,2.65-1.07,.91-.82,1.21-1.98,1.52-3.12,.31-1.12,.57-2.25,.77-3.39,.39-2.21,.68-4.43-.96-6.21-1.44-1.58-3.88-2.34-5.92-2.76Z" style={{ fill: 'silver' }} />
                        <path d="M3028.04,1733.58c-6.76-.21-13.51-.42-20.27-.63-1.03-.03-1.59,.62-1.74,1.39-.72,.63-1.11,1.66-1.11,2.73,.01,4.18,5.58,3.73,8.41,3.96,3.74,.3,7.48,.6,11.22,.9,1.72,.14,4.13,.79,5-1.23,.33-.77,.23-1.71,.25-2.53,.02-.65,.03-1.29,.05-1.94,.34-1.11-.28-2.6-1.81-2.65Z" style={{ fill: '#d0d0d0' }} />
                    </g>
                    <g>
                        <path d="M3040.15,1620.89c5.8,43.85,9.42,87.88,11.05,132.08,.55,14.85,23.66,15.34,23.11,.48-1.7-46.27-5.68-92.34-11.76-138.24-1.94-14.74-24.32-8.89-22.4,5.68Z" style={{ fill: '#5d8aa9' }} />
                        <path d="M3072.47,1753.19c-6.36,.56-12.74,.95-19.12,1.18-.47,.02-.83,.2-1.13,.43-.02,.01-.06,.01-.08,.02-.99,.56-1.11,1.66-.72,2.63,1.36,3.37,2.79,6.71,4.31,10.01,.77,1.68,1.56,3.35,2.38,5.01,.74,1.49,1.63,2.51,3.34,2.77,.47,.07,.9-.13,1.24-.45,.48-.13,.91-.44,1.23-.86,2.23-2.97,4.36-6.01,6.4-9.12,1.91-2.92,3.79-5.84,4.28-9.36,.08-.57-.22-1.09-.66-1.45-.35-.5-.87-.86-1.47-.81Z" style={{ fill: 'silver' }} />
                        <path d="M3058.11,1772.47c.74,1.49,1.63,2.51,3.34,2.77,.47,.07,.9-.13,1.24-.45,.48-.13,.91-.44,1.23-.86,1.54-2.05,2.97-4.17,4.41-6.28-4.1,.04-8.19,.31-12.28,.49,.67,1.45,1.35,2.9,2.06,4.33Z" style={{ fill: '#424242' }} />
                        <path d="M3057.43,1601.74c-4.33-.97-9.32-.35-13.5,.98-1.15,.37-2.28,.81-3.38,1.3-.88,.4-1.78,.82-2.35,1.63-.71,1-.74,2.19-.8,3.38-.05,1.16-.05,2.32,0,3.48,.1,2.24,.31,4.47,2.3,5.85,1.77,1.22,4.31,1.42,6.39,1.39,4.6-.08,9.24-1.36,13.73-2.22,.31-.06,.56-.17,.76-.31,1.07,.19,2.25-.36,2.17-1.75-.16-2.81-.44-5.62-.9-8.39-.43-2.61-1.69-4.74-4.42-5.34Z" style={{ fill: '#5d8aa9' }} />
                        <path d="M3055.03,1615.2c-3.71,.53-7.43,1.06-11.14,1.59-1.71,.25-4.21,.14-4.6,2.3-.15,.82,.15,1.72,.31,2.52,.13,.64,.25,1.27,.38,1.91-.08,1.16,.85,2.48,2.36,2.19,6.64-1.28,13.27-2.57,19.91-3.85,1.01-.19,1.42-.96,1.39-1.74,.56-.77,.72-1.86,.48-2.91-.95-4.07-6.28-2.42-9.09-2.01Z" style={{ fill: '#d0d0d0' }} />
                    </g>
                    <path d="M2975.5,1684.88l18.49,126.96c.35,3.85,2.85,7.12,6.47,8.47,17.18,6.24,67.03,11.79,97.61-7.01,3.31-2.04,4.51-6.36,4.97-10.21l14.52-121.45c-12.21,15.3-129.46,13.34-142.06,3.24Z" style={{ fill: '#7fd4fc' }} />
                </g>
                <g>
                    <polygon points="2433.64 2634.84 2473.23 2635.45 2485.66 1977.09 2446.06 1976.48 2433.64 2634.84" style={{ fill: '#183553' }} />
                    <path d="M2597.19,2620.26l-286.98-4.43c-9.44-.14-17.21,7.39-17.36,16.83h0c-.14,9.44,7.39,17.21,16.83,17.35l286.97,4.43c9.44,.15,17.21-7.39,17.36-16.83,.15-9.43-7.38-17.2-16.82-17.35Z" style={{ fill: '#183553' }} />
                    <path d="M2505.69,2246.45c-.67-23.21-20.02-41.48-43.23-40.82l-14.04,.4c-23.21,.67-41.48,20.02-40.82,43.23l1.91,66.52c.66,23.21,20.02,41.48,43.23,40.82l14.04-.4c23.21-.66,41.48-20.02,40.82-43.22l-1.91-66.53Z" style={{ fill: '#1a3e61' }} />
                    <path d="M2562.98,2645.13c-13.74-.21-25.06,10.76-25.27,24.5s10.76,25.06,24.5,25.27,25.06-10.76,25.27-24.5c.21-13.75-10.75-25.06-24.5-25.27Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M2562.84,2653.92c-8.88-.14-16.2,6.95-16.33,15.84-.13,8.88,6.95,16.2,15.84,16.34,8.88,.14,16.2-6.96,16.33-15.84,.14-8.88-6.95-16.2-15.84-16.34Z" style={{ fill: '#1a3e61' }} />
                    <path d="M2453.31,2643.44c-13.75-.21-25.06,10.76-25.27,24.5s10.76,25.06,24.5,25.27,25.05-10.76,25.27-24.5c.21-13.75-10.76-25.06-24.5-25.27Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M2453.17,2652.23c-8.88-.14-16.2,6.95-16.34,15.84-.13,8.89,6.96,16.2,15.84,16.34,8.88,.14,16.2-6.95,16.34-15.84,.14-8.89-6.95-16.2-15.84-16.34Z" style={{ fill: '#1a3e61' }} />
                    <path d="M2343.63,2641.74c-13.74-.21-25.05,10.76-25.27,24.5-.21,13.74,10.76,25.06,24.5,25.27,13.75,.21,25.05-10.76,25.27-24.5,.21-13.74-10.75-25.06-24.5-25.27Z" style={{ fill: '#5d8aa7' }} />
                    <path d="M2343.5,2650.54c-8.88-.14-16.2,6.95-16.34,15.84-.14,8.88,6.95,16.2,15.84,16.33,8.89,.14,16.2-6.95,16.34-15.84,.13-8.88-6.96-16.2-15.84-16.33Z" style={{ fill: '#1a3e61' }} />
                    <g>
                        <path d="M2135.89,2279.52c62.33,22.42,140.24,45.74,186.5,47.31,41.8,1.42,142.09-88.26,142.09-88.26l-308.32-54.54c-45.48,1.59-36.62,89.61-20.27,95.49Z" style={{ fill: '#72abd1' }} />
                        <path d="M2447.15,1850.54c-22.62-.33-43.84,9.08-47.65,50.21-10.32,111.12-69.61,425.5-69.61,425.5l199.27-22.08,167.51-65.55,104.91-422.94s-260.98,36.23-354.43,34.86Z" style={{ fill: '#5d8aa9' }} />
                        <path d="M2809.14,1816.54c-29.46-4.51-43.17,12.14-46.03,22.87l-88.49,369.33-184.93,14.99c-35.88,1.25-194.2,92.23-159.82,102.52,29.2,8.74,370.08-29.38,393.14-73.94,54.7-105.72,114.59-387.26,114.59-387.26,1.4-26.38-10.58-45.77-28.46-48.51Z" style={{ fill: '#5d8aa9' }} />
                        <path d="M2449.72,1854.21c-29.46-4.51-43.17,12.14-46.03,22.87l-123.38,440.17s45.15,18.93,76.92,3.63c76.45-91.25,120.95-418.15,120.95-418.15,1.4-26.39-10.58-45.78-28.46-48.52Z" style={{ fill: '#72abd1' }} />
                    </g>
                </g>
                <text transform="translate(1676.6 877.37)" style={{ fill: '#2993da', fontFamily: 'Poppins-SemiBoldItalic, Poppins', fontSize: 136.54, isolation: 'isolate' }}><tspan x="0" y="0">ASSESSMENT</tspan></text>
            </svg>
        </HeroContainer>
    )
}