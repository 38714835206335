import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";
import { BorderRadius, Gap, Padding } from "../sizes/sizes";

export const PrimaryButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.primary.text,
    backgroundColor: AppColors.buttons.primary.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.primary.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.primary.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const SecondaryButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.secondary.text,
    backgroundColor: AppColors.buttons.secondary.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.secondary.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.secondary.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const WarnButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.warn.text,
    backgroundColor: AppColors.buttons.warn.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.warn.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.warn.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const DangerButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.warn.text,
    backgroundColor: AppColors.buttons.danger.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.danger.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.danger.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const PrimaryOutlineButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.primaryOutline.text,
    backgroundColor: AppColors.buttons.primaryOutline.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: `1px solid ${AppColors.buttons.primaryOutline.border}`,
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.primaryOutline.hover,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const SecondaryOutlineButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.secondaryOutline.text,
    backgroundColor: AppColors.buttons.secondaryOutline.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: `1px solid ${AppColors.buttons.secondaryOutline.border}`,
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.secondaryOutline.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.secondaryOutline.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const WarnOutlineButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.warnOutline.text,
    backgroundColor: AppColors.buttons.warnOutline.background,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: `1px solid ${AppColors.buttons.warnOutline.border}`,
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.warnOutline.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.warnOutline.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const GhostSecondaryButton = styled.button({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.secondaryGhost.text,
    backgroundColor: 'transparent',

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.secondaryGhost.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.secondaryGhost.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})

export const GhostSecondaryButtonLink = styled.a({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.buttons.secondaryGhost.text,
    backgroundColor: 'transparent',

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.buttons.secondaryGhost.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.buttons.secondaryGhost.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
})