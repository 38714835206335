import { api } from '../../../../services/core-api-service';

export const checkIfAuthenticated = async (): Promise<boolean> => {
  try {
    await api.auth.checkToken();
    return true;
  } catch (err) {
    return false;
  }
};
