import { SubmitAssessmentResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../services/core-api-service";

class SubmitAssessmentCalculatingService {
    public submitAssessment(assessmentId: string): Promise<SubmitAssessmentResult> {
        return api.assessments.submitAssessment(assessmentId);
    }
}

const service = new SubmitAssessmentCalculatingService()

export default service;