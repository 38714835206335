import { FC } from "react";
import { AppShell } from "../../../app/components/app-shell/app-shell";
import { BodyCard } from "../../../common/components/body-card/body-card";
import { BodyContainer } from "../../../common/components/body-container/body-container";
import { AuthenticationShellContainer, ContentContainer } from "./authentication-shell.styled";
import { AuthenticationHero } from "./subcomponents/authentication-hero/authentication-hero";

type AuthenticationShellProps = {
    children: JSX.Element
}

export const AuthenticationShell: FC<AuthenticationShellProps> = ({
    children,
}) => {
    return (
        <AppShell>
            <BodyContainer>
                <BodyCard>
                    <AuthenticationShellContainer>
                        <AuthenticationHero />
                        <ContentContainer>
                            {children}
                        </ContentContainer>
                    </AuthenticationShellContainer>
                </BodyCard>
            </BodyContainer>
        </AppShell>
    )
}