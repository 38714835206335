import {
  DESKTOP_BREAKPOINT_MAX,
  DESKTOP_LARGE_BREAKPOINT_MAX,
  DESKTOP_MID_BREAKPOINT_MAX,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
} from '../../../common/constants/breakpoints/breakpoints';
import { Gap, Padding } from '../../../common/components/sizes/sizes';

import { AppColors } from '../../../common/constants/colors/app-colors';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';

export const LandingContainer = styled.div({
  // layout
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});

export const LinkContainer = styled.div();

/*
 * Top Section
 */
// const growAnimation = keyframes`
//   from: {
//     transform: scale(0);
//   }
//   to {
//     trasform: scale(1);
//   }
// `

export const CTALink = styled(Link)({
  color: AppColors.text.onDarkBackground,
  cursor: 'pointer',
  textDecoration: 'none',
  textTransform: 'uppercase',
  fontSize: 32,
  fontWeight: 700,

  ':hover': {
    textDecoration: 'underline',
    fontSize: 36
  },
});

export const CTAHeader = styled.h1({
  color: AppColors.text.onDarkBackground,
  fontSize: 24,
});

export const HeroSection = styled.div({
  display: 'flex',
  flexFlow: 'column',
  justifyContent: 'space-between',
  backgroundColor: AppColors.background.dark,
  minHeight: '39vh',
  boxSizing: 'border-box',
  padding: `${Padding.desktop.large}px 0 0 ${Padding.desktop.larger}px`,
});

export const HeroTitle = styled.h1({
  color: AppColors.text.onDarkBackground,
  fontSize: 32,
});

export const HeroMessageContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 'clamp(10%, 10%, 100px)',

  [MOBILE_BREAKPOINT]: {
    flexDirection: 'column',
    gap: Gap.mobile.larger,
  },
});

export const HeroMessage = styled.p({
  color: AppColors.text.blue.primary,
  fontSize: 24,
  flex: 9,
  fontWeight: 600
});

export const HeroTimeSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flex: 4,
  backgroundColor: AppColors.background.green.primary,
  padding: Padding.desktop.large,
  fontSize: 24,

  'p:first-child': {
    color: AppColors.text.onDarkBackground,
  },
  'p:nth-child(2)': {
    fontWeight: 700,
  },
});

export const CenteredContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

/*
 * Middle Section
 */
export const MiddleSectionContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: Gap.desktop.large,
  padding: Padding.desktop.large,
  minHeight: '19vh',
  boxSizing: 'border-box',

  [DESKTOP_MID_BREAKPOINT_MAX]: {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const HorizontalCard = styled.div({
  display: 'flex',
  gap: Gap.desktop.large,
  maxWidth: '33%',

  [DESKTOP_MID_BREAKPOINT_MAX]: {
    maxWidth: '80%',
  },
});

export const CardImage = styled.img({
  // size
  width: 'clamp(140px, 175px, 200px)',
  height: 'clamp(140px, 175px, 200px)',

  // object
  objectFit: 'contain',
  objectPosition: 'left top',

  // breakpoints
  [MOBILE_BREAKPOINT]: {
    maxWidth: 100,
  },
});

export const VerticalContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: Gap.desktop.normal,
});

export const CardTitle = styled.h3({
  color: 'black',
  fontSize: 24,
});

export const CardSubTitle = styled.h5({
  color: AppColors.text.gray,
  fontSize: 18,
});

export const CardContent = styled.p({
  color: AppColors.text.gray,
  fontSize: 14,
});

/*
 *  Bottom Section
 */
export const BottomSectionContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  gap: Gap.desktop.large,
  minHeight: '24vh',
  boxSizing: 'border-box',
  padding: Padding.desktop.large,
  backgroundColor: AppColors.background.blue.primary,
  color: AppColors.text.onDarkBackground,
});

export const ContentColumn = styled.div({
  maxWidth: '31%',

  [TABLET_BREAKPOINT]: {
    maxWidth: '49%',
  },
});

export const SectionTitle = styled.h3({
  color: 'white',
  fontSize: 24,
});

export const TextSection = styled.p({
  color: 'white',
  fontSize: 18,
});

/*
 *  Footer Section
 */
export const FooterSectionContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  gap: Gap.desktop.large,
  minHeight: '18vh',
  boxSizing: 'border-box',
  padding: Padding.desktop.large,
  backgroundColor: 'black',
});

export const LogoSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: Gap.desktop.large,
});

export const LogoImage = styled.img({
  height: 'auto',
  width: '250px',
});

export const THAImage = styled.img({
  position: 'absolute',
  bottom: '-40px',
  right: '30px',
  boxShadow: '1px 1px 18px 2px rgba(0,0,0,0.62)',

  // breakpoints
  [DESKTOP_LARGE_BREAKPOINT_MAX]: {
    height: 'auto',
    width: '450px',
  },
  [DESKTOP_BREAKPOINT_MAX]: {
    height: 'auto',
    width: '350px',
  },
  [TABLET_BREAKPOINT]: {
    display: 'none',
  },
});

export const FooterLinkSection = styled.div({
  display: 'flex',
  gap: Gap.desktop.normal,
  color: AppColors.text.green.primary,
  fontSize: 18,
  a: {
    color: AppColors.text.green.primary,
  },

  [MOBILE_BREAKPOINT]: {
    flexDirection: 'column',
    gap: Gap.mobile.large,
    'span:nth-child(2)': {
      display: 'none',
    },
  },
});

export const FooterTHAContainer = styled.div({
  position: 'relative',
});
