import { FC } from "react";
import { BodyCard } from "../body-card/body-card";
import { LoadingSpinner } from "../loading-spinner/loading-spinner";
import { BodyCardLoadingContainer } from "./body-card-loading.styled";

export const BodyCardLoading: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <BodyCardLoadingContainer>
                <LoadingSpinner size={48} />
            </BodyCardLoadingContainer>
        </BodyCard>
    )
}