import { CoreAPI } from "../core";
import { GetSectionResult, SaveSectionPayload, SaveSectionResult } from "./dtos";

export class AssessmentSectionsAPI {
  private basePath: string;

  constructor(private readonly coreApi: CoreAPI) {
    this.basePath = `${this.coreApi.apiUrl}/assessment-sections`;
  }

  async getSectionById(sectionId: string): Promise<GetSectionResult> {
    const result = await this.coreApi.get(`${this.basePath}/${sectionId}`)

    if (!result?.data) throw new Error();

    const getCurrentAssessmentResult = result.data as GetSectionResult;

    return getCurrentAssessmentResult;
  }

  async saveSection(sectionId: string, payload: SaveSectionPayload): Promise<SaveSectionResult> {
    const result = await this.coreApi.post(`${this.basePath}/${sectionId}/save`, payload)

    if (!result?.data) throw new Error();

    const saveSectionResult = result.data as SaveSectionResult;

    return saveSectionResult;
  }
}