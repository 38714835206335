import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString, IsUUID } from "class-validator";

export class CompleteSignUpPayload {
    @IsString()
    @IsNotEmpty()
    securityToken: string;

    @IsString()
    @IsNotEmpty()
    firstName: string;
  
    @IsString()
    @IsNotEmpty()
    lastName: string;
  
    @IsString()
    @IsEmail()
    emailAddress: string;
  
    @IsString()
    @IsPhoneNumber()
    phoneNumber: string;
  
    @IsString()
    @IsNotEmpty()
    jobTitle: string;
  
    @IsString()
    @IsNotEmpty()
    password: string;
}