import { FC } from "react";
import { SectionState } from "../../assessment-sections.types";
import { SectionIconContainer, SectionIconImage, SectionIconSkeleton } from "./section-icon.styled";
import { CompletedTick } from "./subcomponents/completed-tick";

type SectionIconProps = {
    iconSvg: string
    sectionState: SectionState
    size: number
}

export const SectionIcon: FC<SectionIconProps> = ({
    iconSvg,
    sectionState,
    size,
}) => {
    return (
        <SectionIconContainer state={sectionState} size={size}>
            <SectionIconImage src={`data:image/svg+xml;base64,${iconSvg}`} size={size * .5} />
            {sectionState === 'complete' && (
                <CompletedTick />
            )}
        </SectionIconContainer>
    )
}

type SectionIconLoadingStateProps = {
    size: number
}

export const SectionIconLoadingState: FC<SectionIconLoadingStateProps> = ({
    size,
}) => {
    return (
        <SectionIconSkeleton size={size} />
    )
}