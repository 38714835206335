import { AdminCreateUserPayload, AdminCreateUserResult, GetUserTypesResult, SearchAccountsPayload, SearchAccountsResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../../../../services/core-api-service";

class CreateUserService {
    public async getUserTypes(): Promise<GetUserTypesResult> {
        return api.admin.getUserTypes()
    }

    public async createUser(payload: AdminCreateUserPayload): Promise<AdminCreateUserResult> {
        return api.admin.createUser(payload);
    }

    public async searchAccounts(payload: SearchAccountsPayload): Promise<SearchAccountsResult> {
        return api.admin.searchAccounts(payload);
    }
}

export const service = new CreateUserService()