import { FC, MouseEventHandler } from "react";
import { AppColors } from "../../../../constants/colors/app-colors";
import { CloseIcon } from "../../../icons/close-icon/close-icon";
import { DialogCloseButtonContainer } from "./dialog-close-button.styled";

type DialogCloseButtonProps = {
    onClick: MouseEventHandler<HTMLDivElement>
}

export const DialogCloseButton: FC<DialogCloseButtonProps> = ({
    onClick,
}) => {
    return (
        <DialogCloseButtonContainer onClick={onClick}>
            <CloseIcon
                size={36}
                color={AppColors.text.onLightBackground}
            />
        </DialogCloseButtonContainer>
    )
}