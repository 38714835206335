import { FC, useCallback, useState } from "react";
import { PrimaryOutlineButton } from "../../../../../../common/components/buttons/buttons";
import { DisableUserDialog } from "./subcomponents/disable-user-dialog/disable-user-dialog";

type DisableUserButtonProps = {
    userId: string
    onRefresh: () => void
} & Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'>

export const DisableUserButton: FC<DisableUserButtonProps> = ({
    userId,
    onRefresh,
    ...buttonProps
}) => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <>
            <PrimaryOutlineButton
                {...buttonProps}
                onClick={handleButtonClick}
            >
                Disable Account
            </PrimaryOutlineButton>
            <DisableUserDialog
                userId={userId}
                isOpen={dialogOpen}
                onRefresh={onRefresh}
                onClose={handleDialogClose}
            />
        </>
    )
}