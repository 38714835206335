import React from "react";
import { ChangeEvent, useCallback } from "react";
import { FC } from "react";
import { GetSectionResult_ResponseValue, GetSectionResult_Question } from "tech-health-assessment-sdk/dist";
import { ParagraphSkeleton, RadioSkeleton } from "../../../../../../../common/skeletons/skeletons";
import { QuestionRowContainer, QuestionRowResponsesContainer } from "../../section-questions.styled";
import { QuestionText, ResponseLabel, ResponseRadio, ResponseValueContainer } from "./questions-grid-row.styled";

type QuestionsGridRowProps = {
    value: GetSectionResult_Question
    responseValues: GetSectionResult_ResponseValue[]
    readOnly?: boolean

    // events
    onChange: (value: GetSectionResult_Question) => void
    onBlur: () => void
}

export const QuestionsGridRow: FC<QuestionsGridRowProps> = React.forwardRef<HTMLDivElement, QuestionsGridRowProps>(({
    value: question,
    responseValues,
    readOnly,

    // events
    onChange,
    onBlur,
}, ref) => {

    // event handlers
    const handleRadioButtonChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...question,
            selectedAssessmentResponseValueId: event.target.value,
        })
    }, [onChange, question])

    return (
        <QuestionRowContainer ref={ref}>
            <QuestionText>
                {question.questionText}
            </QuestionText>
            <QuestionRowResponsesContainer>
                {responseValues.map((responseValue) => (
                    <ResponseValueContainer key={responseValue.id}>
                        <ResponseLabel
                            htmlFor={`${question.id}_${responseValue.id}`}
                        >
                            {responseValue.label}
                        </ResponseLabel>
                        <ResponseRadio
                            disabled={readOnly}
                            id={`${question.id}_${responseValue.id}`}
                            name={question.id}
                            type="radio"
                            value={responseValue.id}
                            checked={question.selectedAssessmentResponseValueId === responseValue.id}
                            onChange={handleRadioButtonChange}
                            onBlur={onBlur}
                        />
                    </ResponseValueContainer>
                ))}
            </QuestionRowResponsesContainer>
        </QuestionRowContainer>
    )
})

export const QuestionsGridRowLoadingState: FC = () => {
    return (
        <QuestionRowContainer>
            <ParagraphSkeleton />
            <QuestionRowResponsesContainer>
                <ResponseValueContainer>
                    <RadioSkeleton />
                </ResponseValueContainer>
                <ResponseValueContainer>
                    <RadioSkeleton />
                </ResponseValueContainer>
                <ResponseValueContainer>
                    <RadioSkeleton />
                </ResponseValueContainer>
            </QuestionRowResponsesContainer>
        </QuestionRowContainer>
    )
}