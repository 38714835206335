import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";
import { Shadows } from "../../constants/shadows/shadows";
import { BorderRadius, Gap, Padding } from "../sizes/sizes";

export const DialogContainer = styled.div<{
    isOpen: boolean
}>(({ isOpen }) => ({
    // position
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,

    // layout
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',

    // overflow
    overflowY: 'auto',

    // pointer events
    pointerEvents: isOpen ? 'all' : 'none',
}))

const BackdropAppearAnimationKeyframes = keyframes({
    from: {
        backgroundColor: AppColors.dialog.backdrop.closed,
    },
    to: {
        backgroundColor: AppColors.dialog.backdrop.open,
    },
})

export const DialogBackdrop = styled.div<{
    isOpen: boolean
}>(({ isOpen }) => ({
    // position
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,

    // colors
    backgroundColor: isOpen ? AppColors.dialog.backdrop.open : AppColors.dialog.backdrop.closed,

    // cursor
    cursor: 'pointer',

    // animation
    transition: 'all 200ms ease',   
    animation: `${BackdropAppearAnimationKeyframes} 200ms ease`,
    animationFillMode: 'backwards',
}))

const DialogCardAppearKeyframes = keyframes({
    from: {
        transform: 'translateY(18px)',
    },
    to: {
        transform: 'translateY(0px)',
    }
})

export const DialogCard = styled.div<{
    isOpen: boolean
}>(({ isOpen }) => ({
    // position
    position: 'relative',

    // colors
    backgroundColor: 'white',

    // shadow
    boxShadow: Shadows.dialog,
    
    // border
    borderRadius: BorderRadius.normal,

    // opacity
    opacity: isOpen ? 1 : 0,

    // transform
    transform: isOpen ? 'translateY(0px)' : 'translateY(18px)',

    // transition
    transition: 'all 200ms ease',
    animation: `${DialogCardAppearKeyframes} 200ms ease`,
    animationFillMode: 'backwards',
}))

export const DialogTitle = styled.span({
    // text
    fontSize: 28,
    fontWeight: 700,
})

export const DialogDescription = styled.p()

export const DialogHeader = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.normal,

    // padding
    paddingBottom: Padding.desktop.normal,

    // border
    borderBottom: `1px solid ${AppColors.border.default}`,
})

export const DialogFooter = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'max-content',
    gridAutoColumns: 'max-content',
    gap: Gap.desktop.normal,

    // alignment
    justifyContent: 'end',
    
    // padding
    paddingTop: Padding.desktop.normal,

    // border
    borderTop: `1px solid ${AppColors.border.default}`,
})