import { FC } from "react";
import { SubtitleSkeleton, TitleSkeleton } from "../../skeletons/skeletons";
import { Subtitle } from "../subtitle/subtitle";
import { Title } from "../title/title";
import { ActionsContainer, InnerContainer, StandardCardHeaderContainer, TextContainer } from "./standard-card-header.styled";

type StandardCardHeaderProps = {
    title: string | JSX.Element
    subtitle?: string | JSX.Element
    actions?: JSX.Element | JSX.Element[]
}

export const StandardCardHeader: FC<StandardCardHeaderProps> = ({
    title,
    subtitle,
    actions,
}) => {
    return (
        <StandardCardHeaderContainer>
            <InnerContainer>
                <TextContainer>
                    <Title>
                        {title}
                    </Title>
                    {!!subtitle && (
                        <Subtitle>
                            {subtitle}
                        </Subtitle>
                    )}
                </TextContainer>
                {!!actions && (
                    <ActionsContainer>
                        {actions}
                    </ActionsContainer>
                )}
            </InnerContainer>
        </StandardCardHeaderContainer>
    )
}

type StandardCardHeaderLoadingStateProps = {
    hasSubtitle: boolean
    actionSkeletons?: JSX.Element | JSX.Element[]
}

export const StandardCardHeaderLoadingState: FC<StandardCardHeaderLoadingStateProps> = ({
    hasSubtitle,
    actionSkeletons,
}) => {
    return (
        <StandardCardHeaderContainer>
            <InnerContainer>
                <TextContainer>
                    <TitleSkeleton />
                    {hasSubtitle && (
                        <SubtitleSkeleton />
                    )}
                </TextContainer>
                {!!actionSkeletons && (
                    <ActionsContainer>
                        {actionSkeletons}
                    </ActionsContainer>
                )}
            </InnerContainer>
        </StandardCardHeaderContainer>
    )
}