import { FC, useContext } from "react";
import { CalloutCardContext } from "../callout-card/callout-card";
import { CalloutCardButtonElement } from "./callout-card-button.styled";

type CalloutCardButtonProps = {
    children: string | JSX.Element | JSX.Element[]
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export const CalloutCardButton: FC<CalloutCardButtonProps> = ({
    children,
    ...buttonProps
}) => {

    // context
    const { color } = useContext(CalloutCardContext)

    return (
        <CalloutCardButtonElement
            {...buttonProps}
            color={color}
        >
            {children}
        </CalloutCardButtonElement>
    )
}