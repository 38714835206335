import { IsBoolean, IsObject, IsOptional, IsUUID, ValidateNested } from "class-validator";

export class AdminCreateUserResult {
    @IsBoolean()
    success: boolean;

    @IsObject()
    @IsOptional()
    @ValidateNested()
    user?: AdminCreateUserResult_User;
}

export class AdminCreateUserResult_User {
    @IsUUID()
    userId: string;
}