import { AdminCreateAccountPayload, AdminCreateAccountResult, GetAccountTypesResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../../../../services/core-api-service";

class CreateAccountService {
    public async getAccountTypes(): Promise<GetAccountTypesResult> {
        return api.admin.getAccountTypes()
    }

    public async createAccount(payload: AdminCreateAccountPayload): Promise<AdminCreateAccountResult> {
        return api.admin.createAccount(payload);
    }
}

export const service = new CreateAccountService()