import { FC, useMemo } from "react";
import { GetAdminAssessmentsRequest } from "tech-health-assessment-sdk/dist";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { usePagination } from "../../../../common/hooks/use-pagination/use-pagination";
import { AdminAssessmentsGridCard, AdminAssessmentsGridCardLoadingState } from "../../grid-cards/admin-assessments-grid-card/admin-assessments-grid-card";
import { useAdminAssessments } from "./admin-assessments.hooks";

export const AdminAssessments: FC = () => {

    // state
    const [pagination, setPagination] = usePagination()

    // constants
    const request: GetAdminAssessmentsRequest = useMemo(() => ({
        ...pagination,
    }), [pagination])

    // data hooks
    const { data: assessmentsResult, isLoading, refreshData } = useAdminAssessments(request)

    // data abstraction
    const assessments = assessmentsResult?.assessments;

    if (isLoading || !assessments) {
        return (
            <BodyContainer>
                <AdminAssessmentsGridCardLoadingState />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <AdminAssessmentsGridCard
                assessments={assessments}
                onRefresh={refreshData}
                pagination={pagination}
                onPaginationChange={setPagination}
                totalCount={assessmentsResult.totalCount}
            />
        </BodyContainer>
    )
}