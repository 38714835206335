import { IsArray, IsDate, IsNumber, IsObject, IsString, IsUrl, IsUUID, ValidateNested } from "class-validator";
import { AssessmentStatusCode, AssessmentTypeCode } from "../../../../common/codes.types";
import { PaginatedResult } from "../../../common";

export class GetAdminAssessmentsResult extends PaginatedResult {
    @IsArray()
    @ValidateNested()
    assessments: GetAdminAssessmentsResult_Assessment[]

    @IsArray()
    @ValidateNested()
    assessmentStatuses: GetAdminAssessmentsResult_AssessmentStatus[]
}

export class GetAdminAssessmentsResult_Assessment {
    @IsUUID()
    assessmentId: string;

    @IsObject()
    @ValidateNested()
    account: GetAdminAssessmentsResult_Account;

    @IsString()
    assessmentType: GetAdminAssessmentsResult_AssessmentType;

    @IsString()
    assessmentStatus: GetAdminAssessmentsResult_AssessmentStatus;

    @IsNumber()
    completionPercentage: number;

    @IsUrl()
    workspaceLink: string;

    @IsUrl()
    smartsheetLink: string;

    @IsDate()
    createdAt: Date;
}

export class GetAdminAssessmentsResult_Account {
    @IsUUID()
    accountId: string;

    @IsString()
    companyName: string;
}

export class GetAdminAssessmentsResult_AssessmentStatus {
    @IsUUID()
    assessmentStatusId: string;

    @IsString()
    code: AssessmentStatusCode;

    @IsString()
    label: string;
}

export class GetAdminAssessmentsResult_AssessmentType {
    @IsUUID()
    assessmentTypeId: string;

    @IsString()
    code: AssessmentTypeCode;

    @IsString()
    label: string;
}