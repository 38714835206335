import { IsObject, IsString, IsUUID, ValidateNested } from "class-validator";
import { EmailTemplateCode, EmailTemplateTargetCode, UserTypeCode } from "../../../../common/codes.types";

export class GetEmailTemplateResult {
    @IsUUID()
    emailTemplateId: string;

    @IsString()
    label: string;

    @IsString()
    code: EmailTemplateCode;

    @IsString()
    description: string;

    @IsString()
    subject: string;

    @IsString()
    content: string;

    @IsString()
    userType: GetEmailTemplateResult_UserType;

    @IsObject()
    @ValidateNested()
    emailTemplateTarget: GetEmailTemplateResult_EmailTemplateTarget;
}

export class GetEmailTemplateResult_UserType {
    @IsUUID()
    userTypeId: string;

    @IsString()
    code: UserTypeCode;

    @IsString()
    label: string;
}

export class GetEmailTemplateResult_EmailTemplateTarget {
    @IsUUID()
    emailTemplateTargetId: string;

    @IsString()
    code: EmailTemplateTargetCode;

    @IsString()
    label: string;
}