import styled from "@emotion/styled";
import { MOBILE_BREAKPOINT } from "../../constants/breakpoints/breakpoints";
import { Gap, Padding } from "../sizes/sizes";

export const StandardCardLayout = styled.div({
    // layout
    display: 'flex',
    gap: Gap.desktop.smaller,
    flexDirection: 'column',

    // flex
    flex: 1,

    // padding
    paddingBottom: Padding.desktop.large,

    // size
    maxWidth: '100%',

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gap: Gap.mobile.normal,

        // padding
        padding: Padding.mobile.larger,
    },
})