import React, { FC, FunctionComponent } from "react";
import { IconProps } from "../../../../../../../../common/components/icons/icons.types";
import { AppColors } from "../../../../../../../../common/constants/colors/app-colors";
import { AdminGridItemIconContainer, AdminGridItemIconSkeleton } from "./admin-grid-item-icon.styled";

type AdminGridItemIconProps = {
    sourceIconComponent: FunctionComponent<IconProps>
}

export const AdminGridItemIcon: FC<AdminGridItemIconProps> = ({
    sourceIconComponent: SourceIconComponent,
}) => {
    return (
        <AdminGridItemIconContainer size={62}>
            <SourceIconComponent
                size={32}
                color={AppColors.text.onDarkBackground}
            />
        </AdminGridItemIconContainer>
    )
}

export const AdminGridItemIconLoadingState: FC = () => {
    return (
        <AdminGridItemIconSkeleton size={32} />
    )
}