import { useMediaQuery } from 'react-responsive';

const DESKTOP_LARGE_BREAKPOINT_VALUE = 1700;
const DESKTOP_BREAKPOINT_VALUE = 1440;
const DESKTOP_MID_BREAKPOINT_VALUE = 1200;
const TABLET_BREAKPOINT_VALUE = 1024;
const MOBILE_BREAKPOINT_VALUE = 768;

export const useIsDesktop = () =>
  useMediaQuery({ minWidth: DESKTOP_BREAKPOINT_VALUE });
export const useIsTablet = () =>
  useMediaQuery({ maxWidth: TABLET_BREAKPOINT_VALUE });
export const useIsMobile = () =>
  useMediaQuery({ maxWidth: MOBILE_BREAKPOINT_VALUE });

export const DESKTOP_LARGE_BREAKPOINT_MAX = `@media (max-width: ${DESKTOP_LARGE_BREAKPOINT_VALUE}px)`;
export const DESKTOP_MID_BREAKPOINT_MAX = `@media (max-width: ${DESKTOP_MID_BREAKPOINT_VALUE}px)`;
export const DESKTOP_BREAKPOINT_MAX = `@media (max-width: ${DESKTOP_BREAKPOINT_VALUE}px)`;
export const DESKTOP_BREAKPOINT = `@media (min-width: ${DESKTOP_BREAKPOINT_VALUE}px)`;
export const TABLET_BREAKPOINT = `@media (max-width: ${TABLET_BREAKPOINT_VALUE}px)`;
export const MOBILE_BREAKPOINT = `@media (max-width: ${MOBILE_BREAKPOINT_VALUE}px)`;
