import { FC, useCallback, useContext, useState } from "react";
import { AdminEnableUserResult } from "tech-health-assessment-sdk/dist";
import { PrimaryButton, SecondaryButton } from "../../../../../../../../common/components/buttons/buttons";
import { CalloutCard } from "../../../../../../../../common/components/callout-card/callout-card";
import { Dialog, DialogProps } from "../../../../../../../../common/components/dialog/dialog";
import { DialogCard, DialogFooter, DialogHeader, DialogTitle } from "../../../../../../../../common/components/dialog/dialog.styled";
import { LoadingSpinner } from "../../../../../../../../common/components/loading-spinner/loading-spinner";
import { SnackbarContext } from "../../../../../../../../common/components/snackbar-provider/snackbar-provider";
import { StandardDialogInnerContainer } from "../../../../../../../../common/components/standard-dialog-inner-container/standard-dialog-inner-container";
import { AppColors } from "../../../../../../../../common/constants/colors/app-colors";
import { service } from "./enable-user-dialog.service";

type EnableUserDialogProps = {
    userId: string
    onRefresh: () => void
} & Omit<DialogProps, 'children'>

export const EnableUserDialog: FC<EnableUserDialogProps> = ({
    userId,
    isOpen,
    onClose,
    onRefresh,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleDisableUserClick = useCallback(async () => {
        setIsLoading(true)

        let result: AdminEnableUserResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.enableUser(userId)
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support.'
            });
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully enabled user',
            })
        }

        onClose()
        onRefresh()
        setIsLoading(false)
    }, [onClose, onRefresh, showSnackbar, userId])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <DialogCard isOpen={isOpen}>
                <StandardDialogInnerContainer>
                    <DialogHeader>
                        <DialogTitle>
                            Enable User?
                        </DialogTitle>
                    </DialogHeader>
                    <CalloutCard type='info'>
                        <h2>What will happen?</h2>
                        <br />
                        <span>If you proceed...</span>
                        <ul>
                            <li>The user will be able to access their account again.</li>
                            <li>We will not tell the user that you've enabled their account.</li>
                            <li>You can disable the user's account at any time</li>
                        </ul>
                    </CalloutCard>
                    <DialogFooter>
                        <SecondaryButton
                            disabled={isLoading}
                            onClick={onClose}
                        >
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={isLoading}
                            onClick={handleDisableUserClick}
                        >
                            {isLoading && (
                                <LoadingSpinner
                                    size={14}
                                    color={AppColors.text.onDarkBackground}
                                />
                            )}
                            Enable User
                        </PrimaryButton>
                    </DialogFooter>
                </StandardDialogInnerContainer>
            </DialogCard>
        </Dialog>
    )
}