import { DateTime, DateTimeFormatOptions } from "luxon";
import { CellProps, Renderer } from "react-table";

type DateCellOptions = {
    datetimeFormatOptions: DateTimeFormatOptions
}

export const DateCell: <T extends object>(
    options: DateCellOptions
) => Renderer<CellProps<T, unknown>> = 
    (options) => {
        return (props) => props.value ? (
            <>
                {DateTime.fromISO(props.value as string).toLocaleString(options.datetimeFormatOptions)}
            </>
        ) : (<></>)
}