import React, { FC } from 'react'
import { AppColors } from '../../constants/colors/app-colors'
import { LoadingSpinnerContainer, Path, Spinner } from './loading-spinner.styled'

type LoadingSpinnerProps = {
	size?: number
	color?: string
}

export const LoadingSpinner: FC<LoadingSpinnerProps> = ({
	size = 24,
	color = AppColors.text.blue.primary,
}) => {
	return (
		<LoadingSpinnerContainer>
			<Spinner viewBox="0 0 50 50" width={size} height={size}>
				<Path cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke={color} />
			</Spinner>
		</LoadingSpinnerContainer>
	)
}