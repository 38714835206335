import styled from "@emotion/styled"
import { Gap } from "../../../../../common/components/sizes/sizes"

export const ReadyToSubmitSectionContainer = styled.div({
    // layout
    display: 'grid',
})

export const InnerContainer = styled.div({
    display: 'grid',
    gap: Gap.desktop.normal,
    alignItems: 'center',
    gridTemplateColumns: '1fr max-content'
})

export const CardHeader = styled.div({
    display: 'grid',
})

export const CardFooter = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'max-content',
    gridAutoColumns: 'max-content',
    gap: Gap.desktop.normal,
})