import { FC, useCallback, useState } from "react";
import { PrimaryButton } from "../../../../../../common/components/buttons/buttons";
import { ConvertToClientDialog } from "../../../../../submodules/accounts/convert-to-client-dialog/convert-to-client-dialog";

type ConvertToClientButtonProps = {
    accountId: string
    onRefresh: () => void
}

export const ConvertToClientButton: FC<ConvertToClientButtonProps> = ({
    accountId,
    onRefresh,
}) => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <>
            <PrimaryButton onClick={handleButtonClick}>
                Convert Lead to Client
            </PrimaryButton>
            <ConvertToClientDialog
                accountId={accountId}
                isOpen={dialogOpen}
                onRefresh={onRefresh}
                onClose={handleDialogClose}
            />
        </>
    )
}