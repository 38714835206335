import {
  BottomSectionContainer,
  ContentColumn,
  SectionTitle,
  TextSection,
} from './landing.styled';

import { FC } from 'react';

export const LandingBottomSection: FC = () => {
  return (
    <BottomSectionContainer>
      <ContentColumn>
        <SectionTitle>Why Technology Health Assessment</SectionTitle>
        <TextSection>
          To get technology to achieve your business goals, it's essential to
          use the latest innovations to solve problems while balancing security
          risks and optimizing costs. The AcquireTek Health Assessment helps you
          partner with your business leaders by providing a board-ready,
          detailed analysis of your IT health, budget, and strengths and
          weaknesses.
        </TextSection>
      </ContentColumn>
      <ContentColumn>
        <TextSection>
          Our assessment is tailored to your needs and covers seven technology
          functions: ITSM, Info Security, Infrastructure and Platform
          Management, General Management of Technology, Business Analysis and
          Testing, Software Development and Deployment, and Supplier Management.
          With this information, you can make informed decisions about your IT
          strategy and infrastructure to reduce costs and improve efficiency.
        </TextSection>
      </ContentColumn>
    </BottomSectionContainer>
  );
};
