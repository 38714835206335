import { FC } from "react";
import { UpdatePassword } from "../../../../modules/authentication/components/update-password/update-password";
import { useQuery } from "../../../../modules/common/hooks/use-query/use-query";

export const UpdatePasswordRoute: FC = () => {

    // router hooks
    const { securityToken } = useQuery() as { securityToken: string }
    const decodedSecurityToken = atob(securityToken)

    return (
        <UpdatePassword
            securityToken={decodedSecurityToken}
        />
    )
}