import { FC, useMemo } from "react";
import { GetAdminAccountsResult_Account, PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { StandardCardGridContainer } from "../../../../common/components/standard-card-grid-container/standard-card-grid-container";
import { StandardCardHeader, StandardCardHeaderLoadingState } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { Grid, GridLoadingState } from "../../../../common/grid/grid";
import { ButtonSkeleton } from "../../../../common/skeletons/skeletons";
import { columns } from "./admin-accounts-grid-card.columns";
import { CreateAccountButton } from "./subcomponents/create-account-button/create-account-button";

type AdminAccountsGridCardProps = {
    accounts: GetAdminAccountsResult_Account[]
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
    totalCount: number
}

export const AdminAccountsGridCard: FC<AdminAccountsGridCardProps> = ({
    accounts,
    pagination,
    onPaginationChange,
    totalCount,
}) => {

    // constants
    const memoizedColumns = useMemo(() => (columns), [])

    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeader
                    title="Accounts"
                    subtitle="See and manage accounts"
                    actions={(
                        <CreateAccountButton />
                    )}
                />
                <StandardCardGridContainer>
                    <Grid
                        columns={memoizedColumns}
                        rows={accounts}
                        pagination={pagination}
                        onPaginationChange={onPaginationChange}
                        totalCount={totalCount}
                    />
                </StandardCardGridContainer>
            </StandardCardLayout>
        </BodyCard>
    )
}

export const AdminAccountsGridCardLoadingState: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeaderLoadingState
                    hasSubtitle={true}
                    actionSkeletons={(
                        <ButtonSkeleton />
                    )}
                />
                <GridLoadingState />
            </StandardCardLayout>
        </BodyCard>
    )
}