import styled from "@emotion/styled";
import { AppColors } from "../../../common/constants/colors/app-colors";
import { Gap, Padding, BorderRadius } from "../../../common/components/sizes/sizes";
import { SectionState } from "../../assessment-sections.types";
import { MOBILE_BREAKPOINT } from "../../../common/constants/breakpoints/breakpoints";

export const SectionGridItemContainer = styled.div<{
    state: SectionState,
    displayMode: 'normal' | 'compact',
    isActive: boolean,
}>(({ state, displayMode, isActive }) => ({
    // display
    display: 'grid',
    gridTemplateColumns: displayMode === 'normal' ? 'max-content 1fr' : '1fr',
    gridTemplateRows: displayMode === 'normal' ? 'max-content' : 'max-content max-content',
    gap: Gap.desktop.normal,
    justifyItems: displayMode === 'normal' ? 'start' : 'center',

    // cursor
    cursor: 'pointer',

    // padding
    padding: Padding.desktop.smaller,

    // border
    border: `2px solid ${isActive ? AppColors.background.blue.primary : 'transparent'}`,
    borderRadius: BorderRadius.small,

    // text
    color: state === 'complete'
        ? AppColors.text.onLightBackground
        : AppColors.text.blue.primary,
    textAlign: displayMode === 'normal' ? 'left' : 'center',

    // size
    boxSizing: 'border-box',
    maxWidth: displayMode === 'normal' ? 'unset' : 200,

    // hover
    ':hover': {
        backgroundColor: AppColors.background.blue.light,
    },

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        padding: Padding.mobile.smaller,
        paddingTop: Padding.mobile.small,
        paddingBottom: Padding.mobile.small,
    },
}))

export const SectionTextContainer = styled.div<{
    displayMode: 'normal' | 'compact',
}>(({ displayMode }) => ({
    // layout
    display: 'grid',
    alignContent: 'center',
    gap: Gap.desktop.smaller,
    gridTemplateRows: displayMode === 'compact' ? 'calc(2rem + 12px) max-content' : 'unset',
    gridAutoRows: 'max-content',

    // text
    wordBreak: displayMode === 'normal' ? 'normal' : 'break-word',
}))

export const SectionNameLabel = styled.span({
    // text
    fontWeight: 600,
    fontsize: 14,

    // size
    maxHeight: 'calc(2rem + 12px)',

    // overflow
    overflow: 'hidden',
})

export const SectionProgressLabel = styled.span({
    fontSize: 13,
    fontStyle: 'italic',
})

export const SectionGridItemLoadingContainer = styled.div<{
    displayMode: 'normal' | 'compact',
}>(({ displayMode }) => ({
    // display
    display: 'grid',
    gridTemplateColumns: displayMode === 'normal' ? 'max-content 1fr' : '1fr',
    gridTemplateRows: displayMode === 'normal' ? 'max-content' : 'max-content max-content',
    gap: Gap.desktop.normal,
    justifyItems: displayMode === 'normal' ? 'start' : 'center',

    // cursor
    cursor: 'pointer',

    // padding
    padding: Padding.desktop.smaller,

    // border
    borderRadius: BorderRadius.small,

    // text
    textAlign: displayMode === 'normal' ? 'left' : 'center',

    // size
    boxSizing: 'border-box',
    maxWidth: displayMode === 'normal' ? 'unset' : 200,

    // hover
    ':hover': {
        backgroundColor: AppColors.background.blue.light,
    },

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        padding: Padding.mobile.smaller,
        paddingTop: Padding.mobile.small,
        paddingBottom: Padding.mobile.small,
    },
}))

export const SectionTextLoadingContainer = styled.div<{
    displayMode: 'normal' | 'compact',
}>(({ displayMode }) => ({
    display: 'grid',
    alignContent: 'center',
    gap: Gap.desktop.smaller,
    gridAutoRows: 'max-content',

    // text
    wordBreak: displayMode === 'normal' ? 'normal' : 'break-word',
}))