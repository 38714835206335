import styled from "@emotion/styled";
import { Gap } from "../sizes/sizes";

const VERTICAL_FORM_BREAKPOINT = '@media (max-width: 1000px)';

export const FormItemColumns = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    gridAutoColumns: '1fr',
    gap: Gap.desktop.large * 0.75,

    // breakpoints
    [VERTICAL_FORM_BREAKPOINT]: {
        gridAutoFlow: 'row',
        gridAutoRows: 'max-content',
    },
})