import { FC } from "react";
import { IconSvg } from "../icons.styled";
import { IconProps } from "../icons.types";

export const ArrowLeftIcon: FC<IconProps> = ({
    size,
    color,
}) => {
    return (
        <IconSvg
            size={size}
            color={color}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13.6668 6.16658H3.52516L8.1835 1.50825L7.00016 0.333252L0.333496 6.99992L7.00016 13.6666L8.17516 12.4916L3.52516 7.83325H13.6668V6.16658Z"/>
        </IconSvg>
    )
}