import { FC } from "react";
import { SectionQuestions, SectionQuestionsLoadingState } from "../../../../assessment-sections/components/assessment-section/subcomponents/section-questions/section-questions";
import { useAdminAssessmentSection } from "./admin-section.hooks";

type AdminSectionProps = {
    sectionId: string
}

export const AdminSection: FC<AdminSectionProps> = ({
    sectionId
}) => {

    // data hooks
    const { isLoading, data: assessmentSection } = useAdminAssessmentSection(sectionId)

    if (isLoading || !assessmentSection) {
        return (
            <SectionQuestionsLoadingState />
        )
    }
    
    return (
        <SectionQuestions
            section={assessmentSection}
            readOnly
        />
    )
}