import { FC, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthenticationContext } from "../../../../../authentication/providers/authentication-provider/authentication-provider";
import { GhostSecondaryButtonLink, PrimaryOutlineButton } from "../../../../../common/components/buttons/buttons";
import { LoadingSpinner } from "../../../../../common/components/loading-spinner/loading-spinner";
import { SaveContext } from "../../../../providers/save-provider/save-provider";
import { SelfContext } from "../../../../providers/self-provider/self-provider";
import { ExternalUserNavItems } from "./external-user-nav-items/external-user-nav-items";
import { signOut } from "./header.services";
import { ActionsContainer, HeaderContainer, LogoContainer, NavContainer } from "./header.styled";
import { InternalUserNavItems } from "./internal-user-nav-items/internal-user-nav-items";

import { HEADER_LOGO } from '../../../../../common/environment/environment-constants';

export const Header: FC = () => {

    // context
    const { authenticationState, refreshAuthenticationState } = useContext(AuthenticationContext)
    const { canSave, onSave, isSaving } = useContext(SaveContext)
    const { self } = useContext(SelfContext)

    // constants
    const showNavContents = authenticationState === 'authenticated'

    // event handlers
    const handleSaveClick = useCallback(() => {
        onSave()
    }, [onSave])

    const handleLogOutClick = useCallback(() => {
        signOut()
        refreshAuthenticationState()
    }, [refreshAuthenticationState])

    return (
        <HeaderContainer>
            <LogoContainer>
                <Link to="/">
                    <img
                        data-testid="logo-image"
                        src={HEADER_LOGO}
                        alt="AcquireTek Logo"
                    />
                </Link>
            </LogoContainer>
            {showNavContents && (
                <>
                    <NavContainer data-testid="nav-container">
                        {self.userType === 'internal' && (
                            <InternalUserNavItems />
                        )}
                        {self.userType === 'external' && (
                            <ExternalUserNavItems />
                        )}
                    </NavContainer>
                    <ActionsContainer data-testid="actions-container">
                        <GhostSecondaryButtonLink
                            onClick={handleLogOutClick}
                        >
                            Log Out
                        </GhostSecondaryButtonLink>
                        {!!canSave && (
                            <PrimaryOutlineButton
                                disabled={isSaving}
                                onClick={handleSaveClick}
                            >
                                {isSaving && <LoadingSpinner size={14} />}
                                {isSaving ? 'Saving...' : 'Save'}
                            </PrimaryOutlineButton>
                        )}
                    </ActionsContainer>
                </>
            )}
        </HeaderContainer>
    )
}