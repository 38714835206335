import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";

export const BodyCard = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',

    // size
    width: '100%',
    maxWidth: 1340,

    // colors
    backgroundColor: AppColors.background.light.white,

    // borders
    borderRadius: 6,
})