import { FC } from "react";
import { BodyCard } from "../../../../../common/components/body-card/body-card";
import { Title } from "../../../../../common/components/title/title";
import { PreparingAssessmentContainer, PreparingDescriptionText, PreparingDetailsContainer } from "./preparing-assessment-card.styled";
import { PreparingAssessmentHero } from "./subcomponents/preparing-assessment-hero";

export const PreparingAssessmentCard: FC = () => {
    return (
        <BodyCard style={{ flex: 1, display: 'grid', }}>
            <PreparingAssessmentContainer>
                <PreparingAssessmentHero />
                <PreparingDetailsContainer>
                    <Title>We're getting your assessment ready for you</Title>
                    <PreparingDescriptionText>
                        We'll send you an email as soon as your assessment is ready.
                    </PreparingDescriptionText>
                </PreparingDetailsContainer>
            </PreparingAssessmentContainer>
        </BodyCard>
    )
}