import styled from "@emotion/styled";
import { MOBILE_BREAKPOINT } from "../../constants/breakpoints/breakpoints";
import { AppColors } from "../../constants/colors/app-colors";
import { Gap } from "../sizes/sizes";

export const BodyContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.larger,
    alignItems: 'center',
    flex: 1,

    // colors
    backgroundColor: AppColors.background.blue.primary,

    // padding
    paddingLeft: Gap.desktop.larger,
    paddingRight: Gap.desktop.larger,
    paddingTop: Gap.desktop.large,
    paddingBottom: Gap.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gap: Gap.mobile.larger,

        // padding
        paddingLeft: Gap.mobile.large,
        paddingRight: Gap.mobile.large,
        paddingTop: Gap.mobile.large,
        paddingBottom: Gap.mobile.large,
    },
})