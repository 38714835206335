import { FC, useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { AdminAccountRoute } from '../admin-routes/admin-accounts-route/subroutes/admin-account-route/admin-account-route';
import { AdminAccountsRoute } from '../admin-routes/admin-accounts-route/admin-accounts-route';
import { AdminAssessmentResultsRoute } from '../admin-routes/admin-assessments-route/subroutes/admin-assessment-results-route/admin-assessment-results-route';
import { AdminAssessmentRoute } from '../admin-routes/admin-assessments-route/subroutes/admin-assessment-route/admin-assessment-route';
import { AdminAssessmentsRoute } from '../admin-routes/admin-assessments-route/admin-assessments-route';
import { AdminEmailTemplateRoute } from '../admin-routes/admin-email-templates-route/subroutes/admin-email-template-route/admin-email-template-route';
import { AdminEmailTemplatesRoute } from '../admin-routes/admin-email-templates-route/admin-email-templates-route';
import { AdminHomeRoute } from '../admin-routes/admin-home-route/admin-home-route';
import { AdminSectionRoute } from '../admin-routes/admin-assessments-route/subroutes/admin-assessment-route/subroutes/admin-section-route/admin-section-route';
import { AdminUserRoute } from '../admin-routes/admin-users-route/subroutes/admin-user-route/admin-user-route';
import { AdminUsersRoute } from '../admin-routes/admin-users-route/admin-users-route';
import { AppContext } from '../../modules/app/providers/app-provider/app-provider';
import { AppRootRedirector } from '../../modules/app/components/app-root-redirector/app-root-redirector';
import { AppShell } from '../../modules/app/components/app-shell/app-shell';
import { AssessmentRoute } from '../assessment-route/assessment-route';
import { AssessmentSectionRoute } from '../assessment-route/subroutes/assessment-section-route/assessment-section-route';
import { AuthenticationContext } from '../../modules/authentication/providers/authentication-provider/authentication-provider';
import { AuthenticationRoute } from '../authentication-route/authentication-route';
import { BookmarkRoute } from '../authentication-route/subroutes/bookmark-route/bookmark-route';
import { CompleteSignUpRoute } from '../authentication-route/subroutes/complete-sign-up-route/complete-sign-up-route';
import { ForgotPasswordRoute } from '../authentication-route/subroutes/forgot-password-route/forgot-password-route';
import { LandingRoute } from '../authentication-route/subroutes/landing-route/landing-route';
import { ResultsRoute } from '../results-route/results-route';
import { SelfProvider } from '../../modules/app/providers/self-provider/self-provider';
import { SignInRoute } from '../authentication-route/subroutes/sign-in-route/sign-in-route';
import { SignUpRoute } from '../authentication-route/subroutes/sign-up-route/sign-up-route';
import { SubmitAssessmentRoute } from '../submit-assessment-route/submit-assessment-route';
import { UpdatePasswordRoute } from '../authentication-route/subroutes/update-password-route/update-password-route';

export const AppRoutes: FC = () => {
  // context
  const { bookmarkedRoute } = useContext(AppContext);
  const { authenticationState } = useContext(AuthenticationContext);

  useEffect(() => {
    if (authenticationState === 'authenticated' && bookmarkedRoute !== null) {
      // TODO: re-enable this after we're no longer doing a demo
      // navigate(bookmarkedRoute)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationState]);

  if (authenticationState === 'loading') return null;

  if (authenticationState === 'not-authenticated') {
    return (
      <Routes>
        <Route path="landing" element={<LandingRoute />} />
        <Route path="" element={<AuthenticationRoute />}>
          <Route path="sign-in" element={<SignInRoute />} />
          <Route path="sign-up" element={<SignUpRoute />} />
          <Route path="forgot-password" element={<ForgotPasswordRoute />} />
          <Route path="update-password" element={<UpdatePasswordRoute />} />
          <Route path="complete-sign-up" element={<CompleteSignUpRoute />} />
          <Route path="/" element={<BookmarkRoute />} />
          <Route path="*" element={<BookmarkRoute />} />
        </Route>
      </Routes>
    );
  }

  return (
    <SelfProvider>
      <AppShell>
        <Routes>
          <Route path="assessment" element={<AssessmentRoute />}>
            <Route
              path="section/:section_id"
              element={<AssessmentSectionRoute />}
            />
          </Route>
          <Route
            path="submit-assessment/:assessment_id"
            element={<SubmitAssessmentRoute />}
          />
          <Route path="results" element={<ResultsRoute />} />
          <Route path="admin">
            {/* home */}
            <Route path="home" element={<AdminHomeRoute />} />

            {/* accounts */}
            <Route path="accounts" element={<AdminAccountsRoute />} />
            <Route
              path="accounts/:account_id"
              element={<AdminAccountRoute />}
            />

            {/* users */}
            <Route path="users" element={<AdminUsersRoute />} />
            <Route path="users/:user_id" element={<AdminUserRoute />} />

            {/* assessments */}
            <Route path="assessments" element={<AdminAssessmentsRoute />} />
            <Route
              path="assessments/:assessment_id"
              element={<AdminAssessmentRoute />}
            >
              <Route
                path="section/:section_id"
                element={<AdminSectionRoute />}
              />
            </Route>
            <Route
              path="assessments/:assessment_id/results"
              element={<AdminAssessmentResultsRoute />}
            />

            {/* email templates */}
            <Route
              path="email-templates"
              element={<AdminEmailTemplatesRoute />}
            />
            <Route
              path="email-templates/:email_template_id"
              element={<AdminEmailTemplateRoute />}
            />

            {/* fallback */}
            <Route path="" element={<Navigate to="home" />} />
            <Route path="*" element={<Navigate to="home" />} />
          </Route>
          <Route path="*" element={<AppRootRedirector />} />
        </Routes>
      </AppShell>
    </SelfProvider>
  );
};
