import {
  CardContent,
  CardImage,
  CardSubTitle,
  CardTitle,
  HorizontalCard,
  MiddleSectionContainer,
  VerticalContainer,
} from './landing.styled';

import { FC } from 'react';

export const LandingMiddleSection: FC = () => {
  return (
    <MiddleSectionContainer>
      <HorizontalCard>
        <CardImage src="/static/images/authentication-hero-image.png" />
        <VerticalContainer>
          <CardTitle>Performance</CardTitle>
          <CardSubTitle>Tech and business alignment</CardSubTitle>
          <CardContent>
            Answers how effective IT is in supporting your business strategy.
            Gather insights into business alignment and your organization's
            innovation(or lack thereof).
          </CardContent>
        </VerticalContainer>
      </HorizontalCard>

      <HorizontalCard>
        <CardImage src="/static/images/authentication-hero-image.png" />
        <VerticalContainer>
          <CardTitle>Security</CardTitle>
          <CardSubTitle>Assess critical risks</CardSubTitle>
          <CardContent>
            How strong is your security position? An assessment will help you
            understand vulnerabilities and deficiencies.
          </CardContent>
        </VerticalContainer>
      </HorizontalCard>

      <HorizontalCard>
        <CardImage src="/static/images/authentication-hero-image.png" />
        <VerticalContainer>
          <CardTitle>Infrastructure</CardTitle>
          <CardSubTitle>Environment assessment & ITSM</CardSubTitle>
          <CardContent>
            Coupled with an overall health check, the THA helps uncover IT
            strengths and weaknesses and improves the environment and budget for
            optimal performance.
          </CardContent>
        </VerticalContainer>
      </HorizontalCard>
    </MiddleSectionContainer>
  );
};
