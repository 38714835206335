import { CoreAPI } from "../core/core-api";
import { CompleteSignUpPayload, CompleteSignUpResult, GetCompleteSignUpScreenDataRequest, GetCompleteSignUpScreenDataResult, RefreshAccessTokenPayload, RefreshAccessTokenResult, ResetPasswordPayload, ResetPasswordResult, SignInPayload, SignInResult, SignUpPayload, SignUpResult, UpdatePasswordPayload, UpdatePasswordResult } from "./dtos";
import { AccessTokenSubscriber, RefreshTokenSubscriber, UnsubscribeCallback } from "./auth-api.types";

export class AuthAPI {
    private basePath: string;
    public refreshTokenUrl: string;
  
    constructor(private readonly coreApi: CoreAPI) {
      this.basePath = `${this.coreApi.apiUrl}/auth`;
      this.refreshTokenUrl = `${this.basePath}/refreshAccessToken`
    }
  
    public async signIn(payload: SignInPayload): Promise<SignInResult> {
      const result = await this.coreApi.post(`${this.basePath}/signIn`, payload);
  
      if (!result?.data) throw new Error();

      const signIpResult = result.data as SignInResult;

      const newAccessToken = signIpResult.accessToken ?? null;
      const newRefreshToken = signIpResult.refreshToken ?? null;

      this.coreApi.accessToken = newAccessToken;
      this.coreApi.refreshToken = newRefreshToken;

      this.broadcastNewAccessToken(newAccessToken);
      this.broadcastNewRefreshToken(newRefreshToken);
  
      return signIpResult;
    }
  
    public async signOut() {
      this.coreApi.accessToken = null;
      this.coreApi.refreshToken = null;
      
      this.broadcastNewAccessToken(null);
      this.broadcastNewRefreshToken(null);
    }
  
    public async checkToken() {
      return this.coreApi.get(`${this.basePath}/checkToken`);
    }
  
    public async signUp(payload: SignUpPayload): Promise<SignUpResult> {
      const result = await this.coreApi.post(
        `${this.basePath}/signUp`,
        payload,
      );
  
      if (!result?.data) {
        this.broadcastNewAccessToken(null);
        this.broadcastNewRefreshToken(null);
        throw new Error()
      };

      const signUpResult = result.data as SignUpResult;

      const newAccessToken = signUpResult.tokens?.accessToken ?? null;
      const newRefreshToken = signUpResult.tokens?.refreshToken ?? null;

      this.coreApi.accessToken = newAccessToken;
      this.coreApi.refreshToken = newRefreshToken;

      this.broadcastNewAccessToken(newAccessToken);
      this.broadcastNewRefreshToken(newRefreshToken);
  
      return signUpResult;
    }

    public async resetPassword(payload: ResetPasswordPayload): Promise<ResetPasswordResult> {
      const result = await this.coreApi.post(`${this.basePath}/resetPassword`, payload)

      if (!result?.data) throw new Error();
  
      const resultData = result.data as ResetPasswordResult;
  
      return resultData;
    }

    public async refreshToken(payload: RefreshAccessTokenPayload): Promise<RefreshAccessTokenResult> {
      const result = await this.coreApi.post(
        this.refreshTokenUrl,
        payload,
      )

      if (!result?.data) {
        this.broadcastNewAccessToken(null);
        this.broadcastNewRefreshToken(null);
        throw new Error();
      }

      const refreshAccessTokenResult = result.data as RefreshAccessTokenResult;

      const newAccessToken = refreshAccessTokenResult.accessToken ?? null;

      this.coreApi.accessToken = newAccessToken;
      this.broadcastNewAccessToken(newAccessToken);

      return refreshAccessTokenResult;
    }

    public async completeSignUp(payload: CompleteSignUpPayload): Promise<CompleteSignUpResult> {
      const result = await this.coreApi.post(`${this.basePath}/completeSignUp`, payload)

      if (!result?.data) throw new Error();
  
      const resultData = result.data as CompleteSignUpResult;
  
      if (!resultData) {
        this.broadcastNewAccessToken(null);
        this.broadcastNewRefreshToken(null);
        throw new Error()
      };

      const newAccessToken = resultData.accessToken ?? null;
      const newRefreshToken = resultData.refreshToken ?? null;

      this.coreApi.accessToken = newAccessToken;
      this.coreApi.refreshToken = newRefreshToken;

      this.broadcastNewAccessToken(newAccessToken);
      this.broadcastNewRefreshToken(newRefreshToken);

      return resultData;
    }

    public async updatePassword(payload: UpdatePasswordPayload): Promise<UpdatePasswordResult> {
      const result = await this.coreApi.post(`${this.basePath}/updatePassword`, payload)

      if (!result?.data) throw new Error();
  
      const resultData = result.data as UpdatePasswordResult;
  
      return resultData;
    }

    public async getCompleteSignUpScreenData(request: GetCompleteSignUpScreenDataRequest): Promise<GetCompleteSignUpScreenDataResult> {
      const result = await this.coreApi.get(`${this.basePath}/getCompleteSignUpScreenData`, request)

      if (!result?.data) throw new Error();
  
      const resultData = result.data as GetCompleteSignUpScreenDataResult;
  
      return resultData;
    }
  
    private accessTokenSubscribers: AccessTokenSubscriber[] = [];
    subscribeToAccessTokenChanges(
      subscriber: AccessTokenSubscriber,
    ): UnsubscribeCallback {
      this.accessTokenSubscribers.push(subscriber);
      return () => this.unsubscribeFromAccessTokenChanges(subscriber);
    }
  
    private unsubscribeFromAccessTokenChanges(subscriber: AccessTokenSubscriber) {
      const subscriberToRemoveIndex =
        this.accessTokenSubscribers.indexOf(subscriber);
      this.accessTokenSubscribers.splice(subscriberToRemoveIndex, 1);
    }
  
    private broadcastNewAccessToken(accessToken: string | null) {
      this.accessTokenSubscribers.forEach((subscriber) =>
        subscriber(accessToken),
      );
    }
  
    private refreshTokenSubscribers: RefreshTokenSubscriber[] = [];
    subscribeToRefreshTokenChanges(
      subscriber: RefreshTokenSubscriber,
    ): UnsubscribeCallback {
      this.refreshTokenSubscribers.push(subscriber);
      return () => this.unsubscribeFromRefreshTokenChanges(subscriber);
    }
  
    private unsubscribeFromRefreshTokenChanges(subscriber: RefreshTokenSubscriber) {
      const subscriberToRemoveIndex =
        this.refreshTokenSubscribers.indexOf(subscriber);
      this.refreshTokenSubscribers.splice(subscriberToRemoveIndex, 1);
    }
  
    private broadcastNewRefreshToken(accessToken: string | null) {
      this.refreshTokenSubscribers.forEach((subscriber) =>
        subscriber(accessToken),
      );
    }
  }