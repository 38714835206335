import { FC, useCallback, useContext, useState } from "react";
import { GetCurrentAssessmentResultsResult } from "tech-health-assessment-sdk/dist";
import { SelfContext } from "../../../app/providers/self-provider/self-provider";
import { BodyCard } from "../../../common/components/body-card/body-card";
import { BodyContainer } from "../../../common/components/body-container/body-container";
import { PrimaryButton } from "../../../common/components/buttons/buttons";
import { Title } from "../../../common/components/title/title";
import { ButtonSkeleton, ParagraphSkeleton, SpanSkeleton, TitleSkeleton } from "../../../common/skeletons/skeletons";
import { ResultsAreOnTheWayCard } from "../results-are-on-the-way-card/results-are-on-the-way-card";
import { ScoreIcon, ScoreIconSkeleton } from "../score-icon/score-icon";
import { AssessmentResultsContainer, OverallScoreContainer, OverallScoreDescription, OverallScoreTextContainer, OverallScoreTitle, ResultsDetailsGrid, ResultsFooterContainer, ResultsHeaderContainer, TitleContainer } from "./assessment-results.styled";
import { GetSupportCard, GetSupportCardLoadingState } from "./subcomponents/get-support-card/get-support-card";
import { GetSupportDialog } from "./subcomponents/get-support-dialog/get-support-dialog";
import { SectionResultGridItem, SectionResultGridItemLoadingState } from "./subcomponents/section-result-grid-item/section-result-grid-item";

type AssessmentResultsProps = {
    assessmentResults: GetCurrentAssessmentResultsResult
    onRefreshData: () => void
}

export const AssessmentResults: FC<AssessmentResultsProps> = ({
    assessmentResults,
    onRefreshData,
}) => {

    // context
    const { self } = useContext(SelfContext)

    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // constants
    const isExternalUser = self.userType === 'external'
    const hasSubmittedSupportRequest = !!assessmentResults.supportRequests.length

    // event handlers

    const handleCalloutClicked = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    const handleGetSupportSubmitted = useCallback(() => {
        onRefreshData()
    }, [onRefreshData])

    if (assessmentResults.assessmentTypeCode === 'client') {
        return (
            <BodyContainer>
                <ResultsAreOnTheWayCard />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <BodyCard style={{ flex: 1, display: 'grid' }}>
                <AssessmentResultsContainer>
                    <TitleContainer>
                        <Title>
                            Technology Health Assessment
                        </Title>
                    </TitleContainer>
                    <ResultsHeaderContainer>
                        <OverallScoreContainer>
                            <ScoreIcon
                                score={assessmentResults.overallScore}
                                displayMode="large"
                            />
                            <OverallScoreTextContainer>
                                <OverallScoreTitle>
                                    Your Overall Score
                                </OverallScoreTitle>
                                <OverallScoreDescription>
                                    {assessmentResults.overallScore.resultDetails}
                                </OverallScoreDescription>
                            </OverallScoreTextContainer>
                        </OverallScoreContainer>
                        {isExternalUser && (
                            <GetSupportCard
                                assessmentId={assessmentResults.assessmentId}
                                supportRequests={assessmentResults.supportRequests}
                                onSubmit={handleGetSupportSubmitted}
                            />
                        )}
                    </ResultsHeaderContainer>
                    <ResultsDetailsGrid>
                        {assessmentResults.sections.map((section) => (
                            <SectionResultGridItem
                                key={section.sectionId}
                                sectionResult={section}
                            />
                        ))}
                    </ResultsDetailsGrid>
                    <ResultsFooterContainer>
                        <OverallScoreTitle>
                            Don't Stop at a Sample
                            <br />
                            Let us show you how the THA can give you Technology Peace of Mind
                        </OverallScoreTitle>
                        <p>
                            No matter your results these questions are a fraction of the THA value. Next steps are 400 questions, from over 200+ assessments, right sized to your organization’s needs that shows your overall tech health with expert recommendations on areas of improvement. In addition, we provide two executive board ready dashboards.
                            <br />
                            <br />

                            <PrimaryButton
                                disabled={hasSubmittedSupportRequest}
                                onClick={handleCalloutClicked}
                            >
                                Next Steps
                            </PrimaryButton>
                        </p>
                    </ResultsFooterContainer>
                </AssessmentResultsContainer>
            </BodyCard>
            <GetSupportDialog
                assessmentId={assessmentResults.assessmentId}
                onSubmit={handleGetSupportSubmitted}
                isOpen={dialogOpen}
                onClose={handleDialogClose}
            />
        </BodyContainer>
    )
}

export const AssessmentResultsLoadingState: FC = () => {
    return (
        <BodyContainer>
            <BodyCard style={{ flex: 1, display: 'grid' }}>
                <AssessmentResultsContainer>
                    <TitleContainer>
                        <TitleSkeleton />
                    </TitleContainer>
                    <ResultsHeaderContainer>
                        <OverallScoreContainer>
                            <ScoreIconSkeleton displayMode="large" />
                            <OverallScoreTextContainer>
                                <SpanSkeleton fontSize={28} />
                                <ParagraphSkeleton />
                            </OverallScoreTextContainer>
                        </OverallScoreContainer>
                        <GetSupportCardLoadingState />
                    </ResultsHeaderContainer>
                    <ResultsDetailsGrid>
                        <SectionResultGridItemLoadingState />
                        <SectionResultGridItemLoadingState />
                        <SectionResultGridItemLoadingState />
                        <SectionResultGridItemLoadingState />
                        <SectionResultGridItemLoadingState />
                    </ResultsDetailsGrid>
                    <ResultsFooterContainer>
                        <ButtonSkeleton />
                    </ResultsFooterContainer>
                </AssessmentResultsContainer>
            </BodyCard>
        </BodyContainer>
    )
}