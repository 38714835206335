import { FC, useCallback, useContext, useState } from "react";
import { AuthenticationFooterMessage, AuthenticationForm, AuthenticationFormFooter, AuthenticationFormContainer, AuthenticationFormSubTitle, AuthenticationFormTitle } from "../../authentication.styled";
import { useForm } from 'react-hook-form';
import { validate as validateEmail } from 'email-validator';
import { AuthenticationContext } from "../../providers/authentication-provider/authentication-provider";
import { useNavigate } from "react-router-dom";
import { SecondaryButton, PrimaryButton } from "../../../common/components/buttons/buttons";
import { FormItem } from "../../../common/components/form-item/form-item";
import { InlineLoading } from "../../../common/components/inline-loading/inline-loading";
import { Input } from "../../../common/components/input/input";
import { FormValidationMessage } from "../../../common/components/form-validation-message/form-validation-message";
import { service } from "./forgot-password.services";

export const ForgotPassword: FC = () => {

    // context
    const { refreshAuthenticationState } = useContext(AuthenticationContext)

    // router hooks
    const navigate = useNavigate()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [footerMessage, setFooterMessage] = useState<string>('')
    const [isComplete, setIsComplete] = useState<boolean>(false)

    // form state
    const { register, getValues, handleSubmit, formState } = useForm({
        defaultValues: {
            emailAddress: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        const { emailAddress } = getValues()
        const result = await service.sendForgotPasswordLink(emailAddress)

        if (result.success) {
            refreshAuthenticationState()
            setFooterMessage(`Done! Check your email for a link to reset your password.`)
            setIsComplete(true)
        } else {
            setFooterMessage(`Something went wrong. If the issue persists, please contact support.`)
        }

        setIsLoading(false)
    }, [getValues, refreshAuthenticationState])

    const handleReturnToSignInButtonClicked = useCallback(() => {
        navigate('/sign-in')
    }, [navigate])

    return (
        <AuthenticationFormContainer>
            <AuthenticationFormTitle>
                Forgot Password
            </AuthenticationFormTitle>
            <AuthenticationFormSubTitle>
                Enter your email address below and we'll send you a link to reset your password.
            </AuthenticationFormSubTitle>
            <AuthenticationForm
                data-testid="forgot-password-form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <FormItem label="Email Address">
                    <>
                        <Input
                            {...register('emailAddress', {
                                required: true,
                                validate: validateEmail,
                            })}
                            data-testid="email-address-input"
                            type="email"
                            autoComplete="username"
                            placeholder="Email Address"
                            disabled={isLoading}
                        />
                        {formState.errors.emailAddress?.type === 'required' && (
                            <FormValidationMessage data-testid="email-address-missing-warning">
                                Email address is required
                            </FormValidationMessage>
                        )}
                        {formState.errors.emailAddress?.type === 'validate' && (
                            <FormValidationMessage data-testid="email-address-invalid-warning">
                                Please enter a valid email address
                            </FormValidationMessage>
                        )}
                    </>
                </FormItem>
                <AuthenticationFormFooter>
                    {isComplete && (
                        <SecondaryButton onClick={handleReturnToSignInButtonClicked}>
                            Return to Sign In
                        </SecondaryButton>
                    )}
                    <PrimaryButton
                        data-testid="submit-button"
                        type="submit"
                        disabled={isLoading || isComplete}
                    >
                        Send Password Reset Link
                    </PrimaryButton>
                    {!!isLoading && (
                        <InlineLoading>
                            Loading...
                        </InlineLoading>
                    )}
                </AuthenticationFormFooter>
                {footerMessage && (
                    <AuthenticationFooterMessage type='info'>
                        {footerMessage}
                    </AuthenticationFooterMessage>
                )}
            </AuthenticationForm>
        </AuthenticationFormContainer>
    )
}