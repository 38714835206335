import { FC, MouseEventHandler } from "react";
import { AppColors } from "../../../../constants/colors/app-colors";
import { CloseIcon } from "../../../icons/close-icon/close-icon";
import { SnackbarCloseButtonContainer } from "./snackbar-close-button.styled";

type SnackbarCloseButtonProps = {
    onClick: MouseEventHandler<HTMLDivElement>
}

export const SnackbarCloseButton: FC<SnackbarCloseButtonProps> = ({
    onClick,
}) => {
    return (
        <SnackbarCloseButtonContainer onClick={onClick}>
            <CloseIcon
                color={AppColors.text.onDarkBackground}
                size={18}
            />
        </SnackbarCloseButtonContainer>
    )
}