import styled from "@emotion/styled";
import { keyframes } from '@emotion/react';

// animations
const rotate = keyframes({
    from: {
        transform: 'rotate(0deg)',
    },
    to: {
        transform: 'rotate(360deg)',
    }
})

const dash = keyframes({
    '0%': {
        strokeDasharray: '1, 150',
        strokeDashoffset: '0',
    },
    '50%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: '-35',
    },
    '100%': {
        strokeDasharray: '90, 150',
        strokeDashoffset: '-124',
    },
})

// components
export const LoadingSpinnerContainer = styled.div()

export const Spinner = styled.svg({
    animation: `${rotate} 2s linear infinite`,
})

export const Path = styled.circle({
    strokeLinecap: 'round',
    animation: `${dash} 1.5s ease-in-out infinite`,
})