import styled from "@emotion/styled";
import { Gap, Padding } from "../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT } from "../../../common/constants/breakpoints/breakpoints";

export const AuthenticationShellContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: Gap.desktop.large,

    // padding
    padding: Padding.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gridTemplateColumns: '1fr',
        gridAutoFlow: 'row',

        // padding
        padding: Padding.mobile.large,
    },
})

export const ContentContainer = styled.div()