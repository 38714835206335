import { FC, useMemo } from "react";
import { GetAdminUsersRequest } from "tech-health-assessment-sdk/dist";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { usePagination } from "../../../../common/hooks/use-pagination/use-pagination";
import { AdminUsersGridCardLoadingState, AdminUsersGridCard } from "../../grid-cards/admin-users-grid-card/admin-users-grid-card";
import { useAdminUsers } from "./admin-users.hooks";

export const AdminUsers: FC = () => {

    // state
    const [pagination, setPagination] = usePagination()

    // constants
    const request: GetAdminUsersRequest = useMemo(() => ({
        ...pagination
    }), [pagination])

    // data hooks
    const { data: usersResult, isLoading } = useAdminUsers(request)

    // data abstraction
    const users = usersResult?.users;

    if (isLoading || !users) {
        return (
            <BodyContainer>
                <AdminUsersGridCardLoadingState />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <AdminUsersGridCard
                users={users}
                accountId={null}
                pagination={pagination}
                onPaginationChange={setPagination}
                totalCount={usersResult.totalCount}
            />
        </BodyContainer>
    )
}