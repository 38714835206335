import { FC } from "react";
import { Column, useTable } from "react-table";
import { PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { useIsMobile } from "../constants/breakpoints/breakpoints";
import { GridColumnDefinition } from "./grid.types";
import { DesktopGrid, DesktopGridLoadingState } from "./subcomponents/desktop-grid/desktop-grid";
import { MobileGrid, MobileGridLoadingState } from "./subcomponents/mobile-grid/mobile-grid";

export type GridProps<T extends object> = {
    columns: GridColumnDefinition<T>[]
    rows: T[]
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
    totalCount: number
}

export const Grid = <T extends object>({
    rows,
    columns,
    pagination,
    onPaginationChange,
    totalCount,
}: GridProps<T>) => {

    // responsive hooks
    const isMobile = useIsMobile()

    // table hooks
    const tableInstance = useTable({
        data: rows,
        columns: columns as Column<T>[],
    })

    if (isMobile) return (
        <MobileGrid
            tableInstance={tableInstance}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        />
    )

    return (
        <DesktopGrid
            tableInstance={tableInstance}
            pagination={pagination}
            onPaginationChange={onPaginationChange}
            totalCount={totalCount}
        />
    )
}

export const GridLoadingState: FC = () => {
    // responsive hooks
    const isMobile = useIsMobile()

    if (isMobile) {
        return (
            <MobileGridLoadingState />
        )
    }

    return (
        <DesktopGridLoadingState />
    )
}