import styled from "@emotion/styled";
import { Gap, Padding } from "../../../../../components/sizes/sizes";

export const PaginationFooterContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '100px 1fr 100px',
    alignItems: 'center',

    // padding
    paddingLeft: Padding.desktop.large,
    paddingRight: Padding.desktop.large,
    paddingTop: Padding.desktop.normal,
})

export const PreviousButtonContainer = styled.div({
    justifySelf: 'start',
})

export const NextButtonContainer = styled.div({
    justifySelf: 'end',
})

export const ControlsContainer = styled.div({
    display: 'flex',
    flexDirection: 'row',
    gap: Gap.desktop.normal,
    alignItems: 'center',
    justifySelf: 'center',
})