import { FC } from "react";
import { NavLink } from "react-router-dom";
import { THAP_ENVIRONMENT } from "../../../../../../common/environment/environment-constants";
import { NavLinkStyles } from "../header.styled";
import { DemoDashboardNavLink } from "./subcomponents/demo-dashboard-nav-link";

export const ExternalUserNavItems: FC = () => {
    return (
        <>
            <NavLink to="/" style={NavLinkStyles}>
                Home
            </NavLink>
            {THAP_ENVIRONMENT === 'demo' && (
                <DemoDashboardNavLink />
            )}
        </>
    )
}