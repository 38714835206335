import { IsBoolean, IsEmail, IsNotEmpty, IsObject, IsPhoneNumber, IsString, ValidateIf, ValidateNested } from 'class-validator';
import { ErrorTypeCode } from '../../../../common/codes.types';

export class SignUpResult {
  @IsBoolean()
  success: boolean

  @IsString()
  errorCode: ErrorTypeCode | null

  @IsObject()
  @ValidateNested()
  @ValidateIf((result) => !!result.success)
  tokens: SignUpResult_Tokens;
}

export class SignUpResult_Tokens {
  @IsString()
  @IsNotEmpty()
  accessToken: string;

  @IsString()
  @IsNotEmpty()
  refreshToken: string;
}