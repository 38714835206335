export const Gap = {
    mobile: {
        smaller: 4,
        small: 4,
        normal: 8,
        large: 12,
        larger: 24,
    },
    tablet: {
        smaller: 4,
        small: 6,
        normal: 12,
        large: 24,
        larger: 32,
    },
    desktop: {
        smaller: 4,
        small: 6,
        normal: 12,
        large: 24,
        larger: 32,
    }
}

export const Padding = {
    mobile: {
        smaller: 4,
        small: 8,
        normal: 12,
        large: 20,
        larger: 30,
    },
    tablet: {
        smaller: 10,
        small: 12,
        normal: 20,
        large: 40,
        larger: 60,
    },
    desktop: {
        smallest: 6,
        smaller: 10,
        small: 14,
        normal: 20,
        large: 40,
        larger: 60,
    },
}

export const BorderRadius = {
    small: 6,
    normal: 8,
    large: 12,
    larger: 24,
}