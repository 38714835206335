import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";

export const Subtitle = styled.h2({
    // color
    color: AppColors.text.gray,

    // font
    fontSize: 24,

    // margin
    margin: 0,
})