import styled from "@emotion/styled";
import { Padding } from "../../../sizes/sizes";

export const DialogCloseButtonContainer = styled.div({
    // position
    position: 'absolute',
    right: Padding.desktop.normal,
    top: Padding.desktop.normal,

    // cursor
    cursor: 'pointer'
})