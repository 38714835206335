import { FC } from "react";
import { useParams } from "react-router";
import { AdminEmailTemplate } from "../../../../../modules/admin/components/screens/admin-email-template/admin-email-template";

export const AdminEmailTemplateRoute: FC = () => {

    // router hooks
    const { email_template_id } = useParams() as { email_template_id: string }

    return (
        <AdminEmailTemplate
            emailTemplateId={email_template_id}
        />
    )
}