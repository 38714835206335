import { FC, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { UpdatePasswordResult } from "tech-health-assessment-sdk/dist";
import { PrimaryButton } from "../../../common/components/buttons/buttons";
import { FormItem } from "../../../common/components/form-item/form-item";
import { FormValidationMessage } from "../../../common/components/form-validation-message/form-validation-message";
import { InlineLoading } from "../../../common/components/inline-loading/inline-loading";
import { Input } from "../../../common/components/input/input";
import { SnackbarContext } from "../../../common/components/snackbar-provider/snackbar-provider";
import { AuthenticationFooterMessage, AuthenticationForm, AuthenticationFormContainer, AuthenticationFormFooter, AuthenticationFormSubTitle, AuthenticationFormTitle } from "../../authentication.styled";
import { service } from "./update-password.service";

type UpdatePasswordProps = {
    securityToken: string
}

export const UpdatePassword: FC<UpdatePasswordProps> = ({
    securityToken,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // router hooks
    const navigate = useNavigate()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')

    // form state
    const { register, handleSubmit, formState, getValues } = useForm({
        defaultValues: {
            newPassword: '',
            newPasswordConfirm: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)
        setErrorMessage('')

        const formValues = getValues()

        let result: UpdatePasswordResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.updatePassword({
                securityToken,
                newPassword: formValues.newPassword,
            })
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            setErrorMessage(`Something went wrong. If the issue persists, please contact support.`)
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully updated password',
                message: 'You can now sign in using your new password',
            })
            navigate('/sign-in')
        }

        setIsLoading(false)
    }, [getValues, navigate, securityToken, showSnackbar])

    return (
        <AuthenticationFormContainer>
            <AuthenticationFormTitle>
                Update Your Password
            </AuthenticationFormTitle>
            <AuthenticationFormSubTitle>
                Enter your new password below to finish resetting your password.
            </AuthenticationFormSubTitle>
            <AuthenticationForm
                data-testid="forgot-password-form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <FormItem label="New Password">
                    <>
                        <Input
                            {...register('newPassword', { required: true })}
                            data-testid="password-input"
                            type="password"
                            autoComplete="new-password"
                            placeholder="Password"
                            disabled={isLoading}
                        />
                        {formState.errors.newPassword?.type === 'required' && (
                            <FormValidationMessage data-testid="password-missing-warning">
                                New Password is required
                            </FormValidationMessage>
                        )}
                    </>
                </FormItem>
                <FormItem label="Re-enter Your Password">
                    <>
                        <Input
                            {...register('newPasswordConfirm', {
                                required: true,
                                validate: (verifyPassword) => getValues().newPassword === verifyPassword,
                            })}
                            data-testid="verify-password-input"
                            type="password"
                            autoComplete="new-password"
                            placeholder="Re-enter Your Password"
                            disabled={isLoading}
                        />
                        {formState.errors.newPasswordConfirm?.type === 'required' && (
                            <FormValidationMessage data-testid="verify-password-missing-warning">
                                New Password verification is required
                            </FormValidationMessage>
                        )}
                        {formState.errors.newPasswordConfirm?.type === 'validate' && (
                            <FormValidationMessage data-testid="verify-password-validation-warning">
                                Passwords must match
                            </FormValidationMessage>
                        )}
                    </>
                </FormItem>
                <AuthenticationFormFooter>
                    <PrimaryButton
                        data-testid="submit-button"
                        type="submit"
                        disabled={isLoading || !formState.isValid}
                    >
                        Reset Password
                    </PrimaryButton>
                    {!!isLoading && (
                        <InlineLoading>
                            Loading...
                        </InlineLoading>
                    )}
                </AuthenticationFormFooter>
                {errorMessage && (
                    <AuthenticationFooterMessage type='error'>
                        {errorMessage}
                    </AuthenticationFooterMessage>
                )}
            </AuthenticationForm>
        </AuthenticationFormContainer>
    )
}