import { FC, useCallback, useState } from "react";
import { PrimaryButton } from "../../../../../../common/components/buttons/buttons";
import { CreateUserDialog } from "../create-user-dialog/create-user-dialog";

type CreateUserButtonProps = {
    accountId: string | null
}

export const CreateUserButton: FC<CreateUserButtonProps> = ({
    accountId,
}) => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <>
            <PrimaryButton onClick={handleButtonClick}>
                Create User
            </PrimaryButton>
            <CreateUserDialog
                accountId={accountId}
                isOpen={dialogOpen}
                onClose={handleDialogClose}
            />
        </>
    )
}