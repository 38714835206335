import { FC, useCallback, useState } from "react";
import Measure, { ContentRect } from "react-measure";
import { SnackbarItem } from "../../snackbar-provider.types";
import { SnackbarCloseButton } from "../snackbar-close-button/snackbar-close-button";
import { Hidden, SnackbarContainer, SnackbarMessage, SnackbarOuterContainer, SnackbarTextContainer, SnackbarTitle } from "./snackbar.styled";

type SnackbarProps = {
    id: string
    snackbarItem: Required<SnackbarItem>
    show: boolean
    onDismiss: (snackbarId: string) => void
}

export const Snackbar: FC<SnackbarProps> = ({
    id,
    snackbarItem,
    show,
    onDismiss,
}) => {

    // state
    const [snackbarHeight, setSnackbarHeight] = useState<number>()

    // refs
    // const durationIndicatorRef = useRef<HTMLDivElement>(null)

    // constants
    const color =
        snackbarItem.type === 'info'
            ? 'blue'
            : snackbarItem.type === 'error'
                ? 'red'
                : snackbarItem.type === 'success'
                    ? 'green'
                    : 'orange'

    // const showDurationIndicator = snackbarItem.duration > -1

    // event handlers
    const handleCloseButtonClick = useCallback(() => {
        onDismiss(id)
    }, [id, onDismiss])

    const handleResize = useCallback((contentRect: ContentRect) => {
        setSnackbarHeight(contentRect.bounds?.height)
    }, [])

    if (!snackbarHeight) return (
        <Hidden>
            <Measure bounds onResize={handleResize}>
                {({ measureRef }) => (
                    <SnackbarContainer
                        color={color}
                        show={show}
                        ref={measureRef}
                    >
                        <SnackbarTextContainer>
                            <SnackbarTitle>
                                {snackbarItem.title}
                            </SnackbarTitle>
                            {!!snackbarItem.message && (
                                <SnackbarMessage>
                                    {snackbarItem.message}
                                </SnackbarMessage>
                            )}
                        </SnackbarTextContainer>
                        <SnackbarCloseButton
                            onClick={handleCloseButtonClick}
                        />
                        {/* {showDurationIndicator && (
                            <SnackbarDurationIndicator
                                ref={durationIndicatorRef}
                                duration={snackbarItem.duration}
                            />
                        )} */}
                    </SnackbarContainer>
                )}
            </Measure>
        </Hidden>
    );

    return (
        <SnackbarOuterContainer
            show={show}
            snackbarHeight={snackbarHeight}
        >
            <SnackbarContainer
                color={color}
                show={show}
            >
                <SnackbarTextContainer>
                    <SnackbarTitle>
                        {snackbarItem.title}
                    </SnackbarTitle>
                    {!!snackbarItem.message && (
                        <SnackbarMessage>
                            {snackbarItem.message}
                        </SnackbarMessage>
                    )}
                </SnackbarTextContainer>
                <SnackbarCloseButton
                    onClick={handleCloseButtonClick}
                />
{/*                 {showDurationIndicator && (
                    <SnackbarDurationIndicator
                        ref={durationIndicatorRef}
                        duration={snackbarItem.duration}
                    />
                )} */}
            </SnackbarContainer>
        </SnackbarOuterContainer>
    )
}