import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";

export const ProgressBarContainer = styled.div({
    // position
    position: 'relative',

    // border
    borderRadius: 8,

    // colors
    backgroundColor: AppColors.background.gray.primary,
    
    // size
    height: 15,

    // overflow
    overflow: 'hidden',
})

export const ProgressBarCoverage = styled.div<{
    progress: number
}>(({ progress }) => ({
    // position
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,

    // size
    width: `${progress * 100}%`,

    // colors
    backgroundColor: AppColors.background.gray.dark,

    // transition
    transition: `all 500ms ease`,
}))