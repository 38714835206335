import styled from "@emotion/styled";
import { Padding } from "../sizes/sizes";

export const SnackbarsContainer = styled.div({
    // position
    position: 'fixed',
    top: Padding.desktop.normal,
    left: '50vw',

    // transform
    transform: 'translateX(-50%)',

    // size
    width: 650,
    maxWidth: `calc(100vw - ${Padding.desktop.large * 2}px)`,

    // layout
    display: 'grid',
    gridAutoFlow: 'row',
})