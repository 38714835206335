import styled from "@emotion/styled";
import { TABLET_BREAKPOINT } from "../../../../../../../common/constants/breakpoints/breakpoints";

export const HeaderText = styled.span({
    // text
    fontWeight: 700,
    fontSize: 18,
    maxWidth: 155,

    // colors
    color: '#253047',

    // breakpoints
    [TABLET_BREAKPOINT]: {
        maxWidth: 'unset',
    },
})