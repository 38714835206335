import { FC, useCallback, useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { SelfContext } from "../../../../../app/providers/self-provider/self-provider";
import { FormItem } from "../../../../../common/components/form-item/form-item";
import { FormValidationMessage } from "../../../../../common/components/form-validation-message/form-validation-message";
import { Input } from "../../../../../common/components/input/input";
import { FormContainer, GetSupportForm } from "./get-support-dialog.styled";
import { validate as validateEmail } from 'email-validator';
import { FormItemColumns } from "../../../../../common/components/form-item-columns/form-item-columns";
import { phone } from 'phone'
import { PrimaryButton, SecondaryButton } from "../../../../../common/components/buttons/buttons";
import { service } from "./get-support-dialog.service";
import { InlineLoading } from "../../../../../common/components/inline-loading/inline-loading";
import { SnackbarContext } from "../../../../../common/components/snackbar-provider/snackbar-provider";
import { DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../../../common/components/dialog/dialog.styled";
import { Dialog, DialogProps } from "../../../../../common/components/dialog/dialog";

type GetSupportDialogProps = {
    assessmentId: string
    onSubmit: () => void
} & Omit<DialogProps, 'children'>

export const GetSupportDialog: FC<GetSupportDialogProps> = ({
    assessmentId,
    onSubmit,
    isOpen,
    onClose,
}) => {

    // context
    const { self } = useContext(SelfContext)
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { register, getValues, handleSubmit, formState } = useForm({
        defaultValues: {
            firstName: self.firstName,
            lastName: self.lastName,
            jobTitle: self.jobTitle,
            companyName: self.accountName,
            emailAddress: self.emailAddress,
            phoneNumber: self.phoneNumber,
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)
        const formValues = getValues()
        await service.requestSupport(assessmentId, {
            ...formValues,
            phoneNumber: phone(formValues.phoneNumber).phoneNumber!,
        })
        setIsLoading(false)
        onClose()
        onSubmit()
        showSnackbar({
            type: 'success',
            title: 'Help is on the way!',
            message: 'A member of our team will be in touch shortly.'
        })
    }, [assessmentId, getValues, onClose, onSubmit, showSnackbar])

    const handleCancelButtonClick = useCallback(() => {
        onClose()
    }, [onClose])

    const handleSubmitButtonClick = useCallback(() => {
        handleSubmit(handleFormSubmit)
    }, [handleFormSubmit, handleSubmit])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <GetSupportForm
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <DialogHeader>
                    <DialogTitle>
                        Next Steps
                    </DialogTitle>
                    <DialogDescription>
                        Our team is here to help. Fill out the form below to have a member of our team get in touch to assist.
                    </DialogDescription>
                </DialogHeader>
                <FormContainer>
                    <FormItemColumns>
                        <FormItem label="Company Name">
                            <>
                                <Input
                                    {...register('companyName', {
                                        required: true,
                                    })}
                                    data-testid="company-name-input"
                                    type="text"
                                    autoComplete="organization"
                                    placeholder="Company Name"
                                    disabled={isLoading}
                                />
                                {formState.errors.companyName?.type === 'required' && (
                                    <FormValidationMessage data-testid="company-name-missing-warning">
                                        Title is required
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                        <FormItem label="Job Title">
                            <>
                                <Input
                                    {...register('jobTitle', {
                                        required: true,
                                    })}
                                    data-testid="job-title-input"
                                    type="text"
                                    autoComplete="organization-title"
                                    placeholder="Job Title"
                                    disabled={isLoading}
                                />
                                {formState.errors.jobTitle?.type === 'required' && (
                                    <FormValidationMessage data-testid="job-title-missing-warning">
                                        Job Title is required
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                    </FormItemColumns>
                    <FormItemColumns>
                        <FormItem label="First Name">
                            <>
                                <Input
                                    {...register('firstName', { required: true })}
                                    data-testid="first-name-input"
                                    type="text"
                                    autoComplete="fname"
                                    placeholder="First Name"
                                    disabled={isLoading}
                                />
                                {formState.errors.firstName?.type === 'required' && (
                                    <FormValidationMessage data-testid="first-name-missing-warning">
                                        First name is required
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                        <FormItem label="Last Name">
                            <>
                                <Input
                                    {...register('lastName', { required: true })}
                                    data-testid="last-name-input"
                                    type="text"
                                    autoComplete="lname"
                                    placeholder="Last Name"
                                    disabled={isLoading}
                                />
                                {formState.errors.lastName?.type === 'required' && (
                                    <FormValidationMessage data-testid="last-name-missing-warning">
                                        Last name is required
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                    </FormItemColumns>
                    <FormItemColumns>
                        <FormItem label="Email Address">
                            <>
                                <Input
                                    {...register('emailAddress', {
                                        required: true,
                                        validate: validateEmail,
                                    })}
                                    data-testid="email-address-input"
                                    type="email"
                                    autoComplete="username"
                                    placeholder="Email Address"
                                    disabled={isLoading}
                                />
                                {formState.errors.emailAddress?.type === 'required' && (
                                    <FormValidationMessage data-testid="email-address-missing-warning">
                                        Email address is required
                                    </FormValidationMessage>
                                )}
                                {formState.errors.emailAddress?.type === 'validate' && (
                                    <FormValidationMessage data-testid="email-address-invalid-warning">
                                        Please enter a valid email address
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                        <FormItem label="Phone Number">
                            <>
                                <Input
                                    {...register('phoneNumber', {
                                        required: true,
                                        validate: (value) => phone(value).isValid,
                                    })}
                                    data-testid="phone-number-input"
                                    type="phone"
                                    autoComplete="tel"
                                    placeholder="Phone Number"
                                    disabled={isLoading}
                                />
                                {formState.errors.phoneNumber?.type === 'required' && (
                                    <FormValidationMessage data-testid="phone-number-missing-warning">
                                        Phone Number is required
                                    </FormValidationMessage>
                                )}
                                {formState.errors.phoneNumber?.type === 'validate' && (
                                    <FormValidationMessage data-testid="phone-number-invalid-warning">
                                        Please enter a valid phone number
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                    </FormItemColumns>
                </FormContainer>
                <DialogFooter>
                    {isLoading && (
                        <InlineLoading>
                            Submitting Request...
                        </InlineLoading>
                    )}
                    <SecondaryButton
                        disabled={isLoading}
                        type="button"
                        onClick={handleCancelButtonClick}
                    >
                        Cancel
                    </SecondaryButton>
                    <PrimaryButton
                        disabled={!formState.isValid || isLoading}
                        onClick={handleSubmitButtonClick}
                        type="submit"
                    >
                        Submit
                    </PrimaryButton>
                </DialogFooter>
            </GetSupportForm>
        </Dialog>
    )
}