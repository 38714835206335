import styled from "@emotion/styled";
import { Gap, Padding } from "../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT } from "../../../common/constants/breakpoints/breakpoints";

export const AssessmentOverviewContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.large,
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gap: Gap.mobile.larger,
    },
})

export const SectionsGrid = styled.div<{
    displayMode: 'normal' | 'compact'
}>(({ displayMode }) => ({
    // layout
    display: 'grid',
    gap: Gap.desktop.normal,

    // position
    position: 'relative',

    // padding
    paddingLeft: Padding.desktop.large,
    paddingBottom: Padding.desktop.normal,
    paddingRight: Padding.desktop.large,

    // conditional: display mode
    ...(displayMode === 'normal' ? {
        gridAutoFlow: 'row',
        gridTemplateColumns: '1fr 1fr',
        gridTemplateRows: 'max-content',
        gridAutoRows: 'max-content',
    } : {
        // layout
        gridAutoFlow: 'column',
        gridTemplateColumns: 200,
        gridAutoColumns: 200,
        gridTemplateRows: '1fr',

        // size
        maxWidth: '100%',

        // overflow
        overflowX: 'auto',
        overflowY: 'hidden',
    }),

    // breakpoints
    [MOBILE_BREAKPOINT]: displayMode === 'normal' ? {
        // layout
        gridTemplateColumns: '1fr',
        gap: Gap.mobile.normal,
    } : {},
}))


export const TitleContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.normal,
})

export const HeaderContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.normal,
    gridTemplateColumns: '1fr',
    gridAutoFlow: 'column',
    gridAutoColumns: '1fr',
    alignItems: 'center',

    // padding
    paddingLeft: Padding.desktop.large,
    paddingTop: Padding.desktop.large,
    paddingRight: Padding.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        paddingLeft: Padding.mobile.larger,
        paddingTop: Padding.mobile.larger,
        paddingRight: Padding.mobile.larger,
    }
})