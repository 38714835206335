import { IsDate, IsObject, IsString, IsUUID, ValidateNested } from "class-validator";
import { AccountTypeCode } from "../../../../common/codes.types";

export class GetAdminAccountResult {
    @IsUUID()
    accountId: string;

    @IsString()
    companyName: string;

    @IsObject()
    @ValidateNested()
    accountType: GetAdminAccountResult_AccountType;

    @IsDate()
    createdAt: Date;
}

export class GetAdminAccountResult_AccountType {
    @IsUUID()
    accountTypeId: string;

    @IsString()
    code: AccountTypeCode;

    @IsString()
    label: string;
}