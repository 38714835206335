import { createClient } from 'tech-health-assessment-sdk/dist';

const apiUrl = process.env.REACT_APP_API_URL as string;

// TODO: get rid of this magic string
const accessToken = localStorage.getItem('thap_access_token');
const refreshToken = localStorage.getItem('thap_refresh_token');

export const api = createClient({
  apiUrl,
  accessToken: accessToken ?? null,
  refreshToken: refreshToken ?? null,
});

api.auth.subscribeToAccessTokenChanges((newAccessToken) => {
  localStorage.setItem('thap_access_token', newAccessToken ?? '');
});

api.auth.subscribeToRefreshTokenChanges((newRefreshToken) => {
  localStorage.setItem('thap_refresh_token', newRefreshToken ?? '');
})