import styled from "@emotion/styled";
import { Gap } from "../../../common/components/sizes/sizes";
import { SkeletonStyles } from "../../../common/constants/animations/animations";
import { AppColors } from "../../../common/constants/colors/app-colors";

export const ScoreIconContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',
    gap: Gap.desktop.normal,

    // alignment
    justifyItems: 'center',
})

export const ScoreIconCircle = styled.div<{
    displayMode: 'large' | 'normal',
    color: string,
}>(({ displayMode, color }) => ({
    // layout
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',

    // alignment
    justifyItems: 'center',
    alignContent: 'center',

    // size
    width: displayMode === 'normal' ? 80 : 160,
    height: displayMode === 'normal' ? 80 : 160,

    // border
    borderRadius: '50%',

    // color
    backgroundColor: color,
    color: AppColors.text.onDarkBackground,
}))

export const ScoreNumber = styled.span<{
    displayMode: 'large' | 'normal',
}>(({ displayMode }) => ({
    fontSize: displayMode === 'normal' ? 28 : 44,
    fontWeight: 700,
    lineHeight: '3rem',
}))

export const ScoreTextInside = styled.span()

export const ScoreTextOutside = styled.span<{
    color: string,
}>(({ color }) => ({
    color,
    fontWeight: 600,
}))

export const ScoreIconCircleSkeleton = styled.div<{
    displayMode: 'large' | 'normal',
}>(({ displayMode }) => ({
    // size
    width: displayMode === 'normal' ? 80 : 160,
    height: displayMode === 'normal' ? 80 : 160,

    // border
    borderRadius: '50%',

    ...SkeletonStyles(),
}))