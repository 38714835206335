import { createContext, FC, useState, useEffect, useCallback } from "react";
import { AuthenticationState } from "../../authentication.types";
import { checkIfAuthenticated } from "./authentication-provider.services";

type AuthenticationProviderProps = {
    children: JSX.Element
}

export const AuthenticationProvider: FC<AuthenticationProviderProps> = ({
    children,
}) => {

    // state
    const [authenticationState, setAuthenticationState] = useState<AuthenticationState>('loading')

    // event handlers
    const handleRefreshAuthenticationState = useCallback(async () => {
        const isAuthenticated = await checkIfAuthenticated()
        setAuthenticationState(
            isAuthenticated
                ? 'authenticated'
                : 'not-authenticated'
        )
    }, [])

    // side effects
    useEffect(() => {
        handleRefreshAuthenticationState()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <AuthenticationContext.Provider value={{
            authenticationState,
            refreshAuthenticationState: handleRefreshAuthenticationState
        }}>
            {children}
        </AuthenticationContext.Provider>
    )
}

type AuthenticationContextType = {
    authenticationState: AuthenticationState;
    refreshAuthenticationState: () => void;
}

export const AuthenticationContext = createContext<AuthenticationContextType>({
    authenticationState: 'loading',
    refreshAuthenticationState: () => { },
})