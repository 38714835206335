import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "../../../../../../common/components/buttons/buttons";
import { Dialog, DialogProps } from "../../../../../../common/components/dialog/dialog";
import { DialogCard, DialogFooter, DialogHeader, DialogTitle } from "../../../../../../common/components/dialog/dialog.styled";
import { FormItemColumns } from "../../../../../../common/components/form-item-columns/form-item-columns";
import { FormItem } from "../../../../../../common/components/form-item/form-item";
import { FormValidationMessage } from "../../../../../../common/components/form-validation-message/form-validation-message";
import { Input } from "../../../../../../common/components/input/input";
import { StandardDialogInnerContainer } from "../../../../../../common/components/standard-dialog-inner-container/standard-dialog-inner-container";
import { validate as validateEmail } from 'email-validator'
import { phone } from 'phone'
import { LoadingSpinner } from "../../../../../../common/components/loading-spinner/loading-spinner";
import { AppColors } from "../../../../../../common/constants/colors/app-colors";
import ReactSelect from "react-select";
import ReactSelectAsync from "react-select/async";
import { InlineLoading } from "../../../../../../common/components/inline-loading/inline-loading";
import { CalloutCard } from "../../../../../../common/components/callout-card/callout-card";
import { AdminCreateUserPayload, AdminCreateUserResult, UserTypeCode } from "tech-health-assessment-sdk/dist";
import { SnackbarContext } from "../../../../../../common/components/snackbar-provider/snackbar-provider";
import { useNavigate } from "react-router";
import { useUserTypes } from "./create-user-dialog.hooks";
import { service } from "./create-user-dialog.service";
import { CreateUserForm } from "./create-user-dialog.styled";

type CreateUserDialogProps = {
    accountId: string | null
} & Omit<DialogProps, 'children'>

export const CreateUserDialog: FC<CreateUserDialogProps> = ({
    accountId,
    isOpen,
    onClose,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // router hooks
    const navigate = useNavigate()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { register, control, getValues, handleSubmit, reset, resetField, formState, watch } = useForm({
        defaultValues: {
            userType: null as { label: string, value: string } | null,
            account:  accountId ? { value: accountId, label: '' } : null as { label: string, value: string } | null,
            firstName: '',
            lastName: '',
            jobTitle: '',
            emailAddress: '',
            phoneNumber: '',
        }
    })

    // data hooks
    const { data: userTypesResult, isLoading: accountTypesLoading } = useUserTypes()

    // constants
    const userTypeOptions = useMemo(() => (
        userTypesResult?.userTypes.map((userType) => ({
            value: userType.code,
            label: userType.label,
        }))
    ), [userTypesResult?.userTypes])

    const userType = watch('userType');
    const userTypeCode = userType?.value ?? null as UserTypeCode | null;

    // side effects
    useEffect(() => {
        reset()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    useEffect(() => {
        let userTypeOption: { value: string, label: string } | null = null;
        if (accountId) {
            userTypeOption = userTypeOptions?.find((userTypeOption) => (
                userTypeOption.value === 'external'
            )) ?? null
        }
        resetField('userType', {
            defaultValue: userTypeOption,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, accountId, userTypeOptions])

    useEffect(() => {
        if (!!accountId) return;
        let accountOption = null as { value: string, label: string } | null;
        if (accountId) {
            accountOption = { value: accountId, label: '' }
        }
        resetField('account', {
            defaultValue: accountOption,
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, userTypeCode, accountId])

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        const values = getValues()

        let result: AdminCreateUserResult | undefined = undefined;
        let error: unknown;
        try {
            if (!values.userType && !accountId) throw new Error('userType is required');
            const userTypeCode = (values.userType?.value ?? 'external') as UserTypeCode;

            const createUserPayload = new AdminCreateUserPayload();
            createUserPayload.userTypeCode = userTypeCode;
            createUserPayload.firstName = values.firstName;
            createUserPayload.lastName = values.lastName;
            createUserPayload.jobTitle = values.jobTitle;
            createUserPayload.emailAddress = values.emailAddress;
            createUserPayload.phoneNumber = phone(values.phoneNumber).phoneNumber!;

            if (userTypeCode === 'external') {
                if (!values.account && !accountId) throw new Error('account is required');
                createUserPayload.accountId = (values.account?.value ?? accountId) as string;
            }

            result = await service.createUser(createUserPayload)
        } catch (err) {
            console.log(err)
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })

            setIsLoading(false)
            onClose()
            return;
        }

        showSnackbar({
            type: 'success',
            title: 'Successfully created user',
            message: `${values.firstName} ${values.lastName}`,
        })

        setIsLoading(false)
        onClose()

        navigate(`/admin/users/${result.user?.userId}`)
    }, [accountId, getValues, navigate, onClose, showSnackbar])

    const handleCancelButtonClicked = useCallback(() => {
        onClose()
    }, [onClose])

    const handleAccountsLoadOptions = useCallback(async (query: string): Promise<{ label: string, value: string }[]> => {
        const results = await service.searchAccounts({
            accountName: query,
            limit: 10,
        })

        return results.accounts.map(account => ({
            label: account.companyName,
            value: account.accountId,
        }))
    }, [])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <DialogCard isOpen={isOpen}>
                <CreateUserForm onSubmit={handleSubmit(handleFormSubmit)}>
                    <StandardDialogInnerContainer>
                        <DialogHeader>
                            <DialogTitle>
                                Create a New User
                            </DialogTitle>
                        </DialogHeader>
                        {!accountId && (
                            <FormItemColumns>
                                <FormItem label="User Type">
                                    <>
                                        <Controller
                                            control={control}
                                            name="userType"
                                            rules={{ required: true }}
                                            render={({ field }) => (
                                                <ReactSelect
                                                    {...field}
                                                    options={userTypeOptions}
                                                    isDisabled={!userTypesResult || accountTypesLoading || isLoading}
                                                    isLoading={!userTypesResult || accountTypesLoading}
                                                />
                                            )}
                                        />
                                        {(!userTypeOptions || accountTypesLoading) && (
                                            <InlineLoading>
                                                Loading Account Types...
                                            </InlineLoading>
                                        )}
                                        {formState.errors.userType?.type === 'required' && (
                                            <FormValidationMessage data-testid="user-type-missing-warning">
                                                User Type is required
                                            </FormValidationMessage>
                                        )}
                                    </>
                                </FormItem>
                                <FormItem label="Account">
                                    <>
                                        <Controller
                                            control={control}
                                            name="account"
                                            rules={{ required: userTypeCode === 'external' && !accountId }}
                                            render={({ field }) => (
                                                <ReactSelectAsync
                                                    {...field}
                                                    isDisabled={userTypeCode !== 'external'}
                                                    loadOptions={handleAccountsLoadOptions}
                                                    defaultOptions={true}
                                                    cacheOptions={true}
                                                />
                                            )}
                                        />
                                        {formState.errors.userType?.type === 'required' && (
                                            <FormValidationMessage data-testid="account-missing-warning">
                                                Account is required
                                            </FormValidationMessage>
                                        )}
                                    </>
                                </FormItem>
                            </FormItemColumns>
                        )}
                        <FormItemColumns>
                            <FormItem label="First Name">
                                <>
                                    <Input
                                        {...register('firstName', { required: true })}
                                        data-testid="first-name-input"
                                        type="text"
                                        autoComplete="fname"
                                        placeholder="First Name"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.firstName?.type === 'required' && (
                                        <FormValidationMessage data-testid="first-name-missing-warning">
                                            First name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Last Name">
                                <>
                                    <Input
                                        {...register('lastName', { required: true })}
                                        data-testid="last-name-input"
                                        type="text"
                                        autoComplete="lname"
                                        placeholder="Last Name"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.lastName?.type === 'required' && (
                                        <FormValidationMessage data-testid="last-name-missing-warning">
                                            Last name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItemColumns>
                        <FormItemColumns>
                            <FormItem label="Email Address">
                                <>
                                    <Input
                                        {...register('emailAddress', {
                                            required: true,
                                            validate: validateEmail,
                                        })}
                                        data-testid="email-address-input"
                                        type="email"
                                        autoComplete="username"
                                        placeholder="Email Address"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.emailAddress?.type === 'required' && (
                                        <FormValidationMessage data-testid="email-address-missing-warning">
                                            Email address is required
                                        </FormValidationMessage>
                                    )}
                                    {formState.errors.emailAddress?.type === 'validate' && (
                                        <FormValidationMessage data-testid="email-address-invalid-warning">
                                            Please enter a valid email address
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Phone Number">
                                <>
                                    <Input
                                        {...register('phoneNumber', {
                                            required: true,
                                            validate: (value) => phone(value).isValid,
                                        })}
                                        data-testid="phone-number-input"
                                        type="text"
                                        autoComplete="tel"
                                        placeholder="Phone Number"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.phoneNumber?.type === 'required' && (
                                        <FormValidationMessage data-testid="phone-number-missing-warning">
                                            Phone Number is required
                                        </FormValidationMessage>
                                    )}
                                    {formState.errors.phoneNumber?.type === 'validate' && (
                                        <FormValidationMessage data-testid="phone-number-invalid-warning">
                                            Please enter a valid phone number
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItemColumns>
                        <FormItemColumns>
                            <FormItem label="Job Title">
                                <>
                                    <Input
                                        {...register('jobTitle', {
                                            required: true,
                                        })}
                                        data-testid="job-title-input"
                                        type="text"
                                        autoComplete="organization-title"
                                        placeholder="Job Title"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.jobTitle?.type === 'required' && (
                                        <FormValidationMessage data-testid="job-title-missing-warning">
                                            Job Title is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItemColumns>
                        <CalloutCard type="info">
                            <span>
                                We'll send the user a link that they can use to setup their account.
                            </span>
                        </CalloutCard>
                        <DialogFooter>
                            <SecondaryButton
                                disabled={isLoading}
                                type="button"
                                onClick={handleCancelButtonClicked}
                            >
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                disabled={isLoading || !formState.isValid}
                                type="submit"
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Create User
                            </PrimaryButton>
                        </DialogFooter>
                    </StandardDialogInnerContainer>
                </CreateUserForm>
            </DialogCard>
        </Dialog>
    )
}