import { GetAdminEmailTemplatesResult_EmailTemplate } from "tech-health-assessment-sdk/dist";
import { LinkCell } from "../../../../common/grid/cell-renderers/link-cell";
import { GridColumnDefinition } from "../../../../common/grid/grid.types";

export const columns: GridColumnDefinition<GetAdminEmailTemplatesResult_EmailTemplate>[] = [
    {
        Header: 'Name',
        accessor: 'label',
        Cell: LinkCell({
            link: ({ row }) => (`/admin/email-templates/${row.original.emailTemplateId}`),
        })
    },
    {
        Header: 'Description',
        accessor: 'description',
    },
    {
        Header: 'User Type',
        accessor: 'userType',
    },
]