import { IsNotEmpty, IsString } from "class-validator";

export class UpdatePasswordPayload {
    @IsString()
    @IsNotEmpty()
    securityToken: string;

    @IsString()
    @IsNotEmpty()
    newPassword: string;
}