import { FC, useMemo } from "react";
import { GetAdminAssessmentsResult_Assessment, PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { StandardCardGridContainer } from "../../../../common/components/standard-card-grid-container/standard-card-grid-container";
import { StandardCardHeader, StandardCardHeaderLoadingState } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { Grid, GridLoadingState } from "../../../../common/grid/grid";
import { columns } from "./admin-assessments-grid-card.columns";

type AdminAssessmentsGridCardProps = {
    assessments: GetAdminAssessmentsResult_Assessment[]
    onRefresh: () => void
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
    totalCount: number
}

export const AdminAssessmentsGridCard: FC<AdminAssessmentsGridCardProps> = ({
    assessments,
    onRefresh,
    pagination,
    onPaginationChange,
    totalCount,
}) => {

    // constants
    const memoizedColumns = useMemo(() => (columns(onRefresh)), [onRefresh])

    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeader
                    title="Assessments"
                    subtitle="Review assessments and update statuses"
                />
                <StandardCardGridContainer>
                    <Grid
                        columns={memoizedColumns}
                        rows={assessments}
                        pagination={pagination}
                        onPaginationChange={onPaginationChange}
                        totalCount={totalCount}
                    />
                </StandardCardGridContainer>
            </StandardCardLayout>
        </BodyCard>
    )
}

export const AdminAssessmentsGridCardLoadingState: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeaderLoadingState
                    hasSubtitle={true}
                />
                <GridLoadingState />
            </StandardCardLayout>
        </BodyCard>
    )
}