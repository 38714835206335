import {
  IsArray,
  IsNotEmpty,
  IsString,
  IsUUID,
  ValidateIf,
  ValidateNested,
} from 'class-validator';

export class GetSectionResult {
  @IsUUID()
  id: string;

  @IsString()
  @IsNotEmpty()
  name: string;

  @ValidateNested()
  @IsArray()
  questions: GetSectionResult_Question[];

  @IsUUID()
  @ValidateIf((object, value) => value !== null)
  previousSectionId: string | null;

  @IsUUID()
  @ValidateIf((object, value) => value !== null)
  nextSectionId: string | null;

  @IsArray()
  @ValidateNested()
  public responseValues: GetSectionResult_ResponseValue[];
}

export class GetSectionResult_Question {
  @IsUUID()
  id: string;

  @IsString()
  questionText: string;

  @IsUUID()
  selectedAssessmentResponseValueId: string;
}

export class GetSectionResult_ResponseValue {
  @IsUUID()
  @IsNotEmpty()
  public id: string;

  @IsString()
  public label: string;
}
