import styled from "@emotion/styled"
import { Gap } from "../../../../../common/components/sizes/sizes"

export const InnerContainer = styled.div({
    display: 'grid',
    gap: Gap.desktop.normal,
    alignItems: 'center',
})

export const CardHeader = styled.div({
    display: 'grid',
    gap: Gap.desktop.normal,
})

export const CardFooter = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'max-content',
    gridAutoColumns: 'max-content',
    gap: Gap.desktop.normal,
})