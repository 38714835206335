import { IsNumber } from "class-validator";

export class PaginatedResult {
    @IsNumber()
    totalCount: number

    @IsNumber()
    skip: number;

    @IsNumber()
    resultCount: number;
}