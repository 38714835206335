import { IsArray, IsDate, IsEmail, IsObject, IsPhoneNumber, IsString, IsUUID, ValidateNested } from "class-validator";
import { PaginatedResult } from "../../../common";

export class GetAdminAccountsResult extends PaginatedResult {
    @IsArray()
    @ValidateNested()
    accounts: GetAdminAccountsResult_Account[]
}

export class GetAdminAccountsResult_Account {
    @IsUUID()
    accountId: string;

    @IsString()
    companyName: string;

    @IsString()
    accountType: string;

    @IsObject()
    @ValidateNested()
    contact: GetAdminAccountsResult_Contact;

    @IsObject()
    @ValidateNested()
    assessment: GetAdminAccountsResult_Assessment;

    @IsDate()
    createdAt: Date;
}

export class GetAdminAccountsResult_Contact {
    @IsUUID()
    userId: string;

    @IsString()
    name: string;

    @IsString()
    jobTitle: string;

    @IsEmail()
    emailAddress: string;

    @IsPhoneNumber()
    phoneNumber: string;
}

export class GetAdminAccountsResult_Assessment {
    @IsUUID()
    assessmentId: string;

    @IsString()
    assessmentStatus: string;;
}