import { FC } from "react";
import { GetCurrentAssessmentResultsResult_Section } from "tech-health-assessment-sdk/dist";
import { ParagraphSkeleton, SpanSkeleton } from "../../../../../common/skeletons/skeletons";
import { ScoreIcon, ScoreIconSkeleton } from "../../../score-icon/score-icon";
import { SectionResultDescription, SectionResultGridItemContainer, SectionResultGridItemTextContainer, SectionResultTitle } from "./section-result-grid-item.styled";

type SectionResultGridItemProps = {
    sectionResult: GetCurrentAssessmentResultsResult_Section
}

export const SectionResultGridItem: FC<SectionResultGridItemProps> = ({
    sectionResult,
}) => {
    return (
        <SectionResultGridItemContainer>
            <ScoreIcon score={sectionResult.score} />
            <SectionResultGridItemTextContainer>
                <SectionResultTitle>
                    {sectionResult.sectionName}
                </SectionResultTitle>
                <SectionResultDescription>
                    {sectionResult.score.resultDetails}
                </SectionResultDescription>
            </SectionResultGridItemTextContainer>
        </SectionResultGridItemContainer>
    )
}

export const SectionResultGridItemLoadingState: FC = () => {
    return (
        <SectionResultGridItemContainer>
            <ScoreIconSkeleton />
            <SectionResultGridItemTextContainer>
                <SpanSkeleton fontSize={18} />
                <ParagraphSkeleton />
            </SectionResultGridItemTextContainer>
        </SectionResultGridItemContainer>
    )
}