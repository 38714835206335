import { IsArray, IsString, IsUUID, ValidateNested } from "class-validator";
import { AccountTypeCode } from "../../../../common/codes.types";

export class GetAccountTypesResult {
    @IsArray()
    @ValidateNested()
    accountTypes: GetAccountTypesResult_AccountType[]
}

export class GetAccountTypesResult_AccountType {
    @IsUUID()
    accountTypeId: string;

    @IsString()
    code: AccountTypeCode;

    @IsString()
    label: string;
}