import { IsArray, IsUUID, ValidateIf, ValidateNested } from 'class-validator';

export class SaveSectionPayload {
  @ValidateNested()
  @IsArray()
  questions: SaveSectionPayload_Question[];
}

export class SaveSectionPayload_Question {
  @IsUUID()
  questionId: string;

  @IsUUID()
  @ValidateIf((object, value) => value !== null)
  assessmentResponseValueId: string | null;
}
