import { FC } from "react";
import { Outlet } from "react-router";
import { AuthenticationShell } from "../../modules/authentication/components/authentication-shell/authentication-shell";

export const AuthenticationRoute: FC = () => {
    return (
        <AuthenticationShell>
            <Outlet />
        </AuthenticationShell>
    )
}