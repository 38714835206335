import styled from "@emotion/styled";
import { AppColors } from "../../../../../common/constants/colors/app-colors";
import { Gap, Padding } from "../../../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints";

export const SectionQuestionsContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.large,

    // padding
    padding: Padding.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gap: Gap.mobile.larger,

        // padding
        padding: Padding.mobile.larger,
    },
})

export const SectionFooterContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    justifyContent: 'space-between',
    gridTemplateColumns: 'max-content',
    gridAutoColumns: 'max-content',

    // padding
    paddingLeft: Padding.desktop.large,
    paddingBottom: Padding.desktop.large,
    paddingRight: Padding.desktop.large,
})

export const QuestionsFormTitle = styled.h1({
    margin: 0,
})

export const QuestionsContainer = styled.div({
    display: 'grid',
})

export const QuestionRowContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '1fr 465px',
    gap: Gap.desktop.normal,

    // alignment
    alignItems: 'center',

    // border
    borderTop: `1px solid ${AppColors.border.default}`,

    // padding
    paddingTop: Padding.desktop.normal,
    paddingBottom: Padding.desktop.normal,

    // last child
    ':last-child': {
        borderBottom: `1px solid ${AppColors.border.default}`,
    },

    // breakpoints
    [TABLET_BREAKPOINT]: {
        gridTemplateColumns: '1fr',
        gap: Gap.tablet.larger,
    },
})

export const QuestionRowResponsesContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    gridAutoColumns: '1fr',
    gap: 40,

    // alignment
    justifyContent: 'center',
    alignItems: 'center',

    // text
    textAlign: 'center',

    // breakpoints
    [TABLET_BREAKPOINT]: {
        gridTemplateColumns: '1fr',
    },

    [MOBILE_BREAKPOINT]: {
        gridAutoFlow: 'row',
        gap: 20,
    },
})