import { FC } from "react";
import { AssessmentOverview, AssessmentOverviewLoadingState } from "../../../../assessments/components/assessment-overview/assessment-overview";
import { useAdminAssessment } from "./admin-assessment.hooks";

type AdminAssessmentProps = {
    assessmentId: string
    currentSectionId: string | null
}

export const AdminAssessment: FC<AdminAssessmentProps> = ({
    assessmentId,
    currentSectionId,
}) => {

    // data hooks
    const { data: assessment, isLoading } = useAdminAssessment(assessmentId)

    if (isLoading || !assessment) {
        return (
            <AssessmentOverviewLoadingState />
        )
    }

    return (
        <AssessmentOverview
            activeSectionId={currentSectionId}
            assessment={assessment}
        />
    )
}