import { FC } from "react";
import { BodyCardLoading } from "../body-card-loading/body-card-loading";
import { BodyContainer } from "../body-container/body-container";

export const BodyLoading: FC = () => {
    return (
        <BodyContainer>
            <BodyCardLoading />
        </BodyContainer>
    )
}