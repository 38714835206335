import { FC } from "react";
import { CalloutCard } from "../../../../../../../../common/components/callout-card/callout-card";

export const ScoringCalloutCard: FC = () => {
    return (
        <CalloutCard type="warning">
            <h2>What will happen?</h2>
            <br />
            <span>If you proceed...</span>
            <ul>
                <li>The user will not longer be able to see their results until you finish making scoring changes.</li>
                <li>When the user signs in, they will be greeted with a "we're scoring your assessment for you" screen.</li>
                <li>We <b>will not</b> send the user an email to let them know that you made this change.</li>
                <li>This action is reversible - you can undo it at any time.</li>
            </ul>
        </CalloutCard>
    )
}