import { useState } from "react";
import { PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { DEFAULT_SKIP, DEFAULT_TAKE } from "../../constants/cursors/default-page-cursor";

export const usePagination = () => (
    useState<PaginatedRequest>(() => {
        const pagination = new PaginatedRequest()
    
        pagination.skip = DEFAULT_SKIP;
        pagination.take = DEFAULT_TAKE;
    
        return pagination;
    })
)