import { ResetPasswordResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../services/core-api-service";

class ForgotPasswordService {
  public async sendForgotPasswordLink (
    emailAddress: string,
  ): Promise<ResetPasswordResult> {
    return api.auth.resetPassword({ emailAddress })
  }; 
}

export const service = new ForgotPasswordService()