import styled from "@emotion/styled";
import { Gap } from "../../../../common/components/sizes/sizes";

export const CardsLayout = styled.div({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: Gap.desktop.normal,
    width: '100%',
    maxWidth: 1340,
})

export const FormItems = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: Gap.desktop.normal,
})