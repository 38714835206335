import styled from "@emotion/styled";
import { Gap, Padding } from "../../../../common/components/sizes/sizes";

export const GetLeadsDiv = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.large,
    
    // padding
    padding: Padding.desktop.large,

    
    width: 'calc(100vw - 24px)',
    maxWidth: 640,
})
