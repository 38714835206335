import { FC } from "react";
import { CompletedTickContainer } from "./completed-tick.styled";

export const CompletedTick: FC = () => (
    <CompletedTickContainer>
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10.5" cy="10.5" r="10.5" fill="#28B6FE" />
            <path d="M8.81756 12.5345L6.62831 10.3452L5.88281 11.0855L8.81756 14.0202L15.1176 7.72023L14.3773 6.97998L8.81756 12.5345Z" fill="white" />
        </svg>
    </CompletedTickContainer>
)