import { IsArray, IsString, IsUUID, ValidateNested } from "class-validator";

export class SearchAccountsResult {
    @IsArray()
    @ValidateNested()
    accounts: SearchAccountsResult_Account[]
}

export class SearchAccountsResult_Account {
    @IsUUID()
    accountId: string;

    @IsString()
    companyName: string;
}