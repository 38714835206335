import { FC, useContext } from "react";
import { createPortal } from "react-dom";
import { PortalContext } from "../portal-provider/portal-provider";

type PortalProps = {
    children: JSX.Element | JSX.Element[] | string
}

export const Portal: FC<PortalProps> = ({
    children,
}) => {

    // context
    const { portalTargetRef } = useContext(PortalContext)

    if (!portalTargetRef?.current) return null;

    return createPortal(children, portalTargetRef.current)
}