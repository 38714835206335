import { FC, useCallback, useState } from "react";
import { GhostSecondaryButton } from "../../../../../../common/components/buttons/buttons";
import { ResetPasswordDialog } from "./subcomponents/reset-password-dialog/reset-password-dialog";

type ResetPasswordButtonProps = {
    userId: string
} & Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'>

export const ResetPasswordButton: FC<ResetPasswordButtonProps> = ({
    userId,
    ...buttonProps
}) => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <>
            <GhostSecondaryButton
                {...buttonProps}
                onClick={handleButtonClick}
            >
                Reset Password
            </GhostSecondaryButton>
            <ResetPasswordDialog
                userId={userId}
                isOpen={dialogOpen}
                onClose={handleDialogClose}
            />
        </>
    )
}