import { FC } from "react";
import { IconSvg } from "../icons.styled";
import { IconProps } from "../icons.types";

export const EmailIcon: FC<IconProps> = ({
    size,
    color,
}) => {
    return (
        <IconSvg
            size={size}
            color={color}
            viewBox="0 0 18 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M17.3333 2.00016C17.3333 1.0835 16.5833 0.333496 15.6666 0.333496H2.33329C1.41663 0.333496 0.666626 1.0835 0.666626 2.00016V12.0002C0.666626 12.9168 1.41663 13.6668 2.33329 13.6668H15.6666C16.5833 13.6668 17.3333 12.9168 17.3333 12.0002V2.00016ZM15.6666 2.00016L8.99996 6.16683L2.33329 2.00016H15.6666ZM15.6666 12.0002H2.33329V3.66683L8.99996 7.8335L15.6666 3.66683V12.0002Z" fill={color} />
        </IconSvg>
    )
}
