import styled from "@emotion/styled";
import { BorderRadius } from "../components/sizes/sizes";
import { SkeletonStyles } from "../constants/animations/animations";

export const LargeTitleSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    width: '100%',
    height: 52,
    maxWidth: 350,

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const TitleSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    width: '100%',
    height: 42.5,
    maxWidth: 250,

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const SubtitleSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    width: '100%',
    height: 36,
    maxWidth: 450,

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const HeaderSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    width: '100%',
    height: 27.5,
    maxWidth: 200,

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const ParagraphSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    width: '100%',
    height: 58,

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const RadioSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    width: 30,
    height: 30,

    // margin
    margin: 0,

    // border
    borderRadius: '50%',

    ...SkeletonStyles(background),
}))

export const ButtonSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // colors
    color: 'transparent',

    // size
    height: 46.5,
    width: 100,

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const SpanSkeleton = styled.div<{
    background?: 'light' | 'dark'
    fontSize: number,
}>(({ fontSize, background }) => ({
    // colors
    color: 'transparent',

    // size
    height: fontSize / 0.85,
    width: 10 * (fontSize ^ 2),

    // margin
    margin: 0,

    // border
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))

export const InputSkeleton = styled.div<{
    background?: 'light' | 'dark'
}>(({ background }) => ({
    // size
    height: 46.5,

    // border
    border: '1px solid #BDBDBD',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.08)',
    borderRadius: BorderRadius.small,

    ...SkeletonStyles(background),
}))