import styled from "@emotion/styled";
import { Gap } from "../../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT } from "../../../../common/constants/breakpoints/breakpoints";

export const AdminOptionsGrid = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.normal,
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',

    // size
    flex: 1,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        gridTemplateColumns: '1fr',
        gap: Gap.mobile.large,
    }
})