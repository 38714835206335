import { CoreAPI } from "../core";
import { GetDemoDashboardLinkResult, ResetEnvironmentPayload, ResetEnvironmentResult } from "./dtos";

export class DemoAPI {
    private basePath: string;

    constructor(private readonly coreApi: CoreAPI) {
        this.basePath = `${this.coreApi.apiUrl}/demo`
    }

    public async resetEnvironment(payload: ResetEnvironmentPayload): Promise<ResetEnvironmentResult> {
        const result = await this.coreApi.post(`${this.basePath}/resetEnvironment`, payload)

        if (!result?.data) throw new Error();
    
        const resultData = result.data as ResetEnvironmentResult;
    
        return resultData;
    }

    public async getDemoDashboardLink(): Promise<GetDemoDashboardLinkResult> {
        const result = await this.coreApi.get(`${this.basePath}/demoDashboardLink`)

        if (!result?.data) throw new Error();
    
        const resultData = result.data as GetDemoDashboardLinkResult;
    
        return resultData;
    }
}