import { GetAdminAccountResult, GetAdminAssessmentsRequest, GetAdminAssessmentsResult, GetAdminUsersRequest, GetAdminUsersResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../../services/core-api-service";

class AdminAccountService {
    public async getAccount(accountId: string): Promise<GetAdminAccountResult> {
        return api.admin.getAccount(accountId)
    }

    public async getAssessments(request: GetAdminAssessmentsRequest): Promise<GetAdminAssessmentsResult> {
        return api.admin.getAssessments(request)
    }

    public async getUsers(request: GetAdminUsersRequest): Promise<GetAdminUsersResult> {
        return api.admin.getUsers(request)
    }
}

export const service = new AdminAccountService()