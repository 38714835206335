import { DateTime } from "luxon";
import { GetAdminUsersResult_User } from "tech-health-assessment-sdk/dist";
import { DateCell } from "../../../../common/grid/cell-renderers/date-cell";
import { EmailCell } from "../../../../common/grid/cell-renderers/email-cell";
import { LinkCell } from "../../../../common/grid/cell-renderers/link-cell";
import { PhoneCell } from "../../../../common/grid/cell-renderers/phone-cell";
import { GridColumnDefinition } from "../../../../common/grid/grid.types";

export const columns: GridColumnDefinition<GetAdminUsersResult_User>[] = [
    {
        Header: 'Name',
        accessor: 'name',
        Cell: LinkCell({
            link: ({ row }) => (`/admin/users/${row.original.userId}`),
        })
    },
    {
        Header: 'User Type',
        accessor: 'userType',
    },
    {
        Header: 'Email Address',
        accessor: 'emailAddress',
        Cell: EmailCell(),
    },
    {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
        Cell: PhoneCell(),
    },
    {
        Header: 'Title',
        accessor: 'jobTitle',
    },
    {
        Header: 'Account',
        accessor: 'account.companyName',
        Cell: LinkCell({
            link: ({ row }) => (`/admin/accounts/${row.original.account?.accountId}`),
        })
    },
    {
        Header: 'Enabled',
        accessor: 'isEnabled',
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: DateCell({
            datetimeFormatOptions: DateTime.DATETIME_MED,
        })
    },
]