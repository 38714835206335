import { useCallback, useEffect, useState } from "react"
import { GetSectionResult } from "tech-health-assessment-sdk/dist"
import { getSection } from "./assessment-section.services";

export const useAssessmentSection = (sectionId: string): {
    assessmentSection: GetSectionResult | null,
    isLoading: boolean,
    refresh: () => void,
} => {

    // state
    const [assessmentSection, setAssessmentSection] = useState<GetSectionResult | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    // imperative logic
    const retrieveAssessmentSection = useCallback(async (sectionId: string) => {
        setIsLoading(true);
        
        const result = await getSection(sectionId);
        
        setAssessmentSection(result)
        setIsLoading(false)
    }, [])
    
    // side effects
    useEffect(() => {
        retrieveAssessmentSection(sectionId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionId])

    // event handlers
    const handleRefresh = useCallback(() => {
        retrieveAssessmentSection(sectionId)
    }, [retrieveAssessmentSection, sectionId])

    return {
        assessmentSection,
        isLoading,
        refresh: handleRefresh,
    }
}