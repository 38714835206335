import styled from "@emotion/styled"

export const PreviewContainer = styled.div({
    position: 'relative',
    minHeight: 500,
    boxSizing: 'border-box',
})

export const PreviewIFrame = styled.iframe({
    border: 'none',
    
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
})

export const LoadingOverlayContainer = styled.div({
    // position
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,

    // colors
    backgroundColor: `rgba(255, 255, 255, 0.3)`,

    // layout
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    justifyItems: 'center',
    alignItems: 'center',
})