import { useEffect } from "react";

export const useBlockNavigation = (blockNavigation: boolean) => {
    // use the browser's built in 'beforeunload' function to block reloads or natural navigation from the page
    useEffect(() => {
        if (!blockNavigation) return;

        const preventUnload = (event: BeforeUnloadEvent) => {
            const message = 'You have unsaved changes'
            event.preventDefault()
            event.returnValue = message;
        }

        window.addEventListener('beforeunload', preventUnload)

        return () => {
            window.removeEventListener('beforeunload', preventUnload)
        }
    }, [blockNavigation])

    // listen for link clicks to intercept navigation attempts
    useEffect(() => {
        if (!blockNavigation) return;

        const clickEventListener = (event: MouseEvent) => {
            if (!(event.target instanceof HTMLElement)) return;
            if (!event.composedPath().map(element => element instanceof HTMLAnchorElement).includes(true)) return;

            const allowLeave = window.confirm('You have unsaved changes. Are you sure you want to leave?')
            if (!allowLeave) {
                event.preventDefault()
            } else {
                event.target.click()
            };
        }

        window.addEventListener('mousedown', clickEventListener)

        return () => {
            window.removeEventListener('mousedown', clickEventListener)
        }
    }, [blockNavigation])
}