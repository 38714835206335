import { FC, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { BodyLoading } from "../../../common/components/body-loading/body-loading";
import { SelfContext } from "../../providers/self-provider/self-provider";

export const AppRootRedirector: FC = () => {

    // router hooks
    const navigate = useNavigate()

    // context
    const { self } = useContext(SelfContext)

    // side effects
    useEffect(() => {
        if (self.userType === 'internal') {
            navigate('/admin')
        } else if (self.userType === 'external') {
            navigate('/assessment')
        }
    }, [navigate, self.userType])

    return (
        <BodyLoading />
    );
}