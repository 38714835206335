import styled from "@emotion/styled";
import { Gap } from "../../../../../../common/components/sizes/sizes";

export const CreateAccountForm = styled.form()

const VERTICAL_FORM_BREAKPOINT = '@media (max-width: 1000px)';

export const FormContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.normal,

    // breakpoints
    [VERTICAL_FORM_BREAKPOINT]: {
        gap: Gap.desktop.large * 0.75,
    },
})