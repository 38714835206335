import { FC, FunctionComponent, MouseEventHandler } from "react";
import { Link } from "react-router-dom";
import { AdminGridItemIcon, AdminGridItemIconLoadingState } from "./subcomponents/admin-grid-item-icon/admin-grid-item-icon";
import { NameLabel, AdminGridItemContainer, SubtitleLabel, TextContainer, AdminGridItemContainerLoadingContainer, AdminGridItemTextLoadingContainer } from "./admin-grid-item.styled";
import { IconProps } from "../../../../../../common/components/icons/icons.types";
import { SpanSkeleton } from "../../../../../../common/skeletons/skeletons";

type AdminGridItemProps = {
    linkTo?: string
    title: string
    subtitle: string
    iconComponent: FunctionComponent<IconProps>
    onClick?: MouseEventHandler
}

export const AdminGridItem: FC<AdminGridItemProps> = ({
    linkTo,
    title,
    subtitle,
    iconComponent,
    onClick,
}) => {


    if (!!linkTo) {
        return (
            <Link
                to={linkTo}
                style={{ textDecoration: 'none', display: 'grid' }}
                onClick={onClick}
            >
                <AdminGridItemContainer>
                    <AdminGridItemIcon
                        sourceIconComponent={iconComponent}
                    />
                    <TextContainer>
                        <NameLabel>
                            {title}
                        </NameLabel>
                        <SubtitleLabel>
                            {subtitle}
                        </SubtitleLabel>
                    </TextContainer>
                </AdminGridItemContainer>
            </Link>
        )
    }
    return (
        <AdminGridItemContainer onClick={onClick}>
            <AdminGridItemIcon
                sourceIconComponent={iconComponent}
            />
            <TextContainer>
                <NameLabel>
                    {title}
                </NameLabel>
                <SubtitleLabel>
                    {subtitle}
                </SubtitleLabel>
            </TextContainer>
        </AdminGridItemContainer>
    )
}

export const AdminGridItemLoadingState: FC = () => {
    return (
        <AdminGridItemContainerLoadingContainer>
            <AdminGridItemIconLoadingState />
            <AdminGridItemTextLoadingContainer>
                <SpanSkeleton fontSize={14} />
                <SpanSkeleton fontSize={13} />
            </AdminGridItemTextLoadingContainer>
        </AdminGridItemContainerLoadingContainer>
    )
}
