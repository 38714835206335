import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Measure, { BoundingRect, ContentRect } from "react-measure";
import { GetEmailTemplateResult } from "tech-health-assessment-sdk/dist";
import { InlineLoading } from "../../../../../../common/components/inline-loading/inline-loading";
import { service } from "./html-preview.service";
import { LoadingOverlayContainer, PreviewContainer, PreviewIFrame } from "./html-preview.styled";

type HtmlPreviewProps = {
    htmlContent: string
    emailTemplate: GetEmailTemplateResult,
}

export const HtmlPreview: FC<HtmlPreviewProps> = ({
    htmlContent,
    emailTemplate,
}) => {

    // state
    const [boundingRect, setBoundingRect] = useState<BoundingRect | null>(null)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [displayContent, setDisplayContent] = useState<string>(htmlContent)

    // constants
    const hydratedSource = useMemo(() => {
        const context = service.generateSampleContext({
            emailTemplateCode: emailTemplate.code,
            emailTemplateTargetCode: emailTemplate.emailTemplateTarget.code ?? null,
            targetUserTypeCode: emailTemplate.userType.code ?? null,
        })

        return service.hydrateTemplate(displayContent, context)
    }, [displayContent, emailTemplate.code, emailTemplate.emailTemplateTarget.code, emailTemplate.userType.code])

    const previewSource = useMemo(() => (
        `data:text/html;base64,${btoa(hydratedSource)}`
    ), [hydratedSource])

    // side effects
    useEffect(() => {
        setIsLoading(true)
        const timeout = setTimeout(() => {
            setDisplayContent(htmlContent)
            setIsLoading(false)
        }, 500);
        return () => {
            clearTimeout(timeout)
        }
    }, [htmlContent])

    // event handlers
    const handleResize = useCallback((contentRect: ContentRect) => {
        setBoundingRect(contentRect.bounds ?? null)
    }, [])

    return (
        <Measure onResize={handleResize} bounds>
            {({ measureRef }) => (
                <PreviewContainer ref={measureRef}>
                    <PreviewIFrame
                        src={previewSource}
                        width={`${(boundingRect?.width ?? 0) / 0.5}px`}
                        height={`${(boundingRect?.height ?? 0) / 0.5}px`}
                        style={{ zoom: 0.5 }}
                    />
                    {isLoading && (
                        <LoadingOverlayContainer>
                            <InlineLoading>
                                Updating preview...
                            </InlineLoading>
                        </LoadingOverlayContainer>
                    )}
                </PreviewContainer>
            )}
        </Measure>
    )
}