import { FC } from "react";
import { NavLink } from "react-router-dom";
import { NavLinkStyles } from "../header.styled";

export const InternalUserNavItems: FC = () => {
    return (
        <>
            <NavLink to="/admin/home" style={NavLinkStyles}>
                Home
            </NavLink>
            <NavLink to="/admin/accounts" style={NavLinkStyles}>
                Accounts
            </NavLink>
            <NavLink to="/admin/assessments" style={NavLinkStyles}>
                Assessments
            </NavLink>
            <NavLink to="/admin/users" style={NavLinkStyles}>
                Users
            </NavLink>
            <NavLink to="/admin/email-templates" style={NavLinkStyles}>
                Email Templates
            </NavLink>
        </>
    )
}