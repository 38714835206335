import styled from "@emotion/styled";
import { AppColors } from "../../../../../../../common/constants/colors/app-colors";
import { Gap } from "../../../../../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../../../../../../common/constants/breakpoints/breakpoints";

export const QuestionText = styled.span({
    fontWeight: 400,
    fontSize: 18,
    letter: '-1.4%',
    lineHeight: '1.6em',
    color: AppColors.text.onLightBackground,
})

export const ResponseValueContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.tablet.large * .75,

    // alignment
    justifyItems: 'center',
    alignItems: 'center',

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        gridTemplateColumns: 'max-content 1fr',
        justifyItems: 'start',
    },
})

export const ResponseLabel = styled.label({
    // display
    display: 'none',

    // size
    boxSizing: 'border-box',

    // cursor
    cursor: 'pointer',

    // before
    ':before': {
        boxSizing: 'border-box',
    },

    // after
    ':after': {
        boxSizing: 'border-box',
    },

    // breakpoints
    [TABLET_BREAKPOINT]: {
        // layout
        display: 'grid',

        // text
        textAlign: 'center',
        fontWeight: 700,
        fontSize: 18,

        // size
        maxWidth: 155,
    },

    [MOBILE_BREAKPOINT]: {
        gridRow: 1,
        gridColumn: 2,
        maxWidth: 'unset',
        textAlign: 'left',
    },
})

export const ResponseRadio = styled.input({
    // layout
    display: 'grid',
    justifyContent: 'center',
    alignContent: 'center',

    // size
    boxSizing: 'border-box',
    width: 30,
    height: 30,

    // appearance
    appearance: 'none',
    backgroundColor: 'white',
    margin: 0,

    // border
    border: `1px solid ${AppColors.border.darker}`,
    borderRadius: '50%',

    // cursor
    cursor: 'pointer',

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        gridRow: 1,
        gridColumn: 1,
    },

    // after
    ':after': {
        // content
        content: '""',

        // size
        boxSizing: 'border-box',
        width: 12,
        height: 12,

        // border
        borderRadius: '50%',

        // color
        backgroundColor: 'transparent',
    },

    // focused
    ':focus': {
        outline: 'none',
        border: `2px solid ${AppColors.border.blue.primary}`,
    },

    ':hover': {
        backgroundColor: AppColors.background.light.hover,
    },

    // checked
    ':checked': {
        // color
        backgroundColor: AppColors.background.blue.primary,

        // border
        border: `1px solid ${AppColors.border.blue.primary}`,

        // after
        ':after': {
            backgroundColor: AppColors.background.light.white,
        },
    },

    // disabled
    ':disabled': {
        cursor: 'not-allowed',
        opacity: 0.5,
    },
})