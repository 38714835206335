import styled from "@emotion/styled";
import React from "react";
import { Gap } from "../../../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints";
import { AppColors } from "../../../../../common/constants/colors/app-colors";

export const HeaderContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 36,

    // colors
    backgroundColor: AppColors.background.light.white,

    // padding
    paddingLeft: 60,
    paddingRight: 60,
    paddingTop: 28,
    paddingBottom: 28,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        paddingLeft: 20,
        paddingRight: 20,
    },
})

export const LogoContainer = styled.div()

export const NavContainer = styled.nav({
    // layout
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: Gap.desktop.large,
    flex: 1,

    // text
    fontWeight: 600,
    fontSize: 16,
    
    // links
    '> a': {
        textDecoration: 'none',

        ':hover': {
            textDecoration: 'underline',
        }
    },
})

export const ActionsContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 12,
})

export const NavLinkStyles: ((props: {
    isActive: boolean;
    isPending: boolean;
}) => React.CSSProperties | undefined) = ({ isActive }) => ({
    color: isActive ? AppColors.text.blue.primary : AppColors.text.blue.dark,
})