import { FC } from "react";
import { useParams } from "react-router";
import { SubmitAssessment } from "../../modules/assessments/components/submit-assessment/submit-assessment";

export const SubmitAssessmentRoute: FC = () => {

    // router hooks
    const { assessment_id } = useParams() as { assessment_id: string }

    return (
        <SubmitAssessment
            assessmentId={assessment_id}
        />
    )
}