import { SaveSectionPayload, SaveSectionResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../../../services/core-api-service";

class ServiceQuestionsService {
    public async saveSection(sectionId: string, payload: SaveSectionPayload): Promise<SaveSectionResult> {
        return api.assessmentSections.saveSection(sectionId, payload)
    }
}

const service = new ServiceQuestionsService();

export default service;