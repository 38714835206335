import styled from "@emotion/styled"
import { SkeletonStyles } from "../../../common/constants/animations/animations"
import { AppColors } from "../../../common/constants/colors/app-colors"
import { SectionState } from "../../assessment-sections.types"

export const SectionIconContainer = styled.div<{
    state: SectionState,
    size: number,
}>(({ state, size }) => ({
    // position
    position: 'relative',
    
    // display
    display: 'grid',
    width: size,
    height: size,
    justifyContent: 'center',
    alignItems: 'center',

    // border
    borderRadius: '50%',

    // background
    backgroundColor:
        state === 'complete'
        ? AppColors.background.dark
        : AppColors.background.blue.primary,
}))

export const SectionIconImage = styled.img<{
    size: number
}>(({ size }) => ({
    width: size,
    height: size,
}))

export const SectionIconSkeleton = styled.div<{
    size: number,
}>(({ size }) => ({
    // display
    width: size,
    height: size,

    // border
    borderRadius: '50%',

    ...SkeletonStyles(),
}))