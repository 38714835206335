import { AppColors } from "../../../common/constants/colors/app-colors";

class ScoreIconUtils {
    public getColorForScore(resultText: string): string {
        if (resultText === 'Advanced') return AppColors.text.green.primary;
        if (resultText === 'Intermediate') return AppColors.text.blue.primary;
        if (resultText === 'Rudimentary') return AppColors.text.red.primary;
        return AppColors.text.gray;
    }
}

export const utils = new ScoreIconUtils()