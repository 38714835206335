import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "../../../../../../common/components/buttons/buttons";
import { Dialog, DialogProps } from "../../../../../../common/components/dialog/dialog";
import { DialogCard, DialogFooter, DialogHeader, DialogTitle } from "../../../../../../common/components/dialog/dialog.styled";
import { FormItemColumns } from "../../../../../../common/components/form-item-columns/form-item-columns";
import { FormItem } from "../../../../../../common/components/form-item/form-item";
import { FormValidationMessage } from "../../../../../../common/components/form-validation-message/form-validation-message";
import { Input } from "../../../../../../common/components/input/input";
import { StandardDialogInnerContainer } from "../../../../../../common/components/standard-dialog-inner-container/standard-dialog-inner-container";
import { CreateAccountForm } from "./create-account-dialog.styled";
import { validate as validateEmail } from 'email-validator'
import { phone } from 'phone'
import { LoadingSpinner } from "../../../../../../common/components/loading-spinner/loading-spinner";
import { AppColors } from "../../../../../../common/constants/colors/app-colors";
import ReactSelect from "react-select";
import { useAccountTypes } from "./create-account-dialog.hooks";
import { InlineLoading } from "../../../../../../common/components/inline-loading/inline-loading";
import { CalloutCard } from "../../../../../../common/components/callout-card/callout-card";
import { AccountTypeCode, AdminCreateAccountResult } from "tech-health-assessment-sdk/dist";
import { service } from "./create-account-dialog.service";
import { SnackbarContext } from "../../../../../../common/components/snackbar-provider/snackbar-provider";
import { useNavigate } from "react-router";

type CreateAccountDialogProps = {

} & Omit<DialogProps, 'children'>

export const CreateAccountDialog: FC<CreateAccountDialogProps> = ({
    isOpen,
    onClose,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // router hooks
    const navigate = useNavigate()

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { register, control, getValues, handleSubmit, reset, formState } = useForm({
        defaultValues: {
            accountType: null as { label: string, value: string } | null,
            firstName: '',
            lastName: '',
            jobTitle: '',
            companyName: '',
            emailAddress: '',
            phoneNumber: '',
        }
    })

    // data hooks
    const { data: accountTypesResult, isLoading: accountTypesLoading } = useAccountTypes()

    // constants
    const accountTypeOptions = useMemo(() => (
        accountTypesResult?.accountTypes.map((accountType) => ({
            value: accountType.code,
            label: accountType.label,
        }))
    ), [accountTypesResult?.accountTypes])

    // side effects
    useEffect(() => {
        reset()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen])

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        const values = getValues()

        let result: AdminCreateAccountResult | undefined = undefined;
        let error: unknown;
        try {
            if (!values.accountType) throw new Error();

            result = await service.createAccount({
                accountTypeCode: values.accountType.value as AccountTypeCode,
                firstName: values.firstName,
                lastName: values.lastName,
                jobTitle: values.jobTitle,
                companyName: values.companyName,
                emailAddress: values.emailAddress,
                phoneNumber: phone(values.phoneNumber).phoneNumber!,
            })
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })

            setIsLoading(false)
            onClose()
            return;
        }

        showSnackbar({
            type: 'success',
            title: 'Successfully created user and account',
            message: `${values.firstName} ${values.lastName}, ${values.companyName}`,
        })

        setIsLoading(false)
        onClose()

        navigate(`/admin/accounts/${result.account?.accountId}`)
    }, [getValues, navigate, onClose, showSnackbar])

    const handleCancelButtonClicked = useCallback(() => {
        onClose()
    }, [onClose])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <DialogCard isOpen={isOpen}>
                <CreateAccountForm onSubmit={handleSubmit(handleFormSubmit)}>
                    <StandardDialogInnerContainer>
                        <DialogHeader>
                            <DialogTitle>
                                Create a New Account
                            </DialogTitle>
                        </DialogHeader>
                        <FormItem label="Account Type">
                            <>
                                <Controller
                                    control={control}
                                    name="accountType"
                                    rules={{ required: true }}
                                    render={({ field }) => (
                                        <ReactSelect
                                            {...field}
                                            options={accountTypeOptions}
                                            isDisabled={!accountTypesResult || accountTypesLoading || isLoading}
                                            isLoading={!accountTypesResult || accountTypesLoading}
                                        />
                                    )}
                                />
                                {(!accountTypeOptions || accountTypesLoading) && (
                                    <InlineLoading>
                                        Loading Account Types...
                                    </InlineLoading>
                                )}
                                {formState.errors.accountType?.type === 'required' && (
                                    <FormValidationMessage data-testid="account-type-missing-warning">
                                        Account Type is required
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                        <FormItemColumns>
                            <FormItem label="Company Name">
                                <>
                                    <Input
                                        {...register('companyName', {
                                            required: true,
                                        })}
                                        data-testid="company-name-input"
                                        type="text"
                                        autoComplete="organization"
                                        placeholder="Company Name"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.companyName?.type === 'required' && (
                                        <FormValidationMessage data-testid="company-name-missing-warning">
                                            Company Name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItemColumns>
                        <FormItemColumns>
                            <FormItem label="First Name">
                                <>
                                    <Input
                                        {...register('firstName', { required: true })}
                                        data-testid="first-name-input"
                                        type="text"
                                        autoComplete="fname"
                                        placeholder="First Name"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.firstName?.type === 'required' && (
                                        <FormValidationMessage data-testid="first-name-missing-warning">
                                            First name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Last Name">
                                <>
                                    <Input
                                        {...register('lastName', { required: true })}
                                        data-testid="last-name-input"
                                        type="text"
                                        autoComplete="lname"
                                        placeholder="Last Name"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.lastName?.type === 'required' && (
                                        <FormValidationMessage data-testid="last-name-missing-warning">
                                            Last name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItemColumns>
                        <FormItemColumns>
                            <FormItem label="Email Address">
                                <>
                                    <Input
                                        {...register('emailAddress', {
                                            required: true,
                                            validate: validateEmail,
                                        })}
                                        data-testid="email-address-input"
                                        type="email"
                                        autoComplete="username"
                                        placeholder="Email Address"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.emailAddress?.type === 'required' && (
                                        <FormValidationMessage data-testid="email-address-missing-warning">
                                            Email address is required
                                        </FormValidationMessage>
                                    )}
                                    {formState.errors.emailAddress?.type === 'validate' && (
                                        <FormValidationMessage data-testid="email-address-invalid-warning">
                                            Please enter a valid email address
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Phone Number">
                                <>
                                    <Input
                                        {...register('phoneNumber', {
                                            required: true,
                                            validate: (value) => phone(value).isValid,
                                        })}
                                        data-testid="phone-number-input"
                                        type="text"
                                        autoComplete="tel"
                                        placeholder="Phone Number"
                                        disabled={isLoading}
                                    />
                                    {formState.errors.phoneNumber?.type === 'required' && (
                                        <FormValidationMessage data-testid="phone-number-missing-warning">
                                            Phone Number is required
                                        </FormValidationMessage>
                                    )}
                                    {formState.errors.phoneNumber?.type === 'validate' && (
                                        <FormValidationMessage data-testid="phone-number-invalid-warning">
                                            Please enter a valid phone number
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItemColumns>
                        <FormItem label="Job Title">
                            <>
                                <Input
                                    {...register('jobTitle', {
                                        required: true,
                                    })}
                                    data-testid="job-title-input"
                                    type="text"
                                    autoComplete="organization-title"
                                    placeholder="Job Title"
                                    disabled={isLoading}
                                />
                                {formState.errors.jobTitle?.type === 'required' && (
                                    <FormValidationMessage data-testid="job-title-missing-warning">
                                        Job Title is required
                                    </FormValidationMessage>
                                )}
                            </>
                        </FormItem>
                        <CalloutCard type="info">
                            <span>
                                We'll send a temporary password to the user's email address that they can use to log in.
                            </span>
                        </CalloutCard>
                        <DialogFooter>
                            <SecondaryButton
                                disabled={isLoading}
                                type="button"
                                onClick={handleCancelButtonClicked}
                            >
                                Cancel
                            </SecondaryButton>
                            <PrimaryButton
                                disabled={isLoading || !formState.isValid}
                                type="submit"
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Create Account
                            </PrimaryButton>
                        </DialogFooter>
                    </StandardDialogInnerContainer>
                </CreateAccountForm>
            </DialogCard>
        </Dialog>
    )
}