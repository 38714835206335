import { FC } from "react";
import { TableInstance } from 'react-table'
import { PaginatedRequest } from "tech-health-assessment-sdk/dist";

type MobileGridProps<T extends object> = {
    tableInstance: TableInstance<T>
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
    totalCount: number
}

export const MobileGrid = <T extends object>({
    tableInstance,
    pagination,
    onPaginationChange,
}: MobileGridProps<T>) => {
    return (
        <div>
            MobileGrid works!
        </div>
    )
}

export const MobileGridLoadingState: FC = () => {
    return (
        <div>
            Grid loading...
        </div>
    )
}