import styled from "@emotion/styled";

export const BodyCardLoadingContainer = styled.div({
    // layout
    display: 'grid',
    justifyContent: 'center',
    alignItems: 'center',

    // size
    minHeight: 250,
})