import { useCallback, useEffect, useState } from "react";
import { GetCurrentAssessmentResultsResult } from "tech-health-assessment-sdk/dist";
import { service } from "./current-assessment-results.service";

export const useCurrentAssessmentResults = (): {
    currentAssessmentResults: GetCurrentAssessmentResultsResult | null,
    isLoading: boolean,
    refresh: () => void,
} => {

    // state
    const [currentAssessmentResults, setCurrentAssessmentResults] = useState<GetCurrentAssessmentResultsResult | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true)

    // imperative logic
    const retrieveCurrentAssessmentResults = useCallback(async () => {
        setIsLoading(true);
        
        const result = await service.getCurrentAssessmentResults();
        
        setCurrentAssessmentResults(result)
        setIsLoading(false)
    }, [])
    
    // side effects
    useEffect(() => {
        retrieveCurrentAssessmentResults()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // event handlers
    const handleRefresh = useCallback(() => {
        retrieveCurrentAssessmentResults()
    }, [retrieveCurrentAssessmentResults])

    return {
        currentAssessmentResults,
        isLoading,
        refresh: handleRefresh,
    }
}