import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString } from "class-validator";
import { AccountTypeCode } from "../../../../common/codes.types";

export class AdminCreateAccountPayload {
    @IsString()
    @IsNotEmpty()
    accountTypeCode: AccountTypeCode;

    @IsString()
    @IsNotEmpty()
    firstName: string;
  
    @IsString()
    @IsNotEmpty()
    lastName: string;
  
    @IsString()
    @IsEmail()
    emailAddress: string;
  
    @IsString()
    @IsPhoneNumber()
    phoneNumber: string;
  
    @IsString()
    @IsNotEmpty()
    jobTitle: string;
  
    @IsString()
    @IsNotEmpty()
    companyName: string;
}