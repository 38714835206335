import styled from "@emotion/styled";
import { AppColors } from "../../../common/constants/colors/app-colors";

export const AppShellContainer = styled.div({
    // layout
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: AppColors.background.blue.primary,

    // size
    minHeight: '100vh',
})