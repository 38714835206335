import styled from "@emotion/styled"
import { AppColors } from "../../constants/colors/app-colors"
import { BorderRadius, Gap, Padding } from "../sizes/sizes"

export const CalloutCardButtonElement = styled.button<{
    color: keyof Pick<typeof AppColors.notifications, 'blue' | 'orange' | 'green' | 'red'>
}>(({ color }) => ({
    // layout
    display: 'flex',
    alignItems: 'center',
    gap: Gap.desktop.small,

    // colors
    color: AppColors.notifications[color].text,
    backgroundColor: AppColors.notifications[color].background.primary,

    // font
    fontSize: 16,
    fontWeight: 600,

    // border
    border: 'none',
    borderRadius: BorderRadius.small,

    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // cursor
    cursor: 'pointer',

    // hover
    ":hover": {
        backgroundColor: AppColors.notifications[color].background.hover,
    },

    // active
    ":active": {
        backgroundColor: AppColors.notifications[color].background.active,
    },

    // disabled
    ':disabled': {
        opacity: 0.5,
        cursor: 'not-allowed',
    },
}))