import { DateTime } from "luxon";
import { GetAdminAccountsResult_Account } from "tech-health-assessment-sdk/dist";
import { DateCell } from "../../../../common/grid/cell-renderers/date-cell";
import { EmailCell } from "../../../../common/grid/cell-renderers/email-cell";
import { LinkCell } from "../../../../common/grid/cell-renderers/link-cell";
import { PhoneCell } from "../../../../common/grid/cell-renderers/phone-cell";
import { GridColumnDefinition } from "../../../../common/grid/grid.types";

export const columns: GridColumnDefinition<GetAdminAccountsResult_Account>[] = [
    {
        Header: 'Company Name',
        accessor: 'companyName',
        Cell: LinkCell({
            link: ({ row }) => (`/admin/accounts/${row.original.accountId}`),
        })
    },
    {
        Header: 'Account Type',
        accessor: 'accountType',
    },
    {
        Header: 'Assessment Status',
        accessor: 'assessment.assessmentStatus',
    },
    {
        Header: 'Contact Name',
        accessor: 'contact.name',
    },
    {
        Header: 'Contact Title',
        accessor: 'contact.jobTitle',
    },
    {
        Header: 'Contact Email',
        accessor: 'contact.emailAddress',
        Cell: EmailCell(),
    },
    {
        Header: 'Contact Phone',
        accessor: 'contact.phoneNumber',
        Cell: PhoneCell(),
    },
    {
        Header: 'Created At',
        accessor: 'createdAt',
        Cell: DateCell({
            datetimeFormatOptions: DateTime.DATETIME_MED,
        }),
    }
]