import { IsNotEmpty, IsEmail, IsString, IsPhoneNumber, Validate, Contains, Matches, MinLength } from 'class-validator';

export class SignUpPayload {
  @IsString()
  @IsNotEmpty()
  firstName: string;

  @IsString()
  @IsNotEmpty()
  lastName: string;

  @IsString()
  @IsEmail()
  emailAddress: string;

  @IsString()
  @IsPhoneNumber()
  phoneNumber: string;

  @IsString()
  @IsNotEmpty()
  jobTitle: string;

  @IsString()
  @IsNotEmpty()
  companyName: string;
}
