import { FC, useCallback, useContext, useState } from "react";
import { AdminConvertLeadResult } from "tech-health-assessment-sdk/dist";
import { PrimaryButton, SecondaryButton } from "../../../../common/components/buttons/buttons";
import { CalloutCard } from "../../../../common/components/callout-card/callout-card";
import { Dialog, DialogProps } from "../../../../common/components/dialog/dialog";
import { DialogCard, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "../../../../common/components/dialog/dialog.styled";
import { LoadingSpinner } from "../../../../common/components/loading-spinner/loading-spinner";
import { SnackbarContext } from "../../../../common/components/snackbar-provider/snackbar-provider";
import { StandardDialogInnerContainer } from "../../../../common/components/standard-dialog-inner-container/standard-dialog-inner-container";
import { AppColors } from "../../../../common/constants/colors/app-colors";
import { service } from "./convert-to-client-dialog.service";

type ConvertToClientDialogProps = {
    accountId: string
    onRefresh: () => void
} & Omit<DialogProps, 'children'>

export const ConvertToClientDialog: FC<ConvertToClientDialogProps> = ({
    accountId,
    isOpen,
    onClose,
    onRefresh,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // event handlers
    const handleConvertButtonClick = useCallback(async () => {
        setIsLoading(true)

        let result: AdminConvertLeadResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.convertLeadToClient(accountId)
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully converted lead to client',
            })
        }

        setIsLoading(false)
        onRefresh()
        onClose()
    }, [accountId, onClose, onRefresh, showSnackbar])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <DialogCard isOpen={isOpen}>
                <StandardDialogInnerContainer>
                    <DialogHeader>
                        <DialogTitle>
                            Convert Lead to Client?
                        </DialogTitle>
                        <DialogDescription>
                            This will create and populate a dedicated Smartsheet assessment workspace and prepare the assessment for the client.
                        </DialogDescription>
                    </DialogHeader>
                    <CalloutCard>
                        <h2>What will happen?</h2>
                        <br />
                        <span>If you proceed, we will...</span>
                        <ul>
                            <li>Archive the client's current lead assessment. You will still be able to view it but the client will not.</li>
                            <li>Set up a new dedicated smartsheet workspace for this account</li>
                            <li>Create a new assessment for this account using the "client" template</li>
                            <li>Temporarily block the user from accessing their assessment until you finish setting it up</li>
                        </ul>
                    </CalloutCard>
                    <DialogFooter>
                        <SecondaryButton
                            disabled={isLoading}
                            onClick={onClose}
                        >
                            Cancel
                        </SecondaryButton>
                        <PrimaryButton
                            disabled={isLoading}
                            onClick={handleConvertButtonClick}
                        >
                            {isLoading && (
                                <LoadingSpinner
                                    size={14}
                                    color={AppColors.text.onDarkBackground}
                                />
                            )}
                            Convert Lead to Client
                        </PrimaryButton>
                    </DialogFooter>
                </StandardDialogInnerContainer>
            </DialogCard>
        </Dialog>
    )
}