/* eslint-disable react-hooks/rules-of-hooks */
import { useCallback, useContext, useMemo, useState } from "react";
import ReactSelect, { OnChangeValue } from "react-select";
import { CellProps, Renderer } from "react-table";
import { AssessmentStatusCode, GetAdminAssessmentsResult_Assessment, GetAdminAssessmentsResult_AssessmentStatus } from "tech-health-assessment-sdk/dist";
import { PortalContext } from "../../../../../../common/components/portal-provider/portal-provider";
import { ChangeStatusDialog } from "../change-status-dialog/change-status-dialog";

export const AssessmentStatusCell = (onRefresh: () => void): Renderer<CellProps<GetAdminAssessmentsResult_Assessment, GetAdminAssessmentsResult_AssessmentStatus>> => (
    ({
        row,
        value,
        column,
    }) => {
    
        // context
        const { portalTargetRef } = useContext(PortalContext)

        // state
        const [targetStatusCode, setTargetStatusCode] = useState<AssessmentStatusCode | null>(null)
    
        // constants
        const dialogOpen = !!targetStatusCode && targetStatusCode !== value.code;
    
        const allOptions = useMemo(() => ([
            {
                value: 'setup_required',
                label: 'Setup Required',
            },
            {
                value: 'in_progress',
                label: 'In Progress',
            },
            {
                value: 'scoring',
                label: 'Scoring',
            },
            {
                value: 'submitted',
                label: 'Submitted (Scoring Complete)',
            },
        ]), [])
    
        const filteredOptions = useMemo(() => {
            if ((['setup_required', 'in_progress'] as AssessmentStatusCode[]).includes(value.code)) {
                return allOptions.filter(option => ['setup_required', 'in_progress'].includes(option.value))
            }
    
            return []
        }, [allOptions, value.code])
    
        const selectedOption = useMemo(() => (
            allOptions.find(option => option.value === value.code)
        ), [allOptions, value.code])
    
        const targetStatusOption = useMemo(() => (
            (
                allOptions.find(option => option.value === targetStatusCode) as { value: AssessmentStatusCode, label: string }
            ) ?? null
        ), [allOptions, targetStatusCode])
    
        // event handlers
        const handleDialogClose = useCallback(() => {
            setTargetStatusCode(null)
        }, [])
    
        const handleStatusChange = useCallback((option: OnChangeValue<{ value: string, label: string }, false>) => {
            setTargetStatusCode(option ? option?.value as AssessmentStatusCode : null)
        }, [])
    
        if (row.original.assessmentType.code === 'lead' || filteredOptions.length < 1) {
            return <>{value.label}</>;
        }
    
        return (
            <>
                <ReactSelect
                    options={filteredOptions}
                    value={selectedOption}
                    onChange={handleStatusChange}
                    menuPortalTarget={portalTargetRef?.current}
                />
                <ChangeStatusDialog
                    assessmentId={row.original.assessmentId}
                    targetStatusOption={targetStatusOption}
                    isOpen={dialogOpen}
                    onClose={handleDialogClose}
                    onRefresh={onRefresh}
                /> 
            </>
        )
    }
)