import { FC, useMemo } from "react";
import { GetCurrentAssessmentResultsResult_Score } from "tech-health-assessment-sdk/dist";
import { SpanSkeleton, TitleSkeleton } from "../../../common/skeletons/skeletons";
import { ScoreIconCircleSkeleton, ScoreIconCircle, ScoreIconContainer, ScoreNumber, ScoreTextInside, ScoreTextOutside } from "./score-icon.styled";
import { utils } from "./score-icon.utils";

type ScoreIconProps = {
    score: GetCurrentAssessmentResultsResult_Score
    displayMode?: 'large' | 'normal'
}

export const ScoreIcon: FC<ScoreIconProps> = ({
    score,
    displayMode = 'normal',
}) => {

    // constants
    const iconColor = useMemo(() => (
        utils.getColorForScore(score.resultText)
    ), [score.resultText])

    const scoreNumberText = score.resultScore.toFixed(0)

    return (
        <ScoreIconContainer>
            <ScoreIconCircle
                displayMode={displayMode}
                color={iconColor}
            >
                <ScoreNumber displayMode={displayMode}>
                    {scoreNumberText}
                </ScoreNumber>
                {displayMode === 'large' && (
                    <ScoreTextInside>
                        {score.resultText}
                    </ScoreTextInside>
                )}
            </ScoreIconCircle>
            {displayMode === 'normal' && (
                <ScoreTextOutside color={iconColor}>
                    {score.resultText}
                </ScoreTextOutside>
            )}
        </ScoreIconContainer>
    )
}

type ScoreIconSkeletonProps = {
    displayMode?: 'large' | 'normal'
}

export const ScoreIconSkeleton: FC<ScoreIconSkeletonProps> = ({
    displayMode = 'normal',
}) => {
    return (
        <ScoreIconContainer>
            <ScoreIconCircleSkeleton displayMode={displayMode} >
                <TitleSkeleton />
                {displayMode === 'large' && (
                    <SpanSkeleton fontSize={14} />
                )}
            </ScoreIconCircleSkeleton>
            {displayMode === 'normal' && (
                <SpanSkeleton fontSize={14} />
            )}
        </ScoreIconContainer>
    )
}