import { FC, useCallback, useEffect, useState } from "react";
import { Link, LinkProps } from "react-router-dom";
import { SpanSkeleton } from "../../../common/skeletons/skeletons";
import { SectionState } from "../../assessment-sections.types";
import { SectionIcon, SectionIconLoadingState } from "../section-icon/section-icon";
import { SectionNameLabel, SectionGridItemContainer, SectionProgressLabel, SectionTextContainer, SectionGridItemLoadingContainer, SectionTextLoadingContainer } from "./section-grid-item.styled";
import Measure, { ContentRect } from 'react-measure';

type SectionGridItemProps = {
    linkProps: LinkProps,
    sectionName: string
    completionPercentage: number
    iconSvg: string
    displayMode?: 'normal' | 'compact'
    isActive: boolean
    isCurrentSection: boolean
    onScrollIntoView: (position: number) => void
}

export const SectionGridItem: FC<SectionGridItemProps> = ({
    linkProps,
    sectionName,
    completionPercentage,
    iconSvg,
    displayMode = 'normal',
    isActive,
    isCurrentSection,
    onScrollIntoView,
}) => {

    // state
    const [scrollPosition, setScrollPosition] = useState<number>(0)

    // constants
    const sectionState: SectionState = completionPercentage === 1
        ? 'complete'
        : 'in-progress'

    const sectionProgressLabelText = sectionState === 'complete'
        ? 'Section Complete'
        : `${(completionPercentage * 100).toLocaleString('en-US', { maximumFractionDigits: 0, minimumFractionDigits: 0, })}% Complete`

    // const isPopoverOpen = sectionState === 'not-yet-started' && isMouseHovering;

    // side effects
    useEffect(() => {
        if (isActive) {
            onScrollIntoView(scrollPosition)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isActive, scrollPosition])

    // event handlers
    const handleResize = useCallback((contentRect: ContentRect) => {
        const { offset } = contentRect;
        setScrollPosition(offset?.left ?? 0)
    }, [])

    return (
        <Measure offset onResize={handleResize}>
            {({ measureRef }) => (
                <Link
                    ref={measureRef}
                    {...linkProps}
                    style={{ textDecoration: 'none', display: 'grid' }}
                >
                    <SectionGridItemContainer
                        state={sectionState}
                        displayMode={displayMode}
                        isActive={isActive}
                    >
                        <SectionIcon
                            iconSvg={iconSvg}
                            size={60}
                            sectionState={sectionState}
                        />
                        <SectionTextContainer displayMode={displayMode}>
                            <SectionNameLabel>
                                {sectionName}
                            </SectionNameLabel>
                            <SectionProgressLabel>
                                {sectionProgressLabelText}
                            </SectionProgressLabel>
                        </SectionTextContainer>
                    </SectionGridItemContainer>
                </Link>
            )}
        </Measure>
    )
}

type SectionGridItemLoadingStateProps = {
    displayMode: 'normal' | 'compact'
}

export const SectionGridItemLoadingState: FC<SectionGridItemLoadingStateProps> = ({
    displayMode,
}) => {
    return (
        <SectionGridItemLoadingContainer
            displayMode={displayMode}
        >
            <SectionIconLoadingState size={60} />
            <SectionTextLoadingContainer displayMode={displayMode}>
                <SpanSkeleton fontSize={14} />
                <SpanSkeleton fontSize={13} />
            </SectionTextLoadingContainer>
        </SectionGridItemLoadingContainer>
    )
}
