import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AuthenticationFormContainer, AuthenticationFormTitle, AuthenticationFormSubTitle, AuthenticationForm, AuthenticationFormFooter, AuthenticationFooterMessage } from "../../authentication.styled";
import { validate as validateEmail } from 'email-validator'
import { PrimaryButton } from "../../../common/components/buttons/buttons";
import { FormItem } from "../../../common/components/form-item/form-item";
import { InlineLoading } from "../../../common/components/inline-loading/inline-loading";
import { Input } from "../../../common/components/input/input";
import { FormValidationMessage } from "../../../common/components/form-validation-message/form-validation-message";
import { phone } from 'phone'
import { FormItemColumns } from "../../../common/components/form-item-columns/form-item-columns";
import { SnackbarContext } from "../../../common/components/snackbar-provider/snackbar-provider";
import { service } from "./complete-sign-up.service";
import { GetCompleteSignUpScreenDataResult, UserTypeCode } from "tech-health-assessment-sdk/dist";
import { AuthenticationContext } from "../../providers/authentication-provider/authentication-provider";

type CompleteSignUpProps = {
    securityToken: string
}

export const CompleteSignUp: FC<CompleteSignUpProps> = ({
    securityToken,
}) => {

    // router hooks
    const navigate = useNavigate()
    
    // context
    const { refreshAuthenticationState } = useContext(AuthenticationContext)
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [userTypeCode, setUserTypeCode] = useState<UserTypeCode | null>(null)

    // constants
    const formSubtitle = useMemo(() => (
        userTypeCode === 'internal' ? `Finish setting up your account to access the admin portal.` : `Finish setting up your account to access your Technology Health Assessment`
    ), [userTypeCode])

    // form state
    const { register, getValues, handleSubmit, formState, reset } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            jobTitle: '',
            companyName: '',
            emailAddress: '',
            phoneNumber: '',
            password: '',
            verifyPassword: '',
        }
    })

    // imperative logic
    const getCompleteSignUpScreenData = useCallback(async () => {
        setIsLoading(true)

        let result: GetCompleteSignUpScreenDataResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.getCompleteSignUpScreenData({
                securityToken,
            })
        } catch (err) {
            error = err;
        }

        if (!!error || !result) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'It looks like that link isn\'t valid anymore. Please reset your password to continue.',
            })
            navigate('/forgot-password')
            return;
        }

        reset({
            firstName: result.firstName,
            lastName: result.lastName,
            jobTitle: result.jobTitle,
            companyName: result.userType.code === 'internal' ? 'AcquireTek' : result.companyName,
            emailAddress: result.emailAddress,
            phoneNumber: result.phoneNumber,
            password: '',
            verifyPassword: '',
        })

        setUserTypeCode(result.userType.code)

        setIsLoading(false)
    }, [navigate, reset, securityToken, showSnackbar])

    // side effects
    useEffect(() => {
        getCompleteSignUpScreenData()
    }, [getCompleteSignUpScreenData])

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        const formValues = getValues()
        const result = await service.completeSignUp({
            securityToken,
            ...formValues,
            phoneNumber: phone(formValues.phoneNumber).phoneNumber!,
        })

        if (result.success) {
            refreshAuthenticationState()

            let snackbarMessage: string | undefined = undefined;

            if (userTypeCode === 'internal') {
                snackbarMessage = `We've set up your account. You can now access the admin portal.`
            } else {
                snackbarMessage = `We've set up your account. You can now access ${formValues.companyName}'s Tech Health Assessment`
            }

            showSnackbar({
                type: 'success',
                title: 'Welcome!',
                message: snackbarMessage,
            })
        } else {
            setErrorMessage(`Something went wrong. If the issue persists, please contact support.`)
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: `Something went wrong. If the issue persists, please contact support.`,
            })
        }

        setIsLoading(false)
    }, [getValues, refreshAuthenticationState, securityToken, showSnackbar, userTypeCode])

    return (
        <AuthenticationFormContainer>
            <AuthenticationFormTitle>
                Account
            </AuthenticationFormTitle>
            <AuthenticationFormSubTitle>
                {formSubtitle}
            </AuthenticationFormSubTitle>
            <AuthenticationForm
                data-testid="sign-up-form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <FormItemColumns>
                    <FormItem label="Company Name">
                        <>
                            <Input
                                {...register('companyName', {
                                    required: true,
                                })}
                                data-testid="company-name-input"
                                type="text"
                                autoComplete="organization"
                                placeholder="Company Name"
                                disabled={true}
                            />
                            {formState.errors.companyName?.type === 'required' && (
                                <FormValidationMessage data-testid="company-name-missing-warning">
                                    Company Name is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Job Title">
                        <>
                            <Input
                                {...register('jobTitle', {
                                    required: true,
                                })}
                                data-testid="job-title-input"
                                type="text"
                                autoComplete="organization-title"
                                placeholder="Job Title"
                                disabled={isLoading || userTypeCode === 'internal'}
                            />
                            {formState.errors.jobTitle?.type === 'required' && (
                                <FormValidationMessage data-testid="job-title-missing-warning">
                                    Job Title is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                <FormItemColumns>
                    <FormItem label="First Name">
                        <>
                            <Input
                                {...register('firstName', { required: true })}
                                data-testid="first-name-input"
                                type="text"
                                autoComplete="fname"
                                placeholder="First Name"
                                disabled={isLoading}
                            />
                            {formState.errors.firstName?.type === 'required' && (
                                <FormValidationMessage data-testid="first-name-missing-warning">
                                    First name is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Last Name">
                        <>
                            <Input
                                {...register('lastName', { required: true })}
                                data-testid="last-name-input"
                                type="text"
                                autoComplete="lname"
                                placeholder="Last Name"
                                disabled={isLoading}
                            />
                            {formState.errors.lastName?.type === 'required' && (
                                <FormValidationMessage data-testid="last-name-missing-warning">
                                    Last name is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                <FormItemColumns>
                    <FormItem label="Email Address">
                        <>
                            <Input
                                {...register('emailAddress', {
                                    required: true,
                                    validate: validateEmail,
                                })}
                                data-testid="email-address-input"
                                type="email"
                                autoComplete="username"
                                placeholder="Email Address"
                                disabled={isLoading}
                            />
                            {formState.errors.emailAddress?.type === 'required' && (
                                <FormValidationMessage data-testid="email-address-missing-warning">
                                    Email address is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.emailAddress?.type === 'validate' && (
                                <FormValidationMessage data-testid="email-address-invalid-warning">
                                    Please enter a valid email address
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Phone Number">
                        <>
                            <Input
                                {...register('phoneNumber', {
                                    required: true,
                                    validate: (value) => phone(value).isValid,
                                })}
                                data-testid="phone-number-input"
                                type="text"
                                autoComplete="tel"
                                placeholder="Phone Number"
                                disabled={isLoading}
                            />
                            {formState.errors.phoneNumber?.type === 'required' && (
                                <FormValidationMessage data-testid="phone-number-missing-warning">
                                    Phone Number is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.phoneNumber?.type === 'validate' && (
                                <FormValidationMessage data-testid="phone-number-invalid-warning">
                                    Please enter a valid phone number
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                <FormItemColumns>
                    <FormItem label="Password">
                        <>
                            <Input
                                {...register('password', { required: true })}
                                data-testid="password-input"
                                type="password"
                                autoComplete="new-password"
                                placeholder="Password"
                                disabled={isLoading}
                            />
                            {formState.errors.password?.type === 'required' && (
                                <FormValidationMessage data-testid="password-missing-warning">
                                    Password is required
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                    <FormItem label="Re-enter Your Password">
                        <>
                            <Input
                                {...register('verifyPassword', {
                                    required: true,
                                    validate: (verifyPassword) => getValues().password === verifyPassword,
                                })}
                                data-testid="verify-password-input"
                                type="password"
                                autoComplete="new-password"
                                placeholder="Re-enter Your Password"
                                disabled={isLoading}
                            />
                            {formState.errors.verifyPassword?.type === 'required' && (
                                <FormValidationMessage data-testid="verify-password-missing-warning">
                                    Password verification is required
                                </FormValidationMessage>
                            )}
                            {formState.errors.verifyPassword?.type === 'validate' && (
                                <FormValidationMessage data-testid="verify-password-validation-warning">
                                    Passwords must match
                                </FormValidationMessage>
                            )}
                        </>
                    </FormItem>
                </FormItemColumns>
                <AuthenticationFormFooter>
                    <PrimaryButton
                        data-testid="sign-up-button"
                        type="submit"
                        disabled={isLoading}
                    >
                        Complete Account Setup
                    </PrimaryButton>
                    {!!isLoading && (
                        <InlineLoading>
                            Loading...
                        </InlineLoading>
                    )}
                </AuthenticationFormFooter>
                {errorMessage && (
                    <AuthenticationFooterMessage type='error'>
                        {errorMessage}
                    </AuthenticationFooterMessage>
                )}
            </AuthenticationForm>
        </AuthenticationFormContainer>
    )
}