import { useCallback, useEffect, useState } from "react"
import { GetAssessmentResult } from "tech-health-assessment-sdk/dist"
import { getCurrentAssessment } from "./current-assessment-overview.services";

export const useCurrentAssessment = (): {
    currentAssessment: GetAssessmentResult | null,
    isLoading: boolean,
    refresh: () => void,
} => {

    // state
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [currentAssessment, setCurrentAssessment] = useState<GetAssessmentResult | null>(null);

    // imperative logic
    const retrieveCurrentAssessment = useCallback(async () => {
        setIsLoading(true)

        const result = await getCurrentAssessment()
        setCurrentAssessment(result);
        setIsLoading(false)
    }, [])

    // side effects
    useEffect(() => {
        retrieveCurrentAssessment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // event handlers
    const handleRefresh = useCallback(() => {
        retrieveCurrentAssessment()
    }, [retrieveCurrentAssessment])

    return {
        currentAssessment,
        isLoading,
        refresh: handleRefresh,
    }
}