import styled from "@emotion/styled";
import { Gap, Padding } from "../../../../../common/components/sizes/sizes";
import { AppColors } from "../../../../../common/constants/colors/app-colors";

export const SectionResultGridItemContainer = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'max-content 1fr',
    gap: Gap.desktop.normal,
})

export const SectionResultGridItemTextContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'row',
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',
    gap: Gap.desktop.normal,

    // padding
    paddingTop: Padding.desktop.smallest,
})

export const SectionResultTitle = styled.span({
    // text
    fontSize: 18,
    fontWeight: 600,

    // colors
    color: AppColors.text.onLightBackground,
})

export const SectionResultDescription = styled.p({
    color: AppColors.text.onLightBackground,
    whiteSpace: 'pre-wrap',
})