import styled from "@emotion/styled";
import { Gap, Padding } from "../sizes/sizes";

export const StandardDialogInnerContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.large,
    
    // padding
    padding: Padding.desktop.large,

    // size
    boxSizing: 'border-box',
    width: 'calc(100vw - 24px)',
    maxWidth: 640,
})