import { AxiosError } from 'axios';
import { api } from '../../../../services/core-api-service';

export const signIn = async (
  username: string,
  password: string,
): Promise<{ success: boolean; message: string; code: string }> => {
  try {
    await api.auth.signIn({ username, password });
    return {
      success: true,
      code: 'successfully_signed_in',
      message: 'Successfully signed in',
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        return {
          success: false,
          code: 'incorrect_credentials',
          message: "Those credentials don't match what we have on file",
        };
      } else if (error.response?.status === 422) {
        return {
          success: false,
          code: 'leads_credentials',
          message: "We see you have already completed a sample assessment. Please contact us for next steps and quote.",
        };
      }
    }
    return {
      success: false,
      code: 'failed_to_sign_in',
      message: 'Unknown error occurred. Please try again later',
    };
  }
};
