import { IsBoolean, IsEmail, IsObject, IsOptional, IsPhoneNumber, IsString, IsUUID, ValidateIf, ValidateNested } from "class-validator";
import { UserTypeCode } from "../../../../common/codes.types";

export class GetAdminUserResult {
    @IsUUID()
    userId: string;
  
    @IsString()
    firstName: string;
  
    @IsString()
    lastName: string;
  
    @IsEmail()
    emailAddress: string;
  
    @IsPhoneNumber()
    phoneNumber: string;
  
    @IsString()
    @IsOptional()
    jobTitle: string;
  
    @IsString()
    userType: GetAdminUserResult_UserType;

    @IsBoolean()
    isEnabled: boolean;

    @IsObject()
    @ValidateNested()
    @ValidateIf((object, value) => value !== null)
    account: GetAdminUserResult_Account | null;
}

export class GetAdminUserResult_Account {
    @IsUUID()
    accountId: string;

    @IsString()
    companyName: string;

    @IsString()
    accountType: string;
}

export class GetAdminUserResult_UserType {
    @IsUUID()
    userTypeId: string;

    @IsString()
    code: UserTypeCode;

    @IsString()
    label: string;
}