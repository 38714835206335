import styled from "@emotion/styled";
import { SkeletonStyles } from "../../constants/animations/animations";
import { AppColors } from "../../constants/colors/app-colors";
import { BorderRadius, Padding } from "../sizes/sizes";

export const CalloutCardContainer = styled.div<{
    color: keyof Pick<typeof AppColors.notifications, 'blue' | 'orange' | 'green' | 'red'>
}>(({ color }) => ({
    // colors
    color: AppColors.text.onDarkBackground,
    backgroundColor: AppColors.notifications[color].text,

    // padding
    padding: Padding.desktop.normal,

    // border
    borderRadius: BorderRadius.small,
}))

export const CalloutCardContainerSkeleton = styled.div({
    // colors
    color: AppColors.text.onDarkBackground,

    // padding
    padding: Padding.desktop.normal,

    // border
    borderRadius: BorderRadius.small,

    // skeleton styles
    ...SkeletonStyles(),
})