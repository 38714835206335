import { IsArray, IsBoolean, IsDate, IsEmail, IsObject, IsPhoneNumber, IsString, IsUUID, ValidateNested } from "class-validator";
import { PaginatedResult } from "../../../common";

export class GetAdminUsersResult extends PaginatedResult {
    @IsArray()
    @ValidateNested()
    users: GetAdminUsersResult_User[];
}

export class GetAdminUsersResult_User {
    @IsUUID()
    userId: string;

    @IsString()
    name: string;

    @IsString()
    jobTitle: string;

    @IsEmail()
    emailAddress: string;

    @IsPhoneNumber()
    phoneNumber: string;

    @IsString()
    userType: string;
    
    @IsObject()
    @ValidateNested()
    account: GetAdminUsersResult_Account | null;

    @IsBoolean()
    isEnabled: boolean;

    @IsDate()
    createdAt: Date;
}

export class GetAdminUsersResult_Account {
    @IsUUID()
    accountId: string;

    @IsString()
    companyName: string;
}