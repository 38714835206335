import { FC, useCallback } from "react";
import { AssessmentResults, AssessmentResultsLoadingState } from "../assessment-results/assessment-results";
import { useCurrentAssessmentResults } from "./current-assessment-results.hooks";

export const CurrentAssessmentResults: FC = () => {

    // data hooks
    const { currentAssessmentResults, isLoading, refresh } = useCurrentAssessmentResults()

    // event handlers
    const handleRefreshData = useCallback(() => {
        refresh()
    }, [refresh])

    if (!currentAssessmentResults || isLoading) {
        return (
            <AssessmentResultsLoadingState />
        )
    }

    return (
        <AssessmentResults
            assessmentResults={currentAssessmentResults}
            onRefreshData={handleRefreshData}
        />
    )
}