import { FC, useCallback, useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { AdminUpdateAccountResult, GetAdminAccountResult } from "tech-health-assessment-sdk/dist";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { PrimaryButton } from "../../../../common/components/buttons/buttons";
import { FormItem } from "../../../../common/components/form-item/form-item";
import { FormValidationMessage } from "../../../../common/components/form-validation-message/form-validation-message";
import { Input } from "../../../../common/components/input/input";
import { LoadingSpinner } from "../../../../common/components/loading-spinner/loading-spinner";
import { SnackbarContext } from "../../../../common/components/snackbar-provider/snackbar-provider";
import { StandardCardContentContainer } from "../../../../common/components/standard-card-content-container/standard-card-content-container";
import { StandardCardHeader, StandardCardHeaderLoadingState } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { AppColors } from "../../../../common/constants/colors/app-colors";
import { service } from "./admin-account-card.service";
import { AccountDetailsForm, FooterContainer, FormItems } from "./admin-account-card.styled";
import { ConvertToClientButton } from "./subcomponents/convert-to-client-button/convert-to-client-button";

type AdminAccountCardProps = {
    account: GetAdminAccountResult
    onRefresh: () => void
}

export const AdminAccountCard: FC<AdminAccountCardProps> = ({
    account,
    onRefresh,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { control, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm({
        defaultValues: {
            companyName: account.companyName,
        },
    })

    // constants
    const actions = useMemo(() => (
        account.accountType.code === 'lead' ? (
            <ConvertToClientButton
                accountId={account.accountId}
                onRefresh={onRefresh}
            />
        ) : ([])
    ), [account.accountId, account.accountType.code, onRefresh])

    // event handlers
    const handleSave = useCallback(async () => {
        setIsLoading(true)

        const values = getValues()

        let result: AdminUpdateAccountResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.updateAccount(account.accountId, {
                companyName: values.companyName,
            })
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully updated account'
            })
        }

        setIsLoading(false)
        onRefresh()
    }, [account.accountId, getValues, onRefresh, showSnackbar])

    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeader
                    title={account.companyName}
                    subtitle={`${account.accountType.label} Account`}
                    actions={actions}
                />
                <StandardCardContentContainer>
                    <AccountDetailsForm onSubmit={handleSubmit(handleSave)}>
                        <FormItems>
                            <FormItem label="Company Name">
                                <>
                                    <Controller
                                        control={control}
                                        name="companyName"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Company Name"
                                                disabled={isLoading}
                                            />
                                        )}
                                    />
                                    {errors.companyName?.type === 'required' && (
                                        <FormValidationMessage data-testid="company-name-missing-warning">
                                            Company Name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItems>
                        <FooterContainer>
                            <PrimaryButton
                                disabled={!isDirty || !isValid || isLoading}
                                type="submit"
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Save Changes
                            </PrimaryButton>
                        </FooterContainer>
                    </AccountDetailsForm>
                </StandardCardContentContainer>
            </StandardCardLayout>
        </BodyCard>
    )
}

export const AdminAccountCardLoadingState: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeaderLoadingState
                    hasSubtitle={true}
                />
            </StandardCardLayout>
        </BodyCard>
    )
}