import { FC } from "react";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { AdminEmailTemplateCard, AdminEmailTemplateCardLoadingState } from "../../details-cards/admin-email-template-card/admin-email-template-card";
import { useAdminEmailTemplate } from "./admin-email-template.hooks";

type AdminEmailTemplateProps = {
    emailTemplateId: string
}

export const AdminEmailTemplate: FC<AdminEmailTemplateProps> = ({
    emailTemplateId,
}) => {

    // data hooks
    const { data: adminEmailTemplate, isLoading, refreshData } = useAdminEmailTemplate(emailTemplateId)

    if (isLoading || !adminEmailTemplate) {
        return (
            <BodyContainer>
                <AdminEmailTemplateCardLoadingState />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <AdminEmailTemplateCard
                emailTemplate={adminEmailTemplate}
                onRefresh={refreshData}
            />
        </BodyContainer>
    )
}