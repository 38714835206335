import { CoreAPI } from "../core";
import { AdminConvertLeadPayload, AdminConvertLeadResult, AdminCreateAccountPayload, AdminCreateAccountResult, AdminCreateUserPayload, AdminCreateUserResult, AdminDisableUserPayload, AdminDisableUserResult, AdminEnableUserPayload, AdminEnableUserResult, AdminResetUserPasswordPayload, AdminResetUserPasswordResult, AdminUpdateAccountPayload, AdminUpdateAccountResult, AdminUpdateAssessmentStatusPayload, AdminUpdateAssessmentStatusResult, AdminUpdateUserPayload, AdminUpdateUserResult, GetAccountTypesResult, GetAdminAccountResult, GetAdminAccountsRequest, GetAdminAccountsResult, GetAdminAssessmentResult, GetAdminAssessmentsRequest, GetAdminAssessmentsResult, GetAdminEmailTemplatesRequest, GetAdminEmailTemplatesResult, GetAdminResultsResult, GetAdminSectionResult, GetAdminUserResult, GetAdminUsersResult, GetEmailTemplateResult, GetUserTypesResult, SearchAccountsPayload, SearchAccountsResult, UpdateEmailTemplatePayload, UpdateEmailTemplateResult } from "./dtos";

export class AdminAPI {
  private basePath: string;

  constructor(private readonly coreApi: CoreAPI) {
    this.basePath = `${this.coreApi.apiUrl}/admin`;
  }

  public async getAccounts(request: GetAdminAccountsRequest): Promise<GetAdminAccountsResult> {
    const result = await this.coreApi.get(`${this.basePath}/accounts`, request)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminAccountsResult;

    return resultData;
  }

  public async getAssessments(request: GetAdminAssessmentsRequest): Promise<GetAdminAssessmentsResult> {
    const result = await this.coreApi.get(`${this.basePath}/assessments`, request)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminAssessmentsResult;

    return resultData;
  }

  public async getUsers(request: GetAdminAssessmentsRequest): Promise<GetAdminUsersResult> {
    const result = await this.coreApi.get(`${this.basePath}/users`, request)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminUsersResult;

    return resultData;
  }

  public async getAccount(accountId: string): Promise<GetAdminAccountResult> {
    const result = await this.coreApi.get(`${this.basePath}/accounts/${accountId}`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminAccountResult;

    return resultData;
  }

  public async getAssessment(assessmentId: string): Promise<GetAdminAssessmentResult> {
    const result = await this.coreApi.get(`${this.basePath}/assessments/${assessmentId}`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminAssessmentResult;

    return resultData;
  }

  public async getUser(userId: string): Promise<GetAdminUserResult> {
    const result = await this.coreApi.get(`${this.basePath}/users/${userId}`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminUserResult;

    return resultData;
  }

  public async getAssessmentResults(assessmentId: string): Promise<GetAdminResultsResult> {
    const result = await this.coreApi.get(`${this.basePath}/assessments/${assessmentId}/results`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminResultsResult;

    return resultData;
  }

  public async getSection(sectionId: string): Promise<GetAdminSectionResult> {
    const result = await this.coreApi.get(`${this.basePath}/sections/${sectionId}`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminSectionResult;

    return resultData;
  }

  public async updateAccount(accountId: string, payload: AdminUpdateAccountPayload): Promise<AdminUpdateAccountResult> {
    const result = await this.coreApi.post(`${this.basePath}/accounts/${accountId}/update`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminUpdateAccountResult;

    return resultData;
  }

  public async updateUser(userId: string, payload: AdminUpdateUserPayload): Promise<AdminUpdateUserResult> {
    const result = await this.coreApi.post(`${this.basePath}/users/${userId}/update`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminUpdateUserResult;

    return resultData;
  }

  public async disableUser(userId: string, payload: AdminDisableUserPayload): Promise<AdminDisableUserResult> {
    const result = await this.coreApi.post(`${this.basePath}/users/${userId}/disable`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminDisableUserResult;

    return resultData;
  }

  public async enableUser(userId: string, payload: AdminEnableUserPayload): Promise<AdminEnableUserResult> {
    const result = await this.coreApi.post(`${this.basePath}/users/${userId}/enable`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminEnableUserResult;

    return resultData;
  }

  public async resetUserPassword(userId: string, payload: AdminResetUserPasswordPayload): Promise<AdminResetUserPasswordResult> {
    const result = await this.coreApi.post(`${this.basePath}/users/${userId}/resetPassword`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminResetUserPasswordResult;

    return resultData;
  }

  public async convertLeadToClient(accountId: string, payload: AdminConvertLeadPayload): Promise<AdminConvertLeadResult> {
    const result = await this.coreApi.post(`${this.basePath}/accounts/${accountId}/convertLeadToClient`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminConvertLeadResult;

    return resultData;
  }

  public async updateAssessmentStatus(assessmentId: string, payload: AdminUpdateAssessmentStatusPayload): Promise<AdminUpdateAssessmentStatusResult> {
    const result = await this.coreApi.post(`${this.basePath}/assessments/${assessmentId}/updateStatus`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminUpdateAssessmentStatusResult;

    return resultData;
  }

  public async getAccountTypes(): Promise<GetAccountTypesResult> {
    const result = await this.coreApi.get(`${this.basePath}/accountTypes`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAccountTypesResult;

    return resultData;
  }

  public async getUserTypes(): Promise<GetUserTypesResult> {
    const result = await this.coreApi.get(`${this.basePath}/userTypes`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetUserTypesResult;

    return resultData;
  }

  public async createAccount(payload: AdminCreateAccountPayload): Promise<AdminCreateAccountResult> {
    const result = await this.coreApi.post(`${this.basePath}/createAccount`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminCreateAccountResult;

    return resultData;
  }

  public async createUser(payload: AdminCreateUserPayload): Promise<AdminCreateUserResult> {
    const result = await this.coreApi.post(`${this.basePath}/createUser`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as AdminCreateUserResult;

    return resultData;
  }

  public async searchAccounts(payload: SearchAccountsPayload): Promise<SearchAccountsResult> {
    const result = await this.coreApi.post(`${this.basePath}/searchAccounts`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as SearchAccountsResult;

    return resultData;
  }

  public async getEmailTemplates(request: GetAdminEmailTemplatesRequest): Promise<GetAdminEmailTemplatesResult> {
    const result = await this.coreApi.get(`${this.basePath}/emailTemplates`, request)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetAdminEmailTemplatesResult;

    return resultData;
  }

  public async getEmailTemplate(emailTemplateId: string): Promise<GetEmailTemplateResult> {
    const result = await this.coreApi.get(`${this.basePath}/emailTemplates/${emailTemplateId}`)

    if (!result?.data) throw new Error();

    const resultData = result.data as GetEmailTemplateResult;

    return resultData;
  }

  public async updateEmailTemplate(emailTemplateId: string, payload: UpdateEmailTemplatePayload): Promise<UpdateEmailTemplateResult> {
    const result = await this.coreApi.post(`${this.basePath}/emailTemplates/${emailTemplateId}/update`, payload)

    if (!result?.data) throw new Error();

    const resultData = result.data as UpdateEmailTemplateResult;

    return resultData;
  }
}