import { keyframes } from '@emotion/react'
import { Properties } from 'csstype';
import { AppColors } from '../colors/app-colors';

const darkSkeletonAnimation = keyframes({
    from: {
        opacity: 0.05,
    },
    to: {
        opacity: 0.12,
    }
})

const lightSkeletonAnimation = keyframes({
    from: {
        opacity: 0.40,
    },
    to: {
        opacity: 0.50,
    }
})

export const SkeletonStyles = (background: 'light' | 'dark' = 'light'): Properties => ({
    // color
    color: 'transparent',
    backgroundColor: background === 'light' ? AppColors.background.dark : AppColors.background.light.white,

    // interaction
    userSelect: 'none',

    // animation
    animationName: background === 'light' ? darkSkeletonAnimation : lightSkeletonAnimation,
    animationDuration: '700ms',
    animationFillMode: 'both',
    animationIterationCount: 'infinite',
    animationDirection: 'alternate',
    animationTimingFunction: 'ease-out',
})