import { FC } from "react";
import { useParams } from "react-router";
import { CurrentAssessmentOverview } from "../../modules/assessments/components/current-assessment-overview/current-assessment-overview";

export const AssessmentRoute: FC = () => {

    // router hooks
    const { section_id: selectedSectionId } = useParams() as { section_id?: string }

    return (
        <CurrentAssessmentOverview
            activeSectionId={selectedSectionId ?? null}
        />
    )
}