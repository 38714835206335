import { sum } from "lodash";

type ProgressSubscriber = (progress: number, complete: boolean) => void
type Unsubscribe = () => void

export class ProgressAnimation {
    private _keyframes: number[]
    private progress: number = 0;

    constructor(resolution: number, private readonly duration: number) {
        this._keyframes = this.generateKeyframes(resolution)
    }

    // TODO: this algorithm doesn't actually work great - should probably fix at some point 🤷🏼‍♂️
    private generateKeyframes(resolution: number): number[] {
        const keyframes: number[] = []
        for (var i = 0; i < resolution - 1; i++) {
            const keyframe = Math.min(
                Math.max(
                    this.round(Math.random() * (1 - sum(keyframes)), 2),
                    0.02,
                ),
                0.05
            )
            keyframes.push(keyframe)
        }

        if (sum(keyframes) < 1) {
            keyframes.push(1 - sum(keyframes))
        }

        keyframes.sort(() => Math.random() - Math.random())

        return keyframes;
    }

    private round(number: number, decimalPlaces: number): number {
        return parseFloat(number.toFixed(decimalPlaces));
    }

    subscribers: ProgressSubscriber[] = [];
    public subscribe(subscriber: ProgressSubscriber): Unsubscribe {
        this.subscribers.push(subscriber)
        return () => {
            this.subscribers = this.subscribers.filter(filterSubscriber => (
                filterSubscriber !== subscriber
            ));
        }
    }

    public startAnimation = () => {
        const firstKeyframe = this._keyframes.shift()
        this.executeKeyframe(firstKeyframe)
    }

    private executeKeyframe = (keyframe: number | undefined) => {
        this.reportProgress(Math.min(this.progress, 1), this.progress >= 1)
        if (!keyframe) return;
        if (this.progress >= 1) return;
        setTimeout(() => {
            this.progress += keyframe;
            const nextKeyframe = this._keyframes.shift()
            this.executeKeyframe(nextKeyframe);
        }, keyframe * this.duration);
    }

    private reportProgress(progress: number, complete: boolean) {
        this.subscribers.forEach(subscriber => {
            subscriber(progress, complete)
        })
    }
}

export const getCurrentActivityText = (progress: number): string => {
    if (progress < 0.35) {
        return 'Analyzing responses'
    }

    if (progress < 0.95) {
        return 'Scoring assessment'
    }

    return 'Formatting report'
}