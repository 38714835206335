import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";

export const Title = styled.h1<{
    color?: string
}>(({ color }) => ({
    // colors
    color: color ?? AppColors.text.blue.primary,

    // font
    fontSize: 36,

    // margin
    margin: 0,
}))