import { FC, useCallback } from "react";
import { useNavigate } from "react-router";
import { CalloutCardButton } from "../../../../../common/components/callout-card-button/callout-card-button";
import { CalloutCard } from "../../../../../common/components/callout-card/callout-card";
import { CardFooter, CardHeader, InnerContainer, ReadyToSubmitSectionContainer } from "./assessment-complete-card.styled";

type AssessmentCompleteCardProps = {
    assessmentId: string
}

export const AssessmentCompleteCard: FC<AssessmentCompleteCardProps> = ({
    assessmentId,
}) => {

    // router hooks
    const navigate = useNavigate()

    // event handlers
    const handleClick = useCallback(() => {
        navigate(`/submit-assessment/${assessmentId}`)
    }, [assessmentId, navigate])

    return (
        <ReadyToSubmitSectionContainer>
            <CalloutCard type="success">
                <InnerContainer>
                    <CardHeader>
                        <h2>Ready to Submit?</h2>
                        <span>
                            When you're ready, you can submit your assessment and receive your results.
                        </span>
                    </CardHeader>
                    <CardFooter>
                        <CalloutCardButton onClick={handleClick}>
                            Submit Assessment
                        </CalloutCardButton>
                    </CardFooter>
                </InnerContainer>
            </CalloutCard>
        </ReadyToSubmitSectionContainer>
    )
}