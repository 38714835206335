import {
  FooterLinkSection,
  FooterSectionContainer,
  FooterTHAContainer,
  LogoImage,
  LogoSection,
  THAImage,
} from './landing.styled';

import { FC } from 'react';

import { MAIN_LOGO, TECH_ASSESSMENT_SHOT, FOOTER_URL } from '../../../../modules/common/environment/environment-constants'

export const LandingFooterSection: FC = () => {
  return (
    <FooterSectionContainer>
      <LogoSection>
        <LogoImage src={MAIN_LOGO} alt="Griffin Logo" />
        <FooterLinkSection>
          <a
            href={FOOTER_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {FOOTER_URL}
          </a>
          <span> | </span>
          <span>(678)261-8289</span>
        </FooterLinkSection>
      </LogoSection>
      <FooterTHAContainer>
        <THAImage
          src={TECH_ASSESSMENT_SHOT}
          alt="Tech Health Assessment Report"
        />
      </FooterTHAContainer>
    </FooterSectionContainer>
  );
};
