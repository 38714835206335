import { createContext, FC, useCallback, useMemo, useState } from "react";
import { v4 } from "uuid";
import { Portal } from "../portal/portal";
import { SnackbarsContainer } from "./snackbar-provider.styled";
import { SnackbarItem } from "./snackbar-provider.types";
import { Snackbar } from "./subcomponents/snackbar/snackbar";

type SnackbarProviderProps = {
    children: JSX.Element
}

export const SnackbarProvider: FC<SnackbarProviderProps> = ({
    children,
}) => {

    // state
    const [snackbars, setSnackbars] = useState<{ id: string, snackbarItem: Required<SnackbarItem>, show: boolean }[]>([])

    // constants
    const hasSnackbars = !!snackbars.length
    const snackbarsReversed = useMemo(() => (
        [...snackbars].reverse()
    ), [snackbars])

    // imperative logic
    const removeSnackbar = useCallback((snackbarId: string) => {
        // hide the snackbar
        setSnackbars(oldSnackbars => {
            const oldSnackbar = oldSnackbars
                .find(oldSnackbar => (
                    oldSnackbar.id === snackbarId
                ))

            if (!oldSnackbar) return oldSnackbars

            const snackbarIndex = oldSnackbars.indexOf(oldSnackbar)

            const newSnackbars = [...oldSnackbars]
            newSnackbars.splice(snackbarIndex, 1, {
                ...oldSnackbar,
                show: false,
            })

            return newSnackbars
        })
        
        // destroy the snackbar (giving time for the animation to complete)
        setTimeout(() => {
            setSnackbars(oldSnackbars => (
                oldSnackbars.filter(oldSnackbar => (
                    oldSnackbar.id !== snackbarId
                ))
            ))
        }, 500);
    }, [])

    const showSnackbar = useCallback((snackbarItem: SnackbarItem) => {
        const snackbarId = v4();

        const newSnackbarItem: Required<SnackbarItem> = {
            ...snackbarItem,
            duration: snackbarItem.duration ?? 5000,
            type: snackbarItem.type ?? 'info',
            message: snackbarItem.message ?? null,
        }

        setSnackbars(oldSnackbars => ([
            ...oldSnackbars, {
                id: snackbarId,
                show: true,
                snackbarItem: newSnackbarItem,
            }
        ]))

        if (newSnackbarItem.duration > -1) {
            setTimeout(() => {
                removeSnackbar(snackbarId)
            }, newSnackbarItem.duration + 200);
        }

        return () => {
            removeSnackbar(snackbarId)
        }
    }, [removeSnackbar])

    // event handlers
    const handleDismiss = useCallback((snackbarId: string) => {
        removeSnackbar(snackbarId)
    }, [removeSnackbar])

    return (
        <SnackbarContext.Provider value={{
            showSnackbar,
        }}>
            {children}
            {hasSnackbars && (
                <Portal>
                    <SnackbarsContainer>
                        {snackbarsReversed.map((snackbar) => (
                            <Snackbar
                                key={snackbar.id}
                                id={snackbar.id}
                                snackbarItem={snackbar.snackbarItem}
                                show={snackbar.show}
                                onDismiss={handleDismiss}
                            />
                        ))}
                    </SnackbarsContainer>
                </Portal>
            )}
        </SnackbarContext.Provider>
    )
}

type SnackbarContextType = {
    showSnackbar: (snackbarItem: SnackbarItem) => (() => void)
}

export const SnackbarContext = createContext<SnackbarContextType>({
    showSnackbar: () => (() => {})
})