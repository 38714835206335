import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { AppColors } from "../../../../constants/colors/app-colors";
import { Shadows } from "../../../../constants/shadows/shadows";
import { BorderRadius, Padding } from "../../../sizes/sizes";

const SnackbarAppearKeyframes = keyframes({
    '0%': {
        transform: 'translateY(-12px) scale(0.9)',
        opacity: 0,
    },
    '33%': {
        transform: 'scale(0.93)',
    },
    '100%': {
        transform: 'translate(0px) scale(1)',
        opacity: 1,
    }
})

const SnackbarOuterContainerKeyframes = (snackbarHeight: number) => (
    keyframes({
        from: {
            height: 0,
        },
        to: {
            height: snackbarHeight + Padding.desktop.normal,
        },
    })
)

export const SnackbarOuterContainer = styled.div<{
    show: boolean,
    snackbarHeight: number,
}>(({ show, snackbarHeight, }) => ({
    // size
    height: show ? snackbarHeight + Padding.desktop.normal : 0,

    // animation
    animation: `${SnackbarOuterContainerKeyframes(snackbarHeight)} 200ms ease`,

    // transition
    transition: 'all 200ms ease',
}))

export const SnackbarContainer = styled.div<{
    color: keyof Pick<typeof AppColors.notifications, 'blue' | 'orange' | 'green' | 'red'>,
    show: boolean,
}>(({ color, show }) => ({
    // position
    position: 'relative',

    // layout
    display: 'grid',
    gridTemplateColumns: '1fr max-content',
    alignItems: 'center',

    // colors
    color: AppColors.text.onDarkBackground,
    backgroundColor: AppColors.notifications[color].text,
    
    // padding
    paddingLeft: Padding.desktop.normal,
    paddingRight: Padding.desktop.normal,
    paddingTop: Padding.desktop.small,
    paddingBottom: Padding.desktop.small,

    // border
    borderRadius: BorderRadius.normal,

    // shadow
    boxShadow: Shadows.snackbar,

    // overflow
    overflow: 'hidden',

    // animation
    animation: `${SnackbarAppearKeyframes} 400ms ease`,
    animationFillMode: 'backwards',

    // transition
    transition: 'all 200ms ease',
    transform: show ? 'scale(1) translateY(0px)' : 'scale(0.75) translateY(-12px)',
    opacity: show ? 1 : 0,
}))

export const SnackbarTextContainer = styled.div({

})

export const SnackbarTitle = styled.div({
    fontSize: 16,
    fontWeight: 600,
})

export const SnackbarMessage = styled.p()

const SnackbarDurationIndicatorKeyframes = keyframes({
    from: {
        width: '100%',
    },
    to: {
        width: '0%',
    },
})

export const SnackbarDurationIndicator = styled.div<{
    duration: number
}>(({ duration }) => ({
    // position
    position: 'absolute',
    bottom: 0,

    // size
    height: 4,

    // colors
    backgroundColor: 'white',

    // animation
    animation: `${SnackbarDurationIndicatorKeyframes} ${duration}ms linear`
}))

export const Hidden = styled.div({
    opacity: 0,
    pointerEvents: 'none',
})