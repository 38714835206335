import { IsBoolean, IsObject, IsOptional, IsUUID, ValidateNested } from "class-validator";

export class AdminCreateAccountResult {
    @IsBoolean()
    success: boolean;

    @IsObject()
    @IsOptional()
    @ValidateNested()
    account?: AdminCreateAccountResult_Account;

    @IsObject()
    @IsOptional()
    @ValidateNested()
    user?: AdminCreateAccountResult_User;
}

export class AdminCreateAccountResult_Account {
    @IsUUID()
    accountId: string;
}

export class AdminCreateAccountResult_User {
    @IsUUID()
    userId: string;
}