import { IsArray, IsString, IsUUID, ValidateNested } from "class-validator";
import { PaginatedResult } from "../../../common";

export class GetAdminEmailTemplatesResult extends PaginatedResult {
    @IsArray()
    @ValidateNested()
    emailTemplates: GetAdminEmailTemplatesResult_EmailTemplate[]
}

export class GetAdminEmailTemplatesResult_EmailTemplate {
    @IsUUID()
    emailTemplateId: string;

    @IsString()
    label: string;

    @IsString()
    description: string;

    @IsString()
    userType: string;
}