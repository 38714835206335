import styled from "@emotion/styled"
import { Gap, Padding } from "../../../../../common/components/sizes/sizes"
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../../../../common/constants/breakpoints/breakpoints"
import { AppColors } from "../../../../../common/constants/colors/app-colors"

export const PreparingAssessmentContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '0.75fr 1fr',
    gap: Gap.desktop.normal,

    // alignment
    alignItems: 'center',

    // padding
    padding: Padding.desktop.large,

    // breakpoints
    [TABLET_BREAKPOINT]: {
        // layout
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content max-content',

        // padding
        padding: Padding.tablet.large,

        // gap
        gap: Gap.tablet.normal,
    },

    [MOBILE_BREAKPOINT]: {
        padding: Padding.mobile.large,
        gap: Gap.mobile.normal,
    },
})

export const PreparingDetailsContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.smaller,
})

export const PreparingDescriptionText = styled.span({
    fontSize: 30,
    color: AppColors.text.gray,
    paddingBottom: Padding.desktop.normal,
})