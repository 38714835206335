import { FC, useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useNavigate, useOutlet } from "react-router";
import { GetAssessmentResult } from "tech-health-assessment-sdk/dist";
import { SelfContext } from "../../../app/providers/self-provider/self-provider";
import { SectionGridItem, SectionGridItemLoadingState } from "../../../assessment-sections/components/section-grid-item/section-grid-item";
import { BodyCard } from "../../../common/components/body-card/body-card";
import { BodyContainer } from "../../../common/components/body-container/body-container";
import { Subtitle } from "../../../common/components/subtitle/subtitle";
import { Title } from "../../../common/components/title/title";
import { useIsMobile } from "../../../common/constants/breakpoints/breakpoints";
import { LargeTitleSkeleton, SubtitleSkeleton } from "../../../common/skeletons/skeletons";
import { AssessmentOverviewContainer, HeaderContainer, SectionsGrid, TitleContainer } from "./assessment-overview.styled";
import { sortSections } from "./assessment-overview.utils";
import { AssessmentCompleteCard } from "./subcomponents/assessment-complete-card/assessment-complete-card";
import { PreparingAssessmentCard } from "./subcomponents/preparing-assessment-card/preparing-assessment-card";

type AssessmentOverviewProps = {
    assessment: GetAssessmentResult
    activeSectionId: string | null
}

export const AssessmentOverview: FC<AssessmentOverviewProps> = ({
    assessment,
    activeSectionId,
}) => {

    // refs
    const gridRef = useRef<HTMLDivElement>(null)

    // responsive query
    const isMobile = useIsMobile()

    // context
    const { self } = useContext(SelfContext)

    // router hooks
    const outlet = useOutlet()
    const navigate = useNavigate()

    // memoized values and constants
    const hasChildren = outlet ? true : false;
    const displayMode = hasChildren ? 'compact' : 'normal';

    const sortedSections = useMemo(() => {
        if (isMobile) return assessment.sections;
        if (hasChildren) return assessment.sections;
        return sortSections(assessment.sections);
    }, [assessment.sections, hasChildren, isMobile])

    const isAssessmentComplete = useMemo(() => (
        !assessment.sections
            .filter(section => section.completionPercentage < 1)
            .length
    ), [assessment.sections])

    const isExternalUser = self.userType === 'external'

    // side effects
    useEffect(() => {
        if (assessment?.assessmentStatus === 'submitted' && isExternalUser) {
            navigate('/results')
        }
    }, [assessment?.assessmentStatus, isExternalUser, navigate, self.userType])

    // event handlers
    const handleScrollIntoView = useCallback((position: number) => {
        gridRef.current?.scrollTo({ left: position - 252, behavior: 'smooth' })
    }, [])

    if (assessment.assessmentStatus === 'setup_required') {
        return (
            <BodyContainer>
                <PreparingAssessmentCard />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <BodyCard style={{ flex: hasChildren ? 0 : 1 }}>
                <AssessmentOverviewContainer>
                    <HeaderContainer>
                        <TitleContainer>
                            <Title>
                                Technology Health Assessment
                            </Title>
                            {!hasChildren && (
                                <Subtitle>
                                    Complete each section to receive your health assessment.
                                </Subtitle>
                            )}
                        </TitleContainer>
                        {isAssessmentComplete && isExternalUser && (
                            <AssessmentCompleteCard
                                assessmentId={assessment.id}
                            />
                        )}
                    </HeaderContainer>
                    <SectionsGrid
                        ref={gridRef}
                        displayMode={displayMode}
                    >
                        {sortedSections.map(section => (
                            <SectionGridItem
                                key={section.id}
                                linkProps={{
                                    to: `section/${section.id}`,
                                }}
                                sectionName={section.name}
                                completionPercentage={section.completionPercentage}
                                iconSvg={section.iconSvg}
                                displayMode={displayMode}
                                isActive={section.id === activeSectionId}
                                onScrollIntoView={handleScrollIntoView}
                                isCurrentSection={section.id === assessment.currentSectionId}
                            />
                        ))}
                    </SectionsGrid>
                </AssessmentOverviewContainer>
            </BodyCard>
            {outlet}
        </BodyContainer>
    )
}

export const AssessmentOverviewLoadingState: FC = () => {

    // router hooks
    const outlet = useOutlet()

    // memoized values and constants
    const hasChildren = outlet ? true : false;
    const displayMode = hasChildren ? 'compact' : 'normal';

    return (
        <BodyContainer>
            <BodyCard style={{ flex: 1 }}>
                <AssessmentOverviewContainer>
                    <HeaderContainer>
                        <TitleContainer>
                            <LargeTitleSkeleton />
                            {!hasChildren && (
                                <SubtitleSkeleton />
                            )}
                        </TitleContainer>
                    </HeaderContainer>
                    <SectionsGrid displayMode={displayMode} >
                        <SectionGridItemLoadingState displayMode={displayMode} />
                        <SectionGridItemLoadingState displayMode={displayMode} />
                        <SectionGridItemLoadingState displayMode={displayMode} />
                        <SectionGridItemLoadingState displayMode={displayMode} />
                        <SectionGridItemLoadingState displayMode={displayMode} />
                        <SectionGridItemLoadingState displayMode={displayMode} />
                        <SectionGridItemLoadingState displayMode={displayMode} />
                    </SectionsGrid>
                </AssessmentOverviewContainer>
            </BodyCard>
            {outlet}
        </BodyContainer>
    )
}