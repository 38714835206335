import styled from "@emotion/styled";
import { AppColors } from "../../constants/colors/app-colors";
import { Gap } from "../sizes/sizes";

export const FormItemContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateRows: 'max-content max-content',
    gridAutoRows: 'max-content',
    gridTemplateColumns: '1fr',
    gap: Gap.desktop.normal,
    justifyContent: 'flex-start',
})

export const Label = styled.label({
    fontSize: 16,
    fontWeight: 600,
    color: AppColors.text.blue.superDark,
    cursor: 'text',
})