import styled from "@emotion/styled";

export const IconSvg = styled.svg<{
    size: number
    color: string
}>(({ size, color }) => ({
    // size
    width: size,
    height: size,

    // color
    fill: color,
}))