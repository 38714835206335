import { FC } from "react";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { AdminUserCard, AdminUserCardLoadingState } from "../../details-cards/admin-user-card/admin-user-card";
import { useAdminUser } from "./admin-user.hooks";

type AdminUserProps = {
    userId: string
}

export const AdminUser: FC<AdminUserProps> = ({
    userId,
}) => {

    // data hooks
    const { data: user, isLoading, refreshData } = useAdminUser(userId)

    if (!user || isLoading) {
        return (
            <BodyContainer>
                <AdminUserCardLoadingState />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <AdminUserCard
                user={user}
                onRefresh={refreshData}
            />
        </BodyContainer>
    )
}