import { FC, useContext, useEffect } from "react";
import { SaveContext } from "../../../app/providers/save-provider/save-provider";
import { AssessmentOverview, AssessmentOverviewLoadingState } from "../assessment-overview/assessment-overview";
import { useCurrentAssessment } from "./current-assessment-overview.hooks";

type CurrentAssessmentOverviewProps = {
    activeSectionId: string | null
}

export const CurrentAssessmentOverview: FC<CurrentAssessmentOverviewProps> = ({
    activeSectionId,
}) => {

    // data hooks
    const { currentAssessment, isLoading, refresh } = useCurrentAssessment();

    // context
    const { isSaving } = useContext(SaveContext)

    // side effects
    useEffect(() => {
        if (!isSaving) refresh()
    }, [isSaving, refresh])

    if (!currentAssessment || isLoading) {
        return (
            <AssessmentOverviewLoadingState />
        )
    }

    return (
        <AssessmentOverview
            assessment={currentAssessment}
            activeSectionId={activeSectionId}
        />
    )
}