import './app.css';

import { AppProvider } from './modules/app/providers/app-provider/app-provider';
import { AppRoutes } from './routes/app-routes/app-routes';
import { AuthenticationProvider } from './modules/authentication/providers/authentication-provider/authentication-provider';
import { BrowserRouter } from 'react-router-dom';
import { PortalProvider } from './modules/common/components/portal-provider/portal-provider';
import ReactGA from 'react-ga4';
import { SaveProvider } from './modules/app/providers/save-provider/save-provider';
import { SnackbarProvider } from './modules/common/components/snackbar-provider/snackbar-provider';

ReactGA.initialize(process.env.REACT_APP_GA_MESUREMENT_ID!);

function App() {
  return (
    <PortalProvider>
      <SnackbarProvider>
        <AppProvider>
          <SaveProvider>
            <BrowserRouter>
              <AuthenticationProvider>
                <AppRoutes />
              </AuthenticationProvider>
            </BrowserRouter>
          </SaveProvider>
        </AppProvider>
      </SnackbarProvider>
    </PortalProvider>
  );
}

export default App;
