import { IsBoolean, IsNotEmpty, IsString } from "class-validator";

export class CompleteSignUpResult {
    @IsBoolean()
    success: boolean;

    @IsString()
    @IsNotEmpty()
    accessToken: string;
  
    @IsString()
    @IsNotEmpty()
    refreshToken: string;
}