import { FC, useCallback, useState } from "react";
import { WarnOutlineButton } from "../../../../../../common/components/buttons/buttons";
import { EnableUserDialog } from "./subcomponents/enable-user-dialog/enable-user-dialog";

type EnableUserButtonProps = {
    userId: string
    onRefresh: () => void
} & Omit<React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, 'onClick'>

export const EnableUserButton: FC<EnableUserButtonProps> = ({
    userId,
    onRefresh,
    ...buttonProps
}) => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // event handlers
    const handleButtonClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <>
            <WarnOutlineButton
                {...buttonProps}
                onClick={handleButtonClick}
            >
                Enable Account
            </WarnOutlineButton>
            <EnableUserDialog
                userId={userId}
                isOpen={dialogOpen}
                onRefresh={onRefresh}
                onClose={handleDialogClose}
            />
        </>
    )
}