import { FC } from "react";
import { useParams } from "react-router";
import { AdminResults } from "../../../../../modules/admin/components/screens/admin-results/admin-results";

export const AdminAssessmentResultsRoute: FC = () => {

    // router hooks
    const { assessment_id } = useParams() as { assessment_id: string }

    return (
        <AdminResults
            assessmentId={assessment_id}
        />
    )
}