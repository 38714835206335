import { DateTime } from "luxon"
import { AccountTypeCode, AssessmentTypeCode, UserTypeCode } from "tech-health-assessment-sdk/dist"
import { v4 } from "uuid"
import { FieldMaskedAccount, FieldMaskedAssessment, FieldMaskedSupportRequest, FieldMaskedUser } from "./html-preview.types"

// eslint-disable-next-line no-script-url
const VOID_LINK = 'javascript:void(0)'

export const SAMPLE_ENVIRONMENT = {
    siteUrl: VOID_LINK,
    logoSrc: `${window.location.origin}/static/images/logo-acquiretek-over-white-194x62-1 1.png`,
    currentYear: DateTime.now().year,
}

export const SAMPLE_ACCOUNT = ({ accountTypeCode }: {
    accountTypeCode: AccountTypeCode
}): FieldMaskedAccount => ({
    id: v4(),
    adminLink: VOID_LINK,
    name: 'Dunder Mifflin',
    accountType: {
        id: v4(),
        code: accountTypeCode,
        label: accountTypeCode,
    }
})

export const SAMPLE_ASSESSMENT = ({
    assessmentTypeCode,
    accountTypeCode,
}: {
    assessmentTypeCode: AssessmentTypeCode,
    accountTypeCode: AccountTypeCode,
}): FieldMaskedAssessment => ({
    id: v4(),
    adminLink: VOID_LINK,
    assessmentType: {
        id: v4(),
        code: assessmentTypeCode,
        label: assessmentTypeCode,
    },
    account: SAMPLE_ACCOUNT({ accountTypeCode }),
    smartsheet: {
        id: v4(),
        externalId: v4(),
        externalLink: VOID_LINK,
        smartsheetWorkspace: {
            id: v4(),
            externalId: v4(),
            externalLink: VOID_LINK,
        },
        smartsheetFolder: {
            id: v4(),
            externalId: v4(),
            externalLink: VOID_LINK,
            smartsheetWorkspace: {
                id: v4(),
                externalId: v4(),
                externalLink: VOID_LINK,
            },
        },
    },
})

export const SAMPLE_USER = ({
    userTypeCode,
    accountTypeCode,
}: {
    userTypeCode: UserTypeCode
    accountTypeCode: AccountTypeCode | null
}): FieldMaskedUser => ({
    id: v4(),
    adminLink: VOID_LINK,
    resetPasswordLink: VOID_LINK,
    createAccountLink: VOID_LINK,
    firstName: 'Michael',
    lastName: 'Scott',
    emailAddress: 'michael.scott@dundermifflin.com',
    phoneNumber: '+15555555555',
    jobTitle: 'Regional Manager',
    userType: {
        id: v4(),
        code: userTypeCode,
        label: userTypeCode,
    },
    account: accountTypeCode ? SAMPLE_ACCOUNT({ accountTypeCode }) : null,
})

export const SAMPLE_SUPPORT_REQUEST = ({
    userTypeCode,
    accountTypeCode,
    assessmentTypeCode,
}: {
    userTypeCode: UserTypeCode,
    accountTypeCode: AccountTypeCode,
    assessmentTypeCode: AssessmentTypeCode,
}): FieldMaskedSupportRequest => ({
    id: v4(),
    firstName: 'Michael',
    lastName: 'Scott',
    emailAddress: 'michael.scott@dundermifflin.com',
    phoneNumber: '+15555555555',
    jobTitle: 'Regional Manager',
    companyName: 'Dunder Mifflin',
    assessment: SAMPLE_ASSESSMENT({ assessmentTypeCode, accountTypeCode }),
    user: SAMPLE_USER({ userTypeCode, accountTypeCode })
})