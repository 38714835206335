import { FC } from "react";
import { BodyCard } from "../../../common/components/body-card/body-card";
import { Title } from "../../../common/components/title/title";
import { DescriptionText, DetailsContainer, ResultsAreOnTheWayContainer } from "./results-are-on-the-way-card.styled";
import { ResultsAreOnTheWayHero } from "./subcomponents/results-are-on-the-way-hero/results-are-on-the-way-hero";

export const ResultsAreOnTheWayCard: FC = () => {
    return (
        <BodyCard style={{ flex: 1, display: 'grid', }}>
            <ResultsAreOnTheWayContainer>
                <ResultsAreOnTheWayHero />
                <DetailsContainer>
                    <Title>We're preparing your results.</Title>
                    <DescriptionText>
                        Our team will be in touch soon to review your comprehensive technology heath results.
                    </DescriptionText>
                </DetailsContainer>
            </ResultsAreOnTheWayContainer>
        </BodyCard>
    )
}