import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps, Renderer } from "react-table";

type LinkCellOptions<T extends object> = {
    link: string | ((props: CellProps<T, unknown>) => string);
    text?: string | ((props: CellProps<T, unknown>) => string);
    type?: 'react-router' | 'anchor'
}

export const LinkCell: <T extends object>(
    options: LinkCellOptions<T>
) => Renderer<CellProps<T, unknown>> = (options) => {
    return (props) => {
        
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const cellLink = useMemo(() => {
            if (typeof options.link === 'string') return options.link;
            return options.link(props);
        }, [props])

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const cellText = useMemo(() => {
            if (!options.text) return props.value as string ?? '';
            if (typeof options.text === 'string') return options.text;
            return options.text(props)
        }, [props])

        if (!cellText) return <></>;

        if (options.type === 'anchor') {
            return (
                <a href={cellLink} target="_blank" rel="noreferrer">
                    {cellText}
                </a>
            )
        }

        return (
            <Link to={cellLink}>
                {cellText}
            </Link>
        )
    }
}