import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { BrandContainer, BrandCopyrightBar, BrandDescription, ContactContainer, ContactUsTitle, FooterContainer, FooterContent, LogoContainer } from "./footer.styled";
import { DateTime } from 'luxon';

import { FOOTER_LOGO } from '../../../../../common/environment/environment-constants';

export const Footer: FC = () => {

    // constants
    const year = useMemo(() => (DateTime.now().year), [])

    return (
        <FooterContainer data-testid="footer-container">
            <FooterContent>
                <BrandContainer>
                    <LogoContainer>
                        <Link to="/">
                            <img
                                data-testid="logo-image"
                                src={FOOTER_LOGO}
                                alt="AcquireTek Logo"
                            />
                        </Link>
                    </LogoContainer>
                    <BrandDescription>
                        We simplify mergers and acquisitions, working alongside you to deliver a focused and complete integration.
                    </BrandDescription>
                </BrandContainer>
                <ContactContainer>
                    <ContactUsTitle>
                        Contact Us
                    </ContactUsTitle>
                    <p>
                        <span>1165 Sanctuary Parkway, Suite 300</span>
                        <br />
                        <span>Alpharetta, GA 30009</span>
                        <br />
                        <span>Inquiries@AcquireTek.com</span>
                        <br />
                        <span>770-450-4268</span>
                    </p>
                </ContactContainer>
            </FooterContent>
            <BrandCopyrightBar data-testid="copyright">
                Copyright © AcquireTek {year}. All Rights Reserved.
            </BrandCopyrightBar>
        </FooterContainer>
    )
}