import { createContext, FC, useCallback, useState } from "react";

type AppProviderProps = {
    children: JSX.Element
}

export const AppProvider: FC<AppProviderProps> = ({
    children
}) => {

    // state
    const [bookmarkedRoute, setBookmarkedRoute] = useState<string | null>(null)

    // event handlers
    const handleBookmarkRoute = useCallback((route: string) => {
        setBookmarkedRoute(route)
    }, [])

    return (
        <AppContext.Provider value={{
            // bookmarked route
            bookmarkedRoute,
            onBookmarkRoute: handleBookmarkRoute,
        }}>
            {children}
        </AppContext.Provider>
    )
}

type AppContextType = {
    // bookmarked route
    bookmarkedRoute: string | null;
    onBookmarkRoute: (route: string) => void;
}

export const AppContext = createContext<AppContextType>({
    // bookmarked route
    bookmarkedRoute: '',
    onBookmarkRoute: () => { },
})