import { FC } from "react";
import { Subtitle } from "../../../../../common/components/subtitle/subtitle";
import { Title } from "../../../../../common/components/title/title";
import { AuthenticationHeroContainer, HeroHeader, HeroImage } from "./authentication-hero.styled";

export const AuthenticationHero: FC = () => {
    return (
        <AuthenticationHeroContainer>
            <HeroHeader>
                <Title>
                    Technology Health Assessment
                </Title>
                <Subtitle>
                    Assess your technology health today.
                </Subtitle>
            </HeroHeader>
            <HeroImage src="/static/images/authentication-hero-image.png" />
        </AuthenticationHeroContainer>
    )
}