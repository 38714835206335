import styled from "@emotion/styled";
import { Gap, Padding } from "../../../common/components/sizes/sizes";
import { MOBILE_BREAKPOINT, TABLET_BREAKPOINT } from "../../../common/constants/breakpoints/breakpoints";
import { AppColors } from "../../../common/constants/colors/app-colors";

export const AssessmentResultsContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.large,
    gridTemplateRows: 'max-content max-content 1fr max-content',

    // padding
    padding: Padding.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gap: Gap.mobile.larger,
        padding: Padding.mobile.larger,
    },
})

export const TitleContainer = styled.div({
    display: 'grid',
})

export const ResultsHeaderContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '3fr 2fr',
    gap: Gap.desktop.large,

    // border
    borderBottom: `1px solid ${AppColors.border.default}`,

    // padding
    paddingBottom: Padding.desktop.normal,

    // breakpoints
    [TABLET_BREAKPOINT]: {
        // layout
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content max-content',
        gap: Gap.tablet.large,

        // padding
        paddingBottom: Padding.tablet.normal,
    },

    [MOBILE_BREAKPOINT]: {
        gap: Gap.mobile.large,
        paddingBottom: Padding.mobile.larger,
    },
})

export const ResultsDetailsGrid = styled.div({
    // layout
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: Gap.desktop.larger,

    // border
    borderBottom: `1px solid ${AppColors.border.default}`,

    // padding
    paddingBottom: Padding.desktop.normal,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        // layout
        gridTemplateColumns: '1fr',
        gap: Gap.mobile.larger,

        // padding
        paddingBottom: Padding.mobile.larger,
    },
})

export const ResultsFooterContainer = styled.div({
    // layout
    display: 'grid',
    gap: Gap.desktop.normal,
    maxWidth: 1080,
})

export const OverallScoreContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'max-content 1fr',
    gap: Gap.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        gridTemplateColumns: '1fr',
        gridTemplateRows: 'max-content',
        gridAutoFlow: 'row',
        gap: Gap.mobile.large,

        // alignment
        justifyItems: 'start'
    },
})

export const OverallScoreTextContainer = styled.div({
    // layout
    display: 'grid',
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',
    gap: Gap.desktop.normal,

    // padding
    paddingTop: Padding.desktop.smaller,
})

export const OverallScoreTitle = styled.span({
    fontSize: 28,
    fontWeight: 700,
    color: AppColors.text.onLightBackground,
})

export const OverallScoreDescription = styled.p({
    whiteSpace: 'pre-wrap',
})

export const FooterTitle = styled.span({
    fontSize: 28,
    fontWeight: 700,
    color: AppColors.text.onLightBackground,
})