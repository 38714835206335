import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AssessmentSectionsAPI } from '../assessment-sections';
import { AssessmentsAPI } from '../assessments';
import { AuthAPI } from '../auth';
import { SelfAPI } from '../self';
import { AdminAPI } from '../admin';
import { APIConfiguration } from './core-api.types';
import { DemoAPI } from '../demo';

export class CoreAPI {
    apiUrl: string | null;
    accessToken: string | null;
    refreshToken: string | null;
  
    admin: AdminAPI;
    auth: AuthAPI;
    assessments: AssessmentsAPI;
    assessmentSections: AssessmentSectionsAPI;
    demo: DemoAPI;
    self: SelfAPI;
  
    constructor(configuration: APIConfiguration) {
      this.apiUrl = configuration.apiUrl;

      this.accessToken = configuration.accessToken;
      this.refreshToken = configuration.refreshToken;
  
      this.admin = new AdminAPI(this);
      this.auth = new AuthAPI(this);
      this.assessments = new AssessmentsAPI(this);
      this.assessmentSections = new AssessmentSectionsAPI(this);
      this.demo = new DemoAPI(this);
      this.self = new SelfAPI(this);
    }
  
    private generateAxiosConfig(): AxiosRequestConfig {
      const headers: Record<string, string> = {};
  
      if (this.accessToken) {
        headers['Authorization'] = `Bearer ${this.accessToken}`;
      }
  
      return { headers };
    }
  
    async get(url: string, params?: unknown) {
      let result: AxiosResponse | null = null;
      let error: unknown;

      try {
        result = await axios.get(url, {
          ...this.generateAxiosConfig(),
          params,
        });
      } catch (err) {
        error = err;
      }

      if (!error) return result;
      if (!this.refreshToken) throw error;

      // TODO: check if authentication error before doing this
      await this.auth.refreshToken({ refreshToken: this.refreshToken });

      // retry the request
      return axios.get(url, this.generateAxiosConfig());
    }
  
    async post(url: string, data: any) {
      let result: AxiosResponse | null = null;
      let error: unknown;

      try {
        result = await axios.post(url, data, this.generateAxiosConfig());
      } catch (err) {
        error = err;
      }

      if (!error) return result;
      if (!this.refreshToken) throw error;
      if (url === this.auth.refreshTokenUrl) throw error;

      // TODO: check if authentication error before doing this
      await this.auth.refreshToken({ refreshToken: this.refreshToken });

      // retry the request
      return axios.post(url, data, this.generateAxiosConfig());
    }
  }