import { FC, useCallback, useContext, useState } from "react";
import { AuthenticationFooterMessage, AuthenticationForm, AuthenticationFormFooter, AuthenticationFormContainer, AuthenticationFormSubTitle, AuthenticationFormTitle, LinkContainer } from "../../authentication.styled";
import { useForm } from 'react-hook-form';
import { signIn } from "./sign-in.services";
import { Link } from "react-router-dom";
import { validate as validateEmail } from 'email-validator';
import { AuthenticationContext } from "../../providers/authentication-provider/authentication-provider";
import { PrimaryButton } from "../../../common/components/buttons/buttons";
import { FormItem } from "../../../common/components/form-item/form-item";
import { InlineLoading } from "../../../common/components/inline-loading/inline-loading";
import { Input } from "../../../common/components/input/input";
import { FormValidationMessage } from "../../../common/components/form-validation-message/form-validation-message";
import { LeadsDialog } from "./subcomponents/leads-dialog";

export const SignIn: FC = () => {

    // context
    const { refreshAuthenticationState } = useContext(AuthenticationContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>('')
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)
    

    // form state
    const { register, getValues, handleSubmit, formState } = useForm({
        defaultValues: {
            emailAddress: '',
            password: '',
        }
    })

    // event handlers
    const handleFormSubmit = useCallback(async () => {
        setIsLoading(true)

        const { emailAddress, password } = getValues()
        const result = await signIn(emailAddress, password)

        if (result.success) {
            refreshAuthenticationState()
        } else {
            if (result.code === 'leads_credentials') {
                setErrorMessage('')
                setDialogOpen(true);
            } else {
                setErrorMessage(result.message)
            }
        }

        setIsLoading(false)
    }, [getValues, refreshAuthenticationState])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <AuthenticationFormContainer>
            <AuthenticationFormTitle>
                Sign In
            </AuthenticationFormTitle>
            <AuthenticationFormSubTitle>
                Welcome back! Sign in to access your company's technology health assessment.
            </AuthenticationFormSubTitle>
            <AuthenticationForm
                data-testid="sign-in-form"
                onSubmit={handleSubmit(handleFormSubmit)}
            >
                <FormItem label="Email Address">
                    <>
                        <Input
                            {...register('emailAddress', {
                                required: true,
                                validate: validateEmail,
                            })}
                            data-testid="email-address-input"
                            type="email"
                            autoComplete="username"
                            placeholder="Email Address"
                            disabled={isLoading}
                        />
                        {formState.errors.emailAddress?.type === 'required' && (
                            <FormValidationMessage data-testid="email-address-missing-warning">
                                Email address is required
                            </FormValidationMessage>
                        )}
                        {formState.errors.emailAddress?.type === 'validate' && (
                            <FormValidationMessage data-testid="email-address-invalid-warning">
                                Please enter a valid email address
                            </FormValidationMessage>
                        )}
                        <LinkContainer>
                            <Link
                                data-testid="sign-up-link"
                                to="/sign-up"
                            >
                                Don't have an account? Click here to sign up
                            </Link>
                        </LinkContainer>
                    </>
                </FormItem>
                <FormItem label="Password">
                    <>
                        <Input
                            {...register('password', { required: false })}
                            data-testid="password-input"
                            type="password"
                            autoComplete="password"
                            placeholder="Password"
                            disabled={isLoading}
                        />
                        {formState.errors.password?.type === 'required' && (
                            <FormValidationMessage data-testid="password-missing-warning">
                                Password is required
                            </FormValidationMessage>
                        )}
                        <LinkContainer>
                            <Link
                                data-testid="forgot-password-link"
                                to="/forgot-password"
                            >
                                Forgot Password?
                            </Link>
                        </LinkContainer>
                    </>
                </FormItem>
                <AuthenticationFormFooter>
                    <PrimaryButton
                        data-testid="sign-in-button"
                        type="submit"
                        disabled={isLoading}
                    >
                        Sign In
                    </PrimaryButton>
                    {!!isLoading && (
                        <InlineLoading>
                            Loading...
                        </InlineLoading>
                    )}
                </AuthenticationFormFooter>
                {errorMessage && (
                    <AuthenticationFooterMessage type='error'>
                        {errorMessage}
                    </AuthenticationFooterMessage>
                )}
            </AuthenticationForm>
            <LeadsDialog
                isOpen={dialogOpen}
                onClose={handleDialogClose}
            />
        </AuthenticationFormContainer>
    )
}