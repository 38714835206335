import { FC, useMemo } from "react";
import { GetAdminEmailTemplatesRequest } from "tech-health-assessment-sdk/dist";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { usePagination } from "../../../../common/hooks/use-pagination/use-pagination";
import { AdminEmailTemplatesGridCard, AdminEmailTemplatesGridCardLoadingState } from "../../grid-cards/admin-email-templates-grid-card/admin-email-templates-grid-card";
import { useAdminEmailTemplates } from "./admin-email-templates.hooks";

export const AdminEmailTemplates: FC = () => {

    // state
    const [pagination, setPagination] = usePagination()

    // constants
    const request: GetAdminEmailTemplatesRequest = useMemo(() => ({
        ...pagination,
    }), [pagination])

    // data hooks
    const { data: emailTemplatesResult, isLoading } = useAdminEmailTemplates(request)

    // data abstraction
    const emailTemplates = emailTemplatesResult?.emailTemplates;

    if (isLoading || !emailTemplates) {
        return (
            <BodyContainer>
                <AdminEmailTemplatesGridCardLoadingState />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <AdminEmailTemplatesGridCard
                emailTemplates={emailTemplates}
                pagination={pagination}
                onPaginationChange={setPagination}
                totalCount={emailTemplatesResult.totalCount}
            />
        </BodyContainer>
    )
}