import { FC } from "react"
import { SpanSkeleton } from "../../skeletons/skeletons"
import { FormItemContainer, Label } from "./form-item.styled"

type FormItemProps = {
    label: string
    children: JSX.Element | JSX.Element[]
}

export const FormItem: FC<FormItemProps> = ({
    label,
    children,
}) => {
    return (
        <FormItemContainer>
            <Label>{label}</Label>
            {children}
        </FormItemContainer>
    )
}

type FormItemLoadingStateProps = {
    children: JSX.Element | JSX.Element[]
}

export const FormItemLoadingState: FC<FormItemLoadingStateProps> = ({
    children,
}) => {
    return (
        <FormItemContainer>
            <SpanSkeleton fontSize={16} />
            {children}
        </FormItemContainer>
    )
}