import Editor from "@monaco-editor/react";
import { FC, useCallback, useContext, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { GetEmailTemplateResult, UpdateEmailTemplateResult } from "tech-health-assessment-sdk/dist";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { PrimaryButton } from "../../../../common/components/buttons/buttons";
import { FormItem } from "../../../../common/components/form-item/form-item";
import { Input } from "../../../../common/components/input/input";
import { LoadingSpinner } from "../../../../common/components/loading-spinner/loading-spinner";
import { SnackbarContext } from "../../../../common/components/snackbar-provider/snackbar-provider";
import { StandardCardContentContainer } from "../../../../common/components/standard-card-content-container/standard-card-content-container";
import { StandardCardHeader } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { AppColors } from "../../../../common/constants/colors/app-colors";
import { useBlockNavigation } from "../../../../common/hooks/use-block-navigation/use-block-navigation";
import { service } from "./admin-email-template-card.service";
import { CardsLayout, FormItems } from "./admin-email-template-card.styled";
import { HtmlPreview } from "./subcomponents/html-preview/html-preview";

type AdminEmailTemplateCardProps = {
    emailTemplate: GetEmailTemplateResult
    onRefresh: () => void
}

export const AdminEmailTemplateCard: FC<AdminEmailTemplateCardProps> = ({
    emailTemplate,
    onRefresh,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { control, watch, register, formState, getValues } = useForm({
        defaultValues: {
            subject: emailTemplate.subject,
            content: emailTemplate.content,
        }
    })

    const htmlContent = watch('content')

    // block navigation
    useBlockNavigation(formState.isDirty)

    // event handlers
    const handleSaveButtonClick = useCallback(async () => {
        setIsLoading(true)

        const formValues = getValues()

        let result: UpdateEmailTemplateResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.updateEmailTemplate(emailTemplate.emailTemplateId, {
                subject: formValues.subject,
                content: formValues.content,
            })
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully updated email template',
            })
            onRefresh()
        }

        setIsLoading(false)
    }, [emailTemplate.emailTemplateId, getValues, onRefresh, showSnackbar])

    return (
        <CardsLayout style={{ flex: 1 }}>
            <BodyCard style={{ gridColumnStart: 1, gridColumnEnd: 3, gridRowStart: 1 }}>
                <StandardCardLayout>
                    <StandardCardHeader
                        title={emailTemplate.label}
                        subtitle={emailTemplate.description}
                        actions={[
                            <PrimaryButton
                                disabled={!formState.isDirty || isLoading}
                                onClick={handleSaveButtonClick}
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Save Changes
                            </PrimaryButton>
                        ]}
                    />
                    <StandardCardContentContainer>
                        <FormItems>
                            <FormItem label="Email Subject">
                                <Input {...register('subject')} />
                            </FormItem>
                        </FormItems>
                    </StandardCardContentContainer>
                </StandardCardLayout>
            </BodyCard>
            <BodyCard style={{ overflow: 'hidden' }}>
                <StandardCardContentContainer style={{ borderBottom: `1px solid ${AppColors.border.default}` }}>
                    <FormItem label="Email Content (HTML)" >
                        <span />
                    </FormItem>
                </StandardCardContentContainer>
                <Controller
                    control={control}
                    name="content"
                    render={({ field }) => (
                        <Editor
                            defaultLanguage="html"
                            {...field}
                        />
                    )}
                />
            </BodyCard>
            <BodyCard style={{ overflow: 'hidden', border: `1px solid ${AppColors.text.onDarkBackground}`, boxSizing: 'border-box' }}>
                <StandardCardContentContainer style={{ borderBottom: `1px solid ${AppColors.border.default}` }}>
                    <FormItem label="Email Preview (scaled 50%)" >
                        <span />
                    </FormItem>
                </StandardCardContentContainer>
                <HtmlPreview
                    htmlContent={htmlContent}
                    emailTemplate={emailTemplate}
                />
            </BodyCard>
        </CardsLayout>
    )
}

export const AdminEmailTemplateCardLoadingState: FC = () => {
    return (
        <div>
            AdminEmailTemplateCardLoadingState works!
        </div>
    )
}