import { CoreAPI } from "../core/core-api";
import { GetSelfResult } from "./dtos";

export class SelfAPI {
    private basePath: string;

    constructor(private readonly coreApi: CoreAPI) {
        this.coreApi = coreApi;
        this.basePath = `${this.coreApi.apiUrl}/self`;
    }

    async getSelf(): Promise<GetSelfResult> {
        const result = await this.coreApi.get(this.basePath);

        if (!result?.data) throw new Error();

        const getSelfResult = result.data as GetSelfResult;
        
        return getSelfResult;
    };
}