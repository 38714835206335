import { FC, useMemo } from "react";
import { GetAdminAccountsRequest } from "tech-health-assessment-sdk/dist";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { usePagination } from "../../../../common/hooks/use-pagination/use-pagination";
import { AdminAccountsGridCardLoadingState, AdminAccountsGridCard } from "../../grid-cards/admin-accounts-grid-card/admin-accounts-grid-card";
import { useAdminAccounts } from "./admin-accounts.hooks";

export const AdminAccounts: FC = () => {

    // state
    const [pagination, setPagination] = usePagination()

    // constants
    const request: GetAdminAccountsRequest = useMemo(() => ({
        ...pagination
    }), [pagination])

    // data hooks
    const { data: accountsResult, isLoading } = useAdminAccounts(request)

    // data abstraction
    const accounts = accountsResult?.accounts;

    if (isLoading || !accounts) {
        return (
            <BodyContainer>
                <AdminAccountsGridCardLoadingState />
            </BodyContainer>
        )
    }

    return (
        <BodyContainer>
            <AdminAccountsGridCard
                accounts={accounts}
                pagination={pagination}
                onPaginationChange={setPagination}
                totalCount={accountsResult.totalCount}
            />
        </BodyContainer>
    )
}