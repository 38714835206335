import { IsArray, IsString, IsUUID, ValidateNested } from "class-validator";
import { UserTypeCode } from "../../../../common/codes.types";

export class GetUserTypesResult {
    @IsArray()
    @ValidateNested()
    userTypes: GetUserTypesResult_UserType[]
}

export class GetUserTypesResult_UserType {
    @IsUUID()
    userTypeId: string;

    @IsString()
    code: UserTypeCode;
    
    @IsString()
    label: string;
}