import { FC } from "react";
import { GetSectionResult_ResponseValue } from "tech-health-assessment-sdk/dist";
import { useIsTablet } from "../../../../../../../common/constants/breakpoints/breakpoints";
import { HeaderSkeleton } from "../../../../../../../common/skeletons/skeletons";
import { QuestionRowContainer, QuestionRowResponsesContainer } from "../../section-questions.styled";
import { HeaderText } from "./questions-grid-header.styled";

type QuestionsGridHeaderProps = {
    responseValues: GetSectionResult_ResponseValue[],
}

export const QuestionsGridHeader: FC<QuestionsGridHeaderProps> = ({
    responseValues,
}) => {

    // responsive hooks
    const isTablet = useIsTablet()

    return (
        <QuestionRowContainer>
            <HeaderText>
                Best Practice Definition
            </HeaderText>
            {!isTablet && (
                <QuestionRowResponsesContainer>
                    {responseValues.map(responseValue => (
                        <HeaderText key={responseValue.id}>
                            {responseValue.label}
                        </HeaderText>
                    ))}
                </QuestionRowResponsesContainer>
            )}
        </QuestionRowContainer>
    )
}

export const QuestionsGridHeaderLoadingState: FC = () => {

    // responsive hooks
    const isTablet = useIsTablet()

    return (
        <QuestionRowContainer>
            <HeaderSkeleton />
            {!isTablet && (
                <QuestionRowResponsesContainer>
                    <HeaderSkeleton />
                    <HeaderSkeleton />
                    <HeaderSkeleton />
                </QuestionRowResponsesContainer>
            )}
        </QuestionRowContainer>
    )
}