import { createUseData } from "../../../../common/hooks/create-use-data/create-use-data";
import { service } from "./admin-account.service";

export const useAdminAccount = createUseData(
    service.getAccount,
)

export const useAdminAccountAssessments = createUseData(
    service.getAssessments,
)

export const useAdminAccountUsers = createUseData(
    service.getUsers,
)