import { FC, useCallback, useContext, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { AdminUpdateUserResult, GetAdminUserResult } from "tech-health-assessment-sdk/dist";
import { BodyCard } from "../../../../common/components/body-card/body-card";
import { PrimaryButton } from "../../../../common/components/buttons/buttons";
import { FormItem, FormItemLoadingState } from "../../../../common/components/form-item/form-item";
import { FormValidationMessage } from "../../../../common/components/form-validation-message/form-validation-message";
import { Input } from "../../../../common/components/input/input";
import { SnackbarContext } from "../../../../common/components/snackbar-provider/snackbar-provider";
import { StandardCardContentContainer } from "../../../../common/components/standard-card-content-container/standard-card-content-container";
import { StandardCardHeader, StandardCardHeaderLoadingState } from "../../../../common/components/standard-card-header/standard-card-header";
import { StandardCardLayout } from "../../../../common/components/standard-card-layout/standard-card-layout";
import { service } from "./admin-user-card.service";
import { FooterContainer, FormItems, UserDetailsForm } from "./admin-user-card.styled";
import { phone } from 'phone'
import { validate as validateEmail } from 'email-validator'
import { LoadingSpinner } from "../../../../common/components/loading-spinner/loading-spinner";
import { AppColors } from "../../../../common/constants/colors/app-colors";
import { ButtonSkeleton, InputSkeleton } from "../../../../common/skeletons/skeletons";
import { DisableUserButton } from "./subcomponents/disable-user-button/disable-user-button";
import { ResetPasswordButton } from "./subcomponents/reset-password-button/reset-password-button";
import { EnableUserButton } from "./subcomponents/enable-user-button/enable-user-button";
import { SelfContext } from "../../../../app/providers/self-provider/self-provider";

type AdminUserCardProps = {
    user: GetAdminUserResult
    onRefresh: () => void
}

export const AdminUserCard: FC<AdminUserCardProps> = ({
    user,
    onRefresh,
}) => {

    // context
    const { showSnackbar } = useContext(SnackbarContext)
    const { self } = useContext(SelfContext)

    // state
    const [isLoading, setIsLoading] = useState<boolean>(false)

    // form state
    const { control, getValues, handleSubmit, formState: { isDirty, isValid, errors } } = useForm({
        defaultValues: {
            firstName: user.firstName,
            lastName: user.lastName,
            jobTitle: user.jobTitle,
            emailAddress: user.emailAddress,
            phoneNumber: user.phoneNumber,
        },
    })

    // constants
    const cardSubtitle: JSX.Element | string = useMemo(() => {
        if (user.userType.code === 'internal') {
            return 'Internal User'
        }

        return (
            <>
                <span>
                    Member of&nbsp;
                </span>
                <Link to={`/admin/accounts/${user.account?.accountId}`}>
                    {user.account?.companyName}
                </Link>
                <span>
                    &nbsp;(external)
                </span>
            </>
        )
    }, [user.account?.accountId, user.account?.companyName, user.userType.code])

    const isSelf = self.userId === user.userId;

    // event handlers
    const handleSave = useCallback(async () => {
        setIsLoading(true)

        const values = getValues()

        let result: AdminUpdateUserResult | undefined = undefined;
        let error: unknown;
        try {
            result = await service.updateUser(user.userId, {
                firstName: values.firstName,
                lastName: values.lastName,
                jobTitle: values.jobTitle,
                emailAddress: values.emailAddress,
                phoneNumber: values.phoneNumber,
            })
        } catch (err) {
            error = err;
        }

        if (!!error || !result?.success) {
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support',
            })
        } else {
            showSnackbar({
                type: 'success',
                title: 'Successfully updated user'
            })
        }

        setIsLoading(false)
        onRefresh()
    }, [getValues, onRefresh, showSnackbar, user.userId])

    return (
        <BodyCard>
            <StandardCardLayout>
                <StandardCardHeader
                    title={`${user.firstName} ${user.lastName}`}
                    subtitle={cardSubtitle}
                    actions={(
                        <>
                            <ResetPasswordButton
                                disabled={!user.isEnabled}
                                userId={user.userId}
                            />
                            {user.isEnabled ? (
                                <DisableUserButton
                                    disabled={isSelf}
                                    userId={user.userId}
                                    onRefresh={onRefresh}
                                />
                            ) : (
                                <EnableUserButton
                                    disabled={isSelf}
                                    userId={user.userId}
                                    onRefresh={onRefresh}
                                />
                            )}
                        </>
                    )}
                />
                <StandardCardContentContainer>
                    <UserDetailsForm onSubmit={handleSubmit(handleSave)}>
                        <FormItems>
                            <FormItem label="First Name">
                                <>
                                    <Controller
                                        control={control}
                                        name="firstName"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="First Name"
                                                disabled={isLoading}
                                            />
                                        )}
                                    />
                                    {errors.firstName?.type === 'required' && (
                                        <FormValidationMessage data-testid="first-name-missing-warning">
                                            First Name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Last Name">
                                <>
                                    <Controller
                                        control={control}
                                        name="lastName"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Last Name"
                                                disabled={isLoading}
                                            />
                                        )}
                                    />
                                    {errors.lastName?.type === 'required' && (
                                        <FormValidationMessage data-testid="last-name-missing-warning">
                                            Last Name is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Job Title">
                                <>
                                    <Controller
                                        control={control}
                                        name="jobTitle"
                                        rules={{ required: true }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Job Title"
                                                disabled={isLoading}
                                            />
                                        )}
                                    />
                                    {errors.jobTitle?.type === 'required' && (
                                        <FormValidationMessage data-testid="job-title-missing-warning">
                                            Job Title is required
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Email Address">
                                <>
                                    <Controller
                                        control={control}
                                        name="emailAddress"
                                        rules={{
                                            required: true,
                                            validate: validateEmail,
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                {...field}
                                                placeholder="Email Address"
                                                disabled={isLoading}
                                            />
                                        )}
                                    />
                                    {errors.emailAddress?.type === 'required' && (
                                        <FormValidationMessage data-testid="email-address-missing-warning">
                                            Email address is required
                                        </FormValidationMessage>
                                    )}
                                    {errors.emailAddress?.type === 'validate' && (
                                        <FormValidationMessage data-testid="email-address-invalid-warning">
                                            Please enter a valid email address
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                            <FormItem label="Phone Number">
                                <>
                                    <Controller
                                            control={control}
                                            name="phoneNumber"
                                            rules={{
                                                required: true,
                                                validate: (value) => phone(value).isValid,
                                            }}
                                            render={({ field }) => (
                                                <Input
                                                    {...field}
                                                    placeholder="Phone Number"
                                                    disabled={isLoading}
                                                />
                                            )}
                                        />
                                    {errors.phoneNumber?.type === 'required' && (
                                        <FormValidationMessage data-testid="phone-number-missing-warning">
                                            Phone Number is required
                                        </FormValidationMessage>
                                    )}
                                    {errors.phoneNumber?.type === 'validate' && (
                                        <FormValidationMessage data-testid="phone-number-invalid-warning">
                                            Please enter a valid phone number
                                        </FormValidationMessage>
                                    )}
                                </>
                            </FormItem>
                        </FormItems>
                        <FooterContainer>
                            <PrimaryButton
                                disabled={!isDirty || !isValid || isLoading}
                                type="submit"
                            >
                                {isLoading && (
                                    <LoadingSpinner
                                        size={14}
                                        color={AppColors.text.onDarkBackground}
                                    />
                                )}
                                Save Changes
                            </PrimaryButton>
                        </FooterContainer>
                    </UserDetailsForm>
                </StandardCardContentContainer>
            </StandardCardLayout>
        </BodyCard>
    )
}

export const AdminUserCardLoadingState: FC = () => {
    return (
        <BodyCard style={{ flex: 1 }}>
            <StandardCardLayout>
                <StandardCardHeaderLoadingState
                    hasSubtitle={true}
                    actionSkeletons={(
                        <>
                            <ButtonSkeleton />
                            <ButtonSkeleton />
                        </>
                    )}
                />
                <StandardCardContentContainer>
                    <UserDetailsForm>
                        <FormItems>
                            <FormItemLoadingState>
                                <InputSkeleton />
                            </FormItemLoadingState>
                            <FormItemLoadingState>
                                <InputSkeleton />
                            </FormItemLoadingState>
                            <FormItemLoadingState>
                                <InputSkeleton />
                            </FormItemLoadingState>
                            <FormItemLoadingState>
                                <InputSkeleton />
                            </FormItemLoadingState>
                            <FormItemLoadingState>
                                <InputSkeleton />
                            </FormItemLoadingState>
                        </FormItems>
                        <FooterContainer>
                            <ButtonSkeleton />
                        </FooterContainer>
                    </UserDetailsForm>
                </StandardCardContentContainer>
            </StandardCardLayout>
        </BodyCard>
    )
}