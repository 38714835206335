import { FC, useCallback } from "react";

import { PrimaryButton } from "../../../../common/components/buttons/buttons";
import { DialogHeader, DialogTitle, DialogDescription, DialogFooter } from "../../../../common/components/dialog/dialog.styled";
import { Dialog, DialogProps } from "../../../../common/components/dialog/dialog";
import { GetLeadsDiv } from "./leads-dialog.styled";

type LeadsDialogProps = {
} & Omit<DialogProps, 'children'>

export const LeadsDialog: FC<LeadsDialogProps> = ({
    isOpen,
    onClose,
}) => {

    const handleCancelButtonClick = useCallback(() => {
        onClose()
    }, [onClose])

    return (
        <Dialog
            isOpen={isOpen}
            onClose={onClose}
        >
            <GetLeadsDiv>
                <DialogHeader>
                    <DialogTitle>
                    Welcome back!
                    </DialogTitle>
                    <DialogDescription>
                    We see you have already completed a sample assessment. Please contact us for next steps and quote.
                    </DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <PrimaryButton
                        onClick={handleCancelButtonClick}
                        type="submit"
                    >
                        Ok
                    </PrimaryButton>
                </DialogFooter>
                </GetLeadsDiv>
        </Dialog>
    )
}