import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString } from "class-validator";

export class AdminUpdateUserPayload {
    @IsString()
    @IsNotEmpty()
    firstName: string;

    @IsString()
    @IsNotEmpty()
    lastName: string;

    @IsEmail()
    @IsNotEmpty()
    emailAddress: string;

    @IsPhoneNumber()
    @IsNotEmpty()
    phoneNumber: string;

    @IsString()
    @IsNotEmpty()
    jobTitle: string;
}