import { FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router";
import { SecondaryButton } from "../../../../../../../common/components/buttons/buttons";
import { ArrowLeftIcon } from "../../../../../../../common/components/icons/arrow-left-icon/arrow-left-icon";
import { LoadingSpinner } from "../../../../../../../common/components/loading-spinner/loading-spinner";
import { AppColors } from "../../../../../../../common/constants/colors/app-colors";
import { buildSectionUrl } from "../../section-questions.utils";

type PreviousSectionButtonProps = {
    previousSectionId: string | null
    shouldSave: boolean
    isSaving: boolean
    onSave: () => Promise<void>
}

export const PreviousSectionButton: FC<PreviousSectionButtonProps> = ({
    previousSectionId,
    shouldSave,
    isSaving,
    onSave,
}) => {

    // router hooks
    const location = useLocation()
    const navigate = useNavigate()

    // constants
    const isDisabled = isSaving || !previousSectionId;

    // event handlers
    const handleClick = useCallback(async () => {
        if (shouldSave) await onSave();
        const sectionUrl = buildSectionUrl(location.pathname, previousSectionId ?? '')
        navigate(sectionUrl)
    }, [location.pathname, navigate, onSave, previousSectionId, shouldSave])

    return (
        <SecondaryButton
            disabled={isDisabled}
            onClick={handleClick}
        >
            {isSaving ? (
                <LoadingSpinner
                    size={14}
                    color={AppColors.text.onDarkBackground}
                />
            ) : (
                <ArrowLeftIcon
                    size={14}
                    color={AppColors.text.onDarkBackground}
                />
            )}
            {isSaving ? 'Saving...' : 'Back'}
        </SecondaryButton>
    )
}