import { FC, useCallback, useMemo } from "react";
import ReactSelect from "react-select";
import { PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { PrimaryButton } from "../../../../../components/buttons/buttons";
import { ArrowLeftIcon } from "../../../../../components/icons/arrow-left-icon/arrow-left-icon";
import { ArrowRightIcon } from "../../../../../components/icons/arrow-right-icon/arrow-right-icon";
import { AppColors } from "../../../../../constants/colors/app-colors";
import { ButtonSkeleton, InputSkeleton } from "../../../../../skeletons/skeletons";
import { ControlsContainer, NextButtonContainer, PaginationFooterContainer, PreviousButtonContainer } from "./pagination-footer.styled";

type PaginationFooterProps = {
    totalCount: number
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
}

export const PaginationFooter: FC<PaginationFooterProps> = ({
    totalCount,
    pagination,
    onPaginationChange,
}) => {

    // constants
    const lastPageNumber = useMemo(() => (
        Math.ceil(totalCount / pagination.take)
    ), [pagination.take, totalCount])

    const pageOptions = useMemo(() => {
        const pageOptions: { label: string, value: string }[] = []
        for (let i = 0; i < lastPageNumber; i++) {
            const page = (i + 1).toString()
            pageOptions.push({
                label: page,
                value: page,
            })
        }
        return pageOptions;
    }, [lastPageNumber])

    const currentPage = useMemo(() => (
        (Math.ceil(pagination.skip / pagination.take) + 1).toString()
    ), [pagination.skip, pagination.take])

    const currentPageOption = useMemo(() => (
        pageOptions.find(option => option.value === currentPage)
    ), [currentPage, pageOptions])

    const isFirstPage = currentPage === '1'
    const isLastPage = currentPage === lastPageNumber.toString()

    // event handlers
    const handlePageChange = useCallback((targetPage: number) => {
        const skip = (targetPage - 1) * pagination.take

        const newPagination = new PaginatedRequest()
        newPagination.skip = skip
        newPagination.take = pagination.take

        onPaginationChange(newPagination)
    }, [onPaginationChange, pagination.take])

    const handlePreviousButtonClick = useCallback(() => {
        const targetPage = parseInt(currentPage) - 1
        handlePageChange(targetPage)
    }, [currentPage, handlePageChange])

    const handleNextButtonClick = useCallback(() => {
        const targetPage = parseInt(currentPage) + 1
        handlePageChange(targetPage)
    }, [currentPage, handlePageChange])

    const handlePageOptionChange = useCallback((option: { value: string, label: string } | null) => {
        const targetPage = parseInt(option?.value ?? '1')
        handlePageChange(targetPage)
    }, [handlePageChange])

    return (
        <PaginationFooterContainer>
            <PreviousButtonContainer>
                <PrimaryButton
                    disabled={isFirstPage}
                    onClick={handlePreviousButtonClick}
                >
                    <ArrowLeftIcon
                        size={14}
                        color={AppColors.text.onDarkBackground}
                    />
                    Previous
                </PrimaryButton>
            </PreviousButtonContainer>
            <ControlsContainer>
                <span>
                    Page
                </span>
                <ReactSelect
                    options={pageOptions}
                    value={currentPageOption}
                    onChange={handlePageOptionChange}
                />
                <span>
                    of {lastPageNumber}
                </span>

            </ControlsContainer>
            <NextButtonContainer>
                <PrimaryButton
                    disabled={isLastPage}
                    onClick={handleNextButtonClick}
                >
                    Next
                    <ArrowRightIcon
                        size={14}
                        color={AppColors.text.onDarkBackground}
                    />
                </PrimaryButton>
            </NextButtonContainer>
        </PaginationFooterContainer>
    )
}

export const PaginationFooterLoadingState: FC = () => {
    return (
        <PaginationFooterContainer>
            <PreviousButtonContainer>
                <ButtonSkeleton />
            </PreviousButtonContainer>
            <ControlsContainer>
                <InputSkeleton />
            </ControlsContainer>
            <NextButtonContainer>
                <ButtonSkeleton />
            </NextButtonContainer>
        </PaginationFooterContainer>
    )
}