import { FC } from "react";
import { AppShellContainer } from "./app-shell.styled";
import { Footer } from "./subcomponents/footer/footer";
import { Header } from "./subcomponents/header/header";

type AppShellProps = {
    children: JSX.Element
}

export const AppShell: FC<AppShellProps> = ({
    children,
}) => {
    return (
        <AppShellContainer>
            <Header />
            {children}
            <Footer />
        </AppShellContainer>
    )
}