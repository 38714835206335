import { FC, useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { AppContext } from '../../../../modules/app/providers/app-provider/app-provider';

export const BookmarkRoute: FC = () => {
  // context
  const { onBookmarkRoute } = useContext(AppContext);

  // router hooks
  const location = useLocation();
  const navigate = useNavigate();

  // side effects
  useEffect(() => {
    onBookmarkRoute(location.pathname);
    navigate('/landing');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
