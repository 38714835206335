import { FC, useCallback, useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { SnackbarContext } from "../../../common/components/snackbar-provider/snackbar-provider";
import { SubmitAssessmentCalculating, SubmitAssessmentCalculatingLoadingState } from "../submit-assessment-calculating/submit-assessment-calculating";
import { useAssessment } from "./submit-assessment.hooks";
import { service } from "./submit-assessment.service";

type SubmitAssessmentProps = {
    assessmentId: string
}

export const SubmitAssessment: FC<SubmitAssessmentProps> = ({
    assessmentId,
}) => {

    // router hooks
    const navigate = useNavigate()

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // data hooks
    const { data: assessment, isLoading } = useAssessment(assessmentId)

    // imperative logic
    const submitAssessment = useCallback(async () => {
        try {
            await service.submitAssessment(assessmentId)

            navigate('/results')
            showSnackbar({
                type: 'success',
                title: 'Submitted Assessment',
                message: 'You can now view your results. Please reach out if you have any questions.'
            })
        } catch (err) {
            navigate('/')
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support.'
            })
        }
    }, [assessmentId, navigate, showSnackbar])

    // side effects
    useEffect(() => {
        if (assessment?.assessmentType !== 'client') return;
        submitAssessment()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assessment?.assessmentType])

    if (!assessment || isLoading || assessment.assessmentType === 'client') {
        return (
            <SubmitAssessmentCalculatingLoadingState />
        )
    }

    return (
        <SubmitAssessmentCalculating
            assessmentId={assessmentId}
        />
    )
}