import { GetAssessmentResult_Section } from "tech-health-assessment-sdk/dist";

export const sortSections = (sections: GetAssessmentResult_Section[]): GetAssessmentResult_Section[] => {
    const breakpoint = Math.floor(sections.length / 2) - 1;

    const sortedObject: Record<number, GetAssessmentResult_Section> = {};

    sections.forEach((section, index) => {
        let placementIndex: number;
        if (index === 0) {
            placementIndex = 0;
        } else if (index <= breakpoint) {
            placementIndex = index * 2;
        } else {
            const position = index - breakpoint;
            placementIndex = position * 2 - 1;
        }

        sortedObject[placementIndex] = section;
    })

    return Object.values(sortedObject)
}