import { FC, useCallback, useMemo } from "react";
import { GetAdminAssessmentsRequest, GetAdminUsersRequest } from "tech-health-assessment-sdk/dist";
import { BodyContainer } from "../../../../common/components/body-container/body-container";
import { usePagination } from "../../../../common/hooks/use-pagination/use-pagination";
import { AdminAccountCard, AdminAccountCardLoadingState } from "../../details-cards/admin-account-card/admin-account-card";
import { AdminAssessmentsGridCardLoadingState, AdminAssessmentsGridCard } from "../../grid-cards/admin-assessments-grid-card/admin-assessments-grid-card";
import { AdminUsersGridCardLoadingState, AdminUsersGridCard } from "../../grid-cards/admin-users-grid-card/admin-users-grid-card";
import { useAdminAccount, useAdminAccountAssessments, useAdminAccountUsers } from "./admin-account.hooks";

type AdminAccountProps = {
    accountId: string
}

export const AdminAccount: FC<AdminAccountProps> = ({
    accountId,
}) => {

    // pagination
    const [assessmentsPagination, setAssessmentsPagination] = usePagination()
    const [usersPagination, setUsersPagination] = usePagination()

    // constants
    const assessmentsRequest: GetAdminAssessmentsRequest = useMemo(() => ({
        accountId,
        ...assessmentsPagination,
    }), [accountId, assessmentsPagination])

    const usersRequest: GetAdminUsersRequest = useMemo(() => ({
        accountId,
        ...usersPagination,
    }), [accountId, usersPagination])
    
    // data hooks
    const { isLoading: accountLoading, data: account, refreshData: refreshAdminAccount } = useAdminAccount(accountId)
    const { isLoading: assessmentsLoading, data: assessmentsResult, refreshData: refreshAdminAccountAssessments } = useAdminAccountAssessments(assessmentsRequest)
    const { isLoading: usersLoading, data: usersResult, refreshData: refreshAdminAccountUsers } = useAdminAccountUsers(usersRequest)
    
    // data abstraction
    const assessments = assessmentsResult?.assessments;
    const users = usersResult?.users;

    // event handlers
    const handleRefresh = useCallback(() => {
        refreshAdminAccount()
        refreshAdminAccountAssessments()
        refreshAdminAccountUsers()
    }, [refreshAdminAccount, refreshAdminAccountAssessments, refreshAdminAccountUsers])

    return (
        <BodyContainer>
            {(accountLoading || !account) ? (
                <AdminAccountCardLoadingState />
            ) : (
                <AdminAccountCard
                    account={account}
                    onRefresh={handleRefresh}
                />
            )}
            {(assessmentsLoading || !assessments) ? (
                <AdminAssessmentsGridCardLoadingState />
            ) : (
                <AdminAssessmentsGridCard
                    assessments={assessments}
                    onRefresh={handleRefresh}
                    pagination={assessmentsPagination}
                    onPaginationChange={setAssessmentsPagination}
                    totalCount={assessmentsResult.totalCount}
                />
            )}
            {(usersLoading || !users) ? (
                <AdminUsersGridCardLoadingState />
            ) : (
                <AdminUsersGridCard
                    users={users}
                    accountId={account?.accountId ?? null}
                    pagination={usersPagination}
                    onPaginationChange={setUsersPagination}
                    totalCount={usersResult.totalCount}
                />
            )}
        </BodyContainer>
    )
}