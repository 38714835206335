import { FC } from "react";
import { CalloutCard } from "../../../../../../../../common/components/callout-card/callout-card";

export const SubmittedCalloutCard: FC = () => {
    return (
        <CalloutCard type="info">
            <h2>What will happen?</h2>
            <br />
            <span>If you proceed...</span>
            <ul>
                <li>The user will be able to access their assessment results.</li>
                <li>When the user signs in, they will be greeted with the "assessment results" screen.</li>
                <li>We <b>will</b> send the user an email to let them know that you made this change.</li>
                <li>This action is reversible - you can undo it at any time.</li>
            </ul>
        </CalloutCard>
    )
}