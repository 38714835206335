import styled from "@emotion/styled";
import { MOBILE_BREAKPOINT } from "../../constants/breakpoints/breakpoints";
import { AppColors } from "../../constants/colors/app-colors";
import { Gap, Padding } from "../sizes/sizes";

export const StandardCardHeaderContainer = styled.div({
    // padding
    paddingLeft: Padding.desktop.large,
    paddingTop: Padding.desktop.large,
    paddingRight: Padding.desktop.large,

    // breakpoints
    [MOBILE_BREAKPOINT]: {
        paddingLeft: Padding.mobile.normal,
        paddingTop: Padding.mobile.normal,
        paddingRight: Padding.mobile.normal,
    }
})

export const InnerContainer = styled.div({
    // layout
    display: 'grid',
    gridAutoFlow: 'column',
    gridTemplateColumns: '1fr',
    gridAutoColumns: 'max-content',

    // border
    borderBottom: `1px solid ${AppColors.border.default}`,

    // padding
    paddingBottom: Padding.desktop.normal,
})

export const TextContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    gap: Gap.desktop.small,
})

export const ActionsContainer = styled.div({
    display: 'grid',
    gridAutoFlow: 'column',
    gap: Gap.desktop.small,
    gridTemplateRows: 'max-content',
    gridAutoRows: 'max-content',
})