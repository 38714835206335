import _ from "lodash";
import { isNumber } from "lodash";
import { AccountTypeCode, AssessmentTypeCode, EmailTemplateCode, EmailTemplateTargetCode, UserTypeCode } from "tech-health-assessment-sdk/dist";
import { SAMPLE_ACCOUNT, SAMPLE_ASSESSMENT, SAMPLE_ENVIRONMENT, SAMPLE_SUPPORT_REQUEST, SAMPLE_USER } from "./html-preview.constants";

class HtmlPreviewService {
    public generateSampleContext({
        emailTemplateCode,
        emailTemplateTargetCode,
        targetUserTypeCode,
    }: {
        emailTemplateCode: EmailTemplateCode,
        emailTemplateTargetCode: EmailTemplateTargetCode,
        targetUserTypeCode: UserTypeCode | null,
    }): object {
        const context: Record<string, object> = {
            environment: SAMPLE_ENVIRONMENT,
        }

        const accountTypeCode: AccountTypeCode = emailTemplateCode.includes('lead') ? 'lead' : 'client'
        const assessmentTypeCode: AssessmentTypeCode = accountTypeCode;

        context['toUser'] = SAMPLE_USER({
            userTypeCode: targetUserTypeCode ?? 'internal', accountTypeCode,
        })

        switch (emailTemplateTargetCode) {
            case 'account':
                context['account'] = SAMPLE_ACCOUNT({ accountTypeCode })
                break;
            case 'assessment':
                context['assessment'] = SAMPLE_ASSESSMENT({ assessmentTypeCode, accountTypeCode })
                break;
            case 'support_request':
                context['supportRequest'] = SAMPLE_SUPPORT_REQUEST({ userTypeCode: targetUserTypeCode!, assessmentTypeCode, accountTypeCode })
                break;
            case 'user':
                context['user'] = SAMPLE_USER({ userTypeCode: targetUserTypeCode ?? 'internal', accountTypeCode })
                break;
        }

        return context;
    }

    public hydrateTemplate(template: string, context: object): string {
        return template.replace(/{{.+?}}/g, (substring) => {
            const contextFieldPath = substring.replace(/{|}/g, '');
            const value = _.get(context, contextFieldPath);
            if (!value) return 'UNRECOGNIZED_VARIABLE';
            if (typeof value === 'object') return 'UNRECOGNIZED_VARIABLE';
            if (typeof value === 'boolean') return value ? 'true' : 'false';
            if (isNumber(value) || typeof value === 'number')
              return (value as number).toString();
            if (typeof value === 'string') return value;
            return 'UNRECOGNIZED_VARIABLE';
          });
    }
}

export const service = new HtmlPreviewService()