import { GetAssessmentResult, SubmitAssessmentResult } from "tech-health-assessment-sdk/dist";
import { api } from "../../../../services/core-api-service";

class SubmitAssessmentService {
    public async getAssessment(assessmentId: string): Promise<GetAssessmentResult> {
        return api.assessments.getAssessment(assessmentId)
    }

    public async submitAssessment(assessmentId: string): Promise<SubmitAssessmentResult> {
        return api.assessments.submitAssessment(assessmentId)
    }
}

export const service = new SubmitAssessmentService()