import { IsEmail, IsNotEmpty, IsPhoneNumber, IsString, IsUUID } from "class-validator";
import { UserTypeCode } from "../../../../common/codes.types";

export class GetCompleteSignUpScreenDataResult {
    @IsString()
    @IsNotEmpty()
    firstName: string;
  
    @IsString()
    @IsNotEmpty()
    lastName: string;
  
    @IsString()
    @IsEmail()
    emailAddress: string;
  
    @IsString()
    @IsPhoneNumber()
    phoneNumber: string;
  
    @IsString()
    jobTitle: string;
  
    @IsString()
    companyName: string;

    @IsString()
    userType: GetCompleteSignUpScreenDataResult_UserType;
}

export class GetCompleteSignUpScreenDataResult_UserType {
    @IsUUID()
    userTypeId: string;

    @IsString()
    code: UserTypeCode;

    @IsString()
    label: string;
}