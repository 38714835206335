import { FC } from "react";
import { useParams } from "react-router";
import { AdminAssessment } from "../../../../../modules/admin/components/screens/admin-assessment/admin-assessment";

export const AdminAssessmentRoute: FC = () => {

    // router hooks
    const { assessment_id, section_id } = useParams() as { assessment_id: string, section_id?: string }

    return (
        <AdminAssessment
            assessmentId={assessment_id}
            currentSectionId={section_id ?? null}
        />
    )
}