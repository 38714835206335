import { FC, useCallback, useState } from "react";
import { GetCurrentAssessmentResultsResult_SupportRequest } from "tech-health-assessment-sdk/dist";
import { CalloutCardButton } from "../../../../../common/components/callout-card-button/callout-card-button";
import { CalloutCard, CalloutCardLoadingState } from "../../../../../common/components/callout-card/callout-card";
import { ButtonSkeleton, ParagraphSkeleton, TitleSkeleton } from "../../../../../common/skeletons/skeletons";
import { CardFooter, CardHeader, InnerContainer } from "./get-support-card.styled";
import { GetSupportDialog } from "../get-support-dialog/get-support-dialog";

type GetSupportCardProps = {
    assessmentId: string
    supportRequests: GetCurrentAssessmentResultsResult_SupportRequest[]
    onSubmit: () => void
}

export const GetSupportCard: FC<GetSupportCardProps> = ({
    assessmentId,
    supportRequests,
    onSubmit,
}) => {

    // state
    const [dialogOpen, setDialogOpen] = useState<boolean>(false)

    // constants
    const hasSubmittedSupportRequest = !!supportRequests.length
    const calloutCardType = hasSubmittedSupportRequest ? 'success' : 'info'
    const cardTitle = hasSubmittedSupportRequest
        ? 'Help is on the way!'
        : 'Recommendations & Executive Dashboards'
    const buttonText = hasSubmittedSupportRequest
        ? 'Request Submitted'
        : 'Dashboards'

    const cardBody = hasSubmittedSupportRequest ? (
        `Don't stop at a sample Health assessment, click below and let us show you our recommendations and Executive/Board Ready Dashboards.`
    ) : (
        `Don't stop at a sample Health assessment, click below and let us show you our recommendations and Executive/Board Ready Dashboards.`
    )

    // event handlers
    const handleGetSupportButtonClick = useCallback(() => {
        setDialogOpen(true)
    }, [])

    const handleDialogClose = useCallback(() => {
        setDialogOpen(false)
    }, [])

    return (
        <>
            <CalloutCard type={calloutCardType}>
                <InnerContainer>
                    <CardHeader>
                        <h2>{cardTitle}</h2>
                        <span>
                            {cardBody}
                        </span>
                    </CardHeader>
                    <CardFooter>
                        <CalloutCardButton
                            disabled={hasSubmittedSupportRequest}
                            onClick={handleGetSupportButtonClick}
                        >
                            {buttonText}
                        </CalloutCardButton>
                    </CardFooter>
                </InnerContainer>
            </CalloutCard>
            <GetSupportDialog
                assessmentId={assessmentId}
                onSubmit={onSubmit}
                isOpen={dialogOpen}
                onClose={handleDialogClose}
            />
        </>
    )
}

export const GetSupportCardLoadingState: FC = () => {
    return (
        <CalloutCardLoadingState>
            <InnerContainer>
                <CardHeader>
                    <TitleSkeleton background="dark" />
                    <ParagraphSkeleton background="dark" />
                </CardHeader>
                <CardFooter>
                    <ButtonSkeleton background="dark" />
                </CardFooter>
            </InnerContainer>
        </CalloutCardLoadingState>
    )
}