import { FC } from "react";
import { TableInstance } from 'react-table'
import { PaginatedRequest } from "tech-health-assessment-sdk/dist";
import { SpanSkeleton } from "../../../skeletons/skeletons";
import { Table, TableBody, TableCell, HeaderCell, TableHead, TableRow, GridContainer, TableContainer } from "./desktop-grid.styled";
import { PaginationFooter, PaginationFooterLoadingState } from "./subcomponents/pagination-footer/pagination-footer";

type DesktopGridProps<T extends object> = {
    tableInstance: TableInstance<T>
    totalCount: number
    pagination: PaginatedRequest
    onPaginationChange: (pagination: PaginatedRequest) => void
}

export const DesktopGrid = <T extends object>({
    tableInstance,
    totalCount,
    pagination,
    onPaginationChange,
}: DesktopGridProps<T>) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance

    return (
        <GridContainer>
            <TableContainer>
                <Table {...getTableProps()}>
                    <TableHead>
                        {headerGroups.map(headerGroup => (
                            <TableRow {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <HeaderCell {...column.getHeaderProps()}>
                                        {column.render('Header')}
                                    </HeaderCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableHead>
                    <TableBody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row)
                            return (
                                <TableRow {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <TableCell {...cell.getCellProps()} style={{
                                                width: cell.column.width,
                                                minWidth: cell.column.minWidth,
                                                maxWidth: cell.column.maxWidth,
                                            }}>
                                                {cell.render('Cell')}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationFooter
                pagination={pagination}
                onPaginationChange={onPaginationChange}
                totalCount={totalCount}
            />
        </GridContainer>
    )
}

export const DesktopGridLoadingState: FC = () => {
    return (
        <GridContainer>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <HeaderCell>
                                <SpanSkeleton fontSize={18} />
                            </HeaderCell>
                            <HeaderCell>
                                <SpanSkeleton fontSize={18} />
                            </HeaderCell>
                            <HeaderCell>
                                <SpanSkeleton fontSize={18} />
                            </HeaderCell>
                            <HeaderCell>
                                <SpanSkeleton fontSize={18} />
                            </HeaderCell>
                            <HeaderCell>
                                <SpanSkeleton fontSize={18} />
                            </HeaderCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                            <TableCell>
                                <SpanSkeleton fontSize={16} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <PaginationFooterLoadingState />
        </GridContainer>
    )
}