import { FC } from "react";
import { useParams } from "react-router";
import { AdminSection } from "../../../../../../../modules/admin/components/screens/admin-section/admin-section";

export const AdminSectionRoute: FC = () => {

    // router hooks
    const { section_id } = useParams() as { section_id: string }

    return (
        <AdminSection
            sectionId={section_id}
        />
    )
}