import { createContext, FC, RefObject, useRef } from "react"

type PortalProviderProps = {
    children: JSX.Element
}

export const PortalProvider: FC<PortalProviderProps> = ({
    children,
}) => {

    // refs
    const portalTargetRef = useRef<HTMLDivElement>(null)

    return (
        <PortalContext.Provider value={{
            portalTargetRef,
        }}>
            {children}
            <div ref={portalTargetRef} />
        </PortalContext.Provider>
    )
}

type PortalContextType = {
    portalTargetRef: RefObject<HTMLDivElement | null> | null
}

export const PortalContext = createContext<PortalContextType>({
    portalTargetRef: null,
})