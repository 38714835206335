import { FC, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BodyCard } from "../../../common/components/body-card/body-card";
import { BodyContainer } from "../../../common/components/body-container/body-container";
import { InlineLoading } from "../../../common/components/inline-loading/inline-loading";
import { ProgressBar, ProgressBarLoadingState } from "../../../common/components/progress-bar/progress-bar";
import { SnackbarContext } from "../../../common/components/snackbar-provider/snackbar-provider";
import { Title } from "../../../common/components/title/title";
import { AppColors } from "../../../common/constants/colors/app-colors";
import { SpanSkeleton, SubtitleSkeleton, TitleSkeleton } from "../../../common/skeletons/skeletons";
import { CalculatingResultsHero } from "./subcomponents/calculating-hero/calculating-results-hero";
import service from "./submit-assessment-calculating.services";
import { CalculatingDescriptionText, CalculatingDetailsContainer, SubmitAssessmentCalculatingContainer } from "./submit-assessment-calculating.styled";
import { getCurrentActivityText, ProgressAnimation } from "./submit-assessment-calculating.utils";

type SubmitAssessmentCalculatingProps = {
    assessmentId: string
}

export const SubmitAssessmentCalculating: FC<SubmitAssessmentCalculatingProps> = ({
    assessmentId,
}) => {

    // router hooks
    const navigate = useNavigate()

    // context
    const { showSnackbar } = useContext(SnackbarContext)

    // state
    const [progress, setProgress] = useState<number>(0)

    // constants
    const loadingText =
        progress === 0
        ? "Preparing..."
        : progress < 1
        ? `${getCurrentActivityText(progress)}... (${(progress * 100).toFixed(0)}%)`
        : "Finishing up"

    const handleComplete = useCallback(async () => {
        try {
            await service.submitAssessment(assessmentId)

            navigate('/results')
            showSnackbar({
                type: 'success',
                title: 'Submitted Assessment',
                message: 'You can now view your results. Please reach out if you have any questions.'
            })
        } catch (err) {
            navigate('/')
            showSnackbar({
                type: 'error',
                title: 'Something went wrong',
                message: 'If the issue persists, please contact support.'
            })
        }

    }, [assessmentId, navigate, showSnackbar])

    // side effects
    useEffect(() => {
        const timeout = setTimeout(async () => {
            setProgress(0.001)
            await new Promise(resolve => setTimeout(resolve, 500))
            const progressAnimation = new ProgressAnimation(100, 15000)
            progressAnimation.subscribe((newProgress, isComplete) => {
                setProgress(newProgress)
                if (isComplete) handleComplete()
            })
            progressAnimation.startAnimation()
        }, 1200);

        return () => {
            clearTimeout(timeout)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <BodyContainer>
            <BodyCard style={{ flex: 1, display: 'grid', }}>
                <SubmitAssessmentCalculatingContainer>
                    <CalculatingResultsHero />
                    <CalculatingDetailsContainer>
                        <Title>Hang on!</Title>
                        <CalculatingDescriptionText>
                            Calculating your Technology Health Assessment Results
                        </CalculatingDescriptionText>
                        <ProgressBar progress={progress} />
                        <InlineLoading color={AppColors.text.gray}>
                            {loadingText}
                        </InlineLoading>
                    </CalculatingDetailsContainer>
                </SubmitAssessmentCalculatingContainer>
            </BodyCard>
        </BodyContainer>
    )
}

export const SubmitAssessmentCalculatingLoadingState: FC = () => {
    return (
        <BodyContainer>
            <BodyCard style={{ flex: 1, display: 'grid', }}>
                <SubmitAssessmentCalculatingContainer>
                    <CalculatingResultsHero />
                    <CalculatingDetailsContainer>
                        <TitleSkeleton />
                        <SubtitleSkeleton />
                        <ProgressBarLoadingState />
                        <InlineLoading color={AppColors.text.gray}>
                            <SpanSkeleton fontSize={16} />
                        </InlineLoading>
                    </CalculatingDetailsContainer>
                </SubmitAssessmentCalculatingContainer>
            </BodyCard>
        </BodyContainer>
    )
}